import DIContainer from 'services/DIContainer';
import Mapper from './Mapper';
import { CreateOrderDocumentRequestDTO, OrderDocumentResponseDTO } from 'typings/dto/orderDocument';
import UserUtils from 'utils/models/UserUtils';
import { UserResponseDTO } from 'typings/dto/user';

export default class OrderDocumentMapper extends Mapper<OrderDocument, OrderDocumentResponseDTO, CreateOrderDocumentRequestDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        name: 'name',
        technicalName: 'technicalName',
        status: 'status',
        attachments: 'attachments',
        createdBy: 'createdBy',
      },
      null
    );
  }

  public responseDTOToModel(dto: OrderDocumentResponseDTO): OrderDocument {
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      attachments: dto.attachments ? dto.attachments.map(this.subEntitiesMappers.file.responseDTOToModel_temp) : [],
      createdBy: UserUtils.mapUserResponseByType(dto.createdBy as UserResponseDTO, this.getOuterMappers()),
    };
  }
}
