import { AppDispatch } from 'storage';
import slices from 'storage/slices';
import IPageActionsService from './IPageActionService';

export default class PageActionsServiceImpl implements IPageActionsService {
  constructor(private readonly sliceActions: typeof slices.page.actions, private readonly storageDispatch: AppDispatch) {}

  public initializePage = ({
    breadcrumbs = [],
    title = '',
    pageNumber = 1,
    menu = [],
    backPath,
    tabs = [],
    currentTab = '',
    useTabsBlock = true,
  }: Partial<PageState>) => {
    // Если backPath отсутствует, проставляет по последнему breadcrumb со ссылкой
    if (!backPath && breadcrumbs.length) {
      // prettier-ignore
      backPath = breadcrumbs.concat().reverse().find((bc) => Boolean(bc.href))?.href || ''; // findLast
    }
    this.storageDispatch(this.sliceActions.setAllProps({ breadcrumbs, title, pageNumber, menu, backPath, tabs, currentTab, useTabsBlock }));
  };

  public resetPage = () => {
    this.storageDispatch(this.sliceActions.resetPage());
  };
}
