import FrontendRoutesBase from './FrontendRoutesBase';
import { PATH_PARAM_DASHBOARD, PAGE_TYPE, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';

export default class PromotionsConfigRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public root = this.getDBPath(PATH_PARAM_DASHBOARD.promotionsConfig.root);

  public categories: CategoriesRoutes;
  public aggregation: AggregationRoutes;
  public variables: VariablesRoutes;

  constructor() {
    super();
    this.categories = new CategoriesRoutes(this);
    this.aggregation = new AggregationRoutes(this);
    this.variables = new VariablesRoutes(this);
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.promotionsConfig> = (_p, _permissions, translate) => {
    return [{ name: translate('menus.main.promotions'), href: this.getListPath() }];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.promotionsConfig, 'promotionName'> = (
    p,
    pm,
    t,
    n
  ) => {
    const breadcrumbs = this.getInfoBc(p, pm, t);
    breadcrumbs.push({ name: n.promotionName, href: this.getInfoPath(p) });
    return breadcrumbs;
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.promotionsConfig.root, PATH_PARAM_DASHBOARD.promotionsConfig.promotions, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.promotionsConfig> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.promotionsConfig.root,
      PATH_PARAM_DASHBOARD.promotionsConfig.promotions,
      params.promotionId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath = () =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.promotionsConfig.root,
      PATH_PARAM_DASHBOARD.promotionsConfig.promotions,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.promotionsConfig> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.promotionsConfig.root,
      PATH_PARAM_DASHBOARD.promotionsConfig.promotions,
      params.promotionId,
      PAGE_TYPE.edit
    );
}

class CategoriesRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: PromotionsConfigRoutes) {
    super();
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.promotionsConfig> = (_p, _permissions, translate) => {
    return [{ name: translate('pages.promotionsCategoriesList.title'), href: this.getListPath(_p) }];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.promotionsConfig, 'categoryName'> = (
    p,
    pm,
    t,
    n
  ) => {
    const breadcrumbs = this.getInfoBc(p, pm, t);
    breadcrumbs.push({ name: n.categoryName, href: this.getInfoPath(p) });
    return breadcrumbs;
  };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.promotionsConfig> = (_params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.promotionsConfig.root, PATH_PARAM_DASHBOARD.promotionsConfig.categories, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.promotionsConfig> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.promotionsConfig.root,
      PATH_PARAM_DASHBOARD.promotionsConfig.categories,
      params.categoriesId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.promotionsConfig> = (_params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.promotionsConfig.root,
      PATH_PARAM_DASHBOARD.promotionsConfig.categories,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.promotionsConfig> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.promotionsConfig.root,
      PATH_PARAM_DASHBOARD.promotionsConfig.categories,
      params.categoriesId,
      PAGE_TYPE.edit
    );
}

class AggregationRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: PromotionsConfigRoutes) {
    super();
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.promotionsConfig> = (_p, _permissions, translate) => {
    return [{ name: translate('pages.promotionsAggregationInfo.title'), href: this.getInfoPath() }];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.promotionsConfig, 'aggregationName'> = (
    p,
    pm,
    t,
    n
  ) => {
    const breadcrumbs = this.getInfoBc(p, pm, t);
    breadcrumbs.push({ name: n.aggregationName, href: this.getInfoPath() });
    return breadcrumbs;
  };

  getInfoBc = this.getInstanceBc;
  getInfoPath = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.promotionsConfig.root, PATH_PARAM_DASHBOARD.promotionsConfig.aggregation, PAGE_TYPE.info);

  getEditBc = this.getInstanceChildBc;
  getEditPath = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.promotionsConfig.root, PATH_PARAM_DASHBOARD.promotionsConfig.aggregation, PAGE_TYPE.edit);
}

class VariablesRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: PromotionsConfigRoutes) {
    super();
  }
  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.promotionsConfig> = (_p, _permissions, translate) => {
    return [{ name: translate('pages.promotionsVariablesEdit.title'), href: this.getListPath(_p) }];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.promotionsConfig, 'variableName'> = (
    p,
    pm,
    t,
    n
  ) => {
    const breadcrumbs = this.getInfoBc(p, pm, t);
    breadcrumbs.push({ name: n.variableName, href: this.getInfoPath(p) });
    return breadcrumbs;
  };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.promotionsConfig> = (_params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.promotionsConfig.root, PATH_PARAM_DASHBOARD.promotionsConfig.variables, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.promotionsConfig> = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.promotionsConfig.root, PATH_PARAM_DASHBOARD.promotionsConfig.variables, PAGE_TYPE.info);

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.promotionsConfig> = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.promotionsConfig.root, PATH_PARAM_DASHBOARD.promotionsConfig.variables, PAGE_TYPE.edit);
}
