import IAnalyticsService from './IAnalyticsService';

export default class AnalyticsServiceClarityImpl implements IAnalyticsService {
  sendData(userId: string): void {
    try {
      // @ts-ignore
      window.clarity('identify', userId);
    } catch (e) {
      console.error('clarity unexpected. UserId: ', userId);
    }
  }
}
