import { StandardTextFieldProps, TextField, InputAdornment } from '@mui/material';
import { INPUT_FULL_WIDTH_CLASS_NAME } from 'configs/layout';
import { FormikContextType } from 'formik';
import React from 'react';

export type TextFieldMemorizedProps = StandardTextFieldProps & {
  fieldName: string;
  InputProps?: any; // ?
  label?: string;
  formState: FormikContextType<any>;
  inline?: boolean;
  submitOnBlur?: boolean;
  startAdornment?: React.ReactElement;
};

function TextFieldMemorized({
  fieldName,
  children,
  label,
  formState,
  inline,
  fullWidth,
  helperText,
  submitOnBlur,
  startAdornment,
  className,
  onChange,
  ...rest
}: TextFieldMemorizedProps) {
  const { getFieldProps, touched, errors } = formState;
  const errorText = touched[fieldName] && errors[fieldName];
  const classNameFinal = inline ? 'inline-input' : fullWidth ? INPUT_FULL_WIDTH_CLASS_NAME : className;
  const fieldPropsFormState = getFieldProps(fieldName);

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    fieldPropsFormState.onBlur(e);
    formState.handleSubmit();
  };

  const InputProps = startAdornment
    ? {
        startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment>,
      }
    : rest.InputProps;

  return (
    <TextField
      fullWidth
      label={inline ? errorText : label}
      size={inline ? 'medium' : rest.size}
      variant={inline ? 'outlined' : rest.variant}
      className={classNameFinal}
      helperText={helperText || (!inline && errorText)}
      {...fieldPropsFormState}
      onBlur={submitOnBlur ? onBlurHandler : fieldPropsFormState.onBlur}
      onChange={onChange || fieldPropsFormState.onChange}
      {...rest}
      InputProps={InputProps}
      error={Boolean(errorText)}
    >
      {children}
    </TextField>
  );
}

export default React.memo(TextFieldMemorized, (prevProps, nextProps) => {
  const prevName = prevProps.fieldName;
  const nextName = nextProps.fieldName;

  return (
    prevProps.formState.values[prevName] === nextProps.formState.values[nextName] &&
    prevProps.formState.touched[prevName] === nextProps.formState.touched[nextName] &&
    prevProps.formState.errors[prevName] === nextProps.formState.errors[nextName] &&
    prevProps.disabled === nextProps.disabled
  );
});
