import { Theme, alpha } from '@mui/material/styles';
import TextField from './TextField';

// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme) {
  const isLight = theme.palette.mode === 'light';
  const boxShadow = `0 0 2px 0 ${alpha(isLight ? theme.palette.grey[500] : theme.palette.common.black, 0.2)}, ${theme.customShadows.z20}`;
  const textFieldConfig = TextField(theme);
  return {
    MuiAutocomplete: {
      styleOverrides: {
        // [IMPLEMENTATION_DATA] removed
        // popper: {
        //  minWidth: 260,
        //  maxWidth: 260,
        // },
        root: textFieldConfig.MuiTextField.styleOverrides.root,
        paper: {
          boxShadow,
        },
        listbox: {
          padding: theme.spacing(0, 1),
          '& .MuiAutocomplete-option': {
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0),
            borderRadius: theme.shape.borderRadius,
          },
        },
      },
    },
  };
}
