import { useContext } from 'react';
import { DIContext } from './_contexts/DIContext';

/**
 * Отдаёт инстансы всех сервисов в системе
 * @see DIContainer в /services
 */
export default function useDI() {
  return useContext(DIContext);
}
