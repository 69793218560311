import React, { useMemo, useState } from 'react';
import { Box, BoxProps, Stack, SxProps, Typography } from '@mui/material';
import SpoilerIconButton from 'components/ui/buttons/SpoilerIconButton';
import ModelUtils from 'utils/models/ModelUtils';
import { Theme } from '@mui/material/styles';
import OrderSessionServiceItemEditBlock from 'components/_dashboardPagesFeatures/order/add/session/services/add/OrderSessionServiceItemEditBlock';
import { CreateServiceOrderedRequestDTO } from 'typings/dto/serviceOrdered';
import IPermissionService from 'services/permission/IPermissionService';
import ILanguageService from 'services/language/ILanguageService';
import ServiceOrderedUtils from 'utils/models/ServiceOrderedUtils';

type Props = Omit<BoxProps, 'order'> & {
  index: number;
  prices: Price[];
  categoryName: string;
  orderSession: OrderContractSessionData | null;
  submitHandlerNoNavigate: (dto: CreateServiceOrderedRequestDTO) => any | Promise<any>;
  sx?: SxProps<Theme>;
  currency: ILanguageService.CurrencyCode;
  permissionConfig: IPermissionService.PermissionConfigs;
  closeAddBlock: VoidFunction;
  formId?: string;
  isOpened: boolean;
  setIsOpened: (number: number | null) => void;
  deleteHandler: (serviceId: string) => void;
};

function OrderSessionServiceCategoryGroup({
  categoryName,
  prices,
  sx,
  submitHandlerNoNavigate,
  closeAddBlock,
  orderSession,
  currency,
  permissionConfig,
  isOpened,
  setIsOpened,
  index,
  deleteHandler,
  ...rest
}: Props) {
  const [openedServiceId, setOpenedServiceId] = useState<string | null>(null);
  const totalQuantity = useMemo(() => {
    let quantity = 0;
    prices.forEach((price) => {
      const quantityForService = getTotalQuantity(price, orderSession);
      quantity = quantityForService ? quantityForService + quantity : quantity;
    });
    return quantity;
  }, [orderSession?.updatedAt.getTime(), orderSession?.id]);

  const duplicateAndOpen = (priceId: string | null) => {
    if (priceId != null) {
      const duplicatedPrice = ServiceOrderedUtils.duplicatePrice(prices, priceId);
      setOpenedServiceId(duplicatedPrice.id);
    }
  };

  return (
    <Box {...rest} sx={{ mt: 1.5, ...sx }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          border: (theme) => `1px solid ${theme.palette.grey[300]}`,
          borderRadius: 1,
          overflow: { xs: 'auto', sm: 'inherit' },
          py: 2,
          pl: 1.6,
          pr: 1,
        }}
      >
        <Stack direction="row" spacing={1}>
          {totalQuantity > 0 && (
            <Typography variant="body1" color="text.primary">
              {categoryName} ({totalQuantity})
            </Typography>
          )}
          {totalQuantity === 0 && (
            <Typography variant="body1" color="text.secondary">
              {categoryName}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" spacing={1}>
          <SpoilerIconButton isOpened={isOpened} onClick={() => setIsOpened(isOpened ? null : index)} />
        </Stack>
      </Stack>

      {isOpened && (
        <Stack sx={{ mt: 2.5, pt: 0 }} spacing={1}>
          {prices.map((price) => (
            <Stack spacing={2} key={price.id}>
              <OrderSessionServiceItemEditBlock
                orderSession={orderSession}
                currency={currency}
                closeAddBlock={closeAddBlock}
                submitHandlerNoNavigate={submitHandlerNoNavigate}
                permissionConfig={permissionConfig}
                price={price}
                isOpened={openedServiceId === price.id}
                setOpenedBlockId={setOpenedServiceId}
                deleteHandler={deleteHandler}
                duplicateAndOpenBlockId={duplicateAndOpen}
                totalQuantity={totalQuantity}
              />
            </Stack>
          ))}
        </Stack>
      )}
    </Box>
  );
}

export default React.memo(
  OrderSessionServiceCategoryGroup,
  (pp, np) =>
    ModelUtils.checkArraysEquality(pp.prices, np.prices) &&
    pp.isOpened === np.isOpened &&
    ModelUtils.checkEquality(pp.orderSession, np.orderSession)
);

function getTotalQuantity(price: Price, orderSession: OrderContractSessionData | null): number | null {
  if (!orderSession) return null;
  return orderSession.services
    .filter((service) => service.catalogId === price.service.id)
    .map((p) => p.quantity)
    .reduce((p, c) => c + p, 0);
}
