import { Theme } from '@mui/material';
import ILanguageService from 'services/language/ILanguageService';
import IPermissionService from 'services/permission/IPermissionService';
import { CONTRACT_STATUS } from 'typings/models/contract.enum';
import FunctionUtils from 'utils/Function';

export default class ContractUtils {
  public static getPermissionConfigs = (contract: ContractSP): IPermissionService.PermissionConfigs => {
    return { canCreateCustomServices: contract.pricelist.settings.allowAddCustomServices };
  };

  public static getStatusSPContractStr = (contract: ContractSP, languageService: ILanguageService): string => {
    switch (contract.status) {
      case CONTRACT_STATUS.active:
      case CONTRACT_STATUS.new: {
        return languageService.translate('entities.contract.statuses.' + contract.status);
      }
      case CONTRACT_STATUS.suspended: {
        if (contract.suspendedByTenant && contract.suspendedByServiceProvider) {
          return languageService.translate('entities.contract.statuses.SUSPENDED_BY_BOTH');
        } else if (contract.suspendedByTenant) {
          return languageService.translate('entities.contract.statuses.SUSPENDED_BY_ENTERPRISE');
        } else {
          return languageService.translate('entities.contract.statuses.SUSPENDED_BY_SP');
        }
      }
      default: {
        FunctionUtils.exhaustiveCheck(contract.status);
        return '';
      }
    }
  };

  public static getStatusPOContractStr = (contract: ContractPO, languageService: ILanguageService): string => {
    switch (contract.status) {
      case CONTRACT_STATUS.active:
      case CONTRACT_STATUS.new: {
        return languageService.translate('entities.contract.statuses.' + contract.status);
      }
      case CONTRACT_STATUS.suspended: {
        if (contract.suspendedByTenant && contract.suspendedByEnterprise) {
          return languageService.translate('entities.contract.statuses.SUSPENDED_BY_BOTH');
        } else if (contract.suspendedByTenant) {
          return languageService.translate('entities.contract.statuses.SUSPENDED_BY_PO');
        } else {
          return languageService.translate('entities.contract.statuses.SUSPENDED_BY_ENTERPRISE');
        }
      }
      default: {
        FunctionUtils.exhaustiveCheck(contract.status);
        return '';
      }
    }
  };

  public static getStatusColor = (contract: ContractSP | ContractPO, theme: Theme): string => {
    switch (contract.status) {
      case CONTRACT_STATUS.active: {
        return theme.palette.primary.main;
      }
      case CONTRACT_STATUS.new: {
        return theme.palette.warning.main;
      }
      case CONTRACT_STATUS.suspended: {
        return theme.palette.text.secondary;
      }
      default: {
        FunctionUtils.exhaustiveCheck(contract.status);
        return theme.palette.text.secondary;
      }
    }
  };
}
