import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import {
  CreateOrderViewRequestDTO,
  OrderViewResponseDTO,
  OrderViewsPriorityUpdateRequestDTO,
  OrderViewUpdatePartialRequestDTO,
} from 'typings/dto/orderView';
import IOrderViewActionsService from './IOrderViewActionsService';

export default class OrderViewActionsServiceImpl
  extends ModelActionsService<OrderView, CreateOrderViewRequestDTO, OrderViewResponseDTO>
  implements IOrderViewActionsService
{
  /** @throws `BackendResponseError` */
  public updatePriority = (dto: OrderViewsPriorityUpdateRequestDTO): Promise<void> => {
    const path = PATH_BACKEND.orderView.priority;
    return this.entityApiService.patchWithCredentials<void>(path, dto);
  };

  public updatePartial = (dto: OrderViewUpdatePartialRequestDTO): Promise<void> => {
    const path = PATH_BACKEND.orderView.root;
    return this.entityApiService.patchWithCredentials<void>(path, dto);
  };

  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return super.getDefaultSortFilter(filter, 'createdAt');
  };
}
