type Props = {
  children: React.ReactNode;
  disabled: boolean;
};

export default function Fieldset({ children, disabled }: Props) {
  return (
    <fieldset style={{ border: 'none' }} disabled={disabled}>
      {children}
    </fieldset>
  );
}
