import FrontendRoutesBase from './FrontendRoutesBase';
import { PATH_PARAM_DASHBOARD, PAGE_TYPE, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';

export default class PlatformOperatorRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public root = this.getDBPath(PATH_PARAM_DASHBOARD.platformOperatorAccount.root);

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.platformOperatorAccount> = (_p, permissions, translate) => {
    return [{ name: translate('menus.main.platformOperators'), href: this.getListPath() }];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.platformOperatorAccount, 'platformOperatorName'> =
    (p, pm, t, n) => {
      const breadcrumbs = this.getInfoBc(p, pm, t);
      breadcrumbs.push({ name: n.platformOperatorName, href: this.getInfoPath(p) });
      return breadcrumbs;
    };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.platformOperatorAccount.root, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.platformOperatorAccount> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.platformOperatorAccount.root, params.platformOperatorId, PAGE_TYPE.info);

  getAddBc = this.getInstanceBc;
  getAddPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.platformOperatorAccount.root, PAGE_TYPE.new, PAGE_TYPE.add);

  getEmployeeListBc = this.getEmptyBreadcrumbs;
  getEmployeeListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.platformOperatorAccount> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.platformOperatorAccount.root,
      params.platformOperatorId,
      PATH_PARAM_DASHBOARD.platformOperatorAccount.employees,
      PAGE_TYPE.list
    );
}
