import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import IEntityApiService from 'services/entityApi/IEntityApiService';
import { GetFileResponseDTO, UploadFileResponseDTO } from 'typings/dto/files';
import FileUtils from 'utils/File';
import IFileService from './IFileService';
import IHttpService from 'services/http/IHttpService';

/** Описания методов в интерфейсе */
export default class FileServiceImpl implements IFileService {
  private readonly errorImg = '/images/ui/previewFailed.png';
  private readonly webService: IEntityApiService;
  private readonly httpService: IHttpService;

  constructor(webService: IEntityApiService, httpService: IHttpService) {
    this.webService = webService;
    this.httpService = httpService;
  }

  public uploadOneFile(file: File, fileName?: string): Promise<UploadFileResponseDTO> {
    return this.uploadOne(file, IFileService.FILE_TYPE.file, fileName === undefined ? undefined : new Map([['fileName', fileName]]));
  }

  public uploadOneImage(file: File, fileName?: string): Promise<UploadFileResponseDTO> {
    return this.uploadOne(file, IFileService.FILE_TYPE.image, fileName === undefined ? undefined : new Map([['fileName', fileName]]));
  }

  public uploadOneAvatar(file: File, fileName?: string): Promise<UploadFileResponseDTO> {
    return this.uploadOne(file, IFileService.FILE_TYPE.avatar, fileName === undefined ? undefined : new Map([['fileName', fileName]]));
  }

  public uploadOneFileAnonymously(file: File, orderId: string, fileName?: string): Promise<UploadFileResponseDTO> {
    const attr = new Map([['orderId', orderId]]);
    if (fileName) {
      attr.set('fileName', fileName);
    }
    return this.uploadOne(file, IFileService.FILE_TYPE.file, attr, PATH_BACKEND.file.anonymously);
  }

  /** Используется multipart/form-data */
  private uploadOne = (
    file: File,
    FILE_TYPE: IFileService.FILE_TYPE,
    attr?: Map<string, string>,
    url?: string
  ): Promise<UploadFileResponseDTO> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.set('filetype', FILE_TYPE);
    formData.set('filename', file.name);
    if (attr) {
      attr.delete('file');
      attr.delete('filetype');
      attr.forEach((v, k) => formData.set(k, v));
    }
    return this.webService.postMultipart(url || PATH_BACKEND.file.root, formData);
  };

  public getActualFileData = (dbFileData: IFileService.File_): Promise<IFileService.File_> => {
    return this.webService
      .getWithCredentials<GetFileResponseDTO>(`${PATH_BACKEND.file.root}/${dbFileData.id}`)
      .then((responseDTO) => {
        return { ...dbFileData, ...responseDTO, isHydrated: true };
      })
      .catch((error) => {
        console.error(error.message);
        return { ...dbFileData, url: this.errorImg, isHydrated: true };
      });
  };

  public getFileBlob = async (dbFileData: IFileService.File_): Promise<Blob> => {
    const response = await this.webService.getRawWithCredentials(`${PATH_BACKEND.file.root}/${dbFileData.id}/stream`);
    return this.httpService.parseResponseBlob(response);
  };

  public getFileForEditor = (dbFileData: IFileService.File_): Promise<IFileService.FileForUploaderWithData> => {
    return this.getActualFileData(dbFileData).then(this.urlToFile);
  };

  public urlToFile = async (dbFileData: IFileService.File_): Promise<IFileService.FileForUploaderWithData> => {
    try {
      const blob = await fetch(dbFileData.url)
        .then((r) => {
          if (r.ok) return r.blob();
          else return this.getErrorImage();
        })
        .catch(this.getErrorImage);
      const format = FileUtils.getFormat(dbFileData.name);
      const type = format === 'image' ? 'image/' + FileUtils.getType(dbFileData.name) : blob.type;
      const file = new File([blob], dbFileData.name, { type });

      return FileUtils.prepareOldFileForUploader(file, dbFileData);
    } catch (error) {
      console.error(JSON.stringify(error));
      throw new Error('Unable to download preview text');
    }
  };

  public deleteOne = (id: string) => this.webService.deleteWithCredentials(`${PATH_BACKEND.file.root}/${id}`);

  public deleteMany = (ids: string[]) => {
    if (ids.length) {
      return Promise.all(ids.map((id) => this.deleteOne(id)));
    } else {
      return Promise.resolve();
    }
  };

  // Utils ------------------------------------------------------

  private getErrorImage = () => fetch(this.errorImg).then((r) => r.blob());
}
