import { CreateUserSuperadminRequestDTO, UserSuperadminResponseDTO } from 'typings/dto/userSuperadmin';
import ModelActionsService from '../ModelActionsService';
import IUserSuperadminActionsService from './IUserSuperadminActionsService';
import { PATH_BACKEND } from 'configs/routes/pathsBackend';

export default class UserSuperadminActionsServiceImpl
  extends ModelActionsService<UserSuperadmin, CreateUserSuperadminRequestDTO, UserSuperadminResponseDTO>
  implements IUserSuperadminActionsService
{
  /** @throws `BackendResponseError` */
  public loginFromUser = (userId: string): Promise<void> => {
    return this.entityApiService.postWithCredentials(PATH_BACKEND.root + '/auth/update-current-session', userId);
  };
}
