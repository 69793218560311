import { TableCell, TableRow, Typography } from '@mui/material';
import { TABLE_ROW_SELECTABLE_CLASS_NAME } from 'configs/layout';

export default function RecommendationRuleListItem({
  rule,
  index,
  openPopupHandler,
  parentModelPath,
  configError,
}: {
  rule: RecommendationRule;
  index: number;
  openPopupHandler: (index: number) => void;
  parentModelPath: string;
  configError: Record<string, string>;
}) {
  const modelPath = parentModelPath + '[' + index + ']';
  const isValidationError = !rule.serviceItem.id || !rule.serviceCategory.id;
  const isServiceItemNameError = configError[modelPath + '.serviceItem.name'];
  const isServiceCategoryNameError = configError[modelPath + '.serviceCategory.name'];
  const isRuleError = configError[modelPath + '.ruleExpression'];
  const isQuantityRuleError = configError[modelPath + '.quantityRuleExpression'];

  return (
    <TableRow className={TABLE_ROW_SELECTABLE_CLASS_NAME} onClick={() => openPopupHandler(index)}>
      <TableCell
        sx={
          isServiceItemNameError
            ? { background: (theme) => theme.palette.error.light }
            : isValidationError
            ? { background: (theme) => theme.palette.warning.light }
            : null
        }
      >
        <Typography variant="body2" component="span">
          {rule.serviceItem.name}
        </Typography>
      </TableCell>
      <TableCell
        sx={
          isServiceCategoryNameError
            ? { background: (theme) => theme.palette.error.light }
            : isValidationError
            ? { background: (theme) => theme.palette.warning.light }
            : null
        }
      >
        <Typography variant="body2" component="span">
          {rule.serviceCategory.name}
        </Typography>
      </TableCell>
      <TableCell
        sx={
          isRuleError
            ? { background: (theme) => theme.palette.error.light }
            : isValidationError
            ? { background: (theme) => theme.palette.warning.light }
            : null
        }
      >
        <Typography variant="body2" component="span">
          {rule.ruleExpression}
        </Typography>
      </TableCell>
      <TableCell
        sx={
          isQuantityRuleError
            ? { background: (theme) => theme.palette.error.light }
            : isValidationError
            ? { background: (theme) => theme.palette.warning.light }
            : null
        }
      >
        <Typography variant="body2" component="span">
          {rule.quantityRuleExpression}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
