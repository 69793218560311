import EntityUtils from 'utils/Entity';

export default class ModelUtils extends EntityUtils {
  /** Готовый сортировщик по дате по убыванию для встроенной функции sort */
  public static sortByDateDescendingComparator(a: Model, b: Model) {
    return b.createdAt.getTime() - a.createdAt.getTime();
  }

  /** Готовый сортировщик по дате по возрастанию для встроенной функции sort */
  public static sortByDateAscendingComparator(a: Model, b: Model) {
    return a.createdAt.getTime() - b.createdAt.getTime();
  }

  /** Проверяет модели на равенство по id и дате */
  public static checkEquality(oldValue: Model | null | undefined, newValue: Model | null | undefined) {
    return oldValue?.id === newValue?.id && oldValue?.updatedAtNumber === newValue?.updatedAtNumber; // oldValue?.updatedAt.getTime() === newValue?.updatedAt.getTime();
  }

  /** @see checkEquality */
  public static checkArraysEquality(oldValues: Model[], newValues: Model[]) {
    if (oldValues.length !== newValues.length) {
      return false;
    }

    return oldValues.every((value, index) => this.checkEquality(value, newValues[index]));
  }

  // public static checkModelArraysHaveSameModels(oldValues: Model[], newValues: Model[]) {
  //   if (oldValues.length !== newValues.length) {
  //     return false;
  //   }

  //   const oldValuesSorted = oldValues.slice(0).sort(this.sortAlphabeticallyComparator);
  //   return newValues
  //     .slice(0)
  //     .sort(this.sortAlphabeticallyComparator)
  //     .every((value, index) => this.checkModelsEqual(value, oldValuesSorted[index]));
  // }
}
