import { PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { GetPriceAnyRequestListDTO, PriceAnyRequestDTO, PriceAnyResponseDTO, UpdatePriceRequestDTO } from 'typings/dto/price';
import IPricePlatformOperatorActionsService from './IPricePlatformOperatorActionService';

export default class PricePlatformOperatorActionsServiceImpl
  extends ModelActionsService<Price.PlatformOperator, PriceAnyRequestDTO, PriceAnyResponseDTO.PlatformOperator>
  implements IPricePlatformOperatorActionsService
{
  /** @throws `BackendResponseError` */
  public async getList(dto: GetPriceAnyRequestListDTO, filter?: LocationSearchObject, customPath?: string): Promise<void> {
    const path = customPath ? customPath : this.getModelApiRootPath(dto) + '/' + dto.pricelistId + '/' + PATH_BACKEND_PART.price.priceItem;
    super.getList(dto, filter, path);
  }

  /** @throws `BackendResponseError` */
  public updateServicePrice(dto: UpdatePriceRequestDTO.PlatformOperator): Promise<void> {
    const path = this.getModelApiRootPath(dto) + '/' + dto.pricelistId + '/' + PATH_BACKEND_PART.price.priceItem;
    return this.entityApiService.patchWithCredentials<void>(path, dto);
  }

  /** @throws `BackendResponseError` */
  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return super.getDefaultSortFilter(filter, 'createdAt');
  };
}
