import { DateIcon, FolderIcon, OrderLabelIcon, OrderManagersIcon, OrderNumberFieldIcon } from 'components/ui/icons';
import React from 'react';
import ILanguageService from 'services/language/ILanguageService';
import palette from 'theme/palette';
import { BOOLEAN_FILTER } from 'typings/common/commonEnums';
import { OrderRewardSettingsDTO, ServicesTechniciansRewardsResponseDTO } from 'typings/dto/order';
import {
  ORDER_CUSTOM_FIELD_TYPE,
  ORDER_OPERATOR_TYPE,
  ORDER_SERVICE_PROVIDER_ASSIGN_ALGORITHM,
  ORDER_SERVICE_PROVIDER_ASSIGN_TYPE,
  ORDER_SERVICE_PROVIDER_TYPE,
  ORDER_STATUS_NAME,
  ORDER_TECHNICIANS_ASSIGN_STATUS,
} from 'typings/models/order/order.enum';
import { ORDER_FILTER } from 'typings/models/order/orderFilter.enum';
import { DYNAMIC_FIELD_VALUE_TYPE } from 'typings/subEntities/dynamicField.enum';
import { TENANT_TYPE } from 'typings/subEntities/tenant.enum';
import ModelUtils from './ModelUtils';
import { ORDER_TYPE_TECHNICAL_NAME } from 'typings/models/order/orderType.enum';

export default class OrderUtils {
  private static statusToColorMap: Record<ORDER_STATUS_NAME, string> = {
    COMPLETED: palette.light.success.dark,
    CANCELLED: palette.light.error.dark,
    IN_PROGRESS: palette.light.warning.dark,
    IN_PROGRESS_PROVIDER_IS_SET: palette.light.warning.dark,
    IN_PROGRESS_PROVIDER_IS_SET_WORK_IN_PROGRESS: palette.light.warning.dark,
    IN_PROGRESS_PROVIDER_NOT_SET: palette.light.warning.dark,
    WORK_FINISHED: palette.light.grey[600],
    PAUSED: palette.light.info.dark,
    CALCULATION: palette.light.warning.dark,
    CONTRACT_NOT_SIGNED: palette.light.warning.dark,
    CONTRACT_SIGNED: palette.light.warning.dark,
  };

  public static getStatusColor = (status: ORDER_STATUS_NAME): string => {
    return this.statusToColorMap[status] || 'black';
  };

  private static typeToColorMap: Record<ORDER_TYPE_TECHNICAL_NAME, Record<'backgroundColor' | 'borderColor' | 'textColor', string>> = {
    default: {
      backgroundColor: '#1890FF29',
      borderColor: '#33B8F1',
      textColor: '#0C53B7'
    },
    measure: {
      backgroundColor: '#54D62C3D',
      borderColor: '#60D681',
      textColor: '#229A16'
    },
    reclamation: {
      backgroundColor: '#FFC1073D',
      borderColor: '#D49707',
      textColor: '#B78103'
    },
    warranty: {
      backgroundColor: '#E5E1FF',
      borderColor: '#8560D6',
      textColor: '#5D4988'
    },
  }

  public static getTypeColor(orderType: ORDER_TYPE_TECHNICAL_NAME) {
    return this.typeToColorMap[orderType]
  }

  // Костыли со статусом заказа
  public static getStatusNameStr = (order: Order, languageService: ILanguageService, isClientPage?: boolean): string => {
    const { technicalName, isWorkInProgress } = order.status;
    const { translate } = languageService;
    switch (technicalName) {
      case ORDER_STATUS_NAME.inProgress:
      case ORDER_STATUS_NAME.providerNotSet:
      case ORDER_STATUS_NAME.providerSet:
      case ORDER_STATUS_NAME.inProgressProviderSet: {
        if (order.serviceProviderInfo.technicianAssignStatus !== ORDER_TECHNICIANS_ASSIGN_STATUS.full) {
          return isClientPage
            ? translate('pages.clientOrder.status.IN_PROGRESS_PROVIDER_NOT_SET')
            : translate('entities.order.status.IN_PROGRESS_PROVIDER_NOT_SET');
        }
        if (isWorkInProgress) {
          return isClientPage
            ? translate('pages.clientOrder.status.IN_PROGRESS_PROVIDER_IS_SET_WORK_IN_PROGRESS')
            : translate('entities.order.status.IN_PROGRESS_PROVIDER_IS_SET_WORK_IN_PROGRESS');
        } else {
          return isClientPage
            ? translate('pages.clientOrder.status.IN_PROGRESS_PROVIDER_IS_SET')
            : translate('entities.order.status.IN_PROGRESS_PROVIDER_IS_SET');
        }
      }
      case ORDER_STATUS_NAME.completed:
      case ORDER_STATUS_NAME.workFinished:
      case ORDER_STATUS_NAME.paused:
      case ORDER_STATUS_NAME.calculation:
      case ORDER_STATUS_NAME.contractNotSigned:
      case ORDER_STATUS_NAME.contractSigned:
      case ORDER_STATUS_NAME.cancelled: {
        return isClientPage ? translate('pages.clientOrder.status.' + technicalName) : translate('entities.order.status.' + technicalName);
      }
    }
  };

  /** При отображении лейбла таймзоны в карточке заказа мы проверяем, отображены ли поля с датой */
  public static showOrderZoneLabel = (order: Order, hiddenFields: OrderView.HiddenFields): boolean => {
    // Пока только одно поле
    return Boolean(order.visitDateIntervals.length) && !hiddenFields.visitDateIntervals;
  };

  /** @deprecated похоже не нужно, т.к. везде разные статусы чекать надо */
  public static isActive = (order: Order) => {
    return (
      order.status.technicalName !== ORDER_STATUS_NAME.cancelled &&
      order.status.technicalName !== ORDER_STATUS_NAME.workFinished &&
      order.status.technicalName !== ORDER_STATUS_NAME.completed
    );
  };

  public static isNotCancelledAndCompleted = (order: Order) => {
    return order.status.technicalName !== ORDER_STATUS_NAME.cancelled && order.status.technicalName !== ORDER_STATUS_NAME.completed;
  };

  // Custom fields -------------------------------------------------------------------------------

  public static getCustomFieldIcon = (type: ORDER_CUSTOM_FIELD_TYPE): React.FC => {
    switch (type) {
      case ORDER_CUSTOM_FIELD_TYPE.file: {
        return FolderIcon;
      }
      case ORDER_CUSTOM_FIELD_TYPE.date: {
        return DateIcon;
      }
      case ORDER_CUSTOM_FIELD_TYPE.number: {
        return OrderNumberFieldIcon;
      }
      case ORDER_CUSTOM_FIELD_TYPE.text: {
        return OrderLabelIcon;
      }
      case ORDER_CUSTOM_FIELD_TYPE.select_options:
      case ORDER_CUSTOM_FIELD_TYPE.boolean_radiogroup: {
        return OrderLabelIcon;
      }
      case ORDER_CUSTOM_FIELD_TYPE.employee: {
        return OrderManagersIcon;
      }
    }
  };

  public static getCustomFieldBackendFilterName = (customField: Order.CustomField): string => {
    const baseStr = `customFields.${customField.technicalName}.dynamicFieldSettings.value`;
    switch (customField.dynamicField.valueType) {
      case DYNAMIC_FIELD_VALUE_TYPE.idWithName:
      case DYNAMIC_FIELD_VALUE_TYPE.user: {
        return baseStr + '.id';
      }
      case DYNAMIC_FIELD_VALUE_TYPE.string:
      case DYNAMIC_FIELD_VALUE_TYPE.enum: {
        return baseStr;
      }
      case DYNAMIC_FIELD_VALUE_TYPE.technicalNameWithName: {
        return baseStr + '.technicalName';
      }
      default: {
        return baseStr;
      }
    }
  };

  public static getCustomFieldTenantIdBackendFilterName = (customField: Order.CustomField): string => {
    return `customFields.${customField.technicalName}.tenant.id`;
  };

  // Filters ------------------------------------------------------------------------------------

  public static getBooleanFilterOptions = (languageService: ILanguageService): EntityWithName[] => {
    return [
      { id: BOOLEAN_FILTER.true, name: languageService.translate('common.booleanFilter.true') },
      { id: BOOLEAN_FILTER.false, name: languageService.translate('common.booleanFilter.false') },
      { id: BOOLEAN_FILTER.undefined, name: languageService.translate('common.booleanFilter.undefined') },
    ];
  };

  public static prepareFilterRawValue = (lso: LocationSearchObject, paramsNames: ORDER_FILTER[]): string => {
    return paramsNames.reduce((acc, paramName, index) => acc + (lso[paramName]?.rawValue || index), '');
  };

  // Нужно посчитать поровну так, чтобы не было дробных процентов (последний из технишенов будет иметь больше)
  public static countDefaultRewardSettings = (dto: OrderRewardSettingsDTO, technicians: EntityWithName[]): Order.RewardSettings => {
    let rewardDistribution: NonNullable<typeof dto.rewardDistribution>;
    if (dto.useSpecificDistribution && dto.rewardDistribution) {
      rewardDistribution = dto.rewardDistribution;
    } else if (technicians.length) {
      // Массив должен быть отсортирован обязательно по имени по возрастанию, чтобы и на фронте и на беке всегда знать, кому пойдёт больший процент
      const techniciansSorted = [...technicians].sort(ModelUtils.sortByNameAscendingComparator);
      rewardDistribution = {};
      const oneValue = Math.floor(100 / techniciansSorted.length);
      let lastValue = 100;
      for (let i = 0; i < techniciansSorted.length - 1; i++) {
        const techId = techniciansSorted[i].id;
        rewardDistribution[techId] = { value: oneValue };
        lastValue -= oneValue;
      }
      const lastId = techniciansSorted[techniciansSorted.length - 1].id;
      rewardDistribution[lastId] = { value: lastValue };
    } else {
      rewardDistribution = {};
    }

    return {
      useSpecificDistribution: dto.useSpecificDistribution,
      rewardDistribution,
    };
  };

  public static countRewardValuesSettings = (
    dto: ServicesTechniciansRewardsResponseDTO,
    services: ServiceOrdered[]
  ): Order.RewardValues => {
    // Конкретные значения считать не надо на основе ранее вычисленных процентов, нужные приходят с бека, нужно только их собрать воедино
    const totalReward: Order.RewardValues['totalReward'] = {};

    services.forEach((service) => {
      const techToServiceRewardMap = dto[service.id] || {};
      Object.keys(techToServiceRewardMap).forEach((technicianId) => {
        const currentValue = totalReward[technicianId] || 0;
        const newValue = techToServiceRewardMap[technicianId].value || 0;
        totalReward[technicianId] = newValue * service.quantity + currentValue;
      });
    });

    return {
      servicesRewards: dto,
      totalReward,
    };
  };

  /** Блок с установщиками показывается если: 1) Свой заказ и назначены свои установщики. 2) Для СП если он назначен на заказ. 3) Для ПО если он назначен на заказ и не передал его */
  public static areTechniciansShown = (order: Order | null, currentUser: User | null): boolean => {
    const amIOwnerOfOrder = order?.owner.id === currentUser?.tenant.id;
    const isAssignTechnician =
      order?.serviceProviderInfo.assignType !== ORDER_SERVICE_PROVIDER_ASSIGN_TYPE.none &&
      order?.serviceProviderInfo.providerType === ORDER_SERVICE_PROVIDER_TYPE.internalTechnician;
    const isAssignPO = order?.serviceProviderInfo.operatorType === ORDER_OPERATOR_TYPE.platformOperator;
    const isAssignSP = order?.serviceProviderInfo.providerType === ORDER_SERVICE_PROVIDER_TYPE.serviceProvider;
    const isOrderOffered =
      order?.serviceProviderInfo.assignAlgorithm === ORDER_SERVICE_PROVIDER_ASSIGN_ALGORITHM.internalTeamConcurrent ||
      order?.serviceProviderInfo.assignAlgorithm === ORDER_SERVICE_PROVIDER_ASSIGN_ALGORITHM.internalTechnicianConcurrent ||
      order?.serviceProviderInfo.assignAlgorithm === ORDER_SERVICE_PROVIDER_ASSIGN_ALGORITHM.spConcurrent;

    const areTechniciansShown =
      (isAssignTechnician && amIOwnerOfOrder && !isAssignPO) ||
      (isAssignSP && !amIOwnerOfOrder && currentUser?.tenant.type === TENANT_TYPE.serviceProvider) ||
      (isAssignPO && !amIOwnerOfOrder && !isAssignSP && !isOrderOffered);

    return areTechniciansShown;
  };
}
