import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { CreateOrderReportRequestDTO, OrderReportResponseDTO } from 'typings/dto/orderReport';
import IModelActionsService from './IModelActionsService';

export default class OrderReportActionsService
  extends ModelActionsService<OrderReport, CreateOrderReportRequestDTO, OrderReportResponseDTO>
  implements IModelActionsService<OrderReport, CreateOrderReportRequestDTO, OrderReportResponseDTO>
{
  protected getModelApiRootPath = (dto: { orderId: string }) => {
    return PATH_BACKEND.order.root + '/' + dto.orderId + '/' + super.getModelApiRootPath(dto);
  };
}
