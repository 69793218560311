import React, { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { PROMOTION_PREDEFINED_TYPE } from 'typings/models/promotionsConfig.enum';
import { useFormik } from 'formik';
import { Stack, Tooltip } from '@mui/material';
import DeleteButton from 'components/ui/buttons/DeleteButton';
import AutocompleteMemorized from 'components/ui/forms/AutocompleteMemorized';
import EditButton from 'components/ui/buttons/EditButton';
import TextFieldMemorized from 'components/ui/forms/TextFieldMemorized';
import FormUtils from 'utils/Form';
import useLocales from 'hooks/useLocales';
import FormProvider from 'components/ui/forms/FormProvider';
import FunctionUtils from 'utils/Function';
import PredefinedListTypeInfoBlock from 'components/_dashboardPagesFeatures/promotionsConfig/predefined/PredefinedListTypeInfoBlock';
import { INPUT_FULL_WIDTH_CLASS_NAME, INPUT_MEDIUM_WIDTH_CLASS_NAME } from 'configs/layout';

type PropsDefault = {
  formId: string;
  formsStatesMapRef: React.MutableRefObject<FormUtils.ChildFormsMap>;
  initialState?: PromotionsPredefined;
  formChangedCallback: VoidFunction;
  formIndex: number;
  deletePredefinedHandler: (formId: string) => void;
  popupOpenHandler: (index?: string) => void;
  dictionaryMap: Record<string, Dictionary>;
  isEditable?: boolean;
  parentModelPath: string;
  configError: Record<string, string>;
};

export default function PredefinedVariableBlock({
  formId,
  formsStatesMapRef,
  initialState,
  formChangedCallback,
  deletePredefinedHandler,
  popupOpenHandler,
  dictionaryMap,
  isEditable = true,
  parentModelPath,
  formIndex,
  configError,
}: PropsDefault) {
  const { translate } = useLocales();
  const fieldIsRequiredText = translate('errors.fieldIsRequired');

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(fieldIsRequiredText),
    type: Yup.string().required(fieldIsRequiredText),
    values: Yup.array().test({
      message: fieldIsRequiredText,
      test: (thisValue, testContext) =>
        Boolean(testContext.parent.type === PROMOTION_PREDEFINED_TYPE.dictionaryListValue ? thisValue : true),
    }),
    value: Yup.mixed().test({
      message: fieldIsRequiredText,
      test: (thisValue, testContext) =>
        Boolean(testContext.parent.type === PROMOTION_PREDEFINED_TYPE.dictionarySingleValue ? thisValue : true),
    }),
    dictionaryId: Yup.string().test({
      message: fieldIsRequiredText,
      test: (thisValue, testContext) =>
        Boolean(
          testContext.parent.type === PROMOTION_PREDEFINED_TYPE.dictionarySingleValue ||
            testContext.parent.type === PROMOTION_PREDEFINED_TYPE.dictionaryListValue
            ? thisValue
            : true
        ),
    }),
  });

  const initialValues: PromotionsPredefined = useMemo(() => {
    if (formsStatesMapRef.current.get(formId)) {
      return formsStatesMapRef.current.get(formId)?.values;
    }
    if (initialState === undefined) {
      return {
        name: '',
        type: PROMOTION_PREDEFINED_TYPE.expression,
        expression: '',
        dictionaryId: '',
        values: [],
        value: null,
      };
    }
    let initialValues: PromotionsPredefined = {
      ...initialState,
    };
    return initialValues;
  }, []);

  const formState = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values): Promise<PromotionsPredefined> => {
      if (!values.type) {
        throw new Error('Data is missing');
      }

      let model: PromotionsPredefined.PromotionsPredefinedBase = {
        type: values.type,
        name: values.name,
      };

      switch (values.type) {
        case PROMOTION_PREDEFINED_TYPE.dictionaryListValue: {
          if (!values.values || !values.dictionaryId) {
            throw new Error('Data is missing');
          }
          (model as PromotionsPredefined.PromotionsPredefinedDictionaryMultipleValue).dictionaryId = values.dictionaryId;
          (model as PromotionsPredefined.PromotionsPredefinedDictionaryMultipleValue).values = values.values;
          break;
        }
        case PROMOTION_PREDEFINED_TYPE.dictionarySingleValue: {
          if (!values.value) {
            throw new Error('Data is missing');
          }
          (model as PromotionsPredefined.PromotionsPredefinedDictionarySingleValue).dictionaryId = values.dictionaryId;
          (model as PromotionsPredefined.PromotionsPredefinedDictionarySingleValue).value = values.value;
          break;
        }
        case PROMOTION_PREDEFINED_TYPE.expression: {
          (model as PromotionsPredefined.PromotionsPredefinedExpression).expression = values.expression;
          break;
        }
        default: {
          FunctionUtils.exhaustiveCheck(values);
        }
      }
      return model as PromotionsPredefined;
    },
  });

  formsStatesMapRef.current.set(formId, formState);

  const modelPath = parentModelPath + '[' + formIndex + ']';

  useEffect(() => {
    if (configError[modelPath + '.name']) {
      formState.setFieldError('name', configError[modelPath + '.name']);
    }
    if (configError[modelPath + '.expression']) {
      formState.setFieldError('expression', configError[modelPath + '.expression']);
    }
    if (configError[modelPath + '.value']) {
      formState.setFieldError('value', configError[modelPath + '.value']);
    }
    if (configError[modelPath + '.values']) {
      formState.setFieldError('values', configError[modelPath + '.values']);
    }
  }, [configError]);

  return (
    <FormProvider
      formState={formState}
      containerSx={{
        mb: 2,
        mt: 1,
      }}
      fullWidth
    >
      <Stack direction="row" spacing={2}>
        <Tooltip title={formState.values.name || ''} disableInteractive enterTouchDelay={700}>
          <span>
            <TextFieldMemorized
              formState={formState}
              fieldName="name"
              label={translate('pages.promotionsVariablesEdit.name')}
              onChange={(event) => {
                formState.setFieldValue('name', event.target.value);
                formChangedCallback();
              }}
              disabled={!isEditable}
              className={INPUT_MEDIUM_WIDTH_CLASS_NAME}
            />
          </span>
        </Tooltip>

        {formState.values.type === PROMOTION_PREDEFINED_TYPE.expression && (
          <Tooltip title={formState.values.expression || ''} disableInteractive enterTouchDelay={700}>
            <span style={{ width: '100% ' }}>
              <TextFieldMemorized
                formState={formState}
                fieldName="expression"
                label={translate('pages.promotionsVariablesEdit.expression')}
                onChange={(event) => {
                  formState.setFieldValue('expression', event.target.value);
                  formChangedCallback();
                }}
                disabled={!isEditable}
                className={INPUT_FULL_WIDTH_CLASS_NAME}
              />
            </span>
          </Tooltip>
        )}
        {formState.values.type === PROMOTION_PREDEFINED_TYPE.dictionaryListValue && (
          <PredefinedListTypeInfoBlock
            formState={formState}
            fieldName="values"
            label={translate('pages.promotionsVariablesEdit.values')}
            options={dictionaryMap[formState.values.dictionaryId].entries || []}
            className={INPUT_FULL_WIDTH_CLASS_NAME}
          />
        )}
        {formState.values.type === PROMOTION_PREDEFINED_TYPE.dictionarySingleValue && (
          <Tooltip title={formState.values.value.name || ''} disableInteractive enterTouchDelay={700}>
            <span style={{ width: '100% ' }}>
              <AutocompleteMemorized
                formState={formState}
                fieldName="value"
                label={translate('pages.promotionsVariablesEdit.value')}
                options={dictionaryMap[formState.values.dictionaryId].entries || []}
                disabled
                className={INPUT_FULL_WIDTH_CLASS_NAME}
              />
            </span>
          </Tooltip>
        )}

        <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" flexShrink={0} sx={{ width: '80px' }}>
          {(formState.values.type === PROMOTION_PREDEFINED_TYPE.dictionarySingleValue ||
            formState.values.type === PROMOTION_PREDEFINED_TYPE.dictionaryListValue) && (
            <EditButton onClick={() => popupOpenHandler(formId)} disabled={!isEditable} />
          )}
          <DeleteButton onClick={() => deletePredefinedHandler(formId)} disabled={!isEditable} />
        </Stack>
      </Stack>
    </FormProvider>
  );
}
