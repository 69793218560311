import { Theme } from '@mui/material/styles';
import {
  TABLE_VARIANT_1_CLASS_NAME,
  TABLE_INTERNAL_CLASS_NAME,
  TABLE_ROW_EMPTY_CLASS_NAME,
  TABLE_ROW_SELECTABLE_CLASS_NAME,
} from 'configs/layout';
import typography from 'theme/typography';

const cellPadding = 2;
export default function Table(theme: Theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          [`.${TABLE_VARIANT_1_CLASS_NAME} &`]: {
            borderBottom: '2px solid ' + theme.palette.grey[300],
            [`&:has(+ .${TABLE_ROW_EMPTY_CLASS_NAME})`]: {
              borderBottom: 'none',
            },
            '&:nth-of-type(even)': {
              backgroundColor: theme.palette.background.neutral,
            },
            '&:nth-of-type(odd)': {
              backgroundColor: '#F9F9F9',
            },
          },
          [`&.${TABLE_ROW_SELECTABLE_CLASS_NAME}:hover`]: {
            cursor: 'pointer',
            backgroundColor: theme.palette.action.hover,
          },
          [`&.${TABLE_ROW_EMPTY_CLASS_NAME}`]: {
            borderBottom: 'none',
          },
          [`.${TABLE_INTERNAL_CLASS_NAME} &`]: {
            backgroundColor: 'white !important',
            border: 'none !important',
          },
          // хз что это
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
        head: {
          [`.${TABLE_VARIANT_1_CLASS_NAME} &`]: {
            backgroundColor: theme.palette.background.neutral + ' !important',
            borderBottom: '2px solid ' + theme.palette.grey[300],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none',
          [`.${TABLE_VARIANT_1_CLASS_NAME} &`]: {
            padding: theme.spacing(cellPadding),
            // '&:first-of-type': {
            //   paddingLeft: theme.spacing(3),
            // },
            ...typography.body2,
            [theme.breakpoints.down('md')]: {
              ...typography.caption,
            },
          },
          [`.${TABLE_INTERNAL_CLASS_NAME} &`]: {
            padding: theme.spacing(1),
          },
        },
        head: {
          color: theme.palette.text.secondary,
          [`.${TABLE_VARIANT_1_CLASS_NAME} &`]: {
            '&:first-of-type': {
              borderTopLeftRadius: theme.shape.borderRadius,
              borderBottomLeftRadius: 0,
            },
            '&:last-of-type': {
              borderTopRightRadius: theme.shape.borderRadius,
              borderBottomRightRadius: 0,
            },
          },
          [`.${TABLE_INTERNAL_CLASS_NAME} &`]: {
            '&:first-of-type': {
              borderBottomLeftRadius: theme.shape.borderRadius,
            },
            '&:last-of-type': {
              borderBottomRightRadius: theme.shape.borderRadius,
            },
          },
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`,
        },
        toolbar: {
          height: 64,
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: -4,
        },
      },
    },
  };
}

/*


export default function Table(theme: Theme) {
  return {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[200],
          borderRadius: theme.spacing(2),
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          '&.MuiTableRow-opened': { borderBottom: 'none' },
          '&:last-of-type': { borderBottom: 'none' },
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
        head: {
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          verticalAlign: 'top',
          borderBottom: 'none',
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
          '.MuiTableRow-opened~tr &': { paddingTop: 0 },
          '&:first-of-type': {
            paddingLeft: 0,
            paddingRight: 0,
            textAlign: 'center',
            width: theme.spacing(CONTAINER_OFFSET),
          },
          '&:nth-of-type(2)': {
            paddingLeft: 0,
          },
          [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            '&:first-of-type': {
              display: 'none',
            },
          },
        },
        head: {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
          '&:first-of-type': {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
          },
          '&:last-of-type': {
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
          },
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
        },
        body: {},
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`,
        },
        toolbar: {
          height: 64,
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: 2,
        },
      },
    },
  };
}

*/
