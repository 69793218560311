import LoaderComponent from 'components/ui/loadingIndicators/LoaderComponent';
import useLocales from 'hooks/useLocales';
import React, { useState } from 'react';
import { useSelector } from 'storage';
import { RoutingServiceDashboard } from 'services/routing/RoutingService';
import useInitializePage from 'hooks/useInitializePage';
import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';
import { PATH_PARAM_DASHBOARD } from 'services/routing/PATH_PARAM';
import FormUtils from 'utils/Form';
import useChildForms from 'hooks/useChildForms';
import useRerenderComponent from 'hooks/useRerenderComponent';
import useFirstRender from 'hooks/useFirstRender';
import RecommendationsVariablesEditForm from 'components/_dashboardPagesFeatures/recommendationsConfig/predefined/RecommendationsVariablesEditForm';
import useRecommendationTabs from 'pages/dashboard/recommendation/useRecommendationTabs';
import { RecommendationConfigResponseDTO } from 'typings/dto/recommendationsConfig';

const modelPath: keyof RecommendationConfigResponseDTO = 'recommendationsConfig';

function RecommendationVariablesEditPage() {
  const { permissions } = useSelector((state) => state.auth);
  const { recommendationsConfigIsLoading, recommendationsConfig } = useSelector((state) => state.recommendationsConfig);
  const { translate } = useLocales();
  const tabs = useRecommendationTabs();
  const isFirstRender = useFirstRender();
  const variablesList = recommendationsConfig?.predefined;
  const { areAllLoading: areDictionariesAreLoading, dictionaryAll } = useSelector((state) => state.dictionary);
  const varsChildForms = useChildForms(variablesList?.length || 0);
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [toOpenIndex, setToOpenIndex] = useState<string | undefined>(undefined);

  const openPopupHandler = (index?: string) => {
    if (index !== undefined) {
      setToOpenIndex(index);
    } else {
      setToOpenIndex(undefined);
    }
    setIsPopupOpened(true);
  };

  const rerenderPageCallback = useRerenderComponent();

  const isPageInitialized = useInitializePage(() => {
    const title = translate('pages.recommendationsVariablesEdit.title');
    const breadcrumbs = RoutingServiceDashboard.recommendationsConfig.getListBc();
    const menu: (MenuIconItemData | MenuItemData[])[] = [];
    const submenu: MenuItemData[] = [
      {
        title: translate('pages.promotionsVariablesEdit.menu.addFromDictionary'),
        handler: () => {
          openPopupHandler();
        },
      },
      {
        title: translate('pages.promotionsVariablesEdit.menu.addTextVariable'),
        handler: FormUtils.addChildFormHandlerFactory(varsChildForms.formsMapRef.current, rerenderPageCallback),
      },
    ];
    if (permissions.can(MODEL_PERMISSION.CAN_UPDATE_RECOMMENDATIONS)) {
      menu.push(submenu);
    }
    return { menu, title, breadcrumbs, tabs, currentTab: PATH_PARAM_DASHBOARD.recommendationConfig.variables };
  }, [recommendationsConfigIsLoading]);

  if (!isPageInitialized || areDictionariesAreLoading || isFirstRender) {
    return <LoaderComponent />;
  }

  return (
    <RecommendationsVariablesEditForm
      recommendationsConfig={recommendationsConfig}
      isPopupOpened={isPopupOpened}
      popupOpenHandler={openPopupHandler}
      toOpenIndex={toOpenIndex}
      popupCloseHandler={() => setIsPopupOpened(false)}
      rerenderPageCallback={rerenderPageCallback}
      varsChildForms={varsChildForms}
      variablesList={variablesList}
      dictionaryList={dictionaryAll}
      parentModelPath={modelPath}
    />
  );
}

export default React.memo(RecommendationVariablesEditPage, () => true);
