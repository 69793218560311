interface IServerEventsService {
  createSubscription: (
    url: string,
    onMessage: (data: IServerEventsService.SSEEntityUpdateMessageData) => void,
    onError: (error: any) => void
  ) => IServerEventsService.SubscriptionState;
}

namespace IServerEventsService {
  export type SubscriptionState = {
    failedAttempts: number;
    clearSubscription: VoidFunction;
    connectionType: string;
  };

  export type SSEMessage = {
    /**
     * JSON строка
     * Пока data может быть только следующего типа: `SSEEntityUpdateMessageData`
     */
    data?: string;
  };

  /**
   * EventSource работает с кастомными типами событий по принципу навешивания на каждый тип своего слушателя;
   * BroadcastChannel на первый взгляд имеет сходную структуру, но я не нашел как отправлять не дефолтные типы сообщений (не "message"), поэтому свой payload
   */
  export type BroadcastMessage = SSEMessage & {
    name: EVENT_TYPE;
  };

  export type SSEEntityUpdateMessageData = {
    id: string;
    updatedAt: string;
    type: SSE_ENTITY_UPDATE_TYPE;
  };

  export enum SSE_ENTITY_UPDATE_TYPE {
    UPDATED = 'UPDATED',
    DELETED = 'DELETED',
    CREATED = 'CREATED',
  }

  /** Тип (название) сообщения по протоколу SSE, хранится в строке "event"; по дефолту это "message", но бек также может присылать свои кастомные;
   * Методом проб установлено, что поддерживается только lower case, не знаю это часть протокола или бека;
   * Также этот тип используется в текущей реализации и для обмена сообщениями broadcastChannel
   */
  export enum EVENT_TYPE {
    /** Сообщение непосредственно с данными с сервера, полученными от lead вкладки по её SSE соединению; дефолтный тип сообщений */
    message = 'message',
    /** Сервер проверяет, что фронт ещё не разорвал соединение и на связи */
    ping = 'ping',
    /** Сервер просит закрыть соединение и не пытаться переподключиться */
    close = 'close',
    /**
     * Пульс, в виде сообщения, лидовой вкладки (поддерживающей соединение с сервером) - пока он приходит остальным (через BroadcastChannel), они не пытаются стать лидами.
     * Единственный тип сообщений, который не используется в sse
     */
    leadHeartbeat = 'heartbeat',
    /** Перед отключением вкладка шлёт такое сообщение, чтобы другие вкладки установили лидера */
    elections = 'elections',
    /** При создании новой вкладки она запрашивает этим сообщением у существующих есть ли лидер; в ответ должен прийти heartbeat */
    searchForLead = 'search',
  }
}

export default IServerEventsService;
