import FrontendRoutesBase from './FrontendRoutesBase';
import { PAGE_TYPE, PATH_PARAM_DASHBOARD, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';
import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';

export default class WidgetRoutes extends FrontendRoutesBase {
  public root = PATH_PARAM_DASHBOARD.widget.root;
  public settings: SettingsRoutes;
  public brand: BrandRouter;
  public enterprise: EnterpriseRoutes;

  constructor() {
    super();
    this.enterprise = new EnterpriseRoutes();
    this.brand = new BrandRouter(this.enterprise);
    this.settings = new SettingsRoutes(this.brand);
  }
}

class EnterpriseRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public child: BrandRouter | null = null;

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.widget> = (_params, permissions, translate) => {
    const canAccessEnterpriseListPage = permissions.can(MODEL_PERMISSION.FAKE_FORBIDDEN);
    return canAccessEnterpriseListPage ? [{ name: translate('menus.main.enterprises'), href: this.getListPath() }] : [];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.widget, 'enterpriseName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const breadcrumbs = this.getInstanceBc(params, permissions, translate);
    breadcrumbs.push({ name: names.enterpriseName });
    return breadcrumbs;
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.widget.root, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.widget> = (params) => (this.child ? this.child.getListPath(params) : '#');
}

class BrandRouter extends FrontendRoutesBase implements IFrontendRoutes {
  public child: SettingsRoutes | null = null;
  constructor(private parent: EnterpriseRoutes) {
    super();
    this.parent.child = this;
  }

  getInstanceBc = this.parent.getInstanceChildBc;

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.widget, 'enterpriseName' | 'brandName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const breadcrumbs = this.getInstanceBc(params, permissions, translate, names);
    this.setLastBcLink(breadcrumbs, this.parent.getListPath());
    breadcrumbs.push({ name: names.brandName });
    return breadcrumbs;
  };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.widget> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.widget.root, params.enterpriseId, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.widget> = (params) =>
    this.child ? this.child.getMatchPath(params) : '#';
}

class SettingsRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: BrandRouter) {
    super();
    this.parent.child = this;
  }

  getInstanceBc = this.parent.getInstanceChildBc;

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.widget, 'enterpriseName' | 'brandName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const breadcrumbs = this.getInstanceBc(params, permissions, translate, names);
    this.setLastBcLink(breadcrumbs, this.getVisibilityListPath(params));
    return breadcrumbs;
  };

  getMatchBc = this.parent.getInstanceBc;
  getMatchPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.widget> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.widget.root,
      params.enterpriseId,
      params.brandId,
      PATH_PARAM_DASHBOARD.widget.settings,
      PATH_PARAM_DASHBOARD.widget.match,
      PAGE_TYPE.list
    );

  getVisibilityListBc = this.parent.getInstanceBc;
  getVisibilityListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.widget> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.widget.root,
      params.enterpriseId,
      params.brandId,
      PATH_PARAM_DASHBOARD.widget.settings,
      PATH_PARAM_DASHBOARD.widget.serviceVisibility,
      PAGE_TYPE.list
    );

  getVisibilityEditBc = this.getInstanceChildBc;
  getVisibilityEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.widget> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.widget.root,
      params.enterpriseId,
      params.brandId,
      PATH_PARAM_DASHBOARD.widget.settings,
      PATH_PARAM_DASHBOARD.widget.serviceVisibility,
      PAGE_TYPE.edit
    );
}
