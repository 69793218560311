import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import ILanguageService from 'services/language/ILanguageService';
import { ChangeUserCredentialsRequestDTO } from 'typings/dto/auth';
import {
  AllNotificationSettings,
  CreateUserRequestDTO,
  GetUsersForTaskRequestDTO,
  UserNotificationsDTO,
  UserPositionDTO,
  UserResponseDTO,
} from 'typings/dto/user';
import { USER_ROLE } from 'typings/models/user.enum';
import PositionMapper from 'utils/mappers/subEntities/PositionMapper';
import ModelActionsService from '../ModelActionsService';
import IUserBaseActionsService from './IUserBaseActionsService';

export default class UserBaseActionsServiceImpl
  extends ModelActionsService<User.Base, CreateUserRequestDTO, UserResponseDTO>
  implements IUserBaseActionsService
{
  /** @throws `BackendResponseError` */
  public getPositions = async (
    dto: GetModelWithTenantRequestDTO,
    { translate }: ILanguageService,
    positionMapper: PositionMapper
  ): Promise<Array<User.Position & { roles: { id: USER_ROLE; name: string }[] }>> => {
    const url = PATH_BACKEND.user.root + '/positions?tenantId=' + dto.tenantId;
    let responseDTO = await this.entityApiService.getWithCredentials<
      Array<{ position: UserPositionDTO; roles: USER_ROLE[] }>
    >(url);

    return responseDTO.map((item) => {
      const position = positionMapper.responseDTOToModel(item.position);
      return {
        ...position,
        roles: item.roles.map((role) => ({ id: role, name: translate('entities.user.roles.' + role) })),
      };
    });
  };

  public getUsersForTask = async ({ orderId }: GetUsersForTaskRequestDTO) => {
    const url = PATH_BACKEND.user.root + '/order/' + orderId + '/task';
    return super.getAllForFilter(undefined, undefined, url);
  };

  public changeCredentials = ({ userId, ...dto }: ChangeUserCredentialsRequestDTO) => {
    const url = PATH_BACKEND.user.root + '/' + userId + '/change-credentials';
    return this.entityApiService.patchWithCredentials<void>(url, dto, false);
  };

  public getNotificationSettings = (userPositionId: string): Promise<AllNotificationSettings> => {
    const url =
      PATH_BACKEND.notifications.root +
      '/' +
      PATH_BACKEND_PART.notifications.notifications +
      `?userPositionId=${userPositionId}`;
    return this.entityApiService.getWithCredentials<UserNotificationsDTO>(url);
  };

  public getUserNotificationSettings(): Promise<AllNotificationSettings> {
    const url = PATH_BACKEND.user.root + '/' + PATH_BACKEND_PART.notifications.notifications;
    return this.entityApiService.getWithCredentials<UserNotificationsDTO>(url);
  }

  public getUserNotificationSettingsById(userId: string): Promise<AllNotificationSettings> {
    const url = PATH_BACKEND.user.root + '/' + PATH_BACKEND_PART.notifications.notifications + '/' + userId;
    return this.entityApiService.getWithCredentials<UserNotificationsDTO>(url);
  }

  public getClientNotificationSettings(): Promise<AllNotificationSettings> {
    const url = PATH_BACKEND.notifications.root + '/' + PATH_BACKEND_PART.notifications.notifications + '/client';
    return this.entityApiService.getWithCredentials<UserNotificationsDTO>(url);
  }

  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return super.getDefaultSortFilter(filter, 'name');
  };

  public getAllForFilter(
    dto?: Record<string, any> | null,
    filter?: LocationSearchObject,
    customPath?: string
  ): Promise<void> {
    return super.getAllForFilter(dto, filter, customPath || PATH_BACKEND.user.idNameOnly);
  }
}
