import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { CityResponseDTO, CreateCityRequestDTO } from 'typings/dto/city';
import IRegionActionsService from './IRegionActionsService';
import { PATH_BACKEND } from 'configs/routes/pathsBackend';

export default class RegionActionsServiceImpl
  extends ModelActionsService<City, CreateCityRequestDTO, CityResponseDTO>
  implements IRegionActionsService
{
  /** @throws `BackendResponseError` */
  public getValuesFromOrderForFilter = (): Promise<void> => {
    const url = PATH_BACKEND.region.orderFilter;
    return this.getAllForFilter(undefined, undefined, url);
  };
}
