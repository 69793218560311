import i18n from 'i18next';
import ILanguageService from 'services/language/ILanguageService';

/** @deprecated удалить? */
export default class CurrencyMapper {
  public responseDTOToModel = (code: ILanguageService.CurrencyCode): Currency => {
    return {
      id: code,
      code,
      name: i18n.t(`common.currencies.${code}.name`),
      symbol: i18n.t(`common.currencies.${code}.symbol`),
      value: 0, // todo delete
      currency: '', // todo delete
    };
  };
}
