import DIContainer from 'services/DIContainer';
import { CreateOrderReportRequestDTO, OrderReportResponseDTO } from 'typings/dto/orderReport';
import Mapper from './Mapper';

export default class OrderReportMapper extends Mapper<OrderReport, OrderReportResponseDTO, CreateOrderReportRequestDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        completed: 'completed',
        fields: 'fields',
        name: 'name',
        technicalName: 'technicalName',
        description: 'description',
      },
      {
        fields: 'fields',
        name: 'name',
        technicalName: 'technicalName',
        completed: null,
        description: null,
      }
    );
  }

  public responseDTOToModel(dto: OrderReportResponseDTO): OrderReport {
    const outerMappers = this.getOuterMappers();
    const fieldsMapped = dto.fields.map((item) => this.subEntitiesMappers.dynamicField.responseDTOToModel(item, outerMappers));

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      fields: fieldsMapped,
      description: dto.description,
    };
  }
}
