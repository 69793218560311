import { useMemo, useRef } from 'react';
import { FormUtils } from 'utils/Form';

export type ChildFormsState = {
  /**
   * Ссылка на карту форм. Сделано в виде ref для memo компонентов
   * @see FormUtils.ChildFormsMap
   */
  formsMapRef: React.MutableRefObject<FormUtils.ChildFormsMap>;
  /** Массив ключей карты для рендера форм в форме-родителе */
  formsKeys: string[];
  /** сабмит всех форм-потомков, возвращаем промис с массивом ответов */
  submitForms: <T = any>() => Promise<T[]>;
};

/**
 * Создаёт стейт, обслуживающий формы-потомки одной сущности; начальные значения можно передать в сам компонент формы
 * @param initialItemsCount начальное количество форм
 */
export default function useChildForms(initialItemsCount: number = 0): ChildFormsState {
  const initialItems = useMemo(() => {
    // null т.к. туда будет записан id формы в момент инициализации компонента формы, но чтобы стригерить его инициализацию, нужно задать id
    const initialItems = {} as Record<string, null>;
    for (let i = 0; i < initialItemsCount; i++) {
      initialItems[i + 1] = null;
    }
    return new Map(Object.entries(initialItems));
  }, []);

  const formsMapRef = useRef<FormUtils.ChildFormsMap>(initialItems);
  const formsKeys = useMemo(() => Array.from(formsMapRef.current.keys()), [formsMapRef.current.size]);
  const submitForms = async <T = any>() => {
    const forms = Array.from(formsMapRef.current.values());
    return (await Promise.all(forms.map((f) => f?.submitForm()))) as unknown as T[];
  };

  return {
    formsMapRef,
    formsKeys,
    submitForms,
  };
}
