import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useDI from '../../hooks/useDI';
import { useSelector } from '../../storage';

export default function Analytics() {
  const { pathname } = useLocation();
  const { services } = useDI();
  const { currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!currentUser || !currentUser.email) return;
    services.analytics.sendData(currentUser.email);
  }, [pathname]);

  return null;
}
