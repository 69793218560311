import { dispatch } from 'storage';
import SliceFactory from './SliceFactory';

const initialState: PageState = {
  breadcrumbs: [],
  pageNumber: 1,
  title: '',
  menu: [],
  tabs: [],
  useTabsBlock: true,
  currentTab: '',
  backPath: '',
  isInitialized: false,
};

const slice = SliceFactory.createSimpleSlice({
  name: 'page',
  initialState,
  reducers: {
    setPageMenu(state, action) {
      state.menu = action.payload;
    },
    setAllProps(state, action) {
      state.isInitialized = true;
      state.breadcrumbs = action.payload.breadcrumbs;
      state.pageNumber = action.payload.pageNumber;
      state.title = action.payload.title;
      state.menu = action.payload.menu;
      state.backPath = action.payload.backPath;
      state.tabs = action.payload.tabs;
      state.useTabsBlock = action.payload.useTabsBlock;
      state.currentTab = action.payload.currentTab;
    },
    resetPage(state) {
      state.isInitialized = false;
      const { breadcrumbs, pageNumber, title, menu, backPath, tabs, currentTab, useTabsBlock } = initialState;
      state.breadcrumbs = breadcrumbs;
      state.pageNumber = pageNumber;
      state.title = title;
      state.menu = menu;
      state.backPath = backPath;
      state.tabs = tabs;
      state.useTabsBlock = useTabsBlock;
      state.currentTab = currentTab;
    },
  },
});

export default slice;

/** @deprecated Удалить после рефакторинга заказа, есть свежие реализации */
export function initializePageAction({
  breadcrumbs = [],
  title = '',
  pageNumber = 1,
  menu = [],
  backPath = '',
  tabs = [],
  currentTab = '',
  useTabsBlock = true,
}: Partial<PageState>) {
  // Если backPath отсутствует, проставляет по последнему breadcrumb со ссылкой
  if (!backPath && breadcrumbs.length) {
    // prettier-ignore
    backPath = breadcrumbs.concat().reverse().find((bc) => Boolean(bc.href))?.href || '';
  }
  dispatch(slice.actions.setAllProps({ breadcrumbs, title, pageNumber, menu, backPath, tabs, currentTab, useTabsBlock }));
}
