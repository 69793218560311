import { LoadableForm } from './AsyncLoaders';
import { lazy } from 'react';
import { MuiTelInputProps } from 'mui-tel-input';

export default LoadableForm<MuiTelInputProps>(
  lazy(() =>
    import(/* webpackChunkName: "phoneInput" */ 'mui-tel-input').then((module) => ({
      default: module.MuiTelInput,
    }))
  )
);
