import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { CommentResponseDTO, CreateOrderReviewCommentRequestDTO } from 'typings/dto/comment';
import IOrderReviewCommentActionsService from './IOrderReviewCommentActionsService';

export default class ReviewCommentActionsService
  extends ModelActionsService<Commentary, CreateOrderReviewCommentRequestDTO, CommentResponseDTO>
  implements IOrderReviewCommentActionsService
{
  public deleteFromOrder = (commentId: string, reviewId: string, orderId: string): Promise<void> => {
    const url = [
      PATH_BACKEND.order.root,
      orderId,
      PATH_BACKEND_PART.order.review,
      reviewId,
      super.getModelApiRootPath(null),
      commentId,
    ].join('/');
    return this.entityApiService.deleteWithCredentials(url);
  };

  protected getModelApiRootPath = (dto: CreateOrderReviewCommentRequestDTO) => {
    return [PATH_BACKEND.order.root, dto.orderId, PATH_BACKEND_PART.order.review, dto.reviewId, super.getModelApiRootPath(dto)].join('/');
  };
}
