import { Box, BoxProps, alpha } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTheme } from '@mui/material';
import useMediaQuery from 'hooks/useMediaQuery';
import FunctionUtils from 'utils/Function';

type Props = BoxProps & {
  scrollToEnd?: boolean;
  children: React.ReactElement;
  fadeColor?: string;
};

// На IntersectionObserver отказалось работать нормально по горизонтали; TODO возможно стоит ещё раз попробовать с применением css свойства containerType, но не проверял это
/**
 * Визуально добавляет по краям горизонтально скроллящегося элемента фейд, если его можно скроллить
 * Важно! Перезаписывает ref у children
 */
function ScrollFade({ children, scrollToEnd, fadeColor, sx, ...rest }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const elementRef = useRef(null as HTMLElement | null);
  const containerRef = useRef(null as HTMLElement | null);

  const [fromColor, toColor] = useMemo(() => {
    const fromColor = fadeColor ? alpha(fadeColor, 0) : 'rgba(255,255,255,0)';
    const toColor = fadeColor ? alpha(fadeColor, 1) : 'rgba(255,255,255,1)';
    return [fromColor, toColor];
  }, [fadeColor]);

  useEffect(() => {
    console.debug('[Feature: scroll fade] Effect');
    const { current: $element } = elementRef;
    const { current: $container } = containerRef;

    if (!$element || !$container) {
      return;
    }
    if ($element.scrollWidth === $element.clientWidth) {
      $container.classList.remove('scroll-fade-left');
      $container.classList.remove('scroll-fade-right');
      return;
    }

    if (scrollToEnd) {
      $element.scrollTo({ left: $element.scrollWidth });
    }

    const fadeHandler = FunctionUtils.debounce(() => {
      console.debug('[Feature: scroll fade] calculating');
      if ($element.scrollLeft === 0) {
        $container.classList.remove('scroll-fade-left');
        $container.classList.add('scroll-fade-right');
        // 1 это погрешность браузера
      } else if ($element.scrollLeft + $element.clientWidth + 1 >= $element.scrollWidth) {
        $container.classList.remove('scroll-fade-right');
        $container.classList.add('scroll-fade-left');
      } else {
        $container.classList.add('scroll-fade-left');
        $container.classList.add('scroll-fade-right');
      }
    }, 300);

    fadeHandler();
    $element.addEventListener('scroll', fadeHandler);
    return () => $element.removeEventListener('scroll', fadeHandler);
  }, [isMobile, fadeColor]);

  // Есть css maskImage: 'linear-gradient(90deg, black 75%, transparent 100%)'
  // Но с ним не понятно, как transition делать
  // https://stackoverflow.com/questions/60359212/is-it-possible-to-animate-a-css-mask-image
  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        '&::before': {
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '20%',
          pointerEvents: 'none',
          content: '""',
          opacity: 0,
          transition: 'opacity linear 0.3s',
          background: `linear-gradient(-90deg, ${fromColor} 50%, ${toColor} 100%)`,
        },
        '&.scroll-fade-left::before': {
          opacity: 1,
        },
        '&::after': {
          position: 'absolute',
          top: 0,
          right: 0,
          height: '100%',
          width: '20%',
          pointerEvents: 'none',
          content: '""',
          opacity: 0,
          transition: 'opacity linear 0.3s',
          background: `linear-gradient(90deg, ${fromColor} 0%, ${toColor} 100%)`,
        },
        '&.scroll-fade-right::after': {
          opacity: 1,
        },
        ...sx,
      }}
      {...rest}
    >
      {React.cloneElement(children, { ref: (node: any) => (elementRef.current = node) })}
    </Box>
  );
}

export default React.memo(ScrollFade); // children пусть проверяет автоматически (при такой записи без указания функции)
