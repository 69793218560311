/** @deprecated удалить */
export enum STATUS_TYPE {
  good = 'good',
  normal = 'normal',
  warning = 'warning',
  inProgress = 'inProgress',
  bad = 'bad',
  neutral = 'neutral',
}

export enum STATUS_COLOR {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

type StatusColor = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

/** @deprecated удалить */
export const statusColorMap: { [key in STATUS_TYPE]: StatusColor } = {
  [STATUS_TYPE.good]: 'success',
  [STATUS_TYPE.neutral]: 'default',
  [STATUS_TYPE.normal]: 'primary',
  [STATUS_TYPE.warning]: 'warning',
  [STATUS_TYPE.inProgress]: 'info',
  [STATUS_TYPE.bad]: 'error',
};

export enum BOOLEAN_FILTER {
  true = 'true',
  false = 'false',
  undefined = '',
}
