import { Box, TableCell } from '@mui/material';
import useLocales from 'hooks/useLocales';
import React, { useRef, useState } from 'react';
import { useSelector } from 'storage';
import TableVariant1 from 'components/ui/tables/TableVariant1';
import TableRow from '@mui/material/TableRow/TableRow';
import EmptyListBlock from 'components/features/list/EmptyListBlock';
import { ListIcon } from 'components/ui/icons';
import PopupAction from 'components/ui/popups/PopupAction';
import IPermissionService from 'services/permission/IPermissionService';
import ServiceOrderedSessionListInfoItem from 'components/_dashboardPagesFeatures/order/add/session/services/ServiceOrderedSessionListInfoItem';
import useDI from 'hooks/useDI';
import PopupContent from 'components/ui/popups/PopupContent';
import ServiceOrderedSessionEditBlock from 'components/_dashboardPagesFeatures/order/add/session/services/ServiceOrderedSessionEditBlock';
import { CreateServiceOrderedRequestDTO } from 'typings/dto/serviceOrdered';
import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';

type Props = {
  permissionConfig: IPermissionService.PermissionConfigs;
  serviceOrderedList: ServiceOrdered[];
};

function ServiceOrderedSessionListInfoBlock({ permissionConfig, serviceOrderedList }: Props) {
  const { translate } = useLocales();
  const { storageActions } = useDI();
  const snackbarErrorHandler = useSnackbarErrorHandler();
  const { orderSession } = useSelector((state) => state.orderSession);
  const serviceRef = useRef<null | ServiceOrdered>(null);
  const [isDeletePopupOpened, setIsDeletePopupOpened] = useState(false);
  const [isEditPopupOpened, setIsEditPopupOpened] = useState(false);

  if (!orderSession) {
    return null;
  }

  if (!serviceOrderedList.length) {
    return (
      <EmptyListBlock
        icon={<ListIcon />}
        title={translate('pages.orderServiceList.emptyTitle')}
        subtitle={translate('pages.orderServiceList.emptySubtitle')}
      ></EmptyListBlock>
    );
  }

  const deleteHandler = async () => {
    if (serviceRef.current) {
      await storageActions.models.serviceOrdered.deleteFromOrderSession(orderSession.id, serviceRef.current.id);
      await storageActions.models.orderSession.getOrderSession(orderSession.id);
    }
  };

  const editHandler = async (dto: CreateServiceOrderedRequestDTO) => {
    try {
      await storageActions.models.serviceOrdered.update(
        { ...dto, id: serviceRef.current?.id || '' },
        PATH_BACKEND.orderSession.root + '/' + dto.orderId + '/' + PATH_BACKEND_PART.service.root
      );

      await storageActions.models.orderSession.getOrderSession(orderSession.id);
    } catch (error) {
      snackbarErrorHandler({ error });
    }
  };

  return (
    <Box>
      <TableVariant1 header={<TableHeader />}>
        {serviceOrderedList.map((service) => (
          <ServiceOrderedSessionListInfoItem
            orderSession={orderSession}
            permissionConfig={permissionConfig}
            key={service.id}
            service={service}
            editHandler={() => {
              serviceRef.current = service;
              setIsEditPopupOpened(true);
            }}
            deleteHandler={() => {
              serviceRef.current = service;
              setIsDeletePopupOpened(true);
            }}
          />
        ))}
      </TableVariant1>
      <PopupAction
        title={translate(`entities.service.deleteTitle`)}
        isOpened={isDeletePopupOpened}
        actionHandler={deleteHandler}
        closeHandler={() => {
          serviceRef.current = null;
          setIsDeletePopupOpened(false);
        }}
      />
      {serviceRef.current && isEditPopupOpened && (
        <PopupContent
          isOpened={isEditPopupOpened}
          closeHandler={() => {
            serviceRef.current = null;
            setIsEditPopupOpened(false);
          }}
          title={translate('pages.invoiceList.generateInvoices')}
        >
          <ServiceOrderedSessionEditBlock
            orderSession={orderSession}
            serviceOrdered={serviceRef.current}
            currency={orderSession.currency}
            closeHandler={() => {
              serviceRef.current = null;
              setIsEditPopupOpened(false);
            }}
            submitHandler={editHandler}
          />
        </PopupContent>
      )}
    </Box>
  );
}

const TableHeader = React.memo(() => {
  const { translate } = useLocales();

  return (
    <TableRow>
      <TableCell>{translate('fields.name_title')}</TableCell>
      <TableCell>{translate('fields.quantity')}</TableCell>
      <TableCell>{translate('pages.orderServiceList.servicePrice')}</TableCell>
      <TableCell sx={{ width: '1%' }} />
    </TableRow>
  );
});

export default React.memo(ServiceOrderedSessionListInfoBlock, () => true);
