export enum PROMOTIONS_AGGREGATION_STRATEGY {
  firstSuitable = 'FIRST_SUITABLE',
  biggestDiscount = 'BIGGEST_DISCOUNT',
  allDiscounts = 'ALL_DISCOUNTS',
}

export enum PROMOTIONS_DISCOUNT_TYPE {
  additive = 'ADDITIVE', // В валюте
  multiplicative = 'MULTIPLICATIVE', // В процентах
}

export enum PROMOTIONS_CONFIG_STATUS {
  ok = 'OK',
  warning = 'WARNING',
}

export enum PROMOTION_PREDEFINED_TYPE {
  expression = 'EXPRESSION',
  dictionarySingleValue = 'DICTIONARY_SINGLE_VALUE',
  dictionaryListValue = 'DICTIONARY_LIST_VALUE',
}
