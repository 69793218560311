import SetCustom from './implementations/SetCustom';

/** Работает с id */
export default class EntityUtils {
  // Compare ----------------------------------------------------------------------------------------------

  /** Проверяет на равенство по id */
  public static checkEquality(oldValue: Entity | null | undefined, newValue: Entity | null | undefined) {
    return oldValue?.id === newValue?.id;
  }

  /** @see checkEquality */
  public static checkArraysEquality(oldValues: Entity[], newValues: Entity[]) {
    if (oldValues.length !== newValues.length) {
      return false;
    }

    return oldValues.every((value, index) => this.checkEquality(value, newValues[index]));
  }

  // Sort --------------------------------------------------------------------------------------------------

  public static sortAlphabeticallyComparator = sortEntitiesAlphabetically;

  public static sortByNameAscendingComparator = (a: EntityWithName, b: EntityWithName) => {
    return a.name.localeCompare(b.name);
  };

  public static sortByNameDescendingComparator = (a: EntityWithName, b: EntityWithName) => {
    return b.name.localeCompare(a.name);
  };

  // Arrays methods ----------------------------------------------------------------------------------------

  // TODO Можно переписать на кастомном сете с компаратором
  /** @returns новый массив с уникальными значениями из обоих (при совпадении приоритет у arr2); порядок сохраняется от arr1, затем arr2 */
  public static filterDuplicates = <T extends Entity>(arr1: T[], arr2: T[], comparatorKey: keyof T = 'id'): T[] => {
    const map = new Map<any, T>();
    arr1.forEach((entity) => {
      map.set(entity[comparatorKey], entity);
    });
    arr2.forEach((entity) => {
      map.set(entity[comparatorKey], entity);
    });

    return Array.from(map.values());
  };

  /**
   * @param keyGetter геттер ключа сущности, по которому будет проводиться сравнение, по умолчанию id
   * @returns new array without any entities contained in both arrays; sort order extends from biggest array
   */
  public static excludeSame = <T extends Entity>(arr1: T[], arr2: T[], keyGetter: (v: T) => string = (v) => v.id): T[] => {
    let biggestArr: T[];
    let smallestArr: T[];
    const uniqueArr: T[] = [];
    const smallestMap = new Map<any, T>();
    if (arr1.length < arr2.length) {
      biggestArr = arr2;
      smallestArr = arr1;
    } else {
      biggestArr = arr1;
      smallestArr = arr2;
    }
    smallestArr.forEach((entity) => {
      smallestMap.set(keyGetter(entity), entity);
    });
    biggestArr.forEach((entity) => {
      if (!smallestMap.has(keyGetter(entity))) uniqueArr.push(entity);
    });
    return uniqueArr;
  };

  /** Возвращает массив удалённых элементов из первого, сравнивая со вторым массивом */
  public static findRemoved = <T extends Entity>(oldArr: T[], newArr: T[], keyGetter: (v: T) => string = (v) => v.id): T[] => {
    const oldSet = new SetCustom(oldArr, keyGetter);
    newArr.forEach((item) => oldSet.delete(keyGetter(item)));
    return Array.from(oldSet.values());
  };

  /** @todo Можно заморочиться и написать продвинутые алгоритмы при работе с большими данными */
  public static getByIds<T extends Entity>(ids: string[], entities: T[]): T[] {
    return ids.reduce((acc, id) => {
      const entity = entities.find((m) => m.id === id);
      if (entity) acc.push(entity);
      return acc;
    }, [] as T[]);
  }

  /** @returns Обновляет записи из нового списка в старом, отсутствующие добавляет в старый в конец; возвращает новый массив */
  public static updateList<T extends Entity>(newList: T[], oldList: T[]): T[] {
    return EntityUtils.filterDuplicates(oldList, newList);
  }

  // TODO добавить компаратор на вход для возможность реализации поиска в отсортированном массиве
  // TODO возможно стоит не мутировать, а создавать новый список, т.к. списки в основном берутся из хранилища, а там они иммутабельны
  /** @returns Обновляет запись в массиве, если отсутствует, то добавляет вконец; мутирует старый массив */
  public static replaceInList<T extends Entity>(list: T[], newEntity: T) {
    const index = list.findIndex((i) => i.id === newEntity.id);
    if (index === -1) list.push(newEntity);
    else list[index] = newEntity;
  }

  /**
   * @param path строка с вложенностью пропсов через точку
   * @param obj объект, в котором искать
   * @returns пропс по указанному адресу либо null если такого адрес не существует
   */
  public static getPropDeep<T>(path: string, obj: T): any | null {
    const strings = path.split('.') as (keyof T)[];
    return strings.reduce((a, i) => (a ? a[i] : null), obj as any);
  }
}

// TODO: Старое, поудалять после рефакторинга страниц заказа

// Sort entities ----------------------------------------
/**
 * @deprecated The method should not be used
 */
export function sortEntitiesAlphabetically(a: EntityDTOResponse, b: EntityDTOResponse) {
  return a.id.localeCompare(b.id);
}
/**
 * @deprecated The method should not be used
 */
export function sortEntitiesByDateDescending(a: ModelDTOResponse | Model, b: ModelDTOResponse | Model) {
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
}
/**
 * @deprecated The method should not be used
 */
export function sortEntitiesByDateAscending(a: ModelDTOResponse, b: ModelDTOResponse) {
  return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
}

// Compare entities ----------------------------------------
/**
 * @deprecated The method should not be used
 */
export function areArraysEqualSimple(array1: string[], array2: string[]) {
  if (array1?.length !== array2?.length) return false;
  const array2Sorted = array2.slice(0).sort();
  return array1
    .slice(0)
    .sort()
    .every((item, index) => array2Sorted[index] === item);
}
/**
 * @deprecated The method should not be used
 */
export function areArraysEqual(oldValues: Entity[] = [], newValues: Entity[] = []) {
  if (oldValues?.length !== newValues?.length) return false;
  const oldValuesSorted = oldValues.slice(0).sort(sortEntitiesAlphabetically);
  return newValues
    .slice(0)
    .sort(sortEntitiesAlphabetically)
    .every((value, index) => areObjectsEqual(value, oldValuesSorted[index]));
}
/**
 * @deprecated The method should not be used
 */
export function areObjectsEqual(
  oldValue?: (EntityDTOResponse & { updatedAt?: string | Date }) | null,
  newValue?: (EntityDTOResponse & { updatedAt?: string | Date }) | null
) {
  return oldValue?.id === newValue?.id && oldValue?.updatedAt?.toString() === newValue?.updatedAt?.toString();
}
