import { Stack, Tooltip, Typography } from '@mui/material';
import { InfoIcon } from 'components/ui/icons';
import { PRICE_VALUE_TYPE } from '../../../../typings/models/price.enum';
import useLocales from '../../../../hooks/useLocales';
import useDI from '../../../../hooks/useDI';

type PriceWithTooltipProps = {
  price: PriceValue | null;
  isClientPrice: boolean;
  initialPrice?: number;
  text: string;
  quantity: number;
  productPrice: number;
};
export default function PriceWithTooltip(props: PriceWithTooltipProps) {
  const { translate } = useLocales();
  const { statefulUtils } = useDI();
  let { price, text, productPrice, quantity, initialPrice, isClientPrice } = props;
  if (!price || !price.value || (price.type === PRICE_VALUE_TYPE.percent && !productPrice)) {
    return null;
  }

  const tooltipTitle = `${price.value}% ${translate('entities.price.fromThePrice')}${
    price.minAmount ? `, ${translate('entities.price.minShort')} ${statefulUtils.money.toString(price.minAmount)}` : ''
  }${price.maxAmount ? `, ${translate('entities.price.maxShort')} ${statefulUtils.money.toString(price.maxAmount)}` : ''}`;

  let totalPrice;
  switch (price.type) {
    case PRICE_VALUE_TYPE.amount:
      if (isClientPrice && initialPrice) {
        totalPrice = initialPrice * quantity;
      } else {
        totalPrice = price.value * quantity;
      }
      break;
    case PRICE_VALUE_TYPE.category:
      if (initialPrice) {
        totalPrice = initialPrice || 0;
      } else {
        totalPrice = productPrice * price.value * 0.01;
      }
      if (price.minAmount && totalPrice < price.minAmount) {
        totalPrice = price.minAmount;
      }
      if (price.maxAmount && totalPrice > price.maxAmount) {
        totalPrice = price.maxAmount;
      }
      totalPrice *= quantity;
      break;
    case PRICE_VALUE_TYPE.percent:
      const percent = price.value / 100;
      const tempPrice = productPrice * percent;
      totalPrice = tempPrice;
      if (price.minAmount && tempPrice < price.minAmount) {
        totalPrice = price.minAmount;
      }
      if (price.maxAmount && tempPrice > price.maxAmount) {
        totalPrice = price.maxAmount;
      }
      totalPrice *= quantity;
      break;
  }
  const totalPriceText = statefulUtils.money.toString(totalPrice);

  return (
    <Stack direction={'row'} spacing={1} sx={{ mt: 4 }}>
      <Typography color="text.secondary" variant="caption">
        {`${text}: ${totalPriceText}`}
      </Typography>
      {price.type !== PRICE_VALUE_TYPE.amount && (
        <Tooltip title={tooltipTitle} placement="top">
          <InfoIcon sx={{ width: 16, height: 16, color: 'black' }} />
        </Tooltip>
      )}
    </Stack>
  );
}
