// eslint-disable-next-line import/no-duplicates
import { differenceInHours, addMinutes, add, subMinutes, Locale, Duration } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import getTimezoneOffset from 'date-fns-tz/getTimezoneOffset';
import ILanguageService from 'services/language/ILanguageService';
import StringUtils from './String';
import { TIME_ZONE } from 'typings/common/date.enum';
import DateWithTimezone from './implementations/DateWithTimezone';

enum DATE_FORMATS {
  dateLong = 'dateLong',
  dateMedium = 'dateMedium',
  dateShort = 'dateShort',
  dateTimeLong = 'dateTimeLong',
  dateTimeMedium = 'dateTimeMedium',
  dateTimeShort = 'dateTimeShort',
  intervalTimeWithDateFromShort = 'intervalTimeWithDateFromShort',
  intervalTimeWithDateFromMedium = 'intervalTimeWithDateFromMedium',
  intervalTimeWithDateFromLong = 'intervalTimeWithDateFromLong',
  timeMedium = 'timeMedium',
  timeLong = 'timeLong',
  //
  monthOnly = 'monthOnly',
}

// Касательно оптимизации date-fns это бесполезно, т.к. AdapterDateFns всё равно импортирует всю библиотеку, а без него никак (проверить, будет ли переиспользована библиотека из адаптера, или импортируется новая)
class DateUtils {
  public static readonly DAY_MS = 1000 * 60 * 60 * 24;
  private static currentTimezone: TIME_ZONE | null = null;
  private readonly languageService: ILanguageService;
  private readonly intlLocale: ILanguageService.LocaleFull;
  /** Нужно mui */
  private readonly componentsAdapter = AdapterDateFns;
  /** Локаль в date-fns */
  private readonly serviceLocale: Locale;
  // Старая реализация relative через date-fns
  // private readonly formatRelativeLocale: { [key: string]: string };

  private readonly relativeTimeFormatter: Intl.RelativeTimeFormat;
  private readonly dateTimeFormattersMap: Record<DATE_FORMATS, DateTimeFormatterGetter> = {
    [DATE_FORMATS.dateLong]: this.dateFormatterGetterFactory({ dateStyle: 'full' }),
    [DATE_FORMATS.dateMedium]: this.dateFormatterGetterFactory({ dateStyle: 'long' }),
    [DATE_FORMATS.dateShort]: this.dateFormatterGetterFactory({ year: 'numeric', month: '2-digit', day: '2-digit' }),
    [DATE_FORMATS.dateTimeLong]: this.dateFormatterGetterFactory({ dateStyle: 'full' }),
    [DATE_FORMATS.dateTimeMedium]: this.dateFormatterGetterFactory({ dateStyle: 'long', timeStyle: 'short' }),
    [DATE_FORMATS.dateTimeShort]: this.dateFormatterGetterFactory({
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }),
    [DATE_FORMATS.intervalTimeWithDateFromShort]: this.dateFormatterGetterFactory({}),
    [DATE_FORMATS.intervalTimeWithDateFromMedium]: this.dateFormatterGetterFactory({}),
    [DATE_FORMATS.intervalTimeWithDateFromLong]: this.dateFormatterGetterFactory({}),
    [DATE_FORMATS.timeMedium]: this.dateFormatterGetterFactory({ timeStyle: 'short' }),
    [DATE_FORMATS.timeLong]: this.dateFormatterGetterFactory({ timeStyle: 'medium' }),
    [DATE_FORMATS.monthOnly]: this.dateFormatterGetterFactory({ month: 'long' }),
  };

  constructor(languageService: ILanguageService) {
    this.intlLocale = languageService.getCurrentLocale().localeFull;
    this.relativeTimeFormatter = new Intl.RelativeTimeFormat(this.intlLocale, { numeric: 'auto', style: 'long' });
    const localeDate = languageService.getCurrentLocale().localeDate;
    if (!localeDate) throw new Error('Date locale required');
    this.serviceLocale = localeDate;
    this.languageService = languageService;

    // Старая реализация relative через date-fns
    // this.formatRelativeLocale = {
    //   lastWeek: `'${this.languageService.translate('common.date.relative.lastWeek')}'`,
    //   yesterday: `'${this.languageService.translate('common.date.relative.yesterday')}'`,
    //   today: `'${this.languageService.translate('common.date.relative.today')}'`,
    //   tomorrow: `'${this.languageService.translate('common.date.relative.tomorrow')}'`,
    //   nextWeek: `'${this.languageService.translate('common.date.relative.nextWeek')}'`,
    //   other: `'${this.formats.dateLong}'`,
    // };
    // this.serviceLocale = {
    //   ...serviceLocale,
    //   formatRelative: (token: string) => this.formatRelativeLocale[token],
    // };

    this.getDateTimeStr = this.getDateTimeStr.bind(this);
    this.getDateStr = this.getDateStr.bind(this);
    this.getTimeStr = this.getTimeStr.bind(this);
    this.getIntervalDateTimeStr = this.getIntervalDateTimeStr.bind(this);
    this.getIntervalTimeWithDateStr = this.getIntervalTimeWithDateStr.bind(this);
    this.getIntervalTimeStr = this.getIntervalTimeStr.bind(this);
  }

  // Date time str ------------------------------------------------------------------------------------------------------------

  public getDateTimeStr(date: DateWithTimezone, format?: DateUtils.OutputFormat): string;
  public getDateTimeStr(date: Date, format?: DateUtils.OutputFormat, timezone?: TIME_ZONE): string;
  public getDateTimeStr(param1: Date | DateWithTimezone, format: DateUtils.OutputFormat = 'medium', timezoneRaw?: TIME_ZONE): string {
    let formatter: Intl.DateTimeFormat;
    const [date, timezone] = this.toRawDateAndZone(param1, timezoneRaw);

    switch (format) {
      case 'short': {
        formatter = this.dateTimeFormattersMap[DATE_FORMATS.dateTimeShort](timezone);
        break;
      }
      case 'long': {
        formatter = this.dateTimeFormattersMap[DATE_FORMATS.dateTimeLong](timezone);
        break;
      }
      default: {
        formatter = this.dateTimeFormattersMap[DATE_FORMATS.dateTimeMedium](timezone);
      }
    }
    return StringUtils.capitalizeFirstLetter(formatter.format(date));
  }

  /** Относительно текущей даты ("n-минут назад" и пр.) */
  public getRelativeStr = (date: Date | DateWithTimezone, baseDate: Date = new Date()): string => {
    if (date instanceof DateWithTimezone) {
      date = date.getRawDate();
    }
    let duration = (date.getTime() - baseDate.getTime()) / 1000;
    // Двигаясь по DIVISIONS ищем ту, что в пределах данной duration, при этом каждый раз обновляя duration для следующего шага + дефолтная перестраховка для ts в виде DIVISIONS[0]
    const division = TIME_DIVISIONS.find((d) => Math.abs(duration) < d.amount || !(duration /= d.amount)) || TIME_DIVISIONS[0];
    return this.relativeTimeFormatter.format(Math.round(duration), division.name);
  };

  // Date str ------------------------------------------------------------------------------------------------------------

  public getDateStr(date: DateWithTimezone, format?: DateUtils.OutputFormat): string;
  public getDateStr(date: Date, format?: DateUtils.OutputFormat, timezone?: TIME_ZONE): string;
  public getDateStr(param1: Date | DateWithTimezone, format: DateUtils.OutputFormat = 'medium', timezoneRaw?: TIME_ZONE): string {
    let formatter: Intl.DateTimeFormat;
    const [date, timezone] = this.toRawDateAndZone(param1, timezoneRaw);

    switch (format) {
      case 'short': {
        formatter = this.dateTimeFormattersMap[DATE_FORMATS.dateShort](timezone);
        break;
      }
      case 'long': {
        formatter = this.dateTimeFormattersMap[DATE_FORMATS.dateLong](timezone);
        break;
      }
      default: {
        formatter = this.dateTimeFormattersMap[DATE_FORMATS.dateMedium](timezone);
      }
    }
    return StringUtils.capitalizeFirstLetter(formatter.format(date));
  }

  // public getMonthStrArr = () => {
  //   const format = this.dateTimeFormattersMap[DATE_FORMATS.monthOnly]().format;
  //   return Array(12)
  //     .fill(null)
  //     .map((_, i) => format(new Date(2021, i)));
  // };

  // TODO поддержка DateWithTimezone и отдельной timezone?
  public getMonthStr = (date: Date) => {
    return this.dateTimeFormattersMap[DATE_FORMATS.monthOnly]().format(date);
  };

  // Time str ------------------------------------------------------------------------------------------------------------

  public getTimeStr(date: DateWithTimezone, format?: DateUtils.OutputFormat): string;
  public getTimeStr(date: Date, format?: DateUtils.OutputFormat, timezone?: TIME_ZONE): string;
  public getTimeStr(param1: Date | DateWithTimezone, format: DateUtils.OutputFormat = 'medium', timezoneRaw?: TIME_ZONE): string {
    let formatter: Intl.DateTimeFormat;
    const [date, timezone] = this.toRawDateAndZone(param1, timezoneRaw);

    switch (format) {
      case 'long': {
        formatter = this.dateTimeFormattersMap[DATE_FORMATS.timeLong](timezone);
        break;
      }
      default: {
        formatter = this.dateTimeFormattersMap[DATE_FORMATS.timeMedium](timezone);
      }
    }
    return StringUtils.capitalizeFirstLetter(formatter.format(date));
  }

  /** @returns `[hours, minutes]` */
  public minutesToTimeArr = (minutesAll: number): [number, number] => {
    const hours = Math.floor(minutesAll / 60);
    const minutes = minutesAll % 60;
    return [hours, minutes];
  };

  public secondsToTimeStr = (seconds: number): string => {
    const minutes = seconds % 60;
    const hours = Math.floor(minutes / 60);
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  };

  // На Intl пока не реализовано https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DurationFormat
  public getDurationStr = (durationSeconds: number) => {
    const parts: Array<number | undefined> = [];
    let currentStepDuration = durationSeconds;
    for (let i = 0; i < TIME_DIVISIONS.length; i++) {
      const currentDivision = TIME_DIVISIONS[i];
      if (Math.abs(currentStepDuration) < currentDivision.amount) {
        parts.push(currentStepDuration);
        break;
      } else {
        parts.push(currentStepDuration % currentDivision.amount);
        currentStepDuration = Math.trunc(currentStepDuration / currentDivision.amount);
      }
    }
    const [_, minutes = 0, hours = 0] = parts;
    return this.languageService.translate('common.date.durationMediumMask', { hours, minutes });
  };

  // intervals ------------------------------------------------------------------------------------------------------------

  public getHourInterval(interval: DateInterval | DateIntervalWithTimezone): number;
  public getHourInterval(dateFrom: Date, dateTo: Date): number;
  public getHourInterval(param1: Date | DateInterval | DateIntervalWithTimezone, param2?: Date): number {
    // handle overload
    let dateFrom: Date;
    let dateTo: Date;
    if ('from' in param1) {
      [dateFrom] = this.toRawDateAndZone(param1.from);
      [dateTo] = this.toRawDateAndZone(param1.to);
    } else {
      dateFrom = param1 as Date;
      dateTo = param2 as Date;
    }
    // ^----

    return differenceInHours(dateTo, dateFrom);
  }

  // Допускается также равенство дат
  public isIntervalValid = (
    intervalRaw?: DateInterval | DateIntervalWithTimezone | Partial<DateInterval> | Partial<DateIntervalWithTimezone> | null
  ): boolean => {
    if (!intervalRaw?.from || !intervalRaw?.to) return false;
    else return intervalRaw.to.getTime() - intervalRaw.from.getTime() >= 0;
  };

  public getIntervalDateTimeStr(interval: DateIntervalWithTimezone, format?: DateUtils.OutputFormat): string;
  public getIntervalDateTimeStr(interval: DateInterval, format?: DateUtils.OutputFormat, timezone?: TIME_ZONE): string;
  public getIntervalDateTimeStr(
    param1: DateInterval | DateIntervalWithTimezone,
    format: DateUtils.OutputFormat = 'medium',
    timezoneRaw?: TIME_ZONE
  ): string {
    const [timeFrom, timezone] = this.toRawDateAndZone(param1.from, timezoneRaw);
    const [timeTo] = this.toRawDateAndZone(param1.to);
    let dateFormatter: Intl.DateTimeFormat;
    let timeFormatter: Intl.DateTimeFormat = this.dateTimeFormattersMap[DATE_FORMATS.timeMedium](timezone);
    switch (format) {
      case 'short': {
        dateFormatter = this.dateTimeFormattersMap[DATE_FORMATS.dateShort](timezone);
        break;
      }
      case 'long': {
        dateFormatter = this.dateTimeFormattersMap[DATE_FORMATS.dateMedium](timezone);
        break;
      }
      default: {
        dateFormatter = this.dateTimeFormattersMap[DATE_FORMATS.dateLong](timezone);
      }
    }

    const fromStr = dateFormatter.format(timeFrom) + ', ' + timeFormatter.format(timeFrom);
    const toStr = dateFormatter.format(timeTo) + ', ' + timeFormatter.format(timeTo);
    return StringUtils.capitalizeFirstLetter(fromStr + ' – ' + toStr);
  }

  public getIntervalDateStr(interval: DateIntervalWithTimezone, format?: DateUtils.OutputFormat): string;
  public getIntervalDateStr(interval: DateInterval, format?: DateUtils.OutputFormat, timezone?: TIME_ZONE): string;
  public getIntervalDateStr(
    param1: DateInterval | DateIntervalWithTimezone,
    format: DateUtils.OutputFormat = 'medium',
    timezoneRaw?: TIME_ZONE
  ): string {
    const [timeFrom, timezone] = this.toRawDateAndZone(param1.from, timezoneRaw);
    const [timeTo] = this.toRawDateAndZone(param1.to);
    let dateFormatter: Intl.DateTimeFormat;

    switch (format) {
      case 'short': {
        dateFormatter = this.dateTimeFormattersMap[DATE_FORMATS.dateShort](timezone);
        break;
      }
      case 'long': {
        dateFormatter = this.dateTimeFormattersMap[DATE_FORMATS.dateMedium](timezone);
        break;
      }
      default: {
        dateFormatter = this.dateTimeFormattersMap[DATE_FORMATS.dateLong](timezone);
      }
    }

    const fromStr = dateFormatter.format(timeFrom);
    const toStr = dateFormatter.format(timeTo);
    return StringUtils.capitalizeFirstLetter(fromStr + ' – ' + toStr);
  }

  public getIntervalTimeWithDateStr(interval: DateIntervalWithTimezone, format?: DateUtils.OutputFormat): string;
  public getIntervalTimeWithDateStr(interval: DateInterval, format?: DateUtils.OutputFormat, timezone?: TIME_ZONE): string;
  public getIntervalTimeWithDateStr(
    param1: DateInterval | DateIntervalWithTimezone,
    format: DateUtils.OutputFormat = 'medium',
    timezoneRaw?: TIME_ZONE
  ): string {
    const [timeFrom, timezone] = this.toRawDateAndZone(param1.from, timezoneRaw);
    const [timeTo] = this.toRawDateAndZone(param1.to);
    let dateFormatter: Intl.DateTimeFormat;
    let timeFormatter: Intl.DateTimeFormat = this.dateTimeFormattersMap[DATE_FORMATS.timeMedium](timezone);
    switch (format) {
      case 'short': {
        dateFormatter = this.dateTimeFormattersMap[DATE_FORMATS.dateShort](timezone);
        break;
      }
      case 'long': {
        dateFormatter = this.dateTimeFormattersMap[DATE_FORMATS.dateLong](timezone);
        break;
      }
      default: {
        dateFormatter = this.dateTimeFormattersMap[DATE_FORMATS.dateMedium](timezone);
      }
    }

    const str = dateFormatter.format(timeFrom) + ', ' + timeFormatter.format(timeFrom) + ' – ' + timeFormatter.format(timeTo);
    return StringUtils.capitalizeFirstLetter(str);
  }

  public getIntervalTimeStr(interval: DateIntervalWithTimezone, format?: DateUtils.OutputFormat): string;
  public getIntervalTimeStr(interval: DateInterval, format?: DateUtils.OutputFormat, timezone?: TIME_ZONE): string;
  public getIntervalTimeStr(
    param1: DateInterval | DateIntervalWithTimezone,
    format: DateUtils.OutputFormat = 'medium',
    timezoneRaw?: TIME_ZONE
  ): string {
    const [timeFrom, timezone] = this.toRawDateAndZone(param1.from, timezoneRaw);
    const [timeTo] = this.toRawDateAndZone(param1.to);
    let formatter: Intl.DateTimeFormat;
    switch (format) {
      case 'long': {
        formatter = this.dateTimeFormattersMap[DATE_FORMATS.timeLong](timezone);
        break;
      }
      default: {
        formatter = this.dateTimeFormattersMap[DATE_FORMATS.timeMedium](timezone);
      }
    }
    return StringUtils.capitalizeFirstLetter(formatter.format(timeFrom) + ' - ' + formatter.format(timeTo));
  }

  // Utils

  public static isValidDate(d: any) {
    return d instanceof Date && !Number.isNaN(d.getTime());
  }

  // Статик, т.к. требуется получить локаль вне экосистемы реакта (вне di); пока единичный случай, если случаи добавятся, можно будет подумать
  public static getCurrentTimezone(): TIME_ZONE {
    if (this.currentTimezone) {
      return this.currentTimezone;
    } else {
      this.currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone as TIME_ZONE;
      return this.currentTimezone;
    }
  }

  public isValidDate = DateUtils.isValidDate;

  public getCurrentTimezone = DateUtils.getCurrentTimezone;

  public addTime = (date: Date, dataToAdd: Duration) => {
    return add(date, dataToAdd);
  };

  public isMoreThanCurrent(date: Date | DateWithTimezone) {
    if (date instanceof DateWithTimezone) {
      date = date.getRawDate();
    }
    if (!this.isValidDate(date)) return false;
    return date.getTime() - Date.now() > 0;
  }

  /**
   * Офсет таймзоны от Zulu в минутах.
   * @param timeZone Если не передано, то использует текущую.
   * @param date дата нужна, т.к. в некоторых зонах в разное время разное смещение (летнее, зимнее)
   */
  public getTimezoneOffsetMin = (timeZone: TIME_ZONE, date?: Date): number => {
    return getTimezoneOffset(timeZone, date) / 1000 / 60;
    // Есть мега костыльный способ через Intl, но там мутная поддержка параметра timeZoneName, да и текущий через date-fns-tz весит мало
    // const [hoursStr = 0, minutesStr = 0] =
    //   new Intl.DateTimeFormat('jp', { timeZone, timeZoneName: 'short' })
    //     .formatToParts(new Date())
    //     .find((e) => e.type === 'timeZoneName')
    //     ?.value.replace('GMT', '')
    //     .split(':') || [];
    // const hoursToMinutes = Number(hoursStr) * 60;
    // return hoursToMinutes > 0 ? hoursToMinutes + Number(minutesStr) : hoursToMinutes - Number(minutesStr);
  };

  /**
   * Группирует объекты по дням в их дате
   * @param itemList массив объектов c датой
   * @param keyGetter геттер даты по которой сравниваем
   * @returns карта сопоставления даты (цифрой) с массивом объектов, содержащих её
   */
  public getGroupedDates<T>(itemList: T[], keyGetter: (v: T) => Date): Map<number, T[]> {
    const dateMap = new Map<number, T[]>();

    itemList.forEach((item) => {
      const date: Date = new Date(keyGetter(item)); // Дату нужно клонировать
      date.setMilliseconds(0);
      date.setSeconds(0);
      date.setMinutes(0);
      date.setHours(0);
      const dateNumber = date.getTime();
      const dateGroup = dateMap.has(dateNumber) ? dateMap.get(dateNumber)! : [];
      dateGroup.push(item);
      dateMap.set(dateNumber, dateGroup);
    });

    return dateMap;
  }

  /**
   * 1. Перед отправкой на бек преобразовывает отображаемую на фронте дату (цифры даты и времени), состоящую из смещения пояса браузера + zulu, таким образом,
   * чтобы в указанной зоне мы получили те же цифры даты и времени, (получается смещение переданной зоны + zulu для неё). Т.е. если на фронте ставим в полдень, то и после преобразования в новой зоне эта дата наступит в их полдень.
   * 2. Также можно провести обратное преобразование - присланную дату с бека преобразовать в зону браузера, но нужно чтобы обязательно было передано поле timezoneFrom
   * @param date дата с которой работаем, она всегда в зоне браузера (это поведение языка)
   * @param timezoneTo зона, к которой надо привести, если не передано, то приводит к браузерной
   * @param timezoneFrom зона, в которой находилась изначально дата (до парсинга в объект Date, т.к. на этом месте язык обрезает зону), если не передано, то браузерная
   * @returns судя по всему возвращает входящий инстанс Date (мутирует его)
   */
  public changeTimezone = (date: Date, timezoneTo?: TIME_ZONE, timezoneFrom?: TIME_ZONE): Date => {
    const currentTimezone = this.getCurrentTimezone();
    if (!timezoneTo) {
      timezoneTo = currentTimezone;
    }
    if (!timezoneFrom) {
      timezoneFrom = currentTimezone;
    }
    const timezoneFromOffset = this.getTimezoneOffsetMin(timezoneFrom, date);
    const timezoneToOffset = this.getTimezoneOffsetMin(timezoneTo, date);
    if (timezoneFromOffset === timezoneToOffset) {
      return date;
    }
    return subMinutes(date, timezoneToOffset - timezoneFromOffset);
  };

  /**
   * Применяет зону ко времени в zulu, чтобы получить новую дату в zulu, в которой учтено смещение указанного часового пояса и пояса браузера.
   * Используется на фронте для отображения читаемой даты в другой зоне (отличной от браузерной).
   * Intl.DateTimeFormat умеет делать такое смещение сам, но лишь для отображения строки даты.
   * @deprecated похоже что-то старое и не используется
   */
  public applyTimezone = (date: Date, timezoneTo?: TIME_ZONE): Date => {
    if (timezoneTo) {
      const timezoneFrom = this.getCurrentTimezone();
      const timezoneFromOffset = this.getTimezoneOffsetMin(timezoneFrom, date);
      const timezoneToOffset = this.getTimezoneOffsetMin(timezoneTo, date);
      return addMinutes(date, timezoneToOffset - timezoneFromOffset);
    } else {
      return date;
    }
  };

  /**
   * @see DateTimeFormatterGetter
   * Т.к. это браузерный сервис, то и время ему надо передавать браузерное, без учета зоны, а зону отдельно
   */
  private dateFormatterGetterFactory(options: Intl.DateTimeFormatOptions): DateTimeFormatterGetter {
    return (timeZone?: string) => {
      options.timeZone = timeZone;
      return new Intl.DateTimeFormat(this.intlLocale, options);
    };
  }

  /** Утильный метод, чтобы приводить Date и DateWithTimezone к одному виду */
  private toRawDateAndZone = (date: Date | DateWithTimezone, timezone?: TIME_ZONE): [Date, TIME_ZONE] => {
    if (date instanceof DateWithTimezone) {
      return [date.getRawDate(), timezone || date.getTimezone()];
    } else {
      return [date, timezone || this.getCurrentTimezone()];
    }
  };

  // Getters

  public getLocale = () => this.serviceLocale;

  public getAdapter = () => this.componentsAdapter;
}

namespace DateUtils {
  export type OutputFormat = 'short' | 'long' | 'medium';
}

/** Геттер инстанса Intl.DateTimeFormat для отображения строки даты на фронте */
type DateTimeFormatterGetter = (timezone?: TIME_ZONE) => Intl.DateTimeFormat;

/** Нужно для вычисления относительных текстовых дат */
const TIME_DIVISIONS: { amount: number; name: Intl.RelativeTimeFormatUnit }[] = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' },
];

export default DateUtils;
