import FrontendRoutesBase from './FrontendRoutesBase';
import { PAGE_TYPE, PATH_PARAM_DASHBOARD, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';

export default class ServiceProviderRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public root = this.getDBPath(PATH_PARAM_DASHBOARD.serviceProviderAccount.root);

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter = (_params, permissions, translate) => {
    return [{ name: translate('menus.main.serviceCompanies'), href: this.getListPath() }];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.serviceProviderAccount, 'serviceProviderName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    return this.getInfoBc(params, permissions, translate).concat([{ name: names.serviceProviderName, href: this.getInfoPath(params) }]);
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.serviceProviderAccount.root, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getAddBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProviderAccount> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.serviceProviderAccount.root, params.serviceProviderId, PAGE_TYPE.info);

  getEmployeeListBc = this.getEmptyBreadcrumbs;
  getEmployeeListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProviderAccount> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProviderAccount.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProviderAccount.employees,
      PAGE_TYPE.list
    );

  getNotificationsInfoBc = this.getInstanceBc;
  getNotificationsInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.employees,
      params.employeeId,
      PATH_PARAM_DASHBOARD.user.notifications,
      PAGE_TYPE.info
    );

  getNotificationsEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<
    typeof PATH_PARAM_TOKEN.serviceProvider,
    'serviceProviderName' | 'userName'
  > = (p, pm, t, n) => {
    return this.getAddBc(p, pm, t).concat([{ name: n.userName, href: this.getNotificationsInfoPath(p) }]);
  };
  getNotificationsEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.employees,
      params.employeeId,
      PATH_PARAM_DASHBOARD.user.notifications,
      PAGE_TYPE.edit
    );
}
