import ILanguageService from 'services/language/ILanguageService';

export default class MoneyUtils {
  // private languageService: ILanguageService
  private intlLocale: ILanguageService.LocaleFull;
  private defaultCurrencyCode: ILanguageService.CurrencyCode;

  constructor(languageService: ILanguageService) {
    const locale = languageService.getCurrentLocale();
    this.intlLocale = locale.localeFull;
    this.defaultCurrencyCode = locale.localeCurrency;
  }

  /**
   * @param currencyCode @default defaultCurrencyCode
   * @param showSymbol @default true
   */
  public toString = (number: number, currencyCode: ILanguageService.CurrencyCode = this.defaultCurrencyCode, showSymbol = true): string => {
    if (showSymbol) {
      return new Intl.NumberFormat(this.intlLocale, { style: 'currency', currency: currencyCode }).format(number);
    }

    return new Intl.NumberFormat(this.intlLocale, { style: 'currency', currency: currencyCode, currencyDisplay: 'code' })
      .format(number)
      .replace(currencyCode, '')
      .trim();
  };
}
