import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import TextFieldMemorized from 'components/ui/forms/TextFieldMemorized';
import { Link, Stack, Typography, Switch } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import RoutingService from 'services/routing/RoutingService';
import FormProvider from 'components/ui/forms/FormProvider';
import { useFormik } from 'formik';
import TogglePasswordButton from 'components/ui/buttons/TogglePasswordButton';
import { EMAIL_VALIDATION_REGEXP, PASSWORD_MIN_LENGTH } from 'configs/vars';
import useDI from 'hooks/useDI';
import PhoneInputMemorized from 'components/ui/forms/PhoneInputMemorized';
import { INPUT_FULL_WIDTH_CLASS_NAME } from 'configs/layout';

export default function LoginForm() {
  const { statefulUtils, storageActions, services } = useDI();
  const { translate } = services.language;
  const [showPassword, setShowPassword] = useState(false);
  const [useEmail, setUseEmail] = useState(true);
  const formErrorHandler = useSnackbarErrorHandler();

  const initialValues = {
    email: '',
    phone: '',
    password: '',
  };

  const fieldIsRequiredText = translate('errors.fieldIsRequired');
  const emailIsNotValidText = translate('errors.emailIsNotValid');
  const phoneOrEmailRequiredText = translate('errors.phoneOrEmailRequired');
  const invalidPhoneFormatText = translate('errors.invalidPhoneFormat');
  const valueIsTooSmallText = translate('errors.minimumStrLengthValueRequired');
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(EMAIL_VALIDATION_REGEXP, emailIsNotValidText)
      .test('oneOfFields', phoneOrEmailRequiredText, (thisValue, testContext) => Boolean(testContext.parent.phone || thisValue)),
    phone: Yup.string()
      .test({ message: invalidPhoneFormatText, test: (v) => !v || statefulUtils.phone.validate(v) })
      .test({ message: phoneOrEmailRequiredText, test: (thisValue, testContext) => Boolean(testContext.parent.email || thisValue) }),
    password: Yup.string().min(PASSWORD_MIN_LENGTH, valueIsTooSmallText).required(fieldIsRequiredText),
  });

  const formikState = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting, setFieldValue }) => {
      const dto = { password: values.password, emailOrPhone: values.email || statefulUtils.phone.getRFC3966(values.phone) };
      storageActions.auth.login(dto).catch((error) => {
        formErrorHandler({ setSubmitting, error });
        setFieldValue('password', '');
      });
    },
  });

  const { isSubmitting, isValid, setFieldValue } = formikState;

  const loginMethodHandler = () => {
    const newValue = !useEmail;
    setUseEmail(newValue);
    if (newValue === true) setFieldValue('phone', '');
    else setFieldValue('email', '');
  };

  return (
    <FormProvider formState={formikState}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
        <Typography component="div" variant="body2">
          {translate('pages.login.enterEmail')}
        </Typography>
        <Switch onChange={loginMethodHandler} checked={!useEmail} color="default" />
        <Typography component="div" variant="body2">
          {translate('pages.login.enterPhone')}
        </Typography>
      </Stack>

      {useEmail ? (
        <TextFieldMemorized
          fieldName="email"
          placeholder="your@email.com"
          label={translate('pages.auth.email')}
          formState={formikState}
          fullWidth
        />
      ) : (
        <PhoneInputMemorized
          fieldName="phone"
          label={translate('pages.auth.phone')}
          formState={formikState}
          autoFocus
          className={INPUT_FULL_WIDTH_CLASS_NAME}
        />
      )}
      <TextFieldMemorized
        key={`pass_${showPassword}`}
        autoComplete="current-password"
        fieldName="password"
        type={showPassword ? 'text' : 'password'}
        label={translate('pages.auth.password')}
        formState={formikState}
        InputProps={{ endAdornment: <TogglePasswordButton showPassword={showPassword} setShowPassword={setShowPassword} /> }}
        fullWidth
      />

      <Link sx={{ my: 2 }} component={RouterLink} variant="subtitle2" to={RoutingService.auth.getResetPasswordPath()}>
        {translate('pages.login.forgotPassword')}
      </Link>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} disabled={!isValid}>
        {translate('pages.login.enter')}
      </LoadingButton>
    </FormProvider>
  );
}
