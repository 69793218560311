import { PAGE_TYPE, PATH_PARAM_DASHBOARD, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import FrontendRoutesBase from './FrontendRoutesBase';
import IFrontendRoutes from './IFrontendRoutes';
import { FUNDS_TRANSACTION_TYPE } from '../../../typings/models/fundsTransaction.enum';

export default class FundsTransactionRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  root = this.getDBPath(PATH_PARAM_DASHBOARD.fundsTransaction.root);

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.fundsTransaction> = (_params, _permissions, translate) => {
    return [
      {
        name: translate('menus.main.fundsTransaction'),
        href: _params.type === FUNDS_TRANSACTION_TYPE.addition ? this.getListPath() : this.getDeductionListPath(),
      },
    ];
  };
  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.fundsTransaction, 'fundsTransactionName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const breadcrumbs = this.getInstanceBc(params, permissions, translate);
    breadcrumbs.push({ name: names.fundsTransactionName, href: this.getInfoPath(params) });
    return breadcrumbs;
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.fundsTransaction.root, PATH_PARAM_DASHBOARD.fundsTransaction.addition, PAGE_TYPE.list);

  getDeductionListPath = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.fundsTransaction.root, PATH_PARAM_DASHBOARD.fundsTransaction.deduction, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.fundsTransaction> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.fundsTransaction.root, params.fundsTransactionId, PAGE_TYPE.info);

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.fundsTransaction> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.fundsTransaction.root, PAGE_TYPE.new, PAGE_TYPE.add, params.type, params.group);

  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.fundsTransaction> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.fundsTransaction.root, params.fundsTransactionId, PAGE_TYPE.edit);
  getEditBc = this.getInstanceChildBc;
}
