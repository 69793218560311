import { useSelector } from 'storage';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import LoaderComponent from 'components/ui/loadingIndicators/LoaderComponent';
import useInitializePage from 'hooks/useInitializePage';
import useDI from 'hooks/useDI';
import { PAGE_TYPE, PATH_PARAM_TOKEN } from 'services/routing/PATH_PARAM';
import ErrorComponent from 'components/features/ErrorComponent';
import OrderAddFromSessionForm from 'components/_dashboardPagesFeatures/order/add/session/OrderAddFromSessionForm';
import OrderAddFromSessionClientDataForm from 'components/_dashboardPagesFeatures/order/add/session/OrderAddFromSessionClientDataForm';
import OrderAddFromSessionAddressDataForm from 'components/_dashboardPagesFeatures/order/add/session/OrderAddFromSessionAddressDataForm';
import OrderAddFromSessionServicesDataForm from 'components/_dashboardPagesFeatures/order/add/session/OrderAddFromSessionServicesDataForm';
import useServiceOrderedPermissionConfigRequest from 'components/_dashboardPagesFeatures/order/serviceOrdered/useServiceOrderedPermissionConfigRequest';
import { useNavigate } from 'react-router-dom';
import RoutingService from 'services/routing/RoutingService';
import { ItemRecommendationDTO } from 'typings/dto/recommendationsConfig';
import useChildForms from 'hooks/useChildForms';
import { ORDER_DEFAULT_TYPE } from 'typings/models/order/orderType.enum';

function OrderAddSessionPage({ isLinked }: { isLinked?: boolean }) {
  const { currentUser } = useSelector((state) => state.auth);
  const { orderSession, orderSessionIsLoading, error } = useSelector((state) => state.orderSession);
  const { enterprise, enterpriseIsLoading } = useSelector((state) => state.enterprise);
  const { services, storageActions } = useDI();
  const { translate } = services.language;
  const pathParams = useParams<typeof PATH_PARAM_TOKEN.orderSession>();
  const [step, setStep] = useState(1);
  const [addCatalogBlockOpened, setAddCatalogBlockOpened] = useState(false);
  const addressFormStateValuesRef = useRef(null);
  const customerFormStateValuesRef = useRef(null);
  const customerPhonesFormStateValuesRef = useRef(null);
  const { permissionConfig, isLoading: isServicePermissionConfigLoading } = useServiceOrderedPermissionConfigRequest(enterprise?.id || '');
  const navigate = useNavigate();
  const [recommendations, setRecommendations] = useState<ItemRecommendationDTO[]>([]);
  const recommendationsChildForms = useChildForms();
  const [linkedOrderId, setLinkedOrderId] = useState<string | null>(null);
  const { order, orderIsLoading } = useSelector((state) => state.order);

  useEffect(() => {
    const orderSessionActions = storageActions.requestScope.orderSession();
    if (pathParams.orderSessionId !== PAGE_TYPE.new) {
      orderSessionActions.getOrderSession(pathParams.orderSessionId || '');
    }
    const orderActions = storageActions.requestScope.order();
    if (pathParams.orderId) {
      setLinkedOrderId(pathParams.orderId);
      orderActions.getById(pathParams.orderId || '');
    }
    const enterpriseActions = storageActions.requestScope.enterprise();
    enterpriseActions.getById(currentUser?.tenant.id || '');
    return () => {
      orderSessionActions.cancelRequests();
      orderSessionActions.resetData();
      orderActions.cancelRequests();
      enterpriseActions.cancelRequests();
      enterpriseActions.resetData();
    };
  }, [pathParams.orderId]);

  useEffect(() => {
    if (
      !pathParams.orderId &&
      orderSession &&
      orderSession.type?.technicalName === ORDER_DEFAULT_TYPE.complaint &&
      orderSession.linkedOrderId
    ) {
      const orderActions = storageActions.requestScope.order();
      setLinkedOrderId(orderSession.linkedOrderId);
      orderActions.getById(orderSession.linkedOrderId);
    }
  }, [orderSessionIsLoading]);

  const cancelSessionHandler = () => {
    if (orderSession) {
      storageActions.models.orderSession.cancelSession(orderSession?.id || '');
    }
    navigate(RoutingService.dashboard.order.root);
  };

  const isPageInitialized = useInitializePage(() => {
    return { title: translate('pages.orderSessionEdit.orderSessionEditTitle') };
  }, [orderSessionIsLoading, enterpriseIsLoading, orderIsLoading]);

  if (error) return <ErrorComponent error={error} />;
  if (!isPageInitialized || isServicePermissionConfigLoading) return <LoaderComponent />;
  if (!enterprise || !permissionConfig || (pathParams.orderId && !order)) return null;
  else {
    switch (step) {
      case 1: {
        return (
          <OrderAddFromSessionForm
            submitHandler={() => {
              setStep(2);
            }}
            linkedOrderId={linkedOrderId}
            cancelHandler={() => {}}
            enterprise={enterprise}
            orderSession={orderSession}
            cancelSessionHandler={cancelSessionHandler}
            parentLinkedOrder={order ? order : undefined}
            isLinked={isLinked}
          />
        );
      }
      case 2: {
        if (!orderSession) return null;
        return (
          <OrderAddFromSessionClientDataForm
            enterprise={enterprise}
            orderSession={orderSession}
            cancelHandler={() => setStep(1)}
            submitHandler={() => {
              setStep(3);
            }}
            cancelSessionHandler={cancelSessionHandler}
            parentLinkedOrder={order ? order : undefined}
            customerFormStateValuesRef={customerFormStateValuesRef}
            customerPhonesFormStateValuesRef={customerPhonesFormStateValuesRef}
          />
        );
      }
      case 3: {
        if (!orderSession) return null;
        return (
          <OrderAddFromSessionAddressDataForm
            enterprise={enterprise}
            orderSession={orderSession}
            cancelHandler={() => setStep(2)}
            submitHandler={() => {
              setStep(4);
            }}
            cancelSessionHandler={cancelSessionHandler}
            parentLinkedOrder={order ? order : undefined}
            addressFormStateValuesRef={addressFormStateValuesRef}
          />
        );
      }
      case 4: {
        if (!orderSession) return null;
        return (
          <OrderAddFromSessionServicesDataForm
            enterprise={enterprise}
            orderSession={orderSession}
            cancelHandler={() => setStep(3)}
            permissionConfig={permissionConfig}
            cancelSessionHandler={cancelSessionHandler}
            recommendations={recommendations}
            setRecommendations={setRecommendations}
            recommendationsChildForms={recommendationsChildForms}
            addBlockOpened={addCatalogBlockOpened}
            setAddBlockOpened={setAddCatalogBlockOpened}
          />
        );
      }
      default:
        return null;
    }
  }
}

export default React.memo(OrderAddSessionPage, () => true);
