import FrontendRoutesBase from './FrontendRoutesBase';
import { PAGE_TYPE, PATH_PARAM_DASHBOARD, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';

export default class InvoiceProviderRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public root = this.getDBPath(PATH_PARAM_DASHBOARD.invoiceProvider.root);
  public order: InvoiceProviderOrderRoutes;

  constructor() {
    super();
    this.order = new InvoiceProviderOrderRoutes(this);
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.invoice> = (params, _permissions, translate) => {
    return [{ name: translate('menus.main.invoicesProvider'), href: this.getListPath(params) }];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.invoice, 'invoiceName'> = (
    params,
    _permissions,
    translate,
    names
  ) => {
    return this.getInstanceBc(params, _permissions, translate).concat([
      { name: `${translate('pages.invoiceInfo.title')} ${names.invoiceName}`, href: this.getInfoPath(params) },
    ]);
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.invoice> = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.invoiceProvider.root, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.invoice> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.invoiceProvider.root, params.invoiceId, PAGE_TYPE.info);

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.invoice> = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.invoiceProvider.root, PAGE_TYPE.new, PAGE_TYPE.add);

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.invoice> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.invoiceProvider.root, params.invoiceId, PAGE_TYPE.edit);
}

class InvoiceProviderOrderRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: InvoiceProviderRoutes) {
    super();
    this.parent.order = this;
  }

  getInstanceBc = this.parent.getInstanceChildBc;

  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.invoice> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.invoiceProvider.root, params.invoiceId, PAGE_TYPE.info);

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.invoice> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.invoiceProvider.root, params.invoiceId, PATH_PARAM_DASHBOARD.invoiceProvider.orders, PAGE_TYPE.add);
}
