import useIsMountedRef from 'hooks/useIsMountedRef';
import { useState, useEffect } from 'react';
import IFileService from 'services/file/IFileService';
import useSnackbarErrorHandler from '../snackbar/useSnackbarErrorHandler';
import useDI from '../useDI';

/**
 * Получает актуальную ссылку на файл для отображения на странице
 * @returns `[updatedFile, isFileLoading]` updatedFile если был на входе опциональным, то будет и на выходе, иначе будет гарантированным
 */
export default function usePrepareFileForView<T extends IFileService.File_ | null>(fileRaw: T): [T, boolean] {
  const { apiService } = useDI();
  const isMountedRef = useIsMountedRef();
  const handleFormErrors = useSnackbarErrorHandler();
  const [hydratedFile, setHydratedFile] = useState(fileRaw);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!fileRaw) {
      setHydratedFile(null as T);
      return;
    }

    if (fileRaw.isHydrated) {
      setHydratedFile(fileRaw);
      return;
    }

    const initialize = async () => {
      const hydratedFile = await apiService.subEntity.file.getActualFileData(fileRaw);
      if (isMountedRef.current) {
        setHydratedFile(hydratedFile as T);
        setIsLoading(false);
      }
    };
    initialize().catch((error) => handleFormErrors({ error }));
  }, []);

  return [hydratedFile, isLoading];
}
