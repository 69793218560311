import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import slices from './slices';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

// Создаёт копию slices оставляя только reducers (раньше тут вручную создавался такой объект)
function getReducers(): { [K in keyof typeof slices]: typeof slices[K]['reducer'] } {
  const keys = Object.keys(slices) as Array<keyof typeof slices>;
  return keys.reduce(<K extends keyof typeof slices>(acc: any, M_NAME: K) => {
    acc[M_NAME] = slices[M_NAME].reducer;
    return acc;
  }, {} as ReturnType<typeof getReducers>);
}

const rootReducer = combineReducers(getReducers());

export { rootPersistConfig, rootReducer };
