import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { CreateOrderReviewRequestDTO, OrderReviewResponseDTO } from 'typings/dto/orderReview';
import IModelActionsService from './IModelActionsService';

export default class OrderReviewActionsService
  extends ModelActionsService<OrderReview, CreateOrderReviewRequestDTO, OrderReviewResponseDTO>
  implements IModelActionsService<OrderReview, CreateOrderReviewRequestDTO, OrderReviewResponseDTO>
{
  protected getModelApiRootPath = (dto: { orderId: string }) => {
    return PATH_BACKEND.order.root + '/' + dto.orderId + '/' + super.getModelApiRootPath(dto);
  };
}
