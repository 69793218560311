import { FormikContextType } from 'formik';
import useDI from 'hooks/useDI';
import { MuiTelInputProps } from 'mui-tel-input';
import React from 'react';
import PhoneInputAsync from 'components/ui/forms/utilComponents/PhoneInputAsync';

export type PhoneInputMemorizedProps = MuiTelInputProps & {
  fieldName: string;
  InputProps?: any; // ?
  label?: string;
  formState: FormikContextType<any>;
  inline?: boolean;
};

/** Работает с string */
function PhoneInputMemorized({ fieldName, children, label, formState, inline, helperText, ...rest }: PhoneInputMemorizedProps) {
  const { services } = useDI();
  const { getFieldProps, touched, errors } = formState;
  const errorText = touched[fieldName] && errors[fieldName];
  const locale = services.language.getCurrentLocale();

  // docs https://viclafouch.github.io/mui-tel-input/docs/api-reference/
  return (
    <PhoneInputAsync
      fullWidth
      label={inline ? errorText : label}
      size={inline ? 'medium' : rest.size}
      variant={inline ? 'outlined' : rest.variant}
      className={inline ? 'inline-input' : ''}
      helperText={helperText || (!inline && errorText)}
      {...getFieldProps(fieldName)}
      onChange={(v) => formState.setFieldValue(fieldName, v)}
      langOfCountryName={locale.localeFull}
      {...rest}
      defaultCountry={rest.defaultCountry || locale.localeCountry}
      error={Boolean(errorText)}
    >
      {children}
    </PhoneInputAsync>
  );
}

export default React.memo(PhoneInputMemorized, (prevProps, nextProps) => {
  const prevName = prevProps.fieldName;
  const nextName = nextProps.fieldName;
  return (
    prevProps.formState.values[prevName] === nextProps.formState.values[nextName] &&
    prevProps.formState.touched[prevName] === nextProps.formState.touched[nextName] &&
    prevProps.formState.errors[prevName] === nextProps.formState.errors[nextName] &&
    prevProps.disabled === nextProps.disabled
  );
});
