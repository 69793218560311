import { useEffect, useMemo, useState } from 'react';

/** Почему-то родная из material ui работает некорректно, временно сделал эту */
export default function useMediaQuery(query: string) {
  const mqList = useMemo(() => window.matchMedia(query.replace('@media ', '')), []);
  const [isMatch, setIsMatch] = useState(mqList.matches);

  useEffect(() => {
    const listener = () => setIsMatch(mqList.matches);
    mqList.addEventListener('change', listener);
    return () => mqList.removeEventListener('change', listener);
  }, []);

  return isMatch;
}
