import { CallResponseDTO, CreateCallRequestDTO } from 'typings/dto/call';
import Mapper from './Mapper';

export default class CallMapper extends Mapper<Call, CallResponseDTO, CreateCallRequestDTO> {
  constructor() {
    super(
      {
        callbackless: 'callbackless',
        callerPhone: 'callerPhone',
        date: 'date',
        duration: 'duration',
        participantСalls: 'participantСalls',
        recordLink: 'recordLink',
        status: 'status',
        type: 'type',
      },
      null
    );
  }
}
