import FrontendRoutesBase from './FrontendRoutesBase';
import { PAGE_TYPE, PATH_PARAM_DASHBOARD, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';

export default class PriceServiceProviderRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  root = this.getDBPath(PATH_PARAM_DASHBOARD.price.root, PATH_PARAM_DASHBOARD.price.serviceProvider);

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.price> = (_params, _permissions, translate) => {
    return [{ href: this.root, name: translate('entities.pricelist.types.serviceProvider') }];
  };
  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.price> = (params, _permissions, translate) => {
    return [{ href: this.getListPath(params), name: translate('entities.pricelist.types.serviceProvider') }];
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.price> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.price.root, PATH_PARAM_DASHBOARD.price.serviceProvider, params.pricelistId, PAGE_TYPE.list);

  getPricelistInfoPath = this.getListPath;

  getPricelistAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.price> = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.price.root, PATH_PARAM_DASHBOARD.price.serviceProvider, PAGE_TYPE.new, PAGE_TYPE.add);
  getPricelistAddBc = this.getInstanceBc;

  getPricelistEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.price> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.price.root, PATH_PARAM_DASHBOARD.price.serviceProvider, params.pricelistId, PAGE_TYPE.edit);
  getPricelistEditBc = this.getInstanceChildBc;
}
