import FrontendRoutesBase from './FrontendRoutesBase';
import { PAGE_TYPE, PATH_PARAM_DASHBOARD, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';

export default class AdministrationRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  root = this.getDBPath(PATH_PARAM_DASHBOARD.administration.root);

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.administration> = (_params, _permissions, translate) => {
    return [{ name: translate('menus.main.administration'), href: this.getListPath() }];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.administration, 'userName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const breadcrumbs = this.getInstanceBc(params, permissions, translate);
    breadcrumbs.push({ name: names.userName, href: this.getInfoPath(params) });
    return breadcrumbs;
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.administration.root, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.administration> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.administration.root, params.userId, PAGE_TYPE.info);

  getAddBc = this.getInstanceBc;
  getAddPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.administration.root, PAGE_TYPE.new, PAGE_TYPE.add);

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.administration> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.administration.root, params.userId, PAGE_TYPE.edit);

  getNotificationsInfoBc = this.getInstanceBc;
  getNotificationsInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.administration> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.administration.root, params.userId, PATH_PARAM_DASHBOARD.user.notifications, PAGE_TYPE.info);

  getNotificationsEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.administration, 'userName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const breadcrumbs = this.getInstanceChildBc(params, permissions, translate, names);
    this.setLastBcLink(breadcrumbs, this.getNotificationsInfoPath(params));
    return breadcrumbs;
  };
  getNotificationsEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.administration> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.administration.root, params.userId, PATH_PARAM_DASHBOARD.user.notifications, PAGE_TYPE.edit);
}
