import { TextField, Autocomplete, AutocompleteProps, MenuItem, InputAdornment, Typography, Stack } from '@mui/material';
import { FormikContextType } from 'formik';
import useFirstRender from 'hooks/useFirstRender';
import useLocales from 'hooks/useLocales';
import React, { useMemo } from 'react';
import EntityUtils from 'utils/Entity';
import ModelUtils from 'utils/models/ModelUtils';

export type AutocompleteMemorizedProps = Omit<
  AutocompleteProps<any, boolean, boolean, undefined>,
  'renderInput' | 'translate' | 'multiple' | 'options'
> & {
  fieldName: string;
  label: string;
  multiple?: boolean;
  options: EntityWithName[];
  excluded?: EntityWithName[];
  isLoading?: boolean;
  autosubmit?: boolean;
  formState: FormikContextType<any>;
  startAdornment?: React.ReactElement;
};

/** Работает с массивом EntityWithName */
function AutocompleteMemorized({
  fieldName,
  label,
  isLoading,
  multiple,
  disabled,
  options,
  excluded,
  autosubmit,
  startAdornment,
  formState: { getFieldProps, setFieldValue, touched, errors, values, handleSubmit },
  sx,
  ...additionalProps
}: AutocompleteMemorizedProps) {
  const isFirstRender = useFirstRender();
  const { translate } = useLocales();

  const optionsMemorized = useMemo(() => {
    if (!excluded) return options;
    else return EntityUtils.excludeSame(options, excluded);
  }, [isLoading]);

  return isFirstRender || isLoading ? (
    <TextField size={additionalProps.size} label={label} value={values[fieldName]?.name || '...'} sx={sx} />
  ) : (
    <Autocomplete
      sx={sx}
      disabled={disabled}
      multiple={multiple}
      options={optionsMemorized}
      filterSelectedOptions
      noOptionsText={translate('messages.noOptionsLeft')}
      {...getFieldProps(fieldName)}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option) => option.name}
      onChange={(_, values) => {
        setFieldValue(fieldName, values);
        if (autosubmit) setTimeout(handleSubmit, 0); // Без этого костыля не засобмитит // TODO Почему? Проверить ещё раз, метод сменился на handleSubmit
      }}
      {...additionalProps}
      value={values[fieldName]}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.id}>
          <Stack spacing={1}>
            <Typography variant="body1">{option.name}</Typography>
            {option.viewDescription && (
              <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body1" color="text.secondary">
                {option.viewDescription}
              </Typography>
            )}
          </Stack>
        </MenuItem>
      )}
      renderInput={({ InputProps, ...params }) => (
        <TextField
          name={fieldName}
          error={Boolean(touched[fieldName] && errors[fieldName])}
          helperText={touched[fieldName] && errors[fieldName]}
          label={label}
          autoComplete="nope"
          InputProps={
            startAdornment
              ? {
                  ...InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">{startAdornment}</InputAdornment>
                      {InputProps.startAdornment}
                    </>
                  ),
                }
              : InputProps
          }
          {...params}
        />
      )}
    />
  );
}

// Входящий список не учитывается; если есть необходимость его менять (при подгрузке новых данных например), то можно передавать в isLoading флаг загрузки или использовать лайфхак со сменой key
export default React.memo(
  AutocompleteMemorized,
  (prevProps, nextProps) =>
    prevProps.disabled === nextProps.disabled &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.formState.touched[prevProps.fieldName] === nextProps.formState.touched[nextProps.fieldName] &&
    prevProps.formState.errors[prevProps.fieldName] === nextProps.formState.errors[nextProps.fieldName] &&
    (prevProps.multiple
      ? ModelUtils.checkArraysEquality(
          prevProps.formState.values[prevProps.fieldName],
          nextProps.formState.values[nextProps.fieldName]
        )
      : ModelUtils.checkEquality(
          prevProps.formState.values[prevProps.fieldName],
          nextProps.formState.values[nextProps.fieldName]
        ))
);
