import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import SpoilerIconButton from 'components/ui/buttons/SpoilerIconButton';
import useLocales from 'hooks/useLocales';
import EmptyIconButton from 'components/ui/buttons/EmptyIconButton';
import MenuButton from 'components/ui/buttons/MenuButton';
import { useSelector } from 'storage';
import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';
import ModelUtils from 'utils/models/ModelUtils';
import IPermissionService from 'services/permission/IPermissionService';
import ServiceOrderedDetailsBlock from 'components/_dashboardPagesFeatures/order/serviceOrdered/ServiceOrderedDetailsBlock';
import ServiceOrderedClientPriceBlock from 'components/_dashboardPagesFeatures/order/serviceOrdered/ServiceOrderedClientPriceBlock';
import Label from 'components/ui/info/Label';
import { RECOMMENDATION_STATUS } from 'typings/models/order/order.enum';

type Props = {
  orderSession: OrderContractSessionData;
  service: ServiceOrdered;
  editHandler: VoidFunction;
  deleteHandler: VoidFunction;
  permissionConfig: IPermissionService.PermissionConfigs;
};

function ServiceOrderedSessionListInfoItem({
  service,
  permissionConfig,
  orderSession,

  editHandler,
  deleteHandler,
}: Props) {
  const { permissions } = useSelector((state) => state.auth);
  const { translate } = useLocales();
  const [isDetailsOpened, setIsDetailsOpened] = useState(false);

  const menuOptions = useMemo(() => {
    const menuOptions: MenuItemData[] = [];
    if (!orderSession) {
      return menuOptions;
    }
    if (permissions.can(MODEL_PERMISSION.CAN_UPDATE_ORDER_SERVICE)) {
      menuOptions.push({
        title: translate('buttons.edit'),
        handler: editHandler,
        disabled: !permissions.serviceOrderedSessionBusinessPermissionCheck(
          MODEL_PERMISSION.CAN_UPDATE_ORDER_SERVICE,
          service,
          orderSession,
          permissionConfig
        ),
      });
    }
    if (permissions.can(MODEL_PERMISSION.CAN_DELETE_ORDER_SERVICE)) {
      menuOptions.push({
        title: translate('buttons.delete'),
        handler: deleteHandler,
        disabled: !permissions.serviceOrderedSessionBusinessPermissionCheck(
          MODEL_PERMISSION.CAN_DELETE_ORDER_SERVICE,
          service,
          orderSession,
          permissionConfig
        ),
      });
    }

    return menuOptions;
  }, [service.updatedAt.getTime(), orderSession.updatedAt.getTime()]);

  return (
    <>
      <TableRow>
        <TableCell>
          <Box>
            <Typography sx={{ flex: 1, whiteSpace: 'pre-line' }} variant="body2">
              {service.name}
            </Typography>
            {service.recommendationStatus === RECOMMENDATION_STATUS.required && (
              <Label color="default" sx={{ mr: 1, mt: 1 }}>
                {translate('entities.recommendations.type.required')}
              </Label>
            )}
          </Box>
        </TableCell>
        <TableCell>{service.quantity}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <ServiceOrderedClientPriceBlock service={service} />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Stack direction="row">
            {menuOptions.length > 0 ? <MenuButton options={menuOptions} /> : <EmptyIconButton />}
            <SpoilerIconButton isOpened={isDetailsOpened} onClick={() => setIsDetailsOpened(!isDetailsOpened)} />
          </Stack>
        </TableCell>
      </TableRow>

      {isDetailsOpened && (
        <TableRow>
          <TableCell colSpan={7}>
            <ServiceOrderedDetailsBlock service={service} defaultChildOpenState={true} />
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default React.memo(
  ServiceOrderedSessionListInfoItem,
  (pp, np) => ModelUtils.checkEquality(pp.orderSession, np.orderSession) && ModelUtils.checkEquality(pp.service, np.service)
);
