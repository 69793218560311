import { CreateEnterpriseDepartmentRequestDTO, EnterpriseDepartmentResponseDTO } from 'typings/dto/enterpriseDepartment';
import Mapper from './Mapper';

export default class EnterpriseDepartmentMapper extends Mapper<
  EnterpriseDepartment,
  EnterpriseDepartmentResponseDTO,
  CreateEnterpriseDepartmentRequestDTO
> {
  constructor() {
    super(
      { technicalName: 'technicalName', name: 'name', parent: 'parent', serviceArea: 'serviceArea' },
      { technicalName: 'technicalName', name: 'name', parent: 'parentId', serviceArea: 'serviceArea' }
    );
  }

  public responseDTOToModel = (enterpriseDepartment: EnterpriseDepartmentResponseDTO): EnterpriseDepartment => {
    return {
      ...this.responseDTOToModelViaDiffMap(enterpriseDepartment),
      parent: enterpriseDepartment.parent && this.responseDTOToModel(enterpriseDepartment.parent),
    };
  };

  public modelToRequestDTO = (
    enterpriseDepartment: EnterpriseDepartment,
    additionalDTO: GetModelWithTenantRequestDTO
  ): CreateEnterpriseDepartmentRequestDTO => {
    return {
      ...this.modelToRequestDTOViaDiffMap(enterpriseDepartment),
      parentId: enterpriseDepartment.parent?.id,
      tenantId: additionalDTO.tenantId,
    };
  };
}
