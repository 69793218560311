import { useEffect } from 'react';
import DIContainer from 'services/DIContainer';
import useSnackbarErrorHandler from './snackbar/useSnackbarErrorHandler';
import useDI from './useDI';
import IModelActionsService from 'services/storageModelActions/IModelActionsService';

/** Ключ - название модели (MODEL_NAME), значение - dto для получения getAllForFilter у экшинов данной модели */
type ModelsData = {
  [K in DIContainer.ModelsWithActionServices]?: Parameters<DIContainer['storageActions']['models'][K]['getAllForFilter']>[0];
};

/** Хук для получения данных для фильтров (обычно это полный список из id+name) */
export default function useFilterDataInitialize(modelsData: ModelsData) {
  const { storageActions, services } = useDI();
  const { translate } = services.language;
  const handleFormErrors = useSnackbarErrorHandler();

  useEffect(() => {
    const modelNames = Object.keys(modelsData);
    const actionsServices = modelNames.map((modelName) => {
      const MODEL_NAME = modelName as keyof DIContainer['storageActions']['models'];
      const actionsService = storageActions.requestScope[MODEL_NAME]() as IModelActionsService<any, any, any>;
      actionsService.getAllForFilter(modelsData[MODEL_NAME]).catch((error: any) => {
        const sourceMessage = translate('messages.getFilterDataErrorSubtitle') + ' ' + translate(`entities.${MODEL_NAME}.entityName`);
        handleFormErrors({ error, source: sourceMessage });
      });
      return actionsService;
    });

    return () => {
      actionsServices.forEach((actionsService) => {
        actionsService.cancelRequests();
        actionsService.setAllOutdated();
      });
    };
  }, []);
}
