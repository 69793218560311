import { CreateLegalEntityRequestDTO, LegalEntityResponseDTO } from 'typings/dto/legalEntity';
import ModelActionsService from '../ModelActionsService';
import ILegalEntityActionsService from './ILegalEntityActionsService';

export default class LegalEntityActionsServiceImpl
  extends ModelActionsService<LegalEntity, CreateLegalEntityRequestDTO, LegalEntityResponseDTO>
  implements ILegalEntityActionsService
{
  public getAllForFilterForAllOwners = (filter?: LocationSearchObject) => {
    return super.getAllForFilter(null, filter);
  };
}
