import useLocales from 'hooks/useLocales';
import IconButtonStyled from './IconButtonStyled';
import { CancelIcon, PlusCircleIcon } from '../icons';

type Props = React.ComponentProps<typeof IconButtonStyled> & {
  isOpened: boolean;
};

export default function SpoilerPlusIconButton({ isOpened, ...rest }: Props) {
  const { translate } = useLocales();
  return (
    <IconButtonStyled {...rest} title={isOpened ? translate('buttons.hide') : translate('buttons.show')}>
      {isOpened ? <CancelIcon /> : <PlusCircleIcon />}
    </IconButtonStyled>
  );
}
