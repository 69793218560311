class StringUtils {
  private static readonly ENTITY_CODE_MIN_NUMBER = 1;
  private static readonly ENTITY_CODE_MAX_NUMBER = 99999;
  private static readonly entityCodeZeros = ['0000', '000', '00', '0', ''];

  public static generateUUIDv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  /** @returns XXNNNNN */
  public static generateEntityCode(): string {
    const letter1 = this.generateRandomLetter();
    const letter2 = this.generateRandomLetter();
    const numberPart = Math.floor(
      Math.random() * (this.ENTITY_CODE_MAX_NUMBER - this.ENTITY_CODE_MIN_NUMBER + 1) + this.ENTITY_CODE_MIN_NUMBER
    );
    const zeros = this.entityCodeZeros[numberPart.toString().length - 1];
    return `${letter1}${letter2}${zeros}${numberPart}`;
  }

  public static addSymbolToPositiveNumber(n: number): string {
    return n < 0 ? n.toString() : `+${n}`;
  }

  /**
   *  Склонение в зависимости от количества
   *  пример: ["услуга", "услуги", "услуг"];
   * deprecated не реализовать для разных языков
   */
  // public static numberDeclination(quantity: number, textForms: string[]) {
  //   quantity = Math.abs(quantity) % 100;
  //   var n1 = quantity % 10;
  //   if (quantity > 10 && quantity < 20) {
  //     return textForms[2];
  //   }
  //   if (n1 > 1 && n1 < 5) {
  //     return textForms[1];
  //   }
  //   if (n1 === 1) {
  //     return textForms[0];
  //   }
  //   return textForms[2];
  // }

  /** @returns рандомную заглавную латинскую букву */
  private static generateRandomLetter = (): string => {
    return String.fromCharCode(65 + Math.floor(Math.random() * 26));
  };

  public static capitalizeFirstLetter = (str: string): string => {
    return str.length ? str[0].toUpperCase() + str.slice(1) : str;
  };
}

export default StringUtils;
