import React, { createContext } from 'react';
import DIContainer from 'services/DIContainer';

/** Смотри хук useDI */
export const DIContext = createContext<DIContainer>({} as any);

/** Смотри хук useDI */
export function DIContextProvider({ children, diContainer }: { children: React.ReactNode; diContainer: DIContainer }) {
  return <DIContext.Provider value={diContainer}>{children}</DIContext.Provider>;
}
