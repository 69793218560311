import { FormikContextType, setNestedObjectValues } from 'formik';

export class FormUtils {
  // Parent form with child forms ----------------------------------------------------------------------
  // Форма, у которой на странице добавления можно бесконечно много добавлять формы вложенных сущностей, и при сабмите формы родителя все вложенные сущности автоматически валидируются и добавляются в родителя

  /**
   * Валидирует все формы-потомки одного типа (@see FormUtils.ChildFormsMap ), проставляет у них визуальные сообщения об ошибках
   * @returns Все ли формы-потомки валидны
   */
  public static validateChildForms = async (formsMap: FormUtils.ChildFormsMap): Promise<boolean> => {
    const results: boolean[] = [];
    for (const formId of Array.from(formsMap.keys())) {
      const formState = formsMap.get(formId);
      if (!formState) {
        throw new Error("Child form can't be obtained from parent form: check (or create) setter inside child form");
      }

      const validationErrors = await formState.validateForm();
      if (Object.keys(validationErrors).length) {
        formState.setTouched(setNestedObjectValues(validationErrors, true));
        results.push(false);
      } else {
        results.push(true);
      }
    }

    return results.length ? results.every((v) => v === true) : true;
  };

  /**
   * Сабмит для формы-родителя, у которой есть формы потомки
   * @param parentForm стейт формы родителя
   * @param formsMaps массив всех типов форм-потомков @see FormUtils.ChildFormsMap
   * @param onError действия при ошибке
   */
  public static submitParentFormWithChildrenHandlerFactory =
    (parentForm: FormikContextType<any>, formsMaps: FormUtils.ChildFormsMap[], onError?: (error: any) => void) => async () => {
      try {
        parentForm.setSubmitting(true);
        const results: boolean[] = [];
        const requests = formsMaps.map(async (formMap) => {
          const isChildValid = await FormUtils.validateChildForms(formMap);
          results.push(isChildValid);
        });
        await Promise.all(requests);

        const areChildrenValid = results.length ? results.every((v) => v === true) : true;
        if (areChildrenValid) {
          parentForm.handleSubmit();
          // setSubmitting отхендлится внутри parentForm.handleSubmit
        } else {
          const validationErrors = await parentForm.validateForm();
          parentForm.setTouched(setNestedObjectValues(validationErrors, true));
          parentForm.setSubmitting(false);
        }
      } catch (error) {
        if (onError) {
          onError(error);
        } else {
          console.error(error);
        }
      }
    };

  /**
   * Добавление новой формы-потомка
   * @param rerenderPageCallback коллбек, с помощью которого тригерим рендер родителя
   */
  public static addChildFormHandlerFactory = (formsMap: FormUtils.ChildFormsMap, rerenderPageCallback: VoidFunction) => () => {
    const newId = Math.random().toString();
    formsMap.set(newId, null);
    rerenderPageCallback();
  };

  public static deleteChildFormHandlerFactory =
    (formId: string, formsMap: FormUtils.ChildFormsMap, rerenderPageCallback: VoidFunction) => () => {
      formsMap.delete(formId);
      rerenderPageCallback();
    };

  public static addChildForm = (formsMap: FormUtils.ChildFormsMap, rerenderPageCallback: VoidFunction, formId?: string) => {
    const newId = formId ?? Math.random().toString();
    formsMap.set(newId, null);
    rerenderPageCallback();
  }
}
export { FormUtils as default };

export namespace FormUtils {
  /**
   * Карта форм-потомков одного типа (одной сущности, которую родитель может добавлять бесконечное количество раз);
   * сделано в виде map, а не массива, по причинам технической реализации;
   * null может быть, т.к. мы сначала объявляем карту, затем рендерятся потомки на основе id в карте, и если надо, чтобы при загрузке страницы уже был хотя бы один экземпляр, то ключом ставим id, а значением null, при рендере туда подставится стейт формы потомка на основе этого id
   */
  export type ChildFormsMap = Map<string, FormikContextType<any> | null>;
}
