import { ReactNode } from 'react';
import useLocales from 'hooks/useLocales';
import { useSelector } from 'storage';
import IPermissionService from 'services/permission/IPermissionService';
import ForbiddenError from 'utils/errors/ForbiddenError';

// ----------------------------------------------------------------------

type PermissionsGuardProps = {
  permission: IPermissionService.Permission;
  children: ReactNode;
};

export default function RouterPermissionGuard({ permission, children }: PermissionsGuardProps) {
  const { permissions } = useSelector((state) => state.auth);
  const { translate } = useLocales();

  if (!permissions.can(permission)) {
    throw new ForbiddenError(translate('errors.invalidPermissionsPage'));
  }

  return <>{children}</>;
}
