export enum ORDER_DEFAULT_TYPE {
  default = 'default',
  complaint = 'reclamation',
  warranty = 'warranty',
}

export enum ORDER_TYPE_TECHNICAL_NAME {
  default = 'default',
  reclamation = 'reclamation',
  warranty = 'warranty',
  measure = 'measure',
}

export enum ORDER_TYPE_ICON_NAME {
  defaultOrderType = 'DefaultOrderType',
  warrantyOrderType = 'WarrantyOrderType',
  measureOrderType = 'MeasureOrderType',
  reclamationOrderType = 'ReclamationOrderType',
}
