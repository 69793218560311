import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import ModelActionsService from '../ModelActionsService';
import IPlatformOperatorActionsService from './IPlatformOperatorActionsService';
import { CreatePlatformOperatorRequestDTO, PlatformOperatorResponseDTO } from 'typings/dto/platformOperator';

export default class PlatformOperatorActionsServiceImpl
  extends ModelActionsService<PlatformOperator, CreatePlatformOperatorRequestDTO, PlatformOperatorResponseDTO>
  implements IPlatformOperatorActionsService
{
  /** @throws `BackendResponseError` */
  public getValuesFromOrderForFilter = (): Promise<void> => {
    const url = PATH_BACKEND.platformOperator.orderFilter;
    return this.getAllForFilter(undefined, undefined, url);
  };

  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return super.getDefaultSortFilter(filter, 'createdAt');
  };
}
