import { CloseIcon } from 'components/ui/icons';
import { m, AnimatePresence } from 'framer-motion';
import { List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, FormHelperText } from '@mui/material';
import FileUtils from 'utils/File';
import { TextAnimate, varFade } from 'components/features/animate';
import IFileService from 'services/file/IFileService';
import { FormikContextType } from 'formik';
import React, { useEffect, useState } from 'react';
import LoaderComponent from 'components/ui/loadingIndicators/LoaderComponent';
import usePrepareFilesForView from 'hooks/file/usePrepareFilesForView';

type Props = {
  disabled?: boolean;
  formState: FormikContextType<any>;
  fieldName: string;
};

// Взято из аплоадера
function UploadedFilesMemorized({ fieldName, disabled, formState }: Props) {
  const files: IFileService.File_[] = formState.values[fieldName];
  const hasFiles = files.length > 0;
  const [isLoading, setIsLoading] = useState(true);
  const showHelperText = Boolean(formState.errors[fieldName]);

  const [filesHydrated, isInitializationLoading] = usePrepareFilesForView(files);
  useEffect(() => {
    if (!isInitializationLoading) {
      formState.setFieldValue(fieldName, filesHydrated);
      setIsLoading(false);
    }
  }, [isInitializationLoading]);

  const handleRemove = (file: IFileService.File_) => {
    formState.setFieldTouched(fieldName, true);
    const filteredItems = formState.values[fieldName].filter((_file: IFileService.File_) => _file.id !== file.id);
    formState.setFieldValue(fieldName, filteredItems);
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <List disablePadding sx={hasFiles ? { mt: 2 } : undefined}>
      <AnimatePresence>
        {files.map((file) => {
          const { name, sizeBytes, id, url } = file;

          return (
            <ListItem
              key={id}
              component={m.div}
              {...varFade().inRight}
              {...TextAnimate}
              sx={{
                my: 1,
                py: 0.75,
                px: 2,
                borderRadius: 1,
                border: (theme) => `solid 1px ${theme.palette.divider}`,
                bgcolor: 'background.paper',
              }}
            >
              <ListItemIcon>{FileUtils.getThumb(name, url)}</ListItemIcon>
              <ListItemText
                primary={typeof file === 'string' ? file : name}
                secondary={typeof file === 'string' ? '' : FileUtils.getFileSizeStrFormatted(sizeBytes || 0)}
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondaryTypographyProps={{ variant: 'caption' }}
                sx={{ pr: 3 }}
              />
              <ListItemSecondaryAction>
                <IconButton disabled={disabled} edge="end" size="small" onClick={() => handleRemove(file)}>
                  <CloseIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </AnimatePresence>
      {showHelperText && <FormHelperText sx={{ color: 'red' }}>{formState.errors[fieldName]}</FormHelperText>}
    </List>
  );
}

export default React.memo(
  UploadedFilesMemorized,
  (pp, np) =>
    pp.disabled === np.disabled &&
    pp.formState.errors[pp.fieldName] === np.formState.errors[np.fieldName] &&
    FileUtils.checkFSFileArraysEqual(pp.formState.values[pp.fieldName], np.formState.values[np.fieldName])
);
