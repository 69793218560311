import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import {
  CancelOrderServicesPaymentRequestDTO,
  CreateServiceOrderedRequestDTO,
  CreateServicesOrderedRequestDTO,
  PayOrderServicesRequestDTO,
  ServiceOrderedResponseDTO,
  UpdateOrderServiceDiscountRequestDTO,
} from 'typings/dto/serviceOrdered';
import IServiceOrderedActionsService from './IServiceOrderedActionsService';

export default class ServiceOrderedActionsServiceImpl
  extends ModelActionsService<ServiceOrdered, CreateServiceOrderedRequestDTO, ServiceOrderedResponseDTO>
  implements IServiceOrderedActionsService
{
  /** @throws `BackendResponseError` */
  public async create(dto: CreateServiceOrderedRequestDTO, customPath?: string): Promise<string> {
    const path = customPath || this.getModelApiRootPath(dto);
    await this.entityApiService.postWithCredentials(path, dto, false);
    this.storageDispatch(this.modelStorageActions.setOneIsOutdated());
    return '';
  }

  /** @throws `BackendResponseError` */
  public async createMany(dto: CreateServicesOrderedRequestDTO, path: string): Promise<string> {
    await this.entityApiService.postWithCredentials(path, dto, false);
    this.storageDispatch(this.modelStorageActions.setOneIsOutdated());
    return '';
  }

  public payOrderServices = async (dto: PayOrderServicesRequestDTO) => {
    const path =
      this.getModelApiRootPath({ orderId: dto.orderId }) +
      '/' +
      PATH_BACKEND_PART.serviceOrdered.payment +
      '/' +
      PATH_BACKEND_PART.serviceOrdered.accept;
    await this.entityApiService.postWithCredentials(path, dto, false);
  };

  public cancelOrderServicesPayment = async (dto: CancelOrderServicesPaymentRequestDTO) => {
    const path =
      this.getModelApiRootPath({ orderId: dto.orderId }) +
      '/' +
      PATH_BACKEND_PART.serviceOrdered.payment +
      '/' +
      PATH_BACKEND_PART.serviceOrdered.decline;
    await this.entityApiService.postWithCredentials(path, dto, false);
  };

  /** @throws `BackendResponseError` */
  public deleteFromOrder = async (orderId: string, id: string): Promise<void> => {
    await this.modelApiService.delete(this.getModelApiRootPath({ orderId }), id);
    this.storageDispatch(this.modelStorageActions.setListIsOutdated());
  };

  public updateDiscount = ({ orderId, serviceId, ...dto }: UpdateOrderServiceDiscountRequestDTO): Promise<void> => {
    const path = this.getModelApiRootPath({ orderId }) + '/' + serviceId + '/' + PATH_BACKEND_PART.serviceOrdered.discount;
    return this.entityApiService.putWithCredentials(path, dto);
  };

  protected getModelApiRootPath = (dto: { orderId: string }) => {
    return PATH_BACKEND.order.root + '/' + dto.orderId + '/' + super.getModelApiRootPath(dto);
  };

  /** @throws `BackendResponseError` */
  public deleteFromOrderSession = async (orderId: string, id: string): Promise<void> => {
    await this.modelApiService.delete(PATH_BACKEND.orderSession.root + '/' + orderId + '/' + super.getModelApiRootPath({ orderId }), id);
    this.storageDispatch(this.modelStorageActions.setListIsOutdated());
  };
}
