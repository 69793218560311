import parsePhoneNumber, { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import ILanguageService from 'services/language/ILanguageService';

export default class PhoneUtils {
  private countryCode: CountryCode;
  private languageService: ILanguageService;

  constructor(languageService: ILanguageService) {
    this.languageService = languageService;
    this.countryCode = this.languageService.getCurrentLocale().localeCountry;

    this.getRFC3966 = this.getRFC3966.bind(this);
  }

  public getPhoneObjectFromStr = (phoneStr: string, countryCode?: CountryCode): Phone => {
    try {
      const formatter = parsePhoneNumber(phoneStr, countryCode);
      return {
        raw: phoneStr,
        number: formatter?.number.toString() || '',
        country: formatter?.country || this.countryCode, // not safe
        nationalNumber: formatter?.nationalNumber.toString() || '',
        formattedString: formatter?.formatNational().replace(/^8/, '+7') || '', // TODO привязать это к языку!
        isValid: Boolean(formatter),
      };
    } catch (error) {
      console.error(this.languageService.translate('errors.invalidPhoneFormat') + ' ' + phoneStr);
      return {} as any;
    }
  };

  public validate = (str?: string): boolean => (str ? isValidPhoneNumber(str, this.countryCode) : false);

  public getRFC3966(phoneStr: string): string;
  public getRFC3966(phoneObj: Phone): string;
  public getRFC3966(phoneUnknown: string | Phone): string {
    let phoneStr: string;
    let countryCode: CountryCode;

    // Overload handle
    if (typeof phoneUnknown === 'string') {
      phoneStr = phoneUnknown;
      countryCode = this.getPhoneObjectFromStr(phoneUnknown).country;
    } else {
      phoneStr = phoneUnknown.raw;
      countryCode = phoneUnknown.country;
    }
    // ^

    return parsePhoneNumber(phoneStr, countryCode)?.format('RFC3966') || '';
  }

  // /** @return {string} Возвращает цифру код страны */
  // public getCountryCallingCode = (countryCodeRaw: Lowercase<CountryCode> | 'en'): string => {
  //   return getCountryCallingCode(this.getCountryStrCode(countryCodeRaw));
  // };
}
