export enum CONTRACT_TYPE {
  executor = 'executor',
  agent = 'agent',
}

export enum WEBHOOK_API_AUTH_TYPE {
  none = 'NONE',
  basic = 'BASIC',
}

export enum GEO_POINT_TRACKING_TYPE {
  REQUIRED = 'REQUIRED',
  DISABLED = 'DISABLED',
  OPTIONAL = 'OPTIONAL',
}

export enum CREATE_ENTERPRISE_TYPE {
  marya = 'MARYA',
  parent = 'PARENT',
  child = 'CHILD',
}
