import EventSourceCustom from '../../utils/implementations/EventSourceCustom';
import IServerEventsService from './IServerEventsService';

export default class ServerEventsServiceImpl implements IServerEventsService {
  private url = '';
  private subscriptionEntityName = '';

  public createSubscription = (
    url: string,
    onMessageCallback: (data: IServerEventsService.SSEEntityUpdateMessageData) => void,
    onErrorCallback: (error: any) => void
  ): IServerEventsService.SubscriptionState => {
    this.url = url;
    this.subscriptionEntityName = this.url.match(/([^/]+)\/subscribe$/)?.[1] || '';
    const eventSource = new EventSourceCustom(this.url, { withCredentials: true });
    const subscriptionState = this.createSubscriptionState(eventSource);
    eventSource.onopen = () => (subscriptionState.failedAttempts = 0);
    eventSource.onmessage = ServerEventsServiceImpl.onMessageHandlerFactory(onMessageCallback, this.subscriptionEntityName);
    eventSource.onerror = ServerEventsServiceImpl.onErrorHandlerFactory(
      eventSource,
      onErrorCallback,
      subscriptionState,
      this.subscriptionEntityName
    );
    console.debug(`[SSE ${this.subscriptionEntityName}]: Subscribed on ${this.url}`);
    return subscriptionState;
  };

  private createSubscriptionState = (source: EventSource | EventSourceCustom): IServerEventsService.SubscriptionState => {
    return {
      failedAttempts: 0,
      connectionType: source instanceof EventSourceCustom ? source.connectionType : 'sse',
      clearSubscription: () => source.close(),
    };
  };

  // Static ------------------------------------------------------------------------

  public static readonly MAX_RECONNECT_ATTEMPTS = 3;

  public static onErrorHandlerFactory =
    (
      eventSource: EventSource | EventSourceCustom,
      onErrorCallback: (error: any) => void,
      subscriptionState: IServerEventsService.SubscriptionState,
      subscriptionEntityName: string
    ) =>
    () => {
      subscriptionState.failedAttempts += 1;
      if (eventSource.readyState === EventSource.CLOSED || subscriptionState.failedAttempts > this.MAX_RECONNECT_ATTEMPTS) {
        const error = new Error(`[SSE ${subscriptionEntityName}]: Error: connection closed: too many attempts`); // TODO перевод?
        onErrorCallback(error);
        subscriptionState.clearSubscription();
        // Если мы работает с кастомным типом, то у него есть встроенный механизм восстановления соединения через промежуток времени
        // По идее здесь же мы можем сбросить счётчик failedAttempts, чтобы в будущем при переподключении вновь было N попыток, но тогда он будет отражать неверные данные, поэтому пока остаётся как есть и после переподключения есть только одна попытка на реконнект, прежде чем мы вновь попадём сюда же
        if (eventSource instanceof EventSourceCustom) {
          eventSource.reinitialize();
          subscriptionState.failedAttempts = 0;
        }
      }
    };

  public static onMessageHandlerFactory =
    (onMessageCallback: (data: IServerEventsService.SSEEntityUpdateMessageData) => void, subscriptionEntityName: string) =>
    (event: IServerEventsService.SSEMessage) => {
      console.debug(`[SSE ${subscriptionEntityName}]: received a message`, event.data);
      const data = event.data ? JSON.parse(event.data) : {};
      onMessageCallback(data);
    };
}
