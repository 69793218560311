import { PayloadAction } from '@reduxjs/toolkit';
import DIContainer from 'services/DIContainer';
import IPermissionService from 'services/permission/IPermissionService';
import PermissionServiceImpl from 'services/permission/PermissionServiceImpl';
import SliceFactory from './SliceFactory';

// Стейт для авторизации

const initialState: UserAuthState = {
  isLoading: false,
  error: null,
  currentUser: null,
  isAuthenticated: false,
  isInitialized: false,
  isProfileOutdated: true,
  permissions: new PermissionServiceImpl(null, []),
};

type InitialUserPayload = {
  user: User | null;
  permissions: IPermissionService.Permission[];
  permissionConfigs?: IPermissionService.PermissionConfigs;
};

const slice = SliceFactory.createSimpleSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.isInitialized = true;
      state.currentUser = null;
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    getUserSuccess(state, action: PayloadAction<InitialUserPayload>) {
      const { user, permissions, permissionConfigs } = action.payload;
      state.isLoading = false;
      state.isAuthenticated = Boolean(user);
      state.isProfileOutdated = false;
      state.currentUser = user;
      state.permissions = DIContainer.createUserPermissionService(user, permissions, permissionConfigs);
    },
    initializedSuccess(state, action: PayloadAction<InitialUserPayload>) {
      const { user, permissions, permissionConfigs } = action.payload;
      state.isLoading = false;
      state.isInitialized = true;
      state.isProfileOutdated = false;
      state.currentUser = user;
      state.permissions = DIContainer.createUserPermissionService(user, permissions, permissionConfigs);
      state.isAuthenticated = Boolean(user);
    },
    logoutUserSuccess(state) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.currentUser = null;
      state.isProfileOutdated = false;
      state.permissions = DIContainer.createUserPermissionService(null, []);
    },
    setProfileOutdated(state) {
      state.isProfileOutdated = true;
    },
  },
});

export default slice;
