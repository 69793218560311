import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { CreateOrderTypeRequestDTO, OrderTypeResponseDTO } from 'typings/dto/orderType';
import IOrderTypeActionsService from './IOrderTypeActionsService';
import { PATH_BACKEND } from 'configs/routes/pathsBackend';

export default class OrderTypeActionsServiceImpl
  extends ModelActionsService<OrderType, CreateOrderTypeRequestDTO, OrderTypeResponseDTO>
  implements IOrderTypeActionsService
{
  /** @throws `BackendResponseError` */
  public getValuesFromOrderForFilter = (): Promise<void> => {
    const url = PATH_BACKEND.orderType.orderFilter;
    return this.getAllForFilter(undefined, undefined, url);
  };
}
