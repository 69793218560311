import useDI from './useDI';

/** Хук для translate, который был до useDI, поэтому для совместимости тут дублируется функционал */
export default function useLocales() {
  const diContainer = useDI();
  const language = diContainer.services.language;

  return {
    changeLanguage: language.changeLanguage,
    translate: language.translate,
    currentLocale: language.getCurrentLocale(),
    allLocales: language.getLocaleList(),
  };
}
