import { LoadingButton } from '@mui/lab';
import { Alert, Button, Stack, Typography } from '@mui/material';
import PopupContent from 'components/ui/popups/PopupContent';
import useDI from 'hooks/useDI';
import { useEffect, useState } from 'react';
import { useSelector } from 'storage';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import { GEO_POINT_TRACKING_TYPE } from 'typings/models/enterprise.enum';
import CookieUtils from 'utils/Cookie';

const GEO_PERMISSION_IS_CHECKED_COOKIE_NAME = 'isGeoExplained';

type Props = {
  children: React.ReactNode;
};

export default function UserTenantSettingsGlobalGuard({ children }: Props) {
  const { permissions } = useSelector((state) => state.auth);
  const [isInitialized, setIsInitialized] = useState(false);

  const [isGeoPositionPopupOpened, setIsGeoPositionPopupOpened] = useState(false);

  useEffect(() => {
    const permissionConfigs = permissions.getCurrentUserConfigs();
    if (!permissionConfigs.geoPointForServerActions || permissionConfigs.geoPointForServerActions === 'DISABLED') {
      setIsInitialized(true);
    } else {
      setIsGeoPositionPopupOpened(true);
    }
  }, []);

  return isInitialized ? (
    <>{children}</>
  ) : (
    <>{isGeoPositionPopupOpened && <GeoPositionPopup initializeHandler={() => setIsInitialized(true)} />}</>
  );
}

// TODO когда появятся другие попапы переделать initializeHandler
function GeoPositionPopup({ initializeHandler }: { initializeHandler: VoidFunction }) {
  const { services } = useDI();
  const { translate } = services.language;
  const { permissions } = useSelector((state) => state.auth);
  const [isPopupOpened, setIsPopupOpened] = useState(false); // TODO когда появятся другие попапы тут будет контроль данного
  const [isBlockInitialized, setIsBlockInitialized] = useState(false);
  const [isPermissionChecked, setIsPermissionChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleFormErrors = useSnackbarErrorHandler();
  const geoPointForServerActions = permissions.getCurrentUserConfigs().geoPointForServerActions;

  const getPermissionHandler = async () => {
    try {
      setIsLoading(true);
      await services.geo.getCurrentGeoPosition();
      // Если получили геоточку, то завершаем
      CookieUtils.setNamedCookie(GEO_PERMISSION_IS_CHECKED_COOKIE_NAME, String(true), 60 * 24 * 7);
      initializeHandler();
    } catch (error) {
      const message =
        translate('common.address.browserGeoPositionIsRequiredError') +
        ': ' +
        translate(`common.address.browserGeoPositionErrors.${(error as GeolocationPositionError).code}`);
      console.debug('[Geo]: ' + message);

      // Если не получили, то смотрим, требуется ли она
      if (geoPointForServerActions !== GEO_POINT_TRACKING_TYPE.REQUIRED) {
        initializeHandler();
      } else {
        setIsPopupOpened(true);
        setIsPermissionChecked(true);
        setIsLoading(false);
        handleFormErrors({ error: new Error(message) });
      }
    }
  };

  useEffect(() => {
    if (CookieUtils.getNamedCookie(GEO_PERMISSION_IS_CHECKED_COOKIE_NAME)) {
      getPermissionHandler();
    } else {
      setIsPopupOpened(true);
    }

    // Нет поддержки на iOS
    // if ('permissions' in navigator) {
    //   navigator.permissions.query({ name: 'geolocation' }).then((data) => {
    //     switch (data.state) {
    //       // Если пермишен уже есть, то пропускаем
    //       case 'granted': {
    //         initializeHandler();
    //         break;
    //       }
    //       // Если пермишен нет, то смотрим, требуется ли он
    //       case 'denied': {
    //         if (geoPointForServerActions !== GEO_POINT_TRACKING_TYPE.REQUIRED) {
    //           initializeHandler();
    //         } else {
    //           setIsPermissionChecked(true);
    //           setIsPopupOpened(true);
    //         }
    //         break;
    //       }
    //       case 'prompt': {
    //         setIsPopupOpened(true);
    //         break;
    //       }
    //       default: {
    //         FunctionUtils.exhaustiveCheck(data.state);
    //       }
    //     }
    //   });
    // }

    setIsBlockInitialized(true);
  }, []);

  if (!isBlockInitialized) {
    return null;
  }

  let title = '';
  let subtitle = '';
  let buttons: React.ReactNode = null;
  switch (geoPointForServerActions) {
    case GEO_POINT_TRACKING_TYPE.REQUIRED: {
      title = isPermissionChecked
        ? translate('pages.handymanGuard.geoPositionIsRequiredTitle')
        : translate('pages.handymanGuard.geoPositionGuardTitle');
      subtitle = isPermissionChecked
        ? translate('pages.handymanGuard.geoPositionGuardForbiddenSubtitle')
        : translate('pages.handymanGuard.geoPositionGuardRequiredSubtitle');
      buttons = (
        <LoadingButton loading={isLoading} variant="contained" onClick={getPermissionHandler}>
          {isPermissionChecked ? translate('buttons.retry') : translate('buttons.allow')}
        </LoadingButton>
      );
      break;
    }
    case GEO_POINT_TRACKING_TYPE.OPTIONAL: {
      title = translate('pages.handymanGuard.geoPositionGuardTitle');
      subtitle = translate('pages.handymanGuard.geoPositionGuardOptionalSubtitle');
      buttons = (
        <>
          <LoadingButton loading={isLoading} variant="contained" onClick={getPermissionHandler}>
            {translate('buttons.allow')}
          </LoadingButton>
          <Button variant="text" onClick={initializeHandler}>
            {translate('buttons.notNow')}
          </Button>
        </>
      );
      break;
    }
  }

  return (
    <PopupContent disableClose closeHandler={() => {}} title={title} isOpened={isPopupOpened}>
      <Typography variant="body1" color="text.secondary">
        {subtitle}
      </Typography>
      {geoPointForServerActions === 'REQUIRED' && isPermissionChecked && (
        <Alert severity="info">{translate('pages.handymanGuard.geoPositionGuardForbiddenTip')}</Alert>
      )}
      <Stack spacing={1}>{buttons}</Stack>
    </PopupContent>
  );
}
