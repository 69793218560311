import { CreateServiceCategoryGroupRequestDTO, ServiceCategoryGroupResponseDTO } from 'typings/dto/serviceCategoryGroup';
import ModelActionsService from '../ModelActionsService';
import IServiceCategoryGroupActionsService from './IServiceCategoryGroupActionsService';
import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';

/** Используется как для нативных, так и для кастомных от ритейлера */
export default class ServiceCategoryGroupActionsServiceImpl
  extends ModelActionsService<ServiceCategoryGroup, CreateServiceCategoryGroupRequestDTO, ServiceCategoryGroupResponseDTO>
  implements IServiceCategoryGroupActionsService
{
  /** @throws `BackendResponseError` */
  public exportConfig = async (): Promise<void> => {
    const path = `${PATH_BACKEND.serviceCategoryGroup.root}/${PATH_BACKEND_PART.serviceCategoryGroup.export}`;
    await this.entityApiService.postWithCredentials(path);
  };

  /** Используется multipart/form-data */
  public importConfig = (file: File, force: string): Promise<void> => {
    const path = `${PATH_BACKEND.serviceCategoryGroup.root}/${PATH_BACKEND_PART.serviceCategoryGroup.import}`;
    const formData = new FormData();
    formData.append('file', file);
    formData.set('force', force);
    return this.entityApiService.postMultipart(path, formData);
  };
}
