import { StandardTextFieldProps, TextField, Tooltip, Typography } from '@mui/material';
import { INPUT_FULL_WIDTH_CLASS_NAME } from 'configs/layout';
import { FormikContextType } from 'formik';
import React from 'react';
import ModelUtils from 'utils/models/ModelUtils';

export type TextFieldMemorizedProps = StandardTextFieldProps & {
  fieldName: string;
  label?: string;
  formState: FormikContextType<any>;
  options: EntityWithName[];
};

function PredefinedListTypeInfoBlock({
  fieldName,
  children,
  label,
  formState,
  fullWidth,
  helperText,
  className,
  onChange,
  ...rest
}: TextFieldMemorizedProps) {
  const { getFieldProps, touched, errors } = formState;
  const errorText = touched[fieldName] && errors[fieldName];
  const classNameFinal = fullWidth ? INPUT_FULL_WIDTH_CLASS_NAME : className;
  const fieldPropsFromFormState = getFieldProps(fieldName);

  const valueText: string = fieldPropsFromFormState.value
    .map((v: EntityWithName) => {
      return v.name;
    })
    .join(', ');

  return (
    <Tooltip
      sx={{ whiteSpace: 'pre-wrap' }}
      title={
        <React.Fragment>
          {formState.values.values.map((i: EntityWithName) => (
            <Typography key={i.id} variant="inherit" sx={{ mt: 0.5 }}>
              {i.name}
            </Typography>
          ))}
        </React.Fragment>
      }
      disableInteractive
      enterTouchDelay={700}
    >
      <span style={{ width: '100%' }}>
        <TextField
          fullWidth
          label={label}
          size="medium"
          variant="outlined"
          className={classNameFinal}
          helperText={errorText}
          {...rest}
          InputProps={rest.InputProps}
          error={Boolean(errorText)}
          disabled={true}
          value={valueText}
        />
      </span>
    </Tooltip>
  );
}

export default React.memo(PredefinedListTypeInfoBlock, (prevProps, nextProps) => {
  const prevName = prevProps.fieldName;
  const nextName = nextProps.fieldName;

  return (
    prevProps.formState.values[prevName] === nextProps.formState.values[nextName] &&
    ModelUtils.checkArraysEquality(prevProps.formState.values[prevName], nextProps.formState.values[nextName]) &&
    prevProps.formState.touched[prevName] === nextProps.formState.touched[nextName] &&
    prevProps.formState.errors[prevName] === nextProps.formState.errors[nextName] &&
    prevProps.disabled === nextProps.disabled
  );
});
