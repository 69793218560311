import Mapper from './Mapper';

export default class DictionaryMapper extends Mapper<Dictionary, DictionaryDTO, DictionaryDTO> {
  constructor() {
    super({ name: 'name', entries: 'entries' }, null);
  }

  public responseDTOToModel = (dictionary: DictionaryDTO): Dictionary => {
    return {
      ...this.responseDTOToModelViaDiffMap(dictionary),
      entries: dictionary.entries.map((e) => {
        return { name: e.name, id: e.key };
      }),
    };
  };
}
