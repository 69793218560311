import { CreateTaskRequestDTO, TaskResponseDTO } from 'typings/dto/task';
import DateUtils from 'utils/Date';
import DateWithTimezone from 'utils/implementations/DateWithTimezone';
import Mapper from './Mapper';

export default class TaskMapper extends Mapper<Task, TaskResponseDTO, CreateTaskRequestDTO> {
  constructor(private dateUtils: DateUtils) {
    super(
      {
        closedAt: 'closedAt',
        deadline: null,
        description: 'description',
        groupRecipient: 'groupRecipients',
        priority: 'priority',
        status: 'state',
        title: 'name',
        type: 'type',
        userRecipients: 'userRecipients',
        closedByUser: 'closedBy',
        createdByUser: 'createdBy',
        timezone: null,
        linkedEntities: 'linkedEntities',
      },
      {
        closedAt: 'closedAt',
        closedBy: 'closedByUserId',
        createdBy: null,
        description: 'description',
        expiresAt: null,
        groupRecipients: 'groupRecipient',
        name: 'title',
        priority: 'priority',
        startsAt: null,
        state: 'status',
        type: 'type',
        userRecipients: 'userRecipientsIds',
        linkedEntities: 'linkedEntities',
      }
    );
  }

  public responseDTOToModel(dto: TaskResponseDTO): Task {
    const groupRecipients = dto.groupRecipient.map((g) => ({
      ownerId: g.tenantId,
      technicalName: g.groupName,
      roles: g.roles,
    }));

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      expiresAt: dto.deadline?.expiresAt ? new DateWithTimezone(dto.deadline.expiresAt, dto.timezone, this.dateUtils) : undefined,
      startsAt: dto.deadline?.startsAt ? new DateWithTimezone(dto.deadline.startsAt, dto.timezone, this.dateUtils) : undefined,
      closedAt: dto.closedAt ? new DateWithTimezone(dto.closedAt, dto.timezone, this.dateUtils) : undefined,
      groupRecipients,
    };
  }

  public modelToRequestDTO(model: Task): CreateTaskRequestDTO {
    const groupRecipient = model.groupRecipients.map((g) => ({
      groupName: g.technicalName,
      tenantId: g.ownerId,
      roles: g.roles,
    }));
    const startsAt = model.startsAt?.getRawDate().toISOString();
    const expiresAt = model.expiresAt?.getRawDate().toISOString();

    return {
      ...super.modelToRequestDTO(model, null),
      userRecipientsIds: model.userRecipients.map((u) => u.id),
      groupRecipient,
      deadline: { startsAt, expiresAt },
      closedAt: model.closedAt?.getRawDate().toISOString(),
      closedByUserId: model.closedBy?.id,
    };
  }
}
