import { createContext, MutableRefObject, useRef } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export const StableNavigateContext = createContext<MutableRefObject<NavigateFunction>>({} as any);

/**
 * У useNavigate есть не баг а фича - этот хук обновляет компонент каждый раз, когда меняется адрес, даже если компонент в memo. Данный хук позволяет это исправить, но не будет работать navigate(-1)
 * @todo Есть вариант использовать router.navigate из createBrowserRouter из react-router 6.4, но последний ещё сырой
 */
export function StableNavigateContextProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  return <StableNavigateContext.Provider value={navigateRef}>{children}</StableNavigateContext.Provider>;
}
