import numeral from 'numeral';

/*
export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

*/

export default class NumberUtils {
  /**
   * @deprecated Есть в file utils на нативном Intl без сторонних библиотек
   * @todo удалить
   */
  public static formatData(number: string | number) {
    return numeral(number).format('0.0 b');
  }

  /** @returns количество знаков после запятой */
  public static getDecimalCount = (number: number): number => {
    return number.toString().split('.')[1]?.length || 0;
  };
}
