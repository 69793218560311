import useLocales from 'hooks/useLocales';
import { DeleteIcon } from '../icons';
import IconButtonStyled from './IconButtonStyled';

export default function DeleteButton({ title: titleRaw, ...rest }: React.ComponentProps<typeof IconButtonStyled>) {
  const { translate } = useLocales();
  const title = titleRaw || translate('buttons.delete');

  return (
    <IconButtonStyled {...rest} title={title}>
      <DeleteIcon />
    </IconButtonStyled>
  );
}
