import { CheckboxProps, FormControlLabel, Checkbox, Switch, FormHelperText, Box, SwitchProps } from '@mui/material';
import { FormikContextType } from 'formik';
import React from 'react';

type Props = CheckboxProps & SwitchProps & {
  fieldName: string;
  formState: FormikContextType<any>;
  variant?: 'checkbox' | 'switch';
  label?: React.ReactNode;
  autosubmit?: boolean;
  helperText?: string;
};

function CheckboxMemorized({ autosubmit, fieldName, helperText, label, variant = 'checkbox', formState, ...rest }: Props) {
  const { getFieldProps, setFieldValue, values, handleSubmit } = formState;
  const filedPropsFromFormState = getFieldProps(fieldName);

  const onChangeHandler = autosubmit
    ? (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(fieldName, e.target.checked);
        if (autosubmit) handleSubmit();
      }
    : filedPropsFromFormState.onChange;

  if (!label) {
    return variant === 'checkbox' ? (
      <Checkbox color="primary" {...filedPropsFromFormState} onChange={onChangeHandler} checked={values[fieldName]} {...rest} />
    ) : (
      <Switch color="primary" {...filedPropsFromFormState} onChange={onChangeHandler} checked={values[fieldName]} {...rest} />
    );
  }

  const isErrorState = Boolean(formState.errors[fieldName]);
  const showHelperText = Boolean(helperText) || Boolean(formState.touched[fieldName] && isErrorState);

  return (
    <Box>
      <FormControlLabel
        label={label}
        control={
          variant === 'checkbox' ? (
            <Checkbox color="primary" {...filedPropsFromFormState} onChange={onChangeHandler} checked={values[fieldName]} {...rest} />
          ) : (
            <Switch color="primary" {...filedPropsFromFormState} onChange={onChangeHandler} checked={values[fieldName]} {...rest} />
          )
        }
      />
      {showHelperText && (
        <FormHelperText sx={{ color: isErrorState ? 'red' : 'text.secondary' }}>{helperText || formState.errors[fieldName]}</FormHelperText>
      )}
    </Box>
  );
}

export default React.memo(CheckboxMemorized, (prevProps, nextProps) => {
  const prevName = prevProps.fieldName;
  const nextName = nextProps.fieldName;
  return (
    prevProps.formState.values[prevName] === nextProps.formState.values[nextName] &&
    prevProps.formState.touched[prevName] === nextProps.formState.touched[nextName] &&
    prevProps.formState.errors[prevName] === nextProps.formState.errors[nextName] &&
    prevProps.disabled === nextProps.disabled
  );
});
