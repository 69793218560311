import { Box, BoxProps, Table, TableHead, TableBody, Stack, Typography, MenuItem, Menu, TableRow } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import React, { useRef, useState } from 'react';
import ScrollFade from 'components/features/ScrollFade';
import PaginationButtons from '../../features/pagination/PaginationButtons';
import useLocales from 'hooks/useLocales';
import LoaderComponent from '../loadingIndicators/LoaderComponent';

type Props = BoxProps & {
  /** Ждёт TableRow (можно и списком, но обычно одна) */
  header: React.ReactElement | React.ReactElement[];
  /** Ждёт список TableRow */
  children: React.ReactElement[];
  pagination?: Pagination;
  isLoading?: boolean;
  emptyBlock?: JSX.Element;
  /** Параметр поиска, который всегда будет применяться */
  initialFilter?: LocationSearchObject;
};

export default function TableVariant1({ header, initialFilter, children, emptyBlock, pagination, isLoading, sx, ...rest }: Props) {
  return (
    <Box sx={{ mt: 3, ...sx }} {...rest}>
      <ScrollFade>
        <Box sx={{ overflowX: 'auto' }}>
          <Table className="table_variant-1" size="medium">
            <TableHead>{header}</TableHead>
            <TableBody>{isLoading ? <TableRow /> : children}</TableBody>
          </Table>
        </Box>
      </ScrollFade>
      {isLoading ? <LoaderComponent /> : !children.length && emptyBlock ? emptyBlock : null}
      {pagination && pagination.totalPages > 1 && (
        <Stack direction="row" justifyContent={{ xs: 'flex-start', md: 'space-between' }} alignItems="center" spacing={2} sx={{ mt: 2 }}>
          <PaginationButtons count={pagination.totalPages} page={pagination.pageNumber} boundaryCount={1} initialFilter={initialFilter} />
          {/* <SizeSelector size={10} /> */}
        </Stack>
      )}
    </Box>
  );
}

function _SizeSelector({ size: sizeRaw }: { size: number }) {
  const { translate } = useLocales();
  const ref = useRef(null);
  const [isOpened, setIsOpened] = useState(false);
  const [size, setSize] = useState(sizeRaw);

  return (
    <Box>
      <Typography
        ref={ref}
        variant="body2"
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={() => setIsOpened(true)}
      >
        {translate('buttons.rowsSelect')}: {size}
        <ExpandMoreRoundedIcon sx={{ ml: 0.5, mr: -1 }} />
      </Typography>

      <Menu
        open={isOpened}
        anchorEl={ref.current}
        onClose={() => setIsOpened(false)}
        PaperProps={{ sx: { minWidth: 300, maxWidth: '100%' } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {[10, 20, 30].map((item) => (
          <MenuItem
            key={item}
            sx={{ color: 'text.secondary' }}
            onClick={() => {
              setSize(item);
              setIsOpened(false);
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
