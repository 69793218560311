import React, { useMemo } from 'react';
import PredefinedVariableBlock from 'components/_dashboardPagesFeatures/promotionsConfig/predefined/PredefinedVariableBlock';
import PopupAction from 'components/ui/popups/PopupAction';
import useLocales from 'hooks/useLocales';
import { ChildFormsState } from 'hooks/useChildForms';
import PredefinedDictionaryVariableEditPopup from 'components/_dashboardPagesFeatures/promotionsConfig/predefined/PredefinedDictionaryVariableEditPopup';
import { PromotionsConfigResponseDTO } from 'typings/dto/promotionsConfig';

const modelPath: keyof PromotionsConfigResponseDTO['promotionsConfig'] = 'predefined';

type Props = {
  isPopupOpened: boolean;
  popupCloseHandler: () => void;
  variablesList: PromotionsPredefined.PromotionsPredefinedUnknown[] | undefined;
  varsChildForms: ChildFormsState;
  rerenderPageCallback: VoidFunction;
  setAnyDirty: (val: boolean) => void;
  setToDeleteIndex: (index: string | null) => void;
  popupOpenHandler: (index?: string) => void;
  toOpenIndex?: string;
  dictionaryList: Dictionary[];
  closeDeleteHandler: VoidFunction;
  deletePredefinedHandler: VoidFunction;
  setIsDeletePopupOpened: (val: boolean) => void;
  isDeletePopupOpened: boolean;
  isEditable?: boolean;
  parentModelPath: string;
  configError: Record<string, string>;
};

export default function VariablesEditBlock({
  isPopupOpened,
  popupCloseHandler,
  variablesList,
  varsChildForms,
  rerenderPageCallback,
  popupOpenHandler,
  toOpenIndex,
  dictionaryList,
  setToDeleteIndex,
  setAnyDirty,
  closeDeleteHandler,
  deletePredefinedHandler,
  setIsDeletePopupOpened,
  isDeletePopupOpened,
  isEditable = true,
  parentModelPath,
  configError,
}: Props) {
  const { translate } = useLocales();

  const dictionaryMap: Record<string, Dictionary> = useMemo(() => {
    return dictionaryList.reduce((acc: Record<string, Dictionary>, item: Dictionary) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, []);

  return (
    <>
      {variablesList &&
        varsChildForms.formsKeys.map((formId, formIndex) => {
          return (
            <PredefinedVariableBlock
              key={formId}
              formId={formId}
              formsStatesMapRef={varsChildForms.formsMapRef}
              initialState={variablesList[formIndex]}
              formIndex={formIndex}
              formChangedCallback={() => setAnyDirty(true)}
              deletePredefinedHandler={(formId: string) => {
                setToDeleteIndex(formId);
                setIsDeletePopupOpened(true);
              }}
              popupOpenHandler={popupOpenHandler}
              dictionaryMap={dictionaryMap}
              isEditable={isEditable}
              parentModelPath={parentModelPath + '.' + modelPath}
              configError={configError}
            />
          );
        })}

      {isPopupOpened && (
        <PredefinedDictionaryVariableEditPopup
          isOpened={isPopupOpened}
          rerenderPageCallback={rerenderPageCallback}
          formsStatesMapRef={varsChildForms.formsMapRef}
          closeHandler={popupCloseHandler}
          formId={toOpenIndex}
          formChangedCallback={() => setAnyDirty(true)}
          dictionaryMap={dictionaryMap}
        />
      )}

      <PopupAction
        isOpened={isDeletePopupOpened}
        closeHandler={closeDeleteHandler}
        actionHandler={deletePredefinedHandler}
        title={translate('pages.promotionsCategoriesEdit.deleteTitle')}
      />
    </>
  );
}
