import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { CreateOrderLabelRequestDTO, OrderLabelResponseDTO } from 'typings/dto/orderLabel';
import IOrderLabelActionsService from './IOrderLabelActionsService';
import { PATH_BACKEND } from 'configs/routes/pathsBackend';

export default class OrderLabelActionsServiceImpl
  extends ModelActionsService<OrderLabel, CreateOrderLabelRequestDTO, OrderLabelResponseDTO>
  implements IOrderLabelActionsService
{
  /** @throws `BackendResponseError` */
  public getValuesFromOrderForFilter = (): Promise<void> => {
    const url = PATH_BACKEND.orderLabel.orderFilter;
    return this.getAllForFilter(undefined, undefined, url);
  };
}
