import { ButtonProps, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { useLocation } from 'react-router';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import NavigateFrontendUtils from 'utils/NavigateFrontend';
import useLocales from 'hooks/useLocales';

interface IProps extends ButtonProps {
  sortFieldName: string;
  /** Параметр поиска, который всегда будет применяться */
  initialFilter?: LocationSearchObject;
}

function SortByButton({ sortFieldName, initialFilter, children }: IProps) {
  useLocation();
  const { translate } = useLocales();
  const paramsObject = NavigateFrontendUtils.getLocationSearchObjectFromCurrentLocation();
  const isSortedByCurrentField = paramsObject.sortedBy?.value === sortFieldName;
  const isCurrentSortOrderIsDesc = paramsObject.sortOrder?.value === NavigateFrontendUtils.SORT_ORDER.desc;

  let params: LocationSearchObject;
  if (isSortedByCurrentField) {
    params = {
      sortOrder: NavigateFrontendUtils.createLocationSearchParam(
        isCurrentSortOrderIsDesc ? NavigateFrontendUtils.SORT_ORDER.asc : NavigateFrontendUtils.SORT_ORDER.desc
      ),
    };
  } else {
    params = {
      sortOrder: NavigateFrontendUtils.createLocationSearchParam(NavigateFrontendUtils.SORT_ORDER.asc),
      sortedBy: NavigateFrontendUtils.createLocationSearchParam(sortFieldName),
    };
  }
  if (initialFilter) {
    // initialFilter может быть перезаписан
    params = { ...initialFilter, ...params };
  }

  const path = NavigateFrontendUtils.addSearchQueryToCurrentLocation(params);
  const icon = isCurrentSortOrderIsDesc ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />;

  return (
    <Link
      to={path}
      component={RouterLink}
      title={translate('buttons.sortBy')}
      color="inherit"
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      {isSortedByCurrentField && icon}
      {children}
    </Link>
  );
}

export default React.memo(SortByButton, () => true);
