import { TableCell, TableCellProps, Typography } from '@mui/material';
import SortByButton from 'components/ui/buttons/SortByButton';
import IMapper from 'utils/mappers/IMapper';

/** Маппер нужен для проверки, что переданное поле относится к данной сущности */
type Props<M extends Model, D extends ModelDTOResponse> = TableCellProps & {
  sortableByField?: StringKeysOf<M> | `${StringKeysOf<M>}.${keyof EntityWithName}`;
  mapper?: IMapper<M, D, any>;
  /** Параметр поиска, который всегда будет применяться */
  initialFilter?: LocationSearchObject;
};

export default function TableHeaderCell<M extends Model, D extends ModelDTOResponse>({
  children,
  sortableByField,
  mapper,
  initialFilter,
  ...rest
}: Props<M, D>) {
  if (!children) return <TableCell />;
  const content = (
    <Typography variant="subtitle2" color="inherit" component="span">
      {children}
    </Typography>
  );
  return (
    <TableCell {...rest} color="text.secondary">
      {sortableByField ? (
        <SortByButton sortFieldName={sortableByField as string} initialFilter={initialFilter}>
          {content}
        </SortByButton>
      ) : (
        content
      )}
    </TableCell>
  );
}
