import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { BrandResponseDTO, CreateBrandRequestDTO } from 'typings/dto/brand';
import IBrandActionsService from './IBrandActionsService';
import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import { BACKEND_ROOT } from 'configs/apis';

export default class BrandActionsServiceImpl
  extends ModelActionsService<Brand, CreateBrandRequestDTO, BrandResponseDTO>
  implements IBrandActionsService
{
  /** @throws `BackendResponseError` */
  public getValuesFromOrderForFilter = (): Promise<void> => {
    const url = PATH_BACKEND.brand.orderFilter;
    return this.getAllForFilter(undefined, undefined, url);
  };

  /** @throws `BackendResponseError` */
  public getBrandForClientPage = (orderId: string) => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.brand.brandForClientPage}`;
    return this.getById(orderId, url);
  };
}
