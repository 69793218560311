import { enUS } from '@mui/material/locale';
import ILanguageService from 'services/language/ILanguageService';

/**
 * Используется в сервисе перевода, здесь указаны все возможные в системе языки и их настройки.
 * Дефолтный язык для системы и доступные для выбора языки в .env
 * Файлы с переводом лежат рядом в текущей папке, они получаются асинхронно для выбранного языка
 * @see ILanguageService.Locale
 */
export const LOCALES: readonly ILanguageService.Locale[] = [
  {
    label: 'Русский',
    icon: '/icons/ic_flag_ru.svg',
    localeShort: 'ru',
    localeFull: 'ru-RU',
    localeSystem: enUS,
    localeCountry: 'RU',
    localeDateName: 'ru',
    localeCurrency: 'RUB',
  },
  {
    label: 'English (GB)',
    icon: '/icons/ic_flag_en-GB.svg',
    localeShort: 'en',
    localeFull: 'en-GB',
    localeSystem: enUS,
    localeCountry: 'GB',
    localeDateName: 'en-GB',
    localeCurrency: 'GBP',
  },
  {
    label: 'English (US)',
    icon: '/icons/ic_flag_en-US.png',
    localeShort: 'en',
    localeFull: 'en-US',
    localeSystem: enUS,
    localeCountry: 'US',
    localeDateName: 'en-US',
    localeCurrency: 'USD',
  },
];
