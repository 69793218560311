import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import IModelActionsService from './IModelActionsService';
import { CountryResponseDTO, CreateCountryRequestDTO } from 'typings/dto/country';

export default class CountryActionsService
  extends ModelActionsService<Country, CreateCountryRequestDTO, CountryResponseDTO>
  implements IModelActionsService<Country, CreateCountryRequestDTO, CountryResponseDTO>
{
  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return super.getDefaultSortFilter(filter, 'createdAt');
  };
}
