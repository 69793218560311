import FrontendRoutesBase from './FrontendRoutesBase';
import { PATH_PARAM_DASHBOARD as P, PAGE_TYPE, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';
import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';

export default class ServiceCatalogRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public root = this.getDBPath(P.service.root);
  public enterprise: EnterpriseRoutes;
  public categoryGroup: ServiceCategoryGroupRoutes;
  public category: ServiceCategoryRoutes;
  public service: ServiceRoutes;

  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  constructor() {
    super();
    this.enterprise = new EnterpriseRoutes();
    this.categoryGroup = new ServiceCategoryGroupRoutes(this.enterprise);
    this.category = new ServiceCategoryRoutes(this.categoryGroup);
    this.service = new ServiceRoutes(this.category);
  }
}

class EnterpriseRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public child: ServiceCategoryGroupRoutes | null = null;

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.service> = (_params, permissions, translate) => {
    const canAccessEnterpriseListPage = permissions.can(MODEL_PERMISSION.CAN_READ_ENTERPRISE_LIST);
    return canAccessEnterpriseListPage ? [{ name: translate('menus.main.services'), href: this.getListPath() }] : [];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.service, 'enterpriseName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const breadcrumbs = this.getInstanceBc(params, permissions, translate);
    breadcrumbs.push({ name: names.enterpriseName, href: this.getInfoPath(params) });
    return breadcrumbs;
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () => this.getDBPath(P.service.root, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.child ? this.child.getListPath(params) : '#';
}

class ServiceCategoryGroupRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public child: ServiceCategoryRoutes | null = null;
  constructor(private parent: EnterpriseRoutes) {
    super();
    parent.child = this;
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.service, 'enterpriseName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    return this.parent.getInstanceChildBc(params, permissions, translate, names);
  };

  getInstanceChildBc = this.getInstanceBc;

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.getDBPath(P.service.root, params.enterpriseId, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.child ? this.child.getListPath(params) : '#';

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.getDBPath(P.service.root, params.enterpriseId, PAGE_TYPE.new, PAGE_TYPE.add);

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.getDBPath(P.service.root, params.enterpriseId, params.serviceCategoryGroupId, PAGE_TYPE.edit);
}

class ServiceCategoryRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public child: ServiceRoutes | null = null;
  constructor(private parent: ServiceCategoryGroupRoutes) {
    super();
    parent.child = this;
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetterWithNames<
    typeof PATH_PARAM_TOKEN.service,
    'enterpriseName' | 'serviceCategoryGroupName'
  > = (params, permissions, translate, names) => {
    const breadcrumbs = this.parent.getInstanceChildBc(params, permissions, translate, names);
    breadcrumbs.push({ name: names.serviceCategoryGroupName, href: this.parent.getInfoPath(params) });
    return breadcrumbs;
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<
    typeof PATH_PARAM_TOKEN.service,
    'enterpriseName' | 'serviceCategoryGroupName' | 'serviceCategoryName'
  > = (params, permissions, translate, names) => {
    const breadcrumbs = this.getInstanceBc(params, permissions, translate, names);
    breadcrumbs.push({ name: names.serviceCategoryName, href: this.getInfoPath(params) });
    return breadcrumbs;
  };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.getDBPath(P.service.root, params.enterpriseId, params.serviceCategoryGroupId, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.child ? this.child.getListPath(params) : '#';

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.getDBPath(P.service.root, params.enterpriseId, params.serviceCategoryGroupId, PAGE_TYPE.new, PAGE_TYPE.add);

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.getDBPath(P.service.root, params.enterpriseId, params.serviceCategoryGroupId, params.serviceCategoryId, PAGE_TYPE.edit);
}

class ServiceRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: ServiceCategoryRoutes) {
    super();
    parent.child = this;
  }

  getInstanceBc = this.parent.getInstanceChildBc;

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<
    typeof PATH_PARAM_TOKEN.service,
    'enterpriseName' | 'serviceCategoryGroupName' | 'serviceCategoryName' | 'serviceName'
  > = (params, permissions, translate, names) => {
    const breadcrumbs = this.getInstanceBc(params, permissions, translate, names);
    breadcrumbs.push({ name: names.serviceName, href: this.getInfoPath(params) });
    return breadcrumbs;
  };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.getDBPath(P.service.root, params.enterpriseId, params.serviceCategoryGroupId, params.serviceCategoryId, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.getDBPath(
      P.service.root,
      params.enterpriseId,
      params.serviceCategoryGroupId,
      params.serviceCategoryId,
      params.serviceId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.getDBPath(
      P.service.root,
      params.enterpriseId,
      params.serviceCategoryGroupId,
      params.serviceCategoryId,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.service> = (params) =>
    this.getDBPath(
      P.service.root,
      params.enterpriseId,
      params.serviceCategoryGroupId,
      params.serviceCategoryId,
      params.serviceId,
      PAGE_TYPE.edit
    );
}
