export enum FIELD_PERMISSION {
  // Order
  CAN_UPDATE_ORDER_CUSTOMER_PERSONAL_DATA = 'CAN_UPDATE_ORDER_CUSTOMER_PERSONAL_DATA',
  CAN_UPDATE_ORDER_NUMBER_OF_TECHNICIANS = 'CAN_UPDATE_ORDER_NUMBER_OF_TECHNICIANS',
  CAN_FOLLOW_ORDER = 'CAN_FOLLOW_ORDER',
  CAN_UPDATE_ORDER_ENTERPRISE_DEPARTMENT = 'CAN_UPDATE_ORDER_ENTERPRISE_DEPARTMENT',
  CAN_UPDATE_ORDER_DELIVERY_INTERVALS = 'CAN_UPDATE_ORDER_DELIVERY_INTERVALS',
  CAN_READ_ORDER_CUSTOM_FIELD = 'CAN_READ_ORDER_CUSTOM_FIELD',
  /** Лист от одиночного отличается возможностью фильтровать по полям */
  CAN_READ_ORDER_CUSTOM_FIELD_LIST = 'CAN_READ_ORDER_CUSTOM_FIELD_LIST',
  CAN_UPDATE_ORDER_CUSTOM_FIELD = 'CAN_UPDATE_ORDER_CUSTOM_FIELD',
  // Order provider
  CAN_UPDATE_ORDER_SERVICE_PROVIDER = 'CAN_UPDATE_ORDER_SERVICE_PROVIDER',
  CAN_UPDATE_ORDER_SP_TECHNICIANS = 'CAN_UPDATE_ORDER_SP_TECHNICIANS',
  CAN_ACCEPT_OFFERED_ORDER_AS_SP = 'CAN_ACCEPT_OFFERED_ORDER_AS_SP',
  CAN_ACCEPT_OFFERED_ORDER_AS_TECHNICIAN = 'CAN_ACCEPT_OFFERED_ORDER_AS_TECHNICIAN',
  CAN_READ_ORDER_SERVICE_PROVIDER_REWARD = 'CAN_READ_ORDER_SERVICE_PROVIDER_REWARD',
  CAN_READ_ORDER_TECHNICIANS_REWARD_SETTINGS = 'CAN_READ_ORDER_TECHNICIANS_REWARD_SETTINGS',
  CAN_UPDATE_ORDER_TECHNICIANS_REWARD_SETTINGS = 'CAN_UPDATE_ORDER_TECHNICIANS_REWARD_SETTINGS',
  // Order view
  CAN_CREATE_SHARED_ORDER_VIEW = 'CAN_CREATE_SHARED_ORDER_VIEW',
  CAN_UPDATE_SHARED_ORDER_VIEW = 'CAN_UPDATE_SHARED_ORDER_VIEW',
  CAN_DELETE_SHARED_ORDER_VIEW = 'CAN_DELETE_SHARED_ORDER_VIEW',
  // Order service
  CAN_CANCEL_ORDER_SERVICE_PAYMENT = 'CAN_CANCEL_ORDER_SERVICE_PAYMENT',
  CAN_PAY_ORDER_SERVICE_IN_STORE = 'CAN_PAY_ORDER_SERVICE_IN_STORE',
  CAN_PAY_ORDER_SERVICE_FROM_BALANCE = 'CAN_PAY_ORDER_SERVICE_FROM_BALANCE',
  CAN_PAY_ORDER_SERVICE_BY_CARD = 'CAN_PAY_ORDER_SERVICE_BY_CARD',
  CAN_PAY_ORDER_SERVICE_BY_CASH_ON_SITE = 'CAN_PAY_ORDER_SERVICE_BY_CASH_ON_SITE',
  CAN_CREATE_ORDER_SERVICE_CUSTOM = 'CAN_CREATE_ORDER_SERVICE_CUSTOM',
  CAN_UPDATE_ORDER_SERVICE_CUSTOM = 'CAN_UPDATE_ORDER_SERVICE_CUSTOM',
  CAN_DELETE_ORDER_SERVICE_CUSTOM = 'CAN_DELETE_ORDER_SERVICE_CUSTOM',
  CAN_CREATE_ORDER_SERVICE_FROM_CATALOG = 'CAN_CREATE_ORDER_SERVICE_FROM_CATALOG',
  CAN_UPDATE_ORDER_SERVICE_FROM_CATALOG = 'CAN_UPDATE_ORDER_SERVICE_FROM_CATALOG',
  CAN_DELETE_ORDER_SERVICE_FROM_CATALOG = 'CAN_DELETE_ORDER_SERVICE_FROM_CATALOG',
  CAN_UPDATE_ORDER_SERVICE_DISCOUNT = 'CAN_UPDATE_ORDER_SERVICE_DISCOUNT',
  CAN_CREATE_ORDER_SERVICE_RETENTION = 'CAN_CREATE_ORDER_SERVICE_RETENTION',
  CAN_DEDUCT_ORDER_CUSTOMER_BALANCE = 'CAN_DEDUCT_ORDER_CUSTOMER_BALANCE',
  // Order task
  CAN_CREATE_ENT_BENEFICIARY_GROUP_TASKS_IF_PO_ASSIGNED = 'CAN_CREATE_ENT_BENEFICIARY_GROUP_TASKS_IF_PO_ASSIGNED',
  CAN_CREATE_PO_BENEFICIARY_GROUP_TASKS_IF_PO_ASSIGNED = 'CAN_CREATE_PO_BENEFICIARY_GROUP_TASKS_IF_PO_ASSIGNED',
  CAN_CREATE_PROVIDER_GROUP_TASKS_IF_PO_ASSIGNED = 'CAN_CREATE_PROVIDER_GROUP_TASKS_IF_PO_ASSIGNED',
  // User
  CAN_CHANGE_OWN_CREDENTIALS = 'CAN_CHANGE_OWN_CREDENTIALS',
  CAN_UPDATE_OWN_PROFILE = 'CAN_UPDATE_OWN_PROFILE',
  // Enterprise
  CAN_READ_ENTERPRISE_API_SETTINGS = 'CAN_READ_ENTERPRISE_API_SETTINGS',
  CAN_UPDATE_ENTERPRISE_API_SETTINGS = 'CAN_UPDATE_ENTERPRISE_API_SETTINGS',
  CAN_READ_ENTERPRISE_ORDER_SETTINGS = 'CAN_READ_ENTERPRISE_ORDER_SETTINGS',
  CAN_UPDATE_ENTERPRISE_ORDER_SETTINGS = 'CAN_UPDATE_ENTERPRISE_ORDER_SETTINGS',
  CAN_READ_ENTERPRISE_TECHNICIAN_SETTINGS = 'CAN_READ_ENTERPRISE_TECHNICIAN_SETTINGS',
  CAN_UPDATE_ENTERPRISE_TECHNICIAN_SETTINGS = 'CAN_UPDATE_ENTERPRISE_TECHNICIAN_SETTINGS',

  CAN_READ_ENTERPRISE_ORDER_CUSTOM_FIELD_SETTINGS = 'CAN_READ_ENTERPRISE_ORDER_CUSTOM_FIELD_SETTINGS',
  CAN_CREATE_ENTERPRISE_ORDER_CUSTOM_FIELD_SETTINGS = 'CAN_CREATE_ENTERPRISE_ORDER_CUSTOM_FIELD_SETTINGS',
  CAN_UPDATE_ENTERPRISE_ORDER_CUSTOM_FIELD_SETTINGS = 'CAN_UPDATE_ENTERPRISE_ORDER_CUSTOM_FIELD_SETTINGS',
  CAN_DELETE_ENTERPRISE_ORDER_CUSTOM_FIELD_SETTINGS = 'CAN_DELETE_ENTERPRISE_ORDER_CUSTOM_FIELD_SETTINGS',
  CAN_READ_TENANT_NOTIFICATION_SETTINGS = 'CAN_READ_TENANT_NOTIFICATION_SETTINGS',
  // Enterprise user
  CAN_CHANGE_ENTERPRISE_USER_ROLE = 'CAN_CHANGE_ENTERPRISE_USER_ROLE',
  CAN_CHANGE_ENTERPRISE_USER_DEPARTMENT = 'CAN_CHANGE_ENTERPRISE_USER_DEPARTMENT',
  CAN_CHANGE_ENTERPRISE_USER_TECHNICAL_NAME = 'CAN_CHANGE_ENTERPRISE_USER_TECHNICAL_NAME',
  CAN_DEACTIVATE_ENTERPRISE_USER = 'CAN_DEACTIVATE_ENTERPRISE_USER',
  CAN_READ_ENTERPRISE_USER_NOTIFICATION_SETTINGS = 'CAN_READ_ENTERPRISE_USER_NOTIFICATION_SETTINGS',
  CAN_CHANGE_ENTERPRISE_USER_NOTIFICATION_SETTINGS = 'CAN_CHANGE_ENTERPRISE_USER_NOTIFICATION_SETTINGS',
  CAN_UPDATE_ENTERPRISE_USER_CREDENTIALS = 'CAN_UPDATE_ENTERPRISE_USER_CREDENTIALS',
  // Enterprise technician
  CAN_CHANGE_ENTERPRISE_TECHNICIAN_ROLE = 'CAN_CHANGE_ENTERPRISE_TECHNICIAN_ROLE',
  CAN_CHANGE_ENTERPRISE_TECHNICIAN_LEGAL_ENTITY = 'CAN_CHANGE_ENTERPRISE_TECHNICIAN_LEGAL_ENTITY',
  CAN_CHANGE_ENTERPRISE_TECHNICIAN_SERVICE = 'CAN_CHANGE_ENTERPRISE_TECHNICIAN_SERVICE',
  CAN_READ_ENTERPRISE_TECHNICIAN_REVIEW_LIST = 'CAN_READ_ENTERPRISE_TECHNICIAN_REVIEW_LIST',
  CAN_DEACTIVATE_ENTERPRISE_TECHNICIAN_USER = 'CAN_DEACTIVATE_ENTERPRISE_TECHNICIAN_USER',
  // SP user
  CAN_DEACTIVATE_SP_USER = 'CAN_DEACTIVATE_SP_USER',
  CAN_CHANGE_SP_USER_ROLE = 'CAN_CHANGE_SP_USER_ROLE',
  CAN_CHANGE_SP_USER_DEPARTMENT = 'CAN_CHANGE_SP_USER_DEPARTMENT',
  CAN_CHANGE_SP_USER_TECHNICAL_NAME = 'CAN_CHANGE_SP_USER_TECHNICAL_NAME',
  CAN_READ_SP_USER_NOTIFICATION_SETTINGS = 'CAN_READ_SP_USER_NOTIFICATION_SETTINGS',
  CAN_CHANGE_SP_USER_NOTIFICATION_SETTINGS = 'CAN_CHANGE_SP_USER_NOTIFICATION_SETTINGS',
  CAN_CHANGE_SP_TECHNICIAN_SERVICE = 'CAN_CHANGE_SP_TECHNICIAN_SERVICE',
  CAN_READ_SP_TECHNICIAN_REVIEW_LIST = 'CAN_READ_SP_TECHNICIAN_REVIEW_LIST',
  CAN_UPDATE_SP_USER_CREDENTIALS = 'CAN_UPDATE_SP_USER_CREDENTIALS',
  // ContractSP
  CAN_ACCEPT_SP_CONTRACT = 'CAN_ACCEPT_SP_CONTRACT',
  CAN_SUSPEND_SP_CONTRACT = 'CAN_SUSPEND_SP_CONTRACT',
  CAN_READ_SP_CONTRACT_PROVIDER_DATA = 'CAN_READ_SP_CONTRACT_PROVIDER_DATA',
  // ContractPO
  CAN_ACCEPT_PO_CONTRACT = 'CAN_ACCEPT_PO_CONTRACT',
  CAN_SUSPEND_PO_CONTRACT = 'CAN_SUSPEND_PO_CONTRACT',
  CAN_READ_PO_CONTRACT_PROVIDER_DATA = 'CAN_READ_PO_CONTRACT_PROVIDER_DATA',
  // Нужны для того, что бы enterpeise и PO видели разное название вкладки контрактов
  CAN_READ_CONTRACT_PLATFORM_OPERATOR_MENU_ITEM = 'CAN_READ_CONTRACT_PLATFORM_OPERATOR_MENU_ITEM',
  CAN_READ_CONTRACT_ENTERPRISE_MENU_ITEM = 'CAN_READ_CONTRACT_ENTERPRISE_MENU_ITEM',
  // Price
  CAN_READ_CAN_READ_TECHNICIAN_PRICE_MARGIN = 'CAN_READ_CAN_READ_TECHNICIAN_PRICE_MARGIN',
  CAN_READ_SERVICE_PROVIDER_PRICE_MARGIN = 'CAN_READ_SERVICE_PROVIDER_PRICE_MARGIN',
  CAN_UPDATE_CREATED_BY_ME_ORDER_TASK = 'CAN_UPDATE_CREATED_BY_ME_ORDER_TASK',
  CAN_UPDATE_ASSIGNED_TO_ME_ORDER_TASK = 'CAN_UPDATE_ASSIGNED_TO_ME_ORDER_TASK',
  CAN_UPDATE_MY_ORDER_COMMENT = 'CAN_UPDATE_MY_ORDER_COMMENT',
  CAN_DELETE_NOT_YOURS_ORDER_COMMENT = 'CAN_DELETE_NOT_YOURS_ORDER_COMMENT',
  CAN_UPDATE_NOT_YOURS_ORDER_COMMENT = 'CAN_UPDATE_NOT_YOURS_ORDER_COMMENT',
  CAN_DELETE_MY_ORDER_COMMENT = 'CAN_DELETE_MY_ORDER_COMMENT',
}
