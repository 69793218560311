import IPermissionService from 'services/permission/IPermissionService';
import { USER_ROLE } from 'typings/models/user.enum';

export default class EnterpriseUtils {
  public static getPermissionConfigs = (
    enterprise: Enterprise,
    currentUser: UserEnterprise | UserPlatformOperator
  ): IPermissionService.PermissionConfigs => {
    return {
      canCreateCustomServices: currentUser.roles.includes(USER_ROLE.orderManager)
        ? enterprise.clientPricelistSettings.allowAddCustomServices
        : enterprise.technicianPricelistSettings.allowAddCustomServices,
    };
  };

  public static isEntityInherited = (currentUser: User | null, entity: EntityWithTenant): boolean => {
    return currentUser?.tenant.id !== entity.tenant.id;
  };

  public static getEntityOwnerName = (currentUser: User | null, entity: EntityWithTenant): string => {
    const isInherited = this.isEntityInherited(currentUser, entity);
    return isInherited ? entity.tenant.name : '';
  };
}
