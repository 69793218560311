import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import {
  ContractPOResponseDTO,
  CreateContractPORequestDTO,
  GetOrderContractRequestDTO,
  UpdateContractPOSettingsRequestDTO,
} from 'typings/dto/contract';
import { ServiceProviderResponseDTO } from 'typings/dto/serviceProvider';
import IContractPOActionsService from './IContractPOActionsService';
import ContractPOMapper from 'utils/mappers/ContarctPO';

export default class ContractPOActionsServiceImpl
  extends ModelActionsService<ContractPO, CreateContractPORequestDTO, ContractPOResponseDTO>
  implements IContractPOActionsService
{
  /** @throws `BackendResponseError` */
  public accept = (id: string) => {
    return this.entityApiService.postWithCredentials<void>(PATH_BACKEND.contractPO.accept + '/' + id, null, false);
  };

  /** @throws `BackendResponseError` */
  public resume = (id: string) => {
    return this.entityApiService.postWithCredentials<void>(PATH_BACKEND.contractPO.resume + '/' + id, null, false);
  };

  /** @throws `BackendResponseError` */
  public suspend = (id: string) => {
    return this.entityApiService.postWithCredentials<void>(PATH_BACKEND.contractPO.suspend + '/' + id, null, false);
  };

  public reject = this.suspend;

  /** @throws `BackendResponseError` */
  public getOrderContract = (dto: GetOrderContractRequestDTO): Promise<ContractPO> => {
    const url = `${this.getModelApiRootPath(dto)}/tenant/${dto.tenantId}/service-provider`;
    /** id будет добавлен вконец url'а */
    return this.requestById(dto.serviceProviderId, url);
  };

  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return super.getDefaultSortFilter(filter, 'createdAt');
  };

  /** @throws `BackendResponseError` */
  public getContractProviderStatistics = async (contractId: string): Promise<User.TechnicianStatistics> => {
    const dto = await this.entityApiService.getWithCredentials<ServiceProviderResponseDTO['statistics']>(
      `${PATH_BACKEND.contractPO.root}/${contractId}/reviews`
    );

    return (this.modelMapper as ContractPOMapper).responseStatisticsDTOToModel(dto);
  };

  /** @throws `BackendResponseError` */
  public updateContractPOSettings = async (contractId: string, dto: UpdateContractPOSettingsRequestDTO): Promise<void> => {
    const url = `${this.getModelApiRootPath(dto)}/${contractId}/settings/pricelist`;
    await this.entityApiService.putWithCredentials(url, dto);
  };
}
