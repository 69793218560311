import Mapper from './Mapper';
import { CreateFundsTransactionRequestDTO, FundsTransactionResponseDTO } from 'typings/dto/fundsTransaction';
import DIContainer from '../../services/DIContainer';
import { AutomaticFundsTransactionConditionType, FUNDS_TRANSACTION_GROUP } from '../../typings/models/fundsTransaction.enum';

export default class FundsTransactionMapper extends Mapper<
  FundsTransaction,
  FundsTransactionResponseDTO,
  CreateFundsTransactionRequestDTO
> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        name: 'name',
        technicalName: 'technicalName',
        tenant: 'tenant',
        type: 'type',
        usage: 'usage',
        calculationMethod: 'calculationMethod',
        settings: 'settings',
        group: 'group',
        groupSpecificSettings: 'groupSpecificSettings',
        counterpartyType: 'counterpartyType',
      },
      null
    );
  }

  public responseDTOToModel = (dto: FundsTransactionResponseDTO): FundsTransaction => {
    const outerMappers = this.getOuterMappers();
    const customFieldMapper = this.subEntitiesMappers.orderCustomField;
    const model = this.responseDTOToModelViaDiffMap(dto);
    if (
      model.group === FUNDS_TRANSACTION_GROUP.automatic &&
      model.groupSpecificSettings.condition.type === AutomaticFundsTransactionConditionType.orderCustomFieldValue &&
      dto.groupSpecificSettings?.condition.customField
    ) {
      const customFields = customFieldMapper.responseDTOToModel(dto.groupSpecificSettings.condition.customField, outerMappers);
      model.groupSpecificSettings.condition.customField = customFields;
    }

    return {
      ...model,
    };
  };
}
