import { useSnackbar } from 'notistack';
import useIsMountedRef from 'hooks/useIsMountedRef';
import UnauthorizedError from 'utils/errors/UnauthorizedError';
import useDI from 'hooks/useDI';
import useStableNavigate from 'hooks/useStableNavigate';
import RaceConditionError from 'utils/errors/RaceConditionError';

type SnackbarHandlerBaseProps = {
  /** @deprecated использовать callback */
  setSubmitting?: (isSubmitting: boolean) => void;
  /** Пояснение к ошибке, обычно кто вызвал */
  source?: string;
  /** Если надо вызвать какой-то обработчик */
  callback?: VoidFunction;
};

type SnackbarErrorHandlerProps = SnackbarHandlerBaseProps & {
  /** Инстанс ошибки */
  error: any;
};

type SnackbarWarningHandlerProps = SnackbarHandlerBaseProps & {
  /** Тест сообщения */
  warning: string;
};

/** Выводит сообщение об ошибке в виде всплывающего snackbar */
const useSnackbarErrorHandler = () => {
  const navigate = useStableNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { services, storageActions } = useDI();
  const { translate } = services.language;
  const isMountedRef = useIsMountedRef();

  return ({ setSubmitting, callback, error, warning, source }: Partial<SnackbarErrorHandlerProps & SnackbarWarningHandlerProps>) => {
    if (setSubmitting && isMountedRef?.current) {
      setSubmitting(false);
    }
    if (callback && isMountedRef?.current && !(error instanceof RaceConditionError)) {
      callback();
    }

    if (error) {
      let message = `${translate('errors.errorOccurred')}: ${error.message}`;
      if (source) {
        message = message + '; ' + translate('errors.errorSource') + ': ' + source;
      }
      enqueueSnackbar(message, { variant: 'error', autoHideDuration: 10000 });
      console.error(error.message);
      // If 401, always redirects to login page (logout user manually and re request current page to trigger auth guard)
      // Same logic in ErrorComponent
      if (error instanceof UnauthorizedError) {
        storageActions.auth.setUser(null, []);
        navigate(window.location.pathname + window.location.search, { replace: true });
      }
    } else if (warning) {
      enqueueSnackbar(warning, { variant: 'warning', autoHideDuration: 10000 });
      console.warn(warning);
    }
  };
};

export default useSnackbarErrorHandler;
