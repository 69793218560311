import { TIME_ZONE } from 'typings/common/date.enum';
import DateUtils from 'utils/Date';

/**
 * Дата, которая на фронте отображает ровно те цифры дней и времени, какие она имеет в её родной таймзоне (т.е. если в родной зоне указан полдень, то и на любом фронте отобразится как полдень)
 * Все фронтовые нативные методы Date будут отдавать именно изменённое под фронт время (кроме toISOString и toJSON); чтобы получить доступ к оригинальному значению с бека нужно пользоваться кастомными геттерами
 */
export default class DateWithTimezone extends Date implements Date {
  private readonly dateUtils: DateUtils;
  /** Зона начальной даты (которой нет в ISO строке) */
  private readonly timezone: TIME_ZONE;

  /** @param initialDate начальная дата с бека, приходит в нулевой зоне (сюда фронтовую дату не передавать!) */
  constructor(initialDate: string | null | DateWithTimezone, timezone: TIME_ZONE, dateUtils: DateUtils) {
    super();
    this.timezone = timezone;
    this.dateUtils = dateUtils;
    const dateRaw = !initialDate
      ? new Date()
      : initialDate instanceof DateWithTimezone
      ? this.dateUtils.changeTimezone(initialDate.getRawDate(), this.timezone, initialDate.getTimezone())
      : new Date(initialDate);

    const currentTimezone = this.dateUtils.getCurrentTimezone();
    const dateZonedForView = this.dateUtils.changeTimezone(dateRaw, currentTimezone, this.timezone);
    super.setTime(dateZonedForView.getTime());
  }

  /**
   * Дата с бека, приведённая к фронту без учета зоны (взят dateInitialISO и на его основе создан инстанс Date);
   * Соответственно для бека нужна будет эта дата обратно.
   * Каждый раз высчитывать требуется т.к. основную дату могут изменить через нативные методы, которые не переопределены и их не отследить
   */
  public getRawDate = (): Date => {
    const currentTimezone = this.dateUtils.getCurrentTimezone();
    return this.dateUtils.changeTimezone(new Date(this), this.timezone, currentTimezone);
  };

  public getTimezone = (): TIME_ZONE => {
    return this.timezone;
  };

  // // Не тестировалось, но сделано по аналогии с конструктором, он тестировался
  // public setTimezone = (timezone: TIME_ZONE): this => {
  //   this.timezone = timezone
  //   const dateZonedForView = this.dateUtils.changeTimezone(this.getRawDate(), this.timezone, this.getTimezone())
  //   super.setTime(dateZonedForView.getTime());
  //   return this;
  // };

  /** Статик метод для упрощенного создания на основе даты из вьюхи */
  public static fromViewDate = (date: Date, timezone: TIME_ZONE, dateUtils: DateUtils): DateWithTimezone => {
    const dateWithTimezone = new DateWithTimezone(null, timezone, dateUtils);
    dateWithTimezone.setTime(date.getTime());
    return dateWithTimezone;
  };

  // Из дефолтных методов Date переопределены только те, что работают с бекендом

  /** @returns возвращает настоящее iso для бека, фронту не подойдёт! */
  public toISOString = (): string => {
    return this.getRawDate().toISOString();
  };

  /** @returns возвращает настоящее iso для бека, фронту не подойдёт! */
  public toJSON = (): string => {
    return this.getRawDate().toISOString();
  };
}

// TODO удалить, вариант без наследования
// class DateWithTimezone_ {
//   private dateUtils: DateUtils;
//   /** Зона начальной даты (которой нет в ISO строке) */
//   private timezone: TIME_ZONE;
//   /** Дата с бека, приведённая к фронту без учета зоны (взят dateInitialISO и на его основе создан инстанс Date); соответственно для бека нужна будет эта дата обратно */
//   private dateRaw: Date;
//   /** Дата с бека, приведённая к фронту c учетом зоны для отображения (фронт отобразит цифры часов и минут такими, как они бы были в нужной зоне, т.е. полдень в нужной зоне на фронте его зоне отобразится также как полдень) */
//   private dateZonedForView: Date | null = null;

//   /** @param initialDate начальная дата с бека, приходит в нулевой зоне (сюда фронтовую дату не передавать!) */
//   constructor(initialDate: string | Date | null | DateWithTimezone, timezone: TIME_ZONE, dateUtils: DateUtils) {
//     this.timezone = timezone;
//     this.dateUtils = dateUtils;
//     this.dateRaw = !initialDate
//       ? new Date()
//       : initialDate instanceof DateWithTimezone
//       ? this.dateUtils.changeTimezone(initialDate.getRawDate(), this.timezone, initialDate.getTimezone())
//       : new Date(initialDate);
//   }

//   /** @see dateZonedForView */
//   public getDateForView = (): Date => {
//     if (this.dateZonedForView) {
//       return this.dateZonedForView;
//     }

//     const currentTimezone = this.dateUtils.getCurrentTimezone();
//     this.dateZonedForView = this.dateUtils.changeTimezone(this.dateRaw, currentTimezone, this.timezone);
//     return this.dateZonedForView;
//   };

//   /** @see dateZonedForView */
//   public setDateFromView = (date: Date): this => {
//     this.dateZonedForView = date;
//     this.dateRaw = this.dateUtils.changeTimezone(date, this.timezone);
//     return this;
//   };

//   /** @see dateRaw */
//   public getRawDate = (): Date => {
//     return this.dateRaw;
//   };

//   public getTimezone = (): TIME_ZONE => {
//     return this.timezone;
//   };

//   // Date instance compatibility

//   public toISOString = (): string => {
//     return this.dateRaw.toISOString();
//   };

//   public getTime = (): number => {
//     return this.dateRaw.getTime();
//   };
// }
