import IFileService from 'services/file/IFileService';

export default class FileMapper {
  public modelToRequestDTO = (model: IFileService.File_): string => {
    return model.id;
  };

  public responseDTOToModel = (dto: string): IFileService.File_ => {
    return { id: dto, name: '...', sizeBytes: 0, url: '...', isHydrated: false };
  };

  /** Временно, до момента когда на беке разберутся */
  public fsFileResponseDTOToMainApiRequestDTO_temp = (dto: Entity): { id: string; fileType: IFileService.FILE_TYPE } => {
    return {
      id: dto.id,
      fileType: IFileService.FILE_TYPE.file, // temp
    };
  };

  /** Временно, до момента когда на беке разберутся */
  public responseDTOToModel_temp = (dto: { id: string; fileType: string }): IFileService.File_ => {
    return { id: dto.id, name: '...', sizeBytes: 0, url: '...', isHydrated: false };
  };
}
