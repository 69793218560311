import { PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import IPriceTechnicianActionsService from './IPriceTechnicianActionsService';
import {
  GetPriceAnyRequestListDTO,
  PriceAnyRequestDTO,
  PriceAnyResponseDTO,
  UpdateClientOrTechnicianPriceSettingsRequestDTO,
  UpdatePriceRequestDTO,
} from 'typings/dto/price';

export default class PriceTechnicianActionsServiceImpl
  extends ModelActionsService<Price.Technician, PriceAnyRequestDTO, PriceAnyResponseDTO.Technician>
  implements IPriceTechnicianActionsService
{
  /** @throws `BackendResponseError` */
  public async getList(dto: GetPriceAnyRequestListDTO, filter?: LocationSearchObject): Promise<void> {
    const path = this.getModelApiRootPath(dto) + '/' + PATH_BACKEND_PART.price.priceItem;
    super.getList(dto, filter, path);
  }

  /** @throws `BackendResponseError` */
  public updateServicePrice = (dto: UpdatePriceRequestDTO.Technician): Promise<void> => {
    return this.entityApiService.patchWithCredentials<void>(this.getModelApiRootPath(dto), dto);
  };

  /** @throws `BackendResponseError` */
  public updateDefaultTechnicianPriceListSettings = (dto: UpdateClientOrTechnicianPriceSettingsRequestDTO) => {
    const path = this.getModelApiRootPath(dto) + '/' + PATH_BACKEND_PART.price.settings;
    return this.entityApiService.putWithCredentials(path, dto);
  };

  /** @throws `BackendResponseError` */
  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return super.getDefaultSortFilter(filter, 'createdAt');
  };
}
