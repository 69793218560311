import { UploadFileResponseDTO } from 'typings/dto/files';

interface IFileService {
  uploadOneFile(file: File, fileName?: string): Promise<UploadFileResponseDTO>;
  uploadOneImage(file: File, fileName?: string): Promise<UploadFileResponseDTO>;
  uploadOneAvatar(file: File, fileName?: string): Promise<UploadFileResponseDTO>;

  uploadOneFileAnonymously(file: File, orderId: string, fileName?: string): Promise<UploadFileResponseDTO>;

  /** Получает ссылку на файл по его id */
  getActualFileData(dbFileData: IFileService.File_): Promise<IFileService.File_>;
  /** Получает BLOB файла по ссылке */
  getFileBlob(dbFileData: IFileService.File_): Promise<Blob>;
  /** Преобразовывает ссылку в физический файл с которым может работать аплоадер */
  urlToFile(dbFileData: IFileService.File_): Promise<IFileService.FileForUploaderWithData>;
  /** getActualFileData + urlToFile для страниц редактирования */
  getFileForEditor(dbFileData: IFileService.File_): Promise<IFileService.FileForUploaderWithData>;

  deleteOne(id: string): Promise<void>;
  deleteMany(ids: string[]): Promise<void | void[]>;
}

namespace IFileService {
  /** Тип для данных файла из файл-сервиса; название такое чтобы не совпадало с обычным File (даже не смотря на то, что оно хранится внутри namespace) */
  export type File_ = Entity & {
    url: string;
    name: string;
    sizeBytes: number;
    /** Получена ли актуальная ссылка для файла */
    isHydrated: boolean;
  };

  /** Тип, предназначенный для фронтенд-аплоадера, который дополняет только что загруженный физический файл (в браузер) ссылкой на превью */
  export type FileForUploaderWOData = File & { preview: string };
  /** Тип, предназначенный для аплоадера, который дополняет физический файл ссылкой на превью и данными файла из файл-сервиса (т.е. уже существующего файла) */
  export type FileForUploaderWithData = FileForUploaderWOData & { dbData: IFileService.File_; id: string };
  /** Тип, предназначенный для аплоадера, который может быть как уже существующим, так и только что загруженным */
  export type FileForUploaderUnknownData = FileForUploaderWOData | FileForUploaderWithData;

  export enum FILE_TYPE {
    avatar = 'AVATAR',
    file = 'FILE',
    image = 'IMAGE',
    orderReport = 'ORDER_REPORT',
  }
}

export default IFileService;
