export enum DYNAMIC_FIELD_TYPE {
  date = 'DATE',
  text = 'TEXT',
  textarea = 'TEXTAREA',
  file = 'FILE',
  switch = 'SWITCH',
  checkbox = 'CHECKBOX',
  select = 'SELECT',
  radioGroup = 'RADIOGROUP',
  // Спец тип
  visitDate = 'VISIT_DATE',
  number = 'NUMBER',
}

export enum DYNAMIC_FIELD_VALUE_TYPE {
  user = 'USER',
  string = 'STRING',
  idWithName = 'ID_WITH_NAME',
  technicalNameWithName = 'TECHNICALNAME_WITH_NAME',
  enum = 'ENUM',
}

export enum DYNAMIC_FIELD_RESPONSE_TYPE {
  technicalNameWithName = 'TECHNICALNAME_WITH_NAME',
  model = 'MODEL',
  idWithName = 'ID_WITH_NAME',
}
