import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'storage';
import LoginPage from '../../../pages/authentication/LoginPage';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children?: ReactNode;
  onSuccess?: ReactNode;
};

export default function AuthGuard({ children, onSuccess }: AuthGuardProps) {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <LoginPage />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return (
    <>
      {children}
      {onSuccess}
    </>
  );
}
