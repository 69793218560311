import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import {
  CreateServiceProviderRequestDTO,
  FinishSPInvitationRequestDTO,
  FinishSPInvitationResponseDTO,
  GetInvitationDataResponseDTO,
  ServiceProviderResponseDTO,
} from 'typings/dto/serviceProvider';
import ModelActionsService from '../ModelActionsService';
import IServiceProviderActionsService from './IServiceProviderActionsService';

export default class ServiceProviderActionsService
  extends ModelActionsService<ServiceProvider, CreateServiceProviderRequestDTO, ServiceProviderResponseDTO>
  implements IServiceProviderActionsService
{
  // /** @throws `BackendResponseError` */
  // inviteNewSP = (dto: InviteNewSPRequestDTO): Promise<void> => {
  //   return this.entityApiService.postWithCredentials(PATH_BACKEND.serviceProvider.invite, dto, false);
  // };

  /** @throws `BackendResponseError` */
  getInvitationData = (code: string): Promise<GetInvitationDataResponseDTO | undefined> => {
    return this.entityApiService.getWithCredentials(PATH_BACKEND.serviceProvider.invite + '/' + code);
  };

  finishInvitation = (dto: FinishSPInvitationRequestDTO): Promise<FinishSPInvitationResponseDTO> => {
    return this.entityApiService.postWithCredentials<FinishSPInvitationResponseDTO>(PATH_BACKEND.serviceProvider.finishInvitation, dto);
  };

  /** @throws `BackendResponseError` */
  public getValuesFromOrderForFilter = (): Promise<void> => {
    const url = PATH_BACKEND.serviceProvider.orderFilter;
    return this.getAllForFilter(undefined, undefined, url);
  };
}
