import FrontendRoutesBase from './FrontendRoutesBase';
import { PAGE_TYPE, PATH_PARAM_DASHBOARD, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';
import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';

export default class ServiceProviderSettingsRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public root = this.getDBPath(PATH_PARAM_DASHBOARD.serviceProvider.root);
  public employee: ServiceProviderEmployeeRoutes;
  public legalEntity: LegalEntityRoutes;
  public department: DepartmentRoutes;

  constructor() {
    super();
    this.employee = new ServiceProviderEmployeeRoutes(this);
    this.legalEntity = new LegalEntityRoutes(this);
    this.department = new DepartmentRoutes(this);
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter = (_params, permissions, translate) => {
    const canAccessListPage = permissions.can(MODEL_PERMISSION.FAKE_FORBIDDEN);
    return canAccessListPage ? [{ name: translate('menus.main.serviceCompanies'), href: this.getListPath() }] : [];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.serviceProvider, 'serviceProviderName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    return this.getInfoBc(params, permissions, translate).concat([{ name: names.serviceProviderName, href: this.getInfoPath(params) }]);
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.serviceProvider.root, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.serviceProvider.root, params.serviceProviderId, PAGE_TYPE.info);

  getAddBc = this.getInstanceBc;
  getAddPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.serviceProvider.root, PAGE_TYPE.new, PAGE_TYPE.add);

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.serviceProvider.root, params.serviceProviderId, PAGE_TYPE.edit);

  getReviewsBc = this.getInstanceBc;
  getReviewsPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.serviceProvider.root, params.serviceProviderId, PATH_PARAM_DASHBOARD.scEmployee.reviews);
}

class ServiceProviderEmployeeRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: ServiceProviderSettingsRoutes) {
    super();
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.serviceProvider, 'serviceProviderName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const bc = this.parent.getInstanceChildBc(params, permissions, translate, names);
    this.setLastBcLink(bc, this.getListPath(params));
    return bc;
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<
    typeof PATH_PARAM_TOKEN.serviceProvider,
    'serviceProviderName' | 'employeeName'
  > = (params, permissions, translate, names) => {
    return this.getInstanceBc(params, permissions, translate, names).concat([{ name: names.employeeName, href: this.getInfoPath(params) }]);
  };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.employees,
      PAGE_TYPE.list
    );

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.employees,
      params.employeeId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.employees,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.employees,
      params.employeeId,
      PAGE_TYPE.edit
    );

  getEditBc = this.getInstanceChildBc;

  getNotificationsInfoBc = this.getInstanceBc;
  getNotificationsInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.employees,
      params.employeeId,
      PATH_PARAM_DASHBOARD.scEmployee.notifications,
      PAGE_TYPE.info
    );

  getNotificationsEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<
    typeof PATH_PARAM_TOKEN.serviceProvider,
    'serviceProviderName' | 'employeeName'
  > = (params, permissions, translate, names) => {
    const bc = this.getInstanceChildBc(params, permissions, translate, names);
    this.setLastBcLink(bc, this.getNotificationsInfoPath(params));
    return bc;
  };
  getNotificationsEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.employees,
      params.employeeId,
      PATH_PARAM_DASHBOARD.scEmployee.notifications,
      PAGE_TYPE.edit
    );

  getReviewsBc = this.getInstanceBc;
  getReviewsPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.employees,
      params.employeeId,
      PATH_PARAM_DASHBOARD.scEmployee.reviews
    );

  getRegionServiceListBc = this.getInstanceBc;
  getRegionServiceListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.employees,
      params.employeeId,
      PATH_PARAM_DASHBOARD.scEmployee.regionServices,
      PAGE_TYPE.list
    );

  getRegionServiceEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<
    typeof PATH_PARAM_TOKEN.serviceProvider,
    'serviceProviderName' | 'technicianName'
  > = (p, pm, t, n) => {
    return this.getInstanceBc(p, pm, t, n).concat([{ name: n.technicianName, href: this.getRegionServiceListPath(p) }]);
  };
  getRegionServiceEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.employees,
      params.employeeId,
      PATH_PARAM_DASHBOARD.scEmployee.regionServices,
      PAGE_TYPE.edit
    );
}

class LegalEntityRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: ServiceProviderSettingsRoutes) {
    super();
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params, permissions, translate) => {
    const bc = this.parent.getInstanceBc(params, permissions, translate);
    this.setLastBcLink(bc, this.parent.getListPath());
    return bc;
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.serviceProvider, 'serviceProviderName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const bc = this.getInstanceBc(params, permissions, translate);
    bc.push({ name: names.serviceProviderName, href: this.getInfoPath(params) }, { name: translate('entities.legalEntity.entityName') });
    return bc;
  };

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.legalInfo,
      PAGE_TYPE.info
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.legalInfo,
      PAGE_TYPE.edit
    );
}

class DepartmentRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: ServiceProviderSettingsRoutes) {
    super();
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.serviceProvider, 'serviceProviderName'> = (
    p,
    pm,
    t,
    n
  ) => {
    const breadcrumbs = this.parent.getEditBc(p, pm, t, n);
    this.setLastBcLink(breadcrumbs, this.getListPath(p));
    return breadcrumbs;
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<
    typeof PATH_PARAM_TOKEN.serviceProvider,
    'serviceProviderName' | 'departmentName'
  > = (p, pm, t, n) => {
    return this.getAddBc(p, pm, t, n).concat([{ name: n.departmentName, href: this.getInfoPath(p) }]);
  };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.departments,
      PAGE_TYPE.list
    );

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.departments,
      params.departmentId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.departments,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.serviceProvider> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.serviceProvider.root,
      params.serviceProviderId,
      PATH_PARAM_DASHBOARD.serviceProvider.departments,
      params.departmentId,
      PAGE_TYPE.edit
    );
}
