import { SxProps, StandardTextFieldProps, Box } from '@mui/material';
import { FormikContextType } from 'formik';
import useDI from 'hooks/useDI';
import React from 'react';
import AmountInputMemorized from '../AmountInputMemorized';

type Props = Omit<StandardTextFieldProps, 'defaultValue' | 'type' | 'onChange'> & {
  fieldName: string;
  label?: string;
  /** Работает с полем типа number */
  formState: FormikContextType<any>;
  sx?: SxProps;
  onChange?: (newValue: number) => void;
};

/**
 * Принимает и возвращает количество минут
 */
function TimeInputMemorized({ sx, onChange, fieldName, formState, ...restProps }: Props) {
  const { statefulUtils, services } = useDI();
  const { translate } = services.language;

  const value: number = formState.values[fieldName] || 0;
  const [hoursValue, minutesValue] = statefulUtils.date.minutesToTimeArr(value);

  const hoursChangeHandler = (rawValue: number) => {
    if (rawValue < 0) {
      rawValue = 0;
    } else if (rawValue > 24) {
      rawValue = 24;
    }

    const hoursToMinutes = rawValue * 60;
    if (onChange) onChange(hoursToMinutes + minutesValue);
    else formState.setFieldValue(fieldName, hoursToMinutes + minutesValue);
  };

  const minutesChangeHandler = (rawValue: number) => {
    if (rawValue < 0) {
      rawValue = 0;
    } else if (rawValue > 59) {
      rawValue = 59;
    }

    const hoursToMinutes = hoursValue * 60;
    if (onChange) onChange(hoursToMinutes + rawValue);
    else formState.setFieldValue(fieldName, hoursToMinutes + rawValue);
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { md: 'auto auto 1fr', xs: '1fr' },
        gap: 2,
        alignItems: 'center',
      }}
    >
      <AmountInputMemorized
        fieldName={fieldName}
        label={translate('fields.hours')}
        formState={formState}
        {...restProps}
        onChange={(e) => hoursChangeHandler(+e.target.value)}
        value={hoursValue}
        amountClickHandler={amountClickHandlerFactory(hoursValue, hoursChangeHandler, 1)}
      />
      <AmountInputMemorized
        fieldName={fieldName}
        label={translate('fields.minutes')}
        formState={formState}
        {...restProps}
        onChange={(e) => minutesChangeHandler(+e.target.value)}
        value={minutesValue}
        amountClickHandler={amountClickHandlerFactory(minutesValue, minutesChangeHandler, 15)}
      />
      <Box />
    </Box>
  );
}

function amountClickHandlerFactory(value: number, fieldValueSetter: (newValue: number) => void, amountPerClick: number) {
  return (type: 'add' | 'subtract') => {
    if (type === 'add') {
      fieldValueSetter(value + amountPerClick);
    } else {
      const newValue = value > 0 ? value - amountPerClick : 0;
      return fieldValueSetter(newValue);
    }
  };
}

export default React.memo(TimeInputMemorized, (prevProps, nextProps) => {
  const prevName = prevProps.fieldName;
  const nextName = nextProps.fieldName;
  return (
    prevProps.formState.values[prevName] === nextProps.formState.values[nextName] &&
    prevProps.formState.touched[prevName] === nextProps.formState.touched[nextName] &&
    prevProps.formState.errors[prevName] === nextProps.formState.errors[nextName] &&
    prevProps.disabled === nextProps.disabled
  );
});
