import Mapper from 'utils/mappers/Mapper';
import { OrderTableDTO } from 'typings/dto/orderTable';

export default class OrderTableMapper extends Mapper<OrderTable, OrderTableDTO, OrderTableDTO> {
  constructor() {
    super(
      {
        technicalName: 'technicalName',
        columns: 'columns',
        records: 'records',
        name: 'name',
      },
      {
        technicalName: 'technicalName',
        columns: 'columns',
        records: 'records',
        name: 'name',
      }
    );
  }

  public responseDTOToModel = (dto: OrderTableDTO): OrderTable => {
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
    };
  };
}
