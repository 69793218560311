import { LoadingButton } from '@mui/lab';
import { Button, Stack, StackProps } from '@mui/material';
import useLocales from 'hooks/useLocales';
import useStableNavigate from 'hooks/useStableNavigate';
import React from 'react';
import { useSelector } from 'storage';

type Props = StackProps & {
  /** Если не передать, по умолчанию вернёт назад по хлебным крошкам */
  cancelHandler?: VoidFunction;
  isLoading: boolean;
  submitHandler?: () => any;
  /** @deprecated удалить */
  disablePadding?: boolean;
  cancelText?: string;
  submitText?: string;
  fullWidth?: boolean;
};

function ConfirmButtonGroup({
  cancelHandler,
  isLoading,
  submitHandler,
  cancelText,
  submitText,
  fullWidth = false,
  ...rest
}: Props) {
  const { backPath } = useSelector((state) => state.page);
  const { translate } = useLocales();
  const navigate = useStableNavigate();
  const flex = fullWidth ? 1 : { xs: 1, md: 0 };

  return (
    <Stack direction="row" spacing={2} flex={flex} {...rest}>
      <Button
        variant="outlined"
        color="inherit"
        onClick={cancelHandler || (() => navigate(backPath))}
        sx={{ flexGrow: { xs: 1, md: 'inherit' } }}
      >
        {cancelText || translate('buttons.cancel')}
      </Button>
      <LoadingButton
        type={submitHandler ? undefined : 'submit'}
        onClick={submitHandler}
        variant="contained"
        color="primary"
        loading={isLoading}
        sx={{ flexGrow: { xs: 1, md: 'inherit' } }}
      >
        {submitText || translate('buttons.save')}
      </LoadingButton>
    </Stack>
  );
}

export default React.memo(
  ConfirmButtonGroup,
  (pp, np) => pp.isLoading === np.isLoading && pp.submitHandler === np.submitHandler
);
