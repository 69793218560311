import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import useLocales from 'hooks/useLocales';
import IconButtonStyled from './IconButtonStyled';

type Props = React.ComponentProps<typeof IconButtonStyled> & {
  isOpened: boolean;
};

export default function SpoilerIconButton({ isOpened, ...rest }: Props) {
  const { translate } = useLocales();
  return (
    <IconButtonStyled {...rest} title={isOpened ? translate('buttons.hide') : translate('buttons.show')}>
      {isOpened ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
    </IconButtonStyled>
  );
}
