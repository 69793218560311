import { USER_ROLE } from 'typings/models/user.enum';
import { TENANT_TYPE } from 'typings/subEntities/tenant.enum';
import Mapper from './Mapper';
import { userModelToDTODiff, userDTOToModelDiff } from './UserBase';
import {
  CreateUserPlatformOperatorRequestDTO,
  UserPlatformOperatorManagerAttributesRequestDTO,
  UserPlatformOperatorManagerAttributesResponseDTO,
  UserPlatformOperatorResponseDTO,
  UserPlatformOperatorTechnicianAttributesRequestDTO,
  UserPlatformOperatorTechnicianAttributesResponseDTO,
} from 'typings/dto/userPlatformOperator';

export default class UserPlatformOperatorMapper extends Mapper<
  UserPlatformOperator,
  UserPlatformOperatorResponseDTO,
  CreateUserPlatformOperatorRequestDTO
> {
  constructor() {
    super(
      { ...userDTOToModelDiff, enterprise: null, department: null, platformOperator: null },
      { ...userModelToDTODiff, enterprise: null, platformOperator: null, department: null }
    );
  }

  public responseDTOToModel = (dto: UserPlatformOperatorResponseDTO): UserPlatformOperator => {
    const tenantUser: UserPlatformOperator = {
      ...this.getOuterMappers().userEnterprise.responseDTOToModel(dto),
      platformOperator: dto.platformOperator,
      type: dto.tenant.type as TENANT_TYPE.platformOperator,
      tenant: dto.tenant as Tenant<TENANT_TYPE.platformOperator>,
    };

    // На данный момент типизация атрибутов userPlatformOperator и userEnterprise полностью совпадает.
    // Цикл и методы responseTechnicianAttributesToModel, responseManagerAttributesToModel можно убрать.
    // Но в будущем возможно у PO добавятся дополнительные атрибуты, поэтому используются эти методы, которые нужно будет расширить.
    dto.businessRoles.forEach((role) => {
      switch (role) {
        case USER_ROLE.orderTechnician: {
          tenantUser.isTechnician = true;
          tenantUser.attributes = {
            ...tenantUser.attributes,
            ...this.responseTechnicianAttributesToModel(dto.attributes as UserPlatformOperatorTechnicianAttributesResponseDTO),
          };
          break;
        }
        case USER_ROLE.orderManager: {
          tenantUser.isManager = true;
          tenantUser.attributes = {
            ...tenantUser.attributes,
            ...this.responseManagerAttributesToModel(dto.attributes as UserPlatformOperatorManagerAttributesResponseDTO),
          };
          break;
        }
      }
    });

    return tenantUser;
  };

  private responseTechnicianAttributesToModel = (
    dto: UserPlatformOperatorTechnicianAttributesResponseDTO
  ): UserPlatformOperator.InternalTechnician['attributes'] => {
    return {
      ...this.getOuterMappers().userEnterprise.responseTechnicianAttributesToModel(dto),
    };
  };

  private responseManagerAttributesToModel = (
    dto: UserPlatformOperatorManagerAttributesResponseDTO
  ): UserPlatformOperator.Manager['attributes'] => {
    return {
      ...this.getOuterMappers().userEnterprise.responseManagerAttributesToModel(dto),
    };
  };

  public modelToRequestDTO(user: UserPlatformOperator): CreateUserPlatformOperatorRequestDTO {
    const dtoUser: CreateUserPlatformOperatorRequestDTO = {
      ...this.getOuterMappers().userEnterprise.modelToRequestDTO(user),
    };

    // На данный момент типизация атрибутов userPlatformOperator и userEnterprise полностью совпадает.
    // Цикл и методы modelTechnicianAttributesToRequestDTO, modelManagerAttributesToRequestDTO можно убрать.
    // Но в будущем возможно у PO добавятся дополнительные атрибуты, поэтому используются эти методы, которые нужно будет расширить.
    user.roles.forEach((role) => {
      switch (role) {
        case USER_ROLE.orderTechnician: {
          dtoUser.attributes = {
            ...dtoUser.attributes,
            ...this.modelTechnicianAttributesToRequestDTO(user.attributes as UserPlatformOperator.InternalTechnician['attributes']),
          };
          break;
        }
        case USER_ROLE.orderManager: {
          dtoUser.attributes = {
            ...dtoUser.attributes,
            ...this.modelManagerAttributesToRequestDTO(user.attributes as UserPlatformOperator.Manager['attributes']),
          };
          break;
        }
      }
    });

    return dtoUser;
  }

  public modelTechnicianAttributesToRequestDTO(
    user: UserPlatformOperator.InternalTechnician['attributes']
  ): UserPlatformOperatorTechnicianAttributesRequestDTO {
    return {
      ...this.getOuterMappers().userEnterprise.modelTechnicianAttributesToRequestDTO(user),
    };
  }

  public modelManagerAttributesToRequestDTO(
    user: UserPlatformOperator.Manager['attributes']
  ): UserPlatformOperatorManagerAttributesRequestDTO {
    return {
      ...this.getOuterMappers().userEnterprise.modelManagerAttributesToRequestDTO(user),
    };
  }

  public responseDTOToModelIdAndNamesOnly = (dto: UserPlatformOperatorResponseDTO): UserPlatformOperator => {
    const user = this.responseDTOToModelViaDiffMap(dto);
    if (!user.name) {
      // В некоторых эндпоинтах для фильтров юзеры приходят как EntityWithName и стандартный маппер выше не найдёт имя
      user.name = (dto as unknown as EntityWithName).name;
    }
    return user;
  };
}
