import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { CommentResponseDTO, CreateCommentRequestDTO } from 'typings/dto/comment';
import IOrderCommentActionsService from './IOrderCommentActionsService';

export default class OrderCommentActionsServiceImpl
  extends ModelActionsService<Commentary, CreateCommentRequestDTO, CommentResponseDTO>
  implements IOrderCommentActionsService
{
  public create = async ({ userId, orderId, ...dto }: CreateCommentRequestDTO, customPath?: string) => {
    const path = customPath || this.getModelApiRootPath({ orderId }) + '?userId=' + userId;
    const id = await this.modelApiService.create(path, dto);
    this.storageDispatch(this.modelStorageActions.setOneIsOutdated());
    return id;
  };

  protected getModelApiRootPath = (dto: { orderId: string }) => {
    return PATH_BACKEND.order.root + '/' + dto.orderId + '/' + super.getModelApiRootPath(dto);
  };
}
