import useIsMountedRef from 'hooks/useIsMountedRef';
import useStableNavigate from 'hooks/useStableNavigate';
import { useSnackbar } from 'notistack';

type SnackbarSuccessHandlerProps = {
  /** @deprecated использовать callback */
  setSubmitting?: (isSubmitting: boolean) => void;
  /** @todo сделать обязательным! */
  message?: string;
  /** Если надо вызвать редирект */
  redirectPath?: string;
  /** Если надо вызвать какой-то обработчик */
  callback?: VoidFunction;
};

const useSnackbarSuccessHandler = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useStableNavigate();
  const isMountedRef = useIsMountedRef();

  return ({ setSubmitting, callback, message, redirectPath }: SnackbarSuccessHandlerProps) => {
    if (setSubmitting && isMountedRef?.current) setSubmitting(false);
    if (callback && isMountedRef?.current) callback();
    if (message) enqueueSnackbar(message, { variant: 'success', autoHideDuration: 6000 });
    if (redirectPath && isMountedRef?.current) navigate(redirectPath);
  };
};

export default useSnackbarSuccessHandler;
