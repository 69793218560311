import { Button, Stack, StackProps } from '@mui/material';
import React from 'react';
import useLocales from 'hooks/useLocales';
import useIsMobile from 'hooks/useIsMobile';

type Props = StackProps & {
  closeAddBlock: VoidFunction;
  deleteHandler: (serviceId: string) => void;
  canDelete: boolean;
  setNotCreatedHandler: () => void;
  fullWidth?: boolean;
  serviceOrderedId?: string;
};
export default function OrderSessionServiceItemEditButtonGroup({
  closeAddBlock,
  canDelete,
  deleteHandler,
  fullWidth,
  serviceOrderedId,
  setNotCreatedHandler,
  ...rest
}: Props) {
  const flex = fullWidth ? 1 : { xs: 1, md: 0 };
  const isMobile = useIsMobile();
  const { translate } = useLocales();
  return (
    <>
      <Stack spacing={2} flex={flex} {...rest} sx={{ mb: 5 }} direction={isMobile ? 'column' : 'row'}>
        <>
          <Button onClick={closeAddBlock} variant="contained" color="primary" sx={{ flexGrow: { xs: 1, md: 'inherit' } }}>
            {translate('buttons.backToOrder')}
          </Button>{' '}
          {canDelete && serviceOrderedId && (
            <Button
              onClick={() => {
                setNotCreatedHandler();
                deleteHandler(serviceOrderedId);
              }}
              variant="outlined"
              color="error"
              sx={{ flexGrow: { xs: 1, md: 'inherit' }, mt: 0 }}
            >
              {translate('buttons.delete')}
            </Button>
          )}
        </>
      </Stack>
    </>
  );
}
