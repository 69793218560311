import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function EmptyIconButton() {
  return (
    <IconButton sx={{ visibility: 'hidden' }}>
      <MoreVertIcon />
    </IconButton>
  );
}
