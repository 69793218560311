import { Stack, SxProps, Typography, TypographyProps, Collapse } from '@mui/material';
import SpoilerIconButton from 'components/ui/buttons/SpoilerIconButton';
import { useState } from 'react';

type Props = TypographyProps & {
  title: string;
  defaultState?: boolean;
  containerSx?: SxProps;
};

export default function Spoiler({ title, containerSx, children, defaultState = false, ...rest }: Props) {
  const [isDetailOpened, setIsDetailOpened] = useState(defaultState);

  return (
    <Stack sx={containerSx} spacing={1}>
      <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle2" {...rest}>
          {title}
        </Typography>
        <SpoilerIconButton isOpened={isDetailOpened} onClick={() => setIsDetailOpened(!isDetailOpened)} />
      </Stack>
      <Collapse in={isDetailOpened} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Stack>
  );
}
