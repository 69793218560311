import { Suspense } from 'react';
import LoaderComponent from './LoaderComponent';
import LoadingScreen from './LoadingScreen';
import Spinner from './Spinner';

export function LoadableBackground<T = any>(Component: any) {
  return (props: T) => {
    return (
      <Suspense fallback={null}>
        <Component {...props} />
      </Suspense>
    );
  };
}

export function LoadableMini<T = any>(Component: any) {
  return (props: T) => {
    return (
      <Suspense fallback={<Spinner />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

export function Loadable<T = any>(Component: any) {
  return (props: T) => {
    return (
      <Suspense fallback={<LoaderComponent />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

export const LoadableBig = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};
