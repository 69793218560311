import { TextField, MenuItem, StandardTextFieldProps } from '@mui/material';
import { FormikContextType } from 'formik';
import React from 'react';

type Props = StandardTextFieldProps & {
  fieldName: string;
  label?: string;
  options: EntityWithName[];
  formState: FormikContextType<any>;
  canBeEmpty?: boolean;
  autosubmit?: boolean;
};

/** Работает с EntityWithName, но сохраняет только id, либо пустую строку когда ничего не выбрано */
function SelectSemiSimpleMemorized({
  fieldName,
  label,
  options,
  canBeEmpty = false,
  formState: { getFieldProps, setFieldValue, touched, errors, values, handleSubmit },
  autosubmit,
  ...rest
}: Props) {
  const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFieldValue(fieldName, event.target.value);
    if (autosubmit) handleSubmit();
  };

  const value = values[fieldName] || '';

  return (
    <TextField
      select
      fullWidth
      label={label}
      {...getFieldProps(fieldName)}
      error={Boolean(touched[fieldName] && errors[fieldName])}
      helperText={touched[fieldName] && errors[fieldName]}
      value={value}
      onChange={changeHandler}
      {...rest}
    >
      {canBeEmpty && (
        <MenuItem key={`${fieldName}_empty`} value="">
          &nbsp;
        </MenuItem>
      )}
      {options.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  );
}

// Массив options не учитывается
export default React.memo(
  SelectSemiSimpleMemorized,
  (prevProps, nextProps) =>
    prevProps.formState.touched[prevProps.fieldName] === nextProps.formState.touched[nextProps.fieldName] &&
    prevProps.formState.errors[prevProps.fieldName] === nextProps.formState.errors[nextProps.fieldName] &&
    prevProps.formState.values[prevProps.fieldName] === nextProps.formState.values[nextProps.fieldName] &&
    prevProps.disabled === nextProps.disabled
);
