import { BrandResponseDTO, CreateBrandRequestDTO } from 'typings/dto/brand';
import Mapper from './Mapper';
import DIContainer from 'services/DIContainer';
import EnterpriseUtils from 'utils/models/EnterpriseUtils';

export default class BrandMapper extends Mapper<Brand, BrandResponseDTO, CreateBrandRequestDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers, private currentUserGetter: () => User | null) {
    super(
      {
        clientAppSettings: 'clientAppSettings',
        legalEntity: 'legalEntity',
        name: 'name',
        logoId: null,
        technicalName: 'technicalName',
        tenant: 'tenant',
      },
      {
        clientAppSettings: 'clientAppSettings',
        legalEntity: 'legalEntityId',
        name: 'name',
        logo: 'logoId',
        technicalName: 'technicalName',
        tenantId: null,
        isInherited: null,
        tenant: null,
        viewDescription: null,
      }
    );
  }

  public responseDTOToModel = (dto: BrandResponseDTO): Brand => {
    const currentUser = this.currentUserGetter();
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      isInherited: EnterpriseUtils.isEntityInherited(currentUser, dto),
      viewDescription: EnterpriseUtils.getEntityOwnerName(currentUser, dto),
      logo: dto.logoId ? this.subEntitiesMappers.file.responseDTOToModel(dto.logoId) : undefined,
    };
  };

  public responseDTOToModelIdAndNamesOnly = (city: BrandResponseDTO): Brand => {
    return this.responseDTOToModel(city);
  };
}
