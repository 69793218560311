import ModelActionsService from './ModelActionsService';
import IModelActionsService from './IModelActionsService';
import { CreateNotificationRequestDTO, GetNotificationsRequestDTO, NotificationResponseDTO } from 'typings/dto/notification';
import LanguageServiceImpl from 'services/language/LanguageServiceImpl';
import DateUtils from 'utils/Date';
import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import NavigateFrontendUtils from 'utils/NavigateFrontend';
import NavigateBackendUtils from 'utils/NavigateBackend';
import EntityUtils from 'utils/Entity';
import IServerEventsService from 'services/serverEvents/IServerEventsService';

export default class NotificationActionsService
  extends ModelActionsService<UserNotification, CreateNotificationRequestDTO, NotificationResponseDTO>
  implements IModelActionsService<UserNotification, CreateNotificationRequestDTO, NotificationResponseDTO>
{
  public subscribeOnUpdates = (onErrorCallback: (error: any) => void): IServerEventsService.SubscriptionState => {
    const onMessageCallback = (data: IServerEventsService.SSEEntityUpdateMessageData) => {
      const { listOfEntities } = this.getStorageCurrentState();
      const entity = listOfEntities.find((e) => e.id === data.id);
      const dataUpdatedDate = new Date(data.updatedAt);
      if (!DateUtils.isValidDate(dataUpdatedDate)) {
        return onErrorCallback(new Error("Can't parse event date"));
      }

      if (!entity || entity.updatedAt.getTime() < dataUpdatedDate.getTime()) {
        const size =
          listOfEntities.length < NavigateBackendUtils.DEFAULT_PAGE_SIZE ? NavigateBackendUtils.DEFAULT_PAGE_SIZE : listOfEntities.length;
        this.getListBackground(null, { size: NavigateFrontendUtils.createLocationSearchParam(size) });
      }
    };
    return this.serverEventsService.createSubscription(PATH_BACKEND.notification.subscribeOnUpdates, onMessageCallback, onErrorCallback);
  };

  public getListBackground = async (
    dto?: Record<string, any> | null,
    filter?: LocationSearchObject,
    customPath?: string
  ): Promise<void> => {
    try {
      // Помимо списка нужно отдельно запрашивать urgent нотификацию, т.к. в списке приходит 20 записей, но urgent может быть например 21 и мы о ней не узнаем, а так быть не должно
      this.getLastUnreadUrgentNotification();
      const headers = this.getHeaders();
      const path = customPath || this.getPathWithListFilter(this.getModelApiRootPath(null));
      // Дальше копия метода из ModelActionsService
      const response = await this.modelApiService.getList(path, this.modelMapper, dto, filter, headers);
      if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.setList(response));
      else console.warn(`Get list request ${this.modelStorageName} is outdated`);
    } catch (error) {
      if (!this.areActionsOutdated) {
        console.error(error);
        this.storageDispatch(this.modelStorageActions.setError(error));
      }
    }
  };

  public concatListPageBackground = async (pageNumber: number, dto?: Record<string, any> | null) => {
    try {
      const headers = this.getHeaders();
      const path = this.getPathWithListFilter(this.getModelApiRootPath(null));
      const { listOfEntities, lastListRequestFilter } = this.getStorageCurrentState();
      let filter: LocationSearchObject = {};
      if (lastListRequestFilter?.frontendSearchParams) {
        filter = NavigateFrontendUtils.getLocationSearchObjectFromQueryStr(lastListRequestFilter.frontendSearchParams);
      }
      filter.page = NavigateFrontendUtils.createLocationSearchParam(pageNumber);

      const response = await this.modelApiService.getList(path, this.modelMapper, dto, filter, headers);
      if (!this.areActionsOutdated) {
        response.data = EntityUtils.filterDuplicates(listOfEntities, response.data);
        this.storageDispatch(this.modelStorageActions.setList(response));
      } else {
        console.warn(`Get list background request ${this.modelStorageName} is outdated`);
      }
    } catch (error) {
      if (!this.areActionsOutdated) {
        console.error(error);
        this.storageDispatch(this.modelStorageActions.setError(error));
      }
    }
  };

  /** В данный момент используется место oneEntity в хранилище модели */
  public getLastUnreadUrgentNotification = async (): Promise<void> => {
    try {
      const headers = this.getHeaders();
      const path = this.getPathWithListFilter(PATH_BACKEND.notification.urgent, {
        page: NavigateFrontendUtils.createLocationSearchParam(1),
        size: NavigateFrontendUtils.createLocationSearchParam(1),
      });
      const response = await this.modelApiService.getList(path, this.modelMapper, undefined, undefined, headers);
      // Дальше копия метода getByIdBackground из ModelActionsService
      if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.setOne(response.data[0] || null));
      else console.warn(`Background request getLastUnreadUrgentNotification is outdated`);
    } catch (error) {
      if (!this.areActionsOutdated) {
        console.error(error);
        this.storageDispatch(this.modelStorageActions.setError(error));
      }
    }
  };

  /** @throws `BackendResponseError` */
  public markAsRead = async (ids: string[]): Promise<void> => {
    await this.entityApiService.patchWithCredentials(PATH_BACKEND.notification.markAsRead, { ids }, false);
  };

  /** @throws `BackendResponseError` */
  public markAsDismissed = async (id: string): Promise<void> => {
    await this.entityApiService.patchWithCredentials(PATH_BACKEND.notification.markAsDismissed, { id }, false);
  };

  private getHeaders = (): GetNotificationsRequestDTO => {
    return {
      'user-locale': LanguageServiceImpl.getCurrentLocaleNameFromBrowser() || 'en-US',
      'user-timezone': decodeURIComponent(DateUtils.getCurrentTimezone()),
    };
  };

  /** Тут в строку запроса надо дважды вписать sort параметр фильтра (по условиям бека), причем в строгом порядке */
  private getPathWithListFilter = (path: string, filter: LocationSearchObject = {}): string => {
    const sort1 = this.getDefaultSortFilter(filter, 'isUnread', NavigateFrontendUtils.SORT_ORDER.desc);
    const backFilter1 = NavigateBackendUtils.locationSearchObjectToRequestFilter(sort1, this.modelMapper);
    delete backFilter1.frontendSearchParams;
    path = NavigateBackendUtils.createDBRequestStrWithFilter(path, backFilter1);
    const sort2 = this.getDefaultSortFilter({}, 'createdAt', NavigateFrontendUtils.SORT_ORDER.desc);
    const backFilter2 = NavigateBackendUtils.locationSearchObjectToRequestFilter(sort2, this.modelMapper);
    delete backFilter2.frontendSearchParams;
    path = NavigateBackendUtils.addParamsToExistedUrl(path, backFilter2);
    return path;
  };
}
