import { DynamicFieldRequestDTO, DynamicFieldResponseDTO } from 'typings/dto/subEntity/dynamicFields';
import { DYNAMIC_FIELD_RESPONSE_TYPE, DYNAMIC_FIELD_TYPE, DYNAMIC_FIELD_VALUE_TYPE } from 'typings/subEntities/dynamicField.enum';
import FileMapper from './FileMapper';
import FunctionUtils from 'utils/Function';
import DateIntervalMapper from './DateIntervalMapper';
import useChildForms from 'hooks/useChildForms';
import { CreateOrderRequestDTO, OrderResponseDTO } from 'typings/dto/order';
import ILanguageService from 'services/language/ILanguageService';
import IFileService from 'services/file/IFileService';
import DateUtils from 'utils/Date';
import DIContainer from 'services/DIContainer';
import UserUtils from 'utils/models/UserUtils';
import EntityUtils from 'utils/Entity';
import Mapper from '../Mapper';
import DateWithTimezone from 'utils/implementations/DateWithTimezone';

export default class DynamicFieldMapper {
  constructor(
    private dateIntervalMapper: DateIntervalMapper,
    private fileMapper: FileMapper,
    private fileService: IFileService,
    private languageService: ILanguageService,
    private dateUtils: DateUtils
  ) {}

  public responseDTOToModel = (dto: DynamicFieldResponseDTO, outerMappers: DIContainer.Mappers['models']): DynamicField => {
    const fieldModel: DynamicField.DynamicFieldBase = {
      ...Mapper.responseDTOToModel(dto),
      name: dto.name,
      technicalName: dto.technicalName,
      type: dto.type,
      multiple: dto.multiple,
      required: dto.required,
      description: dto.description,
      parameters: dto.parameters,
      values: [],
      valueType: dto.valueType,
    };

    switch (fieldModel.type) {
      case DYNAMIC_FIELD_TYPE.checkbox:
      case DYNAMIC_FIELD_TYPE.switch:
      case DYNAMIC_FIELD_TYPE.textarea:
      case DYNAMIC_FIELD_TYPE.number:
      case DYNAMIC_FIELD_TYPE.text: {
        fieldModel.values = dto.value;
        break;
      }
      case DYNAMIC_FIELD_TYPE.file: {
        fieldModel.values = dto.value.map(this.fileMapper.responseDTOToModel);
        break;
      }
      case DYNAMIC_FIELD_TYPE.date: {
        fieldModel.values = dto.value.map((stringDate: string) => {
          const date = new DateWithTimezone(stringDate, this.dateUtils.getCurrentTimezone(), this.dateUtils);
          return date;
        });
        break;
      }
      case DYNAMIC_FIELD_TYPE.visitDate: {
        fieldModel.values = dto.value.map((intervalDTO: OrderResponseDTO['visitIntervals'][0]) => {
          const interval: Order.VisitDate = this.dateIntervalMapper.responseDTOToModel(intervalDTO, this.dateUtils.getCurrentTimezone()); // Не могу получить тут зону нигде, дальше в самой форме руками обрабатываю дату
          interval.workEstimateMin = intervalDTO.workEstimation?.durationMinutes;
          interval.completionState = intervalDTO.completionState;
          return interval;
        });
        break;
      }
      case DYNAMIC_FIELD_TYPE.radioGroup:
      case DYNAMIC_FIELD_TYPE.select: {
        this.mutateSelectDynamicFieldToModel(dto, fieldModel as DynamicField.DynamicFieldSelectField, outerMappers);
        break;
      }
      default: {
        if (FunctionUtils.exhaustiveCheck(fieldModel.type)) fieldModel.values = [];
      }
    }

    return fieldModel as DynamicField;
  };

  /**
   * @param values это значения из формы, которые пришли в submit
   * @param spFieldsMap карта дочерних форм к спец полям
   * @throws `Error` валидация форм потомков
   */
  public editStateToRequestDTO = async (
    model: DynamicField,
    formValues: Record<string, any>,
    spFieldsMap?: Map<DYNAMIC_FIELD_TYPE, ReturnType<typeof useChildForms>>
  ): Promise<DynamicFieldRequestDTO> => {
    let values: any[] = [];
    const valuesRaw: any = formValues[model.technicalName];
    switch (model.type) {
      // TODO нет учёта multiple
      case DYNAMIC_FIELD_TYPE.switch:
      case DYNAMIC_FIELD_TYPE.checkbox: {
        values = [valuesRaw];
        break;
      }
      case DYNAMIC_FIELD_TYPE.number:
      case DYNAMIC_FIELD_TYPE.textarea:
      case DYNAMIC_FIELD_TYPE.text: {
        // Вырезаем пустую строку если required == false (валидация пропустит)
        if (!valuesRaw && !model.required) values = [];
        else values = [valuesRaw];
        break;
      }
      case DYNAMIC_FIELD_TYPE.file: {
        const initialFiles = model.values;
        const oldFilesUpdated = formValues[model.technicalName + '_old'] as IFileService.File_[];
        const filesToRemove = EntityUtils.findRemoved(initialFiles, oldFilesUpdated);
        const newFileRequests = (valuesRaw as File[]).length ? (valuesRaw as File[]).map((f) => this.fileService.uploadOneFile(f)) : [];
        const newFiles = await Promise.all(newFileRequests);
        values = newFiles.map((f) => f.id).concat(oldFilesUpdated.map((f) => f.id));
        await this.fileService.deleteMany(filesToRemove.map((f) => f.id)).catch((error) => console.error(error?.message)); // Сейчас нет обработчика, но ошибку возвращать нельзя
        break;
      }
      case DYNAMIC_FIELD_TYPE.date: {
        if (valuesRaw) {
          values = valuesRaw.map((date: DateWithTimezone) => {
            return date.toISOString();
          });
        } else {
          values = [];
        }
        break;
      }
      case DYNAMIC_FIELD_TYPE.visitDate: {
        if (spFieldsMap && spFieldsMap.has(DYNAMIC_FIELD_TYPE.visitDate)) {
          const childFormsState = spFieldsMap.get(DYNAMIC_FIELD_TYPE.visitDate)!;
          const installationDateIntervals = await childFormsState.submitForms<Order.VisitDate>();

          // Проверяем, что каждая следующая дата больше предыдущей
          const dateComparator = (
            date: DateInterval | DateIntervalWithTimezone,
            index: number,
            array: (DateInterval | DateIntervalWithTimezone)[]
          ) => {
            const prevDate = array[index - 1];
            if (!prevDate) return true;
            else return prevDate.to.getTime() < date.from.getTime();
          };
          if (!installationDateIntervals.every(dateComparator)) {
            throw new Error(this.languageService.translate('pages.orderEdit.visitDateSubForm.orderDateIntervalsAreNotSequentialError'));
          }

          // В один день нельзя делать несколько визитов
          const groupedDates = this.dateUtils.getGroupedDates(installationDateIntervals, (interval) => interval.from);
          if (Array.from(groupedDates.values()).some((dayValues) => dayValues.length > 1)) {
            throw new Error(this.languageService.translate('pages.orderEdit.visitDateSubForm.tooManyOrderDateIntervalsDuringTheDayError'));
          }

          values = installationDateIntervals.map((interval) => {
            const intervalDTO: CreateOrderRequestDTO['visitIntervals'][0] = this.dateIntervalMapper.modelToRequestDTO(interval);
            intervalDTO.completionState = interval.completionState;
            if (interval.workEstimateMin) {
              intervalDTO.workEstimation = { durationMinutes: interval.workEstimateMin };
            }
            return intervalDTO;
          });
          break;
        } else {
          values = [];
          break;
        }
      }
      case DYNAMIC_FIELD_TYPE.radioGroup:
      case DYNAMIC_FIELD_TYPE.select: {
        const { valueType } = model;
        values = this.selectDynamicFieldValueToRequestDTO(model, values, valueType, valuesRaw, model.multiple);
        break;
      }
      default: {
        FunctionUtils.exhaustiveCheck(model);
        values = [];
      }
    }

    return {
      name: model.name,
      technicalName: model.technicalName,
      type: model.type,
      value: values,
      valueType: model.valueType,
      multiple: model.multiple,
      required: model.required,
      description: model.description,
    };
  };

  // TODO не тестировалось
  public modelToRequestDTO = (model: DynamicField): DynamicFieldRequestDTO => {
    let values: any[] = [];
    switch (model.type) {
      // TODO нет учёта multiple
      case DYNAMIC_FIELD_TYPE.switch:
      case DYNAMIC_FIELD_TYPE.checkbox:
      case DYNAMIC_FIELD_TYPE.textarea:
      case DYNAMIC_FIELD_TYPE.number:
      case DYNAMIC_FIELD_TYPE.text: {
        values = model.values;
        break;
      }
      case DYNAMIC_FIELD_TYPE.file: {
        values = model.values.map((f) => f.id);
        break;
      }
      case DYNAMIC_FIELD_TYPE.date: {
        const dates = model.values;
        values = dates.map((date) => {
          return date.toISOString();
        });
        break;
      }
      case DYNAMIC_FIELD_TYPE.visitDate: {
        const installationDateIntervals = model.values;
        values = installationDateIntervals.map((interval) => {
          const intervalDTO: CreateOrderRequestDTO['visitIntervals'][0] = this.dateIntervalMapper.modelToRequestDTO(interval);
          intervalDTO.completionState = interval.completionState;
          if (interval.workEstimateMin) {
            intervalDTO.workEstimation = { durationMinutes: interval.workEstimateMin };
          }
          return intervalDTO;
        });
        break;
      }
      case DYNAMIC_FIELD_TYPE.radioGroup:
      case DYNAMIC_FIELD_TYPE.select: {
        const { responseType, responseSource } = model.dataSource;
        switch (responseType) {
          case DYNAMIC_FIELD_RESPONSE_TYPE.model: {
            switch (responseSource) {
              case 'USER': {
                values = (model.values as EntityWithName[]).map((item) => item.id);
                break;
              }
              case undefined: {
                break;
              }
              default: {
                FunctionUtils.exhaustiveCheck(responseSource);
              }
            }
            break;
          }
          case DYNAMIC_FIELD_RESPONSE_TYPE.idWithName: {
            values = (model.values as EntityWithName[]).map((item) => item.id);
            break;
          }
        }
        break;
      }
      default: {
        FunctionUtils.exhaustiveCheck(model);
        values = [];
      }
    }

    return {
      name: model.name,
      technicalName: model.technicalName,
      type: model.type,
      value: values,
      valueType: model.valueType,
      multiple: model.multiple,
      required: model.required,
      description: model.description,
    };
  };

  /**
   * Переделывает модель в id, если не модель, то оставляет как есть для запроса
   * @returns преобразует массив значений используемых на фронте к значениям и форматам бекенда
   */
  private selectDynamicFieldValueToRequestDTO(
    field: DynamicField.DynamicFieldSelectField,
    values: any[],
    valueType: DYNAMIC_FIELD_VALUE_TYPE,
    valuesRaw: any,
    multiple: boolean
  ) {
    const { responseType } = field.dataSource;
    switch (responseType) {
      case DYNAMIC_FIELD_RESPONSE_TYPE.model: {
        switch (valueType) {
          case DYNAMIC_FIELD_VALUE_TYPE.technicalNameWithName:
          case DYNAMIC_FIELD_VALUE_TYPE.user: {
            if (multiple) values = (valuesRaw as EntityWithName[]).map((item) => item.id);
            else values = valuesRaw ? [(valuesRaw as EntityWithName).id] : [];
            break;
          }
          case DYNAMIC_FIELD_VALUE_TYPE.string:
          case DYNAMIC_FIELD_VALUE_TYPE.enum:
          case DYNAMIC_FIELD_VALUE_TYPE.idWithName: {
            break;
          }
          default: {
            FunctionUtils.exhaustiveCheck(valueType);
          }
        }
        break;
      }
      case DYNAMIC_FIELD_RESPONSE_TYPE.idWithName: {
        if (multiple) values = (valuesRaw as EntityWithName[]).map((item) => item.id);
        else values = valuesRaw ? [(valuesRaw as EntityWithName).id] : [];
        break;
      }
      case DYNAMIC_FIELD_RESPONSE_TYPE.technicalNameWithName: {
        switch (valueType) {
          case DYNAMIC_FIELD_VALUE_TYPE.user: {
            if (multiple) values = (valuesRaw as EntityWithName[]).map((item) => item.id);
            else values = valuesRaw ? [(valuesRaw as EntityWithName).id] : [];
            break;
          }
          case DYNAMIC_FIELD_VALUE_TYPE.technicalNameWithName: {
            if (multiple) values = (valuesRaw as EntityWithName[]).map((item) => item.id);
            else values = valuesRaw ? [valuesRaw.id] : [];
            break;
          }
          case DYNAMIC_FIELD_VALUE_TYPE.string:
          case DYNAMIC_FIELD_VALUE_TYPE.enum:
          case DYNAMIC_FIELD_VALUE_TYPE.idWithName: {
            break;
          }
          default: {
            FunctionUtils.exhaustiveCheck(valueType);
          }
        }
      }
    }
    return values;
  }

  /**
   * Мутирует dynamicField.values в нужный вид модели, основываясь на responseType
   */
  private mutateSelectDynamicFieldToModel(
    dto: DynamicFieldResponseDTO,
    fieldModel: DynamicField.DynamicFieldSelectField,
    outerMappers: DIContainer.Mappers['models']
  ) {
    if (!dto.dataSource) {
      return;
    }
    const { responseType, responseSource } = dto.dataSource;
    fieldModel.dataSource = dto.dataSource!;
    switch (responseType) {
      case DYNAMIC_FIELD_RESPONSE_TYPE.model: {
        switch (responseSource) {
          case 'USER': {
            fieldModel.values = dto.value.map((item) => UserUtils.mapUserResponseByType(item, outerMappers));
            break;
          }
          case undefined: {
            break;
          }
          default: {
            FunctionUtils.exhaustiveCheck(responseSource);
          }
        }
        break;
      }
      case DYNAMIC_FIELD_RESPONSE_TYPE.technicalNameWithName: {
        fieldModel.values = dto.value.map((value) => {
          return {
            id: value.technicalName,
            name: value.name,
          };
        });
        fieldModel.dataSource.options = fieldModel.dataSource.options.map((item) => {
          return {
            id: item.technicalName,
            name: item.name,
          };
        });
        break;
      }
      case DYNAMIC_FIELD_RESPONSE_TYPE.idWithName: {
        switch (responseSource) {
          case undefined: {
            fieldModel.values = dto.value;
            break;
          }
          case 'USER': {
            fieldModel.values = dto.value.map((item) => UserUtils.mapUserResponseByType(item, outerMappers));
            break;
          }
          default: {
            FunctionUtils.exhaustiveCheck(responseSource);
          }
        }

        break;
      }
      default: {
        FunctionUtils.exhaustiveCheck(responseType);
      }
    }
  }
}
