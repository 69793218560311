import { LOCALES } from 'configs/locales/locales';
import i18n from 'i18next';
import ILanguageService from './ILanguageService';

export default class LanguageServiceImpl implements ILanguageService {
  private static LOCAL_STORAGE_VAR_NAME = 'i18nextLng';
  private locale: ILanguageService.Locale;
  private localeList: ILanguageService.Locale[];
  /** При инициализации можно передать обработчик, который сработает каждый раз, когда меняют язык */
  private onLangChange?: (newLocaleName: ILanguageService.LocaleFull) => void;
  // Не совсем понятна пока поддержка данного метода с нужными настройками, поэтому потенциальный null
  private countryNameConstructor: Intl.DisplayNames | null;

  // Т.к. пока будет использоваться от силы пара валют, проще держать для них уже один раз вычисленные значения
  private currencyNameCodeMap: Map<ILanguageService.CurrencyCode, string> = new Map();
  private currencySymbolCodeMap: Map<ILanguageService.CurrencyCode, string> = new Map();

  constructor(onLangChange?: (newLocale: ILanguageService.LocaleFull) => void) {
    this.onLangChange = onLangChange;
    const receivedLocaleName = LanguageServiceImpl.getCurrentLocaleNameFromBrowser() || this.getDefaultLocaleName();
    const availableLocaleNames = this.getLocaleNamesFromEnvironment();
    this.localeList = LOCALES.filter((L) => availableLocaleNames.includes(L.localeFull));
    this.locale =
      this.localeList.find((locale) => locale.localeFull === receivedLocaleName) ||
      this.localeList.find((locale) => locale.localeFull === this.getDefaultLocaleName()) ||
      this.localeList[0] ||
      {};
    LanguageServiceImpl.setCurrentLocaleNameToBrowser(this.locale.localeFull);

    this.countryNameConstructor = Intl?.DisplayNames ? new Intl.DisplayNames(this.locale.localeFull, { type: 'region' }) : null;
  }

  /**
   * Асинхронно в рантайме догружает данные выбранного языка
   * @throws `Error` Если не сфейлился запрос
   */
  public getLocaleAsyncData = async () => {
    this.locale.translation = await import(
      /* webpackMode: "lazy", webpackChunkName: "lang[index]" */
      'configs/locales/' + this.locale.localeShort + '.json'
    );
    this.locale.localeDate = await import(
      /* webpackMode: "lazy", webpackChunkName: "dateLocale[index]", webpackExclude: /_lib/ */
      `date-fns/locale/${this.locale.localeDateName}/index.js`
    );

    i18n
      // .use(LanguageDetector)
      .init({
        resources: {
          [this.locale.localeShort]: { translations: this.locale.translation },
        },
        lng: this.locale.localeShort,
        fallbackLng: this.getDefaultLocaleName(),
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
          escapeValue: false,
        },
      });
  };

  public translate = i18n.t;
  public getCurrentLocale = () => this.locale;
  public getLocaleList = () => this.localeList;

  public changeLanguage = (newLocaleName: ILanguageService.LocaleFull) => {
    if (newLocaleName === this.getCurrentLocale().localeFull) {
      return;
    }
    const locale = this.getLocaleList().find((l) => l.localeFull === newLocaleName);
    if (locale) {
      this.locale = locale;
      i18n.changeLanguage(newLocaleName);
      LanguageServiceImpl.setCurrentLocaleNameToBrowser(newLocaleName);
      if (this.onLangChange) this.onLangChange(newLocaleName);
    }
  };

  public getCountryName = (countryCode: ILanguageService.LocaleCountry): string => {
    return this.countryNameConstructor ? this.countryNameConstructor.of(countryCode) || countryCode : countryCode;
  };

  public getCurrencyName = (currencyCode: ILanguageService.CurrencyCode): string => {
    const existedName = this.currencyNameCodeMap.get(currencyCode);
    if (existedName) {
      return existedName;
    }

    const newName = Intl?.DisplayNames
      ? new Intl.DisplayNames(this.locale.localeFull, { type: 'currency' }).of(currencyCode) || currencyCode
      : currencyCode;
    this.currencyNameCodeMap.set(currencyCode, newName);
    return newName;
  };

  public getCurrencySymbol = (currencyCode: ILanguageService.CurrencyCode): string => {
    const existedSymbol = this.currencySymbolCodeMap.get(currencyCode);
    if (existedSymbol) {
      return existedSymbol;
    }

    const newSymbol = Intl?.NumberFormat
      ? new Intl.NumberFormat(this.locale.localeFull, { style: 'currency', currency: currencyCode })
          .formatToParts(1)
          .find((x) => x.type === 'currency')?.value || currencyCode
      : currencyCode;
    this.currencySymbolCodeMap.set(currencyCode, newSymbol);
    return newSymbol;
  };

  // Getters

  /** Получает список языков из переменной среды, которые доступны данной сборке */
  private getLocaleNamesFromEnvironment = (): ILanguageService.LocaleFull[] =>
    (process.env.REACT_APP_LANGS?.split(',') as ILanguageService.LocaleFull[] | undefined) || [this.getDefaultLocaleName()];

  /** Получает язык по умолчанию из переменной среды */
  private getDefaultLocaleName = (): ILanguageService.LocaleFull =>
    (process.env.REACT_APP_DEFAULT_LANG as ILanguageService.LocaleFull | undefined) || 'en-US';

  // Статик и публичный, т.к. требуется получить локаль вне экосистемы реакта (вне di); пока единичный случай, если случаи добавятся, можно будет подумать
  /** Получает выбранный ранее пользователем язык из браузера */
  public static getCurrentLocaleNameFromBrowser = (): ILanguageService.LocaleFull | null => {
    return localStorage.getItem(this.LOCAL_STORAGE_VAR_NAME) as ILanguageService.LocaleFull | null;
  };

  // Пришлось тоже загнать в статик из-за getCurrentLocaleNameFromBrowser
  /** Сохраняет в браузер выбранный пользователем язык */
  private static setCurrentLocaleNameToBrowser = (newLocaleName: ILanguageService.LocaleFull): void => {
    localStorage.setItem(this.LOCAL_STORAGE_VAR_NAME, newLocaleName);
  };
}
