import { Draft } from 'immer/dist/internal';
import IReducers from './IReducers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import defaultState from 'storage/defaultState';
import MODEL_NAME from 'typings/models/_model.enum';

// Как обычно через классы сделать не получится из-за своего формата @reduxjs/toolkit
// Поэтому объединил всё тут
/** Фабрика сущностей @reduxjs/toolkit, создаёт для каждой модели слайс с типовыми редьюсерами с помощью createSlice */
export default class SliceFactory {
  public static createSimpleSlice = createSlice;

  public static createModelSlice = <N extends MODEL_NAME, M extends Model, S extends ModelState<M, N>>(
    ENTITY_NAME: N,
    initialState?: S,
    reducers?: IReducers<N, M, S>
  ) => {
    // oneEntityName, listEntitiesName и allEntitiesName это названия полей конкретного стейта, в которых присутствует имя entity, дублирующие oneEntity, listOfEntities, allEntities (для удобства извлечения данных из стейта)
    const oneEntityName = ENTITY_NAME;
    const listEntitiesName = `${ENTITY_NAME}List` as const;
    const allEntitiesName = `${ENTITY_NAME}All` as const;
    // Тоже самое, только с индикаторами загрузки
    const isEntityLoadingName = `${ENTITY_NAME}IsLoading` as const;
    const areAllLoadingName = `${ENTITY_NAME}AllAreLoading` as const;

    const initialStateInitialized: S = initialState || {
      ...(defaultState as S), // Typescript боится, что мы не укажем остальные поля из S, которых нет в defaultState, но мы точно это делаем ниже, хоть и не можем свериться
      [oneEntityName]: null,
      [listEntitiesName]: [],
      [allEntitiesName]: [],
      [isEntityLoadingName]: false,
      [areAllLoadingName]: false,
    };

    const reducersInitialized = reducers || this.createModelReducers<N, M, S>(ENTITY_NAME, initialStateInitialized);

    return createSlice({
      name: ENTITY_NAME,
      initialState: initialStateInitialized,
      reducers: reducersInitialized,
    });
  };

  /** @see IReducers */
  public static createModelReducers = <N extends string, M extends Model, S extends ModelState<M, N>>(
    ENTITY_NAME: N,
    initialState: S
  ): IReducers<N, M, S> => {
    return {
      startLoading(stateRaw: Draft<S>) {
        const state = stateRaw as S;
        state.isLoading = true;
        const isEntityLoadingName = `${ENTITY_NAME}IsLoading` as const;
        state[isEntityLoadingName] = true as any;
      },
      stopLoading(stateRaw: Draft<S>) {
        const state = stateRaw as S;

        state.isLoading = false;
        const isEntityLoadingName = `${ENTITY_NAME}IsLoading` as const;
        state[isEntityLoadingName] = false as any;
      },
      startAllLoading(stateRaw: Draft<S>) {
        const state = stateRaw as S;
        state.areAllLoading = true;
        const areAllLoadingName = `${ENTITY_NAME}AllAreLoading` as const;
        state[areAllLoadingName] = true as any;
      },
      stopAllLoading(stateRaw: Draft<S>) {
        const state = stateRaw as S;
        state.areAllLoading = false;
        const areAllLoadingName = `${ENTITY_NAME}AllAreLoading` as const;
        state[areAllLoadingName] = false as any;
      },
      setOneIsOutdated(stateRaw: Draft<S>) {
        const state = stateRaw as S;
        state.isOneOutdated = true;
        state.isListOutdated = true;
        state.areAllOutdated = true;
      },
      setListIsOutdated(stateRaw: Draft<S>) {
        const state = stateRaw as S;
        state.isListOutdated = true;
        state.areAllOutdated = true;
      },
      setAllAreOutdated(stateRaw: Draft<S>) {
        const state = stateRaw as S;
        state.areAllOutdated = true;
      },
      setError(stateRaw: Draft<S>, action: PayloadAction<any>) {
        const state = stateRaw as S;
        state.error = action.payload;
      },
      setOne(stateRaw: Draft<S>, action: PayloadAction<M | null>) {
        const state = stateRaw as S;
        state.isOneOutdated = false;
        state.error = null;

        state.oneEntity = action.payload;
        const oneEntityName = ENTITY_NAME;
        state[oneEntityName] = action.payload as any;
      },
      setList(stateRaw: Draft<S>, action: PayloadAction<EntityListData<M>>) {
        const state = stateRaw as S;
        const { data, pagination, requestProps } = action.payload;
        state.isListOutdated = false;
        state.pagination = pagination;
        state.lastListRequestFilter = requestProps?.lastListRequestFilter;
        state.error = null;

        state.listOfEntities = data;
        const listEntitiesName = `${ENTITY_NAME}List` as const;
        state[listEntitiesName] = data as any;
      },
      setAll(stateRaw: Draft<S>, action: PayloadAction<EntityListData<M>>) {
        const state = stateRaw as S;
        const { data, requestProps } = action.payload;
        state.lastAllRequestFilter = requestProps?.lastAllRequestFilter;
        state.areAllOutdated = false;
        state.error = null;

        state.allEntities = data;
        const allEntitiesName = `${ENTITY_NAME}All` as const;
        state[allEntitiesName] = data as any;
      },
      resetState(stateRaw: Draft<S>) {
        const state = stateRaw as S;
        const stateKeys = Object.keys(state) as (keyof typeof state)[];
        stateKeys.forEach((key) => {
          state[key] = initialState[key];
        });
      },
    };
  };
}
