import { PAGE_TYPE, PATH_PARAM_DASHBOARD, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import FrontendRoutesBase from './FrontendRoutesBase';
import IFrontendRoutes from './IFrontendRoutes';

export default class ContractSPRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  root = this.getDBPath(PATH_PARAM_DASHBOARD.contractSP.root);

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.contract> = (_params, _permissions, translate) => {
    return [{ name: translate('menus.main.contracts'), href: this.getListPath() }];
  };
  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.contract, 'contractName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const breadcrumbs = this.getInstanceBc(params, permissions, translate);
    breadcrumbs.push({ name: names.contractName, href: this.getInfoPath(params) });
    return breadcrumbs;
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.contractSP.root, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.contract> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.contractSP.root, params.contractId, PAGE_TYPE.info);

  getAddBc = this.getInstanceBc;
  getAddPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.contractSP.root, PAGE_TYPE.new, PAGE_TYPE.add);

  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.contract> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.contractSP.root, params.contractId, PAGE_TYPE.edit);
  getEditBc = this.getInstanceChildBc;

  getPriceListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.contract> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.contractSP.root, params.contractId, PATH_PARAM_DASHBOARD.contractSP.prices, PAGE_TYPE.list);
  getPriceListBc = this.getInstanceBc;

  getLegalEntitiesPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.contract> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.contractSP.root, params.contractId, PATH_PARAM_DASHBOARD.contractSP.legalEntity);
  getLegalEntitiesBc = this.getInstanceBc;

  getReviewsPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.contract> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.contractSP.root, params.contractId, PATH_PARAM_DASHBOARD.contractSP.reviews);
  getReviewsBc = this.getInstanceBc;

  // TODO сделать роуты юзеров отдельным классом как в других routes service
  getEmployeesPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.contract> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.contractSP.root, params.contractId, PATH_PARAM_DASHBOARD.contractSP.employees, PAGE_TYPE.list);
  getEmployeesBc = this.getInstanceBc;

  getEmployeeInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.contract> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.contractSP.root,
      params.contractId,
      PATH_PARAM_DASHBOARD.contractSP.employees,
      params.employeeId,
      PAGE_TYPE.info
    );

  getEmployeeInfoBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.contract, 'employeeName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const breadcrumbs = this.getInstanceBc(params, permissions, translate);
    breadcrumbs.push({ name: names.employeeName, href: this.getEmployeesPath(params) });
    return breadcrumbs;
  };
}
