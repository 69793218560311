import IHttpService from 'services/http/IHttpService';
import ILanguageService from 'services/language/ILanguageService';
import FetchError from 'utils/errors/FetchError';
import IGeoService from './IGeoService';

export default class GeoServiceGoogleImpl implements IGeoService {
  private readonly API_KEY = process.env.REACT_APP_ADDRESS_SERVICE_KEY || '';
  private readonly API_URL = 'https://maps.googleapis.com/maps/api/geocode/json?';
  constructor(private httpService: IHttpService, private languageService: ILanguageService) {}

  // Учитывается точность поиска, поверхностные не пропускает; TODO сюда тоже можно добавить accuracy
  /** @throws Error */
  public formatAddress = async (address: string, region?: string | null): Promise<string | null> => {
    // Получение данных копирует getGeoByAddress
    const url = this.getRequestAddress(address, region);
    const response = await this.httpService.fetch(url).then(this.httpService.parseResponseJson);
    if (!response) {
      return null;
    }
    const result = this.getPreciseResult(response);
    return result && result.formatted_address;
  };

  /** @throws Error */
  public getGeoByAddress = async (address: string, region?: string | null, accuracy?: number): Promise<IGeoService.Point | null> => {
    const url = this.getRequestAddress(address, region);
    // TODO А почему тут нет обработки ошибки?
    const response = await this.httpService.fetch(url).then(this.httpService.parseResponseJson);
    const result = this.getPreciseResult(response, accuracy);
    return result ? { lat: result.geometry.location.lat, lon: result.geometry.location.lng } : null;
  };

  /** @throws Error */
  public getAddressByGeo = async (geo: IGeoService.Point, accuracy?: number): Promise<string | null> => {
    try {
      const url = this.getRequestAddress(geo);
      const response: AddressServiceResponseDTO = await this.httpService.fetch(url).then(this.httpService.parseResponseJson);
      return this.getPreciseResult(response, accuracy)?.formatted_address || null;
    } catch (error) {
      throw this.getError(error);
    }
  };

  private getRequestAddress(address: string, region?: string | null): string;
  private getRequestAddress(geo: IGeoService.Point): string;
  private getRequestAddress(param1: string | IGeoService.Point, region?: string | null): string {
    let geocodeString: string;
    if (typeof param1 === 'string') {
      geocodeString = `&address=${encodeURIComponent(this.getAddressWithRegion(param1, region))}`;
    } else {
      geocodeString = `&latlng=${param1.lat},${param1.lon}`;
    }
    return `${this.API_URL}${geocodeString}&key=${this.API_KEY}&language=${this.languageService.getCurrentLocale().localeShort}`;
  }

  public getCurrentGeoPosition = GeoServiceGoogleImpl.getCurrentGeoPosition;

  private getAddressWithRegion = (address: string, region?: string | null): string => {
    if (!region) {
      return address;
    }
    return address.toLocaleLowerCase().includes(region.toLocaleLowerCase()) ? address : region + ', ' + address;
  };

  /**
   * Ищет среди всех присланных результатов наиболее точные, не ниже определённого уровня
   * accuracy по умолчанию использует не ниже 8
   */
  private getPreciseResult = (response: AddressServiceResponseDTO, accuracy?: number): AddressServiceResponseDTO['results'][0] | null => {
    if (response.status !== 'OK') {
      return null;
    }
    let preciseResult = response.results.find((res) => res.types.includes(AddressPriorityMap[9]));
    if (!preciseResult) {
      preciseResult = response.results.find((res) => res.types.includes(AddressPriorityMap[8]));
    }
    if (!preciseResult && accuracy !== undefined) {
      // ... todo
      preciseResult = response.results[0];
    }
    return preciseResult || null;
  };

  private getError = (error: any): Error => {
    let message = 'Address service Error: ';
    if (error instanceof FetchError) {
      message += 'Fetch error ' + error.code;
    } else {
      message += error?.message || 'unknown error';
    }
    return new Error(message);
  };

  // На статике сделано чтобы можно было переиспользовать в других имплементациях, т.к. пока одно и тоже
  /** @throws `GeolocationPositionError` в случае если нет пермишена или непредвиденная ошибка */
  public static getCurrentGeoPosition = (): Promise<IGeoService.Point> => {
    if (!('geolocation' in navigator)) {
      Promise.resolve(new FetchError('Geolocation is not supported in this browser', 2)); // TODO Использована FetchError т.к. имеет сходный проп code
    }

    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        const point: IGeoService.Point = { lat: coords.latitude, lon: coords.longitude, accuracy: coords.accuracy };
        resolve(point);
      }, reject);
    });
  };
}

type AddressServiceResponseDTO = {
  /** Статус запроса */
  status: 'OK' | string;
  results: [
    {
      /** Полный читаемый адрес */
      formatted_address: string;
      /** Отдельные компоненты адреса */
      address_components: Array<{
        long_name: string;
        short_name: string;
        /** Тип фрагмента адреса */
        types: string[];
      }>;
      /** Координаты */
      geometry: {
        location: {
          lat: 30.8963554;
          lng: 31.9558105;
        };
        /** Хз */
        location_type: string;
      };
      /** Уникальный id точки */
      place_id: string;
      /** Точность адреса */
      types: ADDRESS_PRIORITY_TYPE[];
    }
  ];
};

enum ADDRESS_PRIORITY_TYPE {
  premise = 'premise',
  streetAddress = 'street_address',
}

/** Точность адреса и какой enum ей соответствует */
const AddressPriorityMap = {
  9: ADDRESS_PRIORITY_TYPE.premise,
  8: ADDRESS_PRIORITY_TYPE.streetAddress,
};
