const defaultState: ModelState<any, string> = {
  isLoading: false,
  error: null,
  oneEntity: null,
  listOfEntities: [],
  allEntities: [],
  isOneOutdated: true,
  isListOutdated: true,
  areAllOutdated: true,
  areAllLoading: false,
};

export default defaultState;
