import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { ContractSPResponseDTO, CreateContractSPRequestDTO, GetOrderContractRequestDTO } from 'typings/dto/contract';
import IContractSPActionsService from './IContractActionsService';
import { ServiceProviderResponseDTO } from 'typings/dto/serviceProvider';
import ContractSPMapper from 'utils/mappers/ContractSP';

export default class ContractSPActionsServiceImpl
  extends ModelActionsService<ContractSP, CreateContractSPRequestDTO, ContractSPResponseDTO>
  implements IContractSPActionsService
{
  /** @throws `BackendResponseError` */
  public accept = (id: string) => {
    return this.entityApiService.postWithCredentials<void>(PATH_BACKEND.contractSP.accept + '/' + id, null, false);
  };

  /** @throws `BackendResponseError` */
  public resume = (id: string) => {
    return this.entityApiService.postWithCredentials<void>(PATH_BACKEND.contractSP.resume + '/' + id, null, false);
  };

  /** @throws `BackendResponseError` */
  public suspend = (id: string) => {
    return this.entityApiService.postWithCredentials<void>(PATH_BACKEND.contractSP.suspend + '/' + id, null, false);
  };

  public reject = this.suspend;

  /** @throws `BackendResponseError` */
  public resendInvite = (id: string) => {
    return this.entityApiService.postWithCredentials<void>(PATH_BACKEND.contractSP.resendInvite + '/' + id, null, false);
  };

  /** @throws `BackendResponseError` */
  public getOrderContract = (dto: GetOrderContractRequestDTO): Promise<ContractSP> => {
    const url = `${this.getModelApiRootPath(dto)}/tenant/${dto.tenantId}/service-provider`;
    /** id будет добавлен вконец url'а */
    return this.requestById(dto.serviceProviderId, url);
  };

  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return super.getDefaultSortFilter(filter, 'createdAt');
  };

  /** @throws `BackendResponseError` */
  public getContractProviderStatistics = async (contractId: string): Promise<User.TechnicianStatistics> => {
    const dto = await this.entityApiService.getWithCredentials<ServiceProviderResponseDTO['statistics']>(
      `${PATH_BACKEND.contractSP.root}/${contractId}/reviews`
    );

    return (this.modelMapper as ContractSPMapper).responseStatisticsDTOToModel(dto);
  };
}
