import FrontendRoutesBase from './FrontendRoutesBase';
import { PATH_PARAM_AUTH } from '../PATH_PARAM';

export default class AuthRoutes extends FrontendRoutesBase {
  root = PATH_PARAM_AUTH.root;

  getLoginPath = () => this.getPath(PATH_PARAM_AUTH.root, PATH_PARAM_AUTH.login);

  getResetPasswordPath = () => this.getPath(PATH_PARAM_AUTH.root, PATH_PARAM_AUTH.resetPassword);

  getProfile = (hc: string) => `${this.getPath(PATH_PARAM_AUTH.root, PATH_PARAM_AUTH.profile)}?v=${hc}`;
}
