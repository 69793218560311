import FrontendRoutesBase from './FrontendRoutesBase';
import { PAGE_TYPE, PATH_PARAM_DASHBOARD, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';

export default class PriceTechnicianRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  root = this.getDBPath(PATH_PARAM_DASHBOARD.price.root, PATH_PARAM_DASHBOARD.price.technician);

  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc = this.getEmptyBreadcrumbs;

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.price.root,
      PATH_PARAM_DASHBOARD.price.technician,
      PATH_PARAM_DASHBOARD.price.default,
      PAGE_TYPE.list
    );

  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.price> = () =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.price.root,
      PATH_PARAM_DASHBOARD.price.technician,
      PATH_PARAM_DASHBOARD.price.default,
      PAGE_TYPE.edit
    );
  getEditBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.price> = (_params, _permissions, translate) => {
    return [{ href: this.getListPath(), name: translate('entities.pricelist.types.technician') }];
  };
}
