import { TextField, TextFieldProps } from '@mui/material';
import { Suspense } from 'react';

// Переиспользуемые загрузчики для асинхронных компонентов

/** Компонент, который будет отображен во время загрузки основного компонента */
export function FallbackComponent(props: TextFieldProps) {
  return <TextField {...props} disabled value={'...'} />;
}

/** HOC для функционала загрузки асинхронных компонентов */
export function LoadableForm<T = any>(Component: any) {
  return (props: T & { fullWidth?: boolean; sx?: any; className?: string; size?: 'small' | 'medium' }) => {
    return (
      <Suspense fallback={<FallbackComponent fullWidth={props.fullWidth} sx={props.sx} size={props.size} className={props.className} />}>
        <Component {...props} />
      </Suspense>
    );
  };
}
