import throttle from 'lodash/throttle';

export default class FunctionUtils {
  /** Функция выполнится только если в течении следующих n миллисекунд не было повторного её вызова, иначе ждёт */
  public static debounce(func: (...args: any[]) => any, interval: number) {
    let timer: ReturnType<typeof setTimeout> | null = null;

    return function debounced(this: any, ...args: any[]) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => func.apply(this, args), interval);
    };
  }

  // TODO взять свой из соседнего репо (вроде в модальном виджете)
  public static throttle = throttle;

  /** Костыль для TS для switch case, подсвечивает ошибку, если не обработаны все case; пока тут пусть лежит */
  public static exhaustiveCheck = (never: never) => never;
}
