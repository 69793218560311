import { Box, Stack, Typography } from '@mui/material';
import useLocales from 'hooks/useLocales';
import LoginForm from 'components/_authenticationPagesFeatures/LoginForm';
import { Helmet } from 'react-helmet-async';
import LogoOnlyLayout from 'components/layouts/LogoOnlyLayout';

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { translate } = useLocales();

  return (
    <LogoOnlyLayout>
      <Helmet>
        <title>
          {translate('pages.login.title')} | {translate('common.app.name')}
        </title>
      </Helmet>
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {translate('pages.login.title')}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>{translate('pages.login.subtitle')}</Typography>
        </Box>
      </Stack>

      <LoginForm />
    </LogoOnlyLayout>
  );
}
