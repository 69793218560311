import FrontendRoutesBase from './FrontendRoutesBase';
import { PATH_PARAM_DASHBOARD, PAGE_TYPE } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';

export default class MeRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public root = this.getDBPath(PATH_PARAM_DASHBOARD.me.root);
  public isMePageSection = () => new RegExp('^' + this.root).test(window.location.pathname);

  getInstanceBc = this.getEmptyBreadcrumbs;
  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<{}, 'userName'> = (_params, _permissions, _translate, names) => {
    return [{ name: names.userName, href: this.getInfoPath() }];
  };

  getAnyTabInfoBc = this.getInstanceBc;
  getInfoPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.me.root, PAGE_TYPE.info);

  getEditBc = this.getInstanceChildBc;
  getEditPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.me.root, PAGE_TYPE.edit);

  getNotificationsInfoPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.me.root, PATH_PARAM_DASHBOARD.user.notifications, PAGE_TYPE.info);
  getNotificationsEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<{}, 'userName'> = (params, permissions, translate, names) => {
    const bc = this.getInstanceChildBc(params, permissions, translate, names);
    this.setLastBcLink(bc, this.getNotificationsInfoPath());
    return bc;
  };
  getNotificationsEditPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.me.root, PATH_PARAM_DASHBOARD.user.notifications, PAGE_TYPE.edit);

  // technician

  getTechnicianReviewsPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.me.root, PATH_PARAM_DASHBOARD.scEmployee.reviews);

  getEnterpriseTechnicianLegalEntityInfoPath = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.me.root, PATH_PARAM_DASHBOARD.enterpriseEmployee.legalEntity, PAGE_TYPE.info);
  getEnterpriseTechnicianLegalEntityEditPath = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.me.root, PATH_PARAM_DASHBOARD.enterpriseEmployee.legalEntity, PAGE_TYPE.edit);
  getEnterpriseTechnicianLegalEntityEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<{}, 'userName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const bc = this.getInstanceChildBc(params, permissions, translate, names);
    this.setLastBcLink(bc, this.getEnterpriseTechnicianLegalEntityInfoPath());
    return bc;
  };

  getTechnicianRegionServiceListPath = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.me.root, PATH_PARAM_DASHBOARD.scEmployee.regionServices, PAGE_TYPE.list);
  getTechnicianRegionServiceEditPath = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.me.root, PATH_PARAM_DASHBOARD.scEmployee.regionServices, PAGE_TYPE.edit);
  getTechnicianRegionServiceEditBc: IFrontendRoutes.BreadcrumbsGetterWithNames<{}, 'userName'> = (
    params,
    permissions,
    translate,
    names
  ) => {
    const bc = this.getInstanceChildBc(params, permissions, translate, names);
    this.setLastBcLink(bc, this.getTechnicianRegionServiceListPath());
    return bc;
  };
}
