import { IconButton, IconButtonProps, Tooltip } from '@mui/material';

type Props = IconButtonProps & {
  title?: string;
};

export default function IconButtonStyled({ onClick, title = '', children, disabled, ...restProps }: Props) {
  if (!title || disabled) {
    return (
      <IconButton onClick={onClick} disabled={disabled} {...restProps}>
        {children}
      </IconButton>
    );
  }

  // span чтобы Tooltip не ругался когда кнопка disabled
  return (
    <Tooltip title={title} disableInteractive enterTouchDelay={700}>
      <span>
        <IconButton onClick={onClick} {...restProps}>
          {children}
        </IconButton>
      </span>
    </Tooltip>
  );
}
