import { useEffect, useState } from 'react';
import useDI from './useDI';

/**
 * Корректно проставляет шапку сайта
 * Реализовано в качестве хука; есть реализация в качестве компонена layouts/dashboard/page
 * @param callback Эти данные будут переданы в шапку сайта
 * @param isEntityLoading Метка о загрузке сущностей (одиночное значение (legacy) или массив (актуальное)), данные которой пишутся в шапку. При каждой перезагрузке перерисовывает шапку. Обычно достаточно указать только саму сущность и родителя, т.к. все участники выше по иерархии уже не изменятся без размонтирования данного компонента
 */
export default function useInitializePage(callback: () => Partial<PageState>, isEntityLoading: boolean | boolean[]) {
  const { storageActions } = useDI();
  const [isNotFirstRender, setIsNotFirstRender] = useState(false);
  const isEntityLoaded = Array.isArray(isEntityLoading) ? isEntityLoading.every((i) => i === false) : !isEntityLoading;

  useEffect(() => {
    // У нового реакт роутера есть интересный нюанс в работы с <Outlet /> - он сначала пробрасывает его и отображает по целевому адресу, а затем только у всех родителей срабатывают эффекты.
    // Поэтому если в memo потомке отображение зависит от стейта, а добаление туда данных от эффектов родителя, то потомок отобразит старые данные, т.к. эффект родителя ещё не сработал.
    // Мелкий фикс с добавлением setIsPageInitialized решает это (первый рендеринг ничего не рендерит, дальше включается зависимость от флага загрузки новых данных).
    if (!isNotFirstRender) return setIsNotFirstRender(true);
    if (isEntityLoaded) storageActions.page.initializePage(callback());
  }, [isNotFirstRender, isEntityLoaded]);

  const isReady = isNotFirstRender && isEntityLoaded;
  return isReady;
}
