import IAuthApiService from 'services/auth/IAuthApiService';
import {
  ChangeCredentialsRequestDTO,
  ChangePasswordRequestDTO,
  ResendInviteRequestDTO,
  ResetPasswordRequestDTO,
  SendOtpRequestDTO,
  SigninRequestDTO,
  VerifyCredentialsRequestDTO,
} from 'typings/dto/auth';
import { AppDispatch } from 'storage';
import slices from 'storage/slices';
import IAuthActionsService from './IAuthActionsService';
import { OTP_LIFETIME_STORAGE_VAR_NAME, RESEND_VERIFICATION_CODE_TIMEOUT_SECONDS } from 'configs/vars';
import IPermissionService from 'services/permission/IPermissionService';
import { TENANT_TYPE } from 'typings/subEntities/tenant.enum';
import { USER_ROLE } from 'typings/models/user.enum';
import DIContainer from 'services/DIContainer';

export default class AuthActionsService implements IAuthActionsService {
  constructor(
    private readonly apiService: IAuthApiService,
    private readonly sliceActions: typeof slices.auth.actions,
    private readonly storageDispatch: AppDispatch
  ) {}

  /** @throws `BackendResponseError` */
  public login = async (data: SigninRequestDTO) => {
    await this.apiService.signin(data);
    this.storageDispatch(this.sliceActions.setProfileOutdated());
  };

  public logout = async () => {
    await this.apiService.signout();
    this.storageDispatch(this.sliceActions.logoutUserSuccess());
  };

  // public updateProfile = () => {
  //   ...
  // };

  public initialize = async (modelsActions: DIContainer['storageActions']['models']) => {
    try {
      this.storageDispatch(this.sliceActions.startLoading());
      const { user, permissions } = await this.apiService.getCurrentUser();
      let permissionConfigs: IPermissionService.PermissionConfigs;
      // TODO вынести эту логику дополучения конфигов куда-то

      switch (user?.type) {
        case TENANT_TYPE.platformOperator:
        case TENANT_TYPE.enterprise: {
          // Полный ритейлер нужен для отображения всяких элементов, т.к. в нём есть настройки этих элементов (пока что это только orderCustomFields)
          user.enterprise = await modelsActions.enterprise.requestById(user.tenant.id);
          if (user.roles.includes(USER_ROLE.orderTechnician)) {
            permissionConfigs = {
              geoPointForServerActions: (user.enterprise as Enterprise).settings.technician.geoPositionTrackingType,
            };
          } else {
            permissionConfigs = {};
          }
          break;
        }
        default: {
          permissionConfigs = {};
        }
      }

      this.storageDispatch(this.sliceActions.initializedSuccess({ user, permissions, permissionConfigs }));
    } catch (e: any) {
      console.error(e.message);
      this.storageDispatch(this.sliceActions.initializedSuccess({ user: null, permissions: [] }));
    }
  };

  public resetPassword = (dto: ResetPasswordRequestDTO) => {
    return this.apiService.resetPassword(dto);
  };

  public getVerifyCredentialsPageMode = async (hashCode: string) => {
    return this.apiService.getVerifyCredentialsPageMode(hashCode);
  };

  public verifyCredentials = (dto: VerifyCredentialsRequestDTO) => {
    return this.apiService.verifyCredentials(dto).finally(() => {
      // Бек автоматически логаутит, нужно сделать это на фронте
      this.setUser(null, []);
    });
  };

  public changeCredentials = (dto: ChangeCredentialsRequestDTO) => {
    return this.apiService.changeCredentials(dto);
  };

  public changePassword = (dto: ChangePasswordRequestDTO) => {
    return this.apiService.changePassword(dto);
  };

  public sendOtpCode = (dto: SendOtpRequestDTO) => {
    const timeNow = Date.now();
    const localStorageItem = localStorage.getItem(OTP_LIFETIME_STORAGE_VAR_NAME);
    if (localStorageItem) {
      const otpISCreationTime = +localStorageItem;
      if (Number.isNaN(otpISCreationTime)) throw new Error('Unable to read local storage');
      if (otpISCreationTime + RESEND_VERIFICATION_CODE_TIMEOUT_SECONDS * 1000 > timeNow) return Promise.resolve();
    }
    localStorage.setItem(OTP_LIFETIME_STORAGE_VAR_NAME, JSON.stringify(timeNow));
    return this.apiService.sendOtpCode(dto);
  };

  public resendInvite = (dto: ResendInviteRequestDTO): Promise<void> => {
    return this.apiService.resendInvite(dto);
  };

  /** Перезагружает всё приложение (все действия с интерфейсом после вызова этого метода не сработают), не вызывать где попало */
  public setProfileOutdated = () => {
    this.storageDispatch(this.sliceActions.setProfileOutdated());
  };

  // temp ? -------------------------

  public setUser = (
    user: User | null,
    permissions: IPermissionService.Permission[],
    permissionConfigs?: IPermissionService.PermissionConfigs
  ) => {
    if (user) this.storageDispatch(this.sliceActions.getUserSuccess({ user, permissions, permissionConfigs }));
    else this.storageDispatch(this.sliceActions.logoutUserSuccess());
  };
}
