import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { GetActionListRequestDTO, ServerActionRequestDTO, ServerActionResponseDTO, StartActionRequestDTO } from 'typings/dto/serverAction';
import IServerActionActionsService from './IServerActionActionsService';

export default class ServerActionActionsServiceImpl
  extends ModelActionsService<ServerAction, ServerActionRequestDTO, ServerActionResponseDTO>
  implements IServerActionActionsService
{
  public getList = (dto: GetActionListRequestDTO) => {
    return super.getList(dto);
  };

  public getOrderActionById = (actionId: string, orderId: string) => {
    return this.getById(actionId, PATH_BACKEND.action.root, { orderId });
  };

  /** @throws `BackendResponseError` */
  public start = async ({ actionId, orderId, ...restDTO }: StartActionRequestDTO) => {
    const path = PATH_BACKEND.action.start + '/' + actionId + '/order/' + orderId;
    return this.entityApiService.postWithCredentials<void>(path, restDTO, false);
  };
}
