import Mapper from './Mapper';
import { ContractSPResponseDTO, CreateContractSPRequestDTO } from 'typings/dto/contract';
import { ServiceProviderResponseDTO } from 'typings/dto/serviceProvider';

export default class ContractSPMapper extends Mapper<ContractSP, ContractSPResponseDTO, CreateContractSPRequestDTO> {
  constructor() {
    super(
      {
        owner: 'owner',
        history: 'history',
        inviteEmail: 'inviteEmail',
        pricelist: 'pricelist',
        serviceCategories: 'regionServiceCategories',
        serviceProvider: 'serviceProvider',
        status: 'status',
        suspendedByTenant: 'suspendedByTenant',
        suspendedByServiceProvider: 'suspendedByServiceProvider',
        technicalName: 'technicalName',
      },
      null
    );
  }

  public responseDTOToModel = (dto: ContractSPResponseDTO): ContractSP => {
    const outerMappers = this.getOuterMappers();
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      history: dto.history.map(outerMappers.orderHistory.responseDTOToModel),
      pricelist: outerMappers.pricelistServiceProvider.responseDTOToModel(dto.pricelist),
      regionServiceCategories: dto.serviceCategories.map((i) => ({
        categories: i.serviceCategories.map(outerMappers.serviceCategory.responseDTOToModel),
        region: i.region,
      })),
    };
  };

  public responseStatisticsDTOToModel = (dto: ServiceProviderResponseDTO['statistics']): User.TechnicianStatistics => {
    const outerMappers = this.getOuterMappers();
    return {
      ...dto,
      reviews: dto.reviews.map(outerMappers.orderReview.responseDTOToModel),
    };
  };
}
