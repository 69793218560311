import FrontendRoutesBase from './FrontendRoutesBase';
import { PATH_PARAM_DASHBOARD, PAGE_TYPE, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';

export default class EnterpriseRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public root = this.getDBPath(PATH_PARAM_DASHBOARD.enterpriseAccount.root);

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.enterpriseAccount> = (_p, permissions, translate) => {
    return [{ name: translate('menus.main.enterprises'), href: this.getListPath() }];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.enterpriseAccount, 'enterpriseName'> = (
    p,
    pm,
    t,
    n
  ) => {
    const breadcrumbs = this.getInfoBc(p, pm, t);
    breadcrumbs.push({ name: n.enterpriseName, href: this.getInfoPath(p) });
    return breadcrumbs;
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.enterpriseAccount.root, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterpriseAccount> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.enterpriseAccount.root, params.enterpriseId, PAGE_TYPE.info);

  getAddBc = this.getInstanceBc;
  getAddPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.enterpriseAccount.root, PAGE_TYPE.new, PAGE_TYPE.add);

  getEmployeeListBc = this.getEmptyBreadcrumbs;
  getEmployeeListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.enterpriseAccount> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.enterpriseAccount.root,
      params.enterpriseId,
      PATH_PARAM_DASHBOARD.enterpriseAccount.employees,
      PAGE_TYPE.list
    );
}
