import { TableRow } from '@mui/material';
import React, { useState } from 'react';
import TableVariant1 from 'components/ui/tables/TableVariant1';
import useLocales from 'hooks/useLocales';
import TableHeaderCell from 'components/ui/tables/TableHeaderCell';
import useDI from 'hooks/useDI';
import RecommendationRuleListItem from 'components/_dashboardPagesFeatures/recommendationsConfig/rules/RecommendationRuleListItem';
import RecommendationRuleEditPopup from 'components/_dashboardPagesFeatures/recommendationsConfig/rules/RecommendationRuleEditPopup';
import { LoadingButton } from '@mui/lab';
import { cloneDeep } from 'lodash';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';

type Props = {
  recommendationsConfig: RecommendationsConfig;
  recommendationRules: RecommendationRule[];
  dictionaryMap: Record<string, Dictionary>;
  servicesList: BusinessService[];
  parentModelPath: string;
};

export default function RecommendationsRulesListBlock({
  recommendationRules,
  dictionaryMap,
  servicesList,
  recommendationsConfig,
  parentModelPath,
}: Props) {
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [toOpenIndex, setToOpenIndex] = useState<number>(0);
  const { storageActions } = useDI();
  const handleFormErrors = useSnackbarErrorHandler();
  const { translate } = useLocales();
  const [anyDirty, setAnyDirty] = useState(false);
  const [configError, setConfigError] = useState<ConfigError>({});

  const submitHandler = async () => {
    try {
      if (!recommendationsConfig) return;
      if (recommendationRules.some((r) => !r.serviceItem.id)) {
        throw new Error(translate('pages.contractEdit.regionCategoriesRequiredError'));
      }

      const updatedRecommendationsConfig: RecommendationsConfig = cloneDeep(recommendationsConfig);

      const repoRules: RecommendationRule[] = recommendationsConfig.rules;
      const updateRecommendationRules: RecommendationRule[] = recommendationRules.filter((rule) => rule.ruleExpression);
      const mergedRecommendationRules = updateRecommendationRules;

      repoRules.forEach((repoRule) => {
        let isFound = false;
        for (let i = 0; i < updateRecommendationRules.length; i++) {
          if (repoRule.id === updateRecommendationRules[i].id) {
            isFound = true;
            break;
          }
        }
        if (!isFound) {
          mergedRecommendationRules.push(repoRule);
        }
      });
      updatedRecommendationsConfig.rules = mergedRecommendationRules.filter((rule) => rule.ruleExpression);

      await storageActions.models.recommendationsConfig.updateRecommendationsConfig(updatedRecommendationsConfig);
    } catch (error: any) {
      setConfigError(error.details);
      handleFormErrors({ error, callback: () => {} });
    }
  };
  const openPopupHandler = (index: number) => {
    setToOpenIndex(index);
    setIsPopupOpened(true);
  };

  return (
    <>
      {anyDirty && (
        <LoadingButton
          onClick={submitHandler}
          variant="contained"
          color="primary"
          loading={false}
          sx={{ flexGrow: { xs: 1, md: 'inherit' }, mt: 2 }}
        >
          {translate('buttons.save')}
        </LoadingButton>
      )}

      <TableVariant1 header={<TableHeader />} sx={{ mt: 2 }}>
        {recommendationRules.map((rule, index) => (
          <RecommendationRuleListItem
            key={rule.id}
            rule={rule}
            index={index}
            openPopupHandler={openPopupHandler}
            parentModelPath={parentModelPath}
            configError={configError}
          />
        ))}
      </TableVariant1>
      {isPopupOpened && (
        <RecommendationRuleEditPopup
          isOpened={isPopupOpened}
          closeHandler={() => setIsPopupOpened(false)}
          services={servicesList}
          ruleIndex={toOpenIndex}
          rules={recommendationRules}
          formChangedCallback={() => setAnyDirty(true)}
          dictionaryMap={dictionaryMap}
          parentModelPath={parentModelPath}
          configError={configError}
        />
      )}
    </>
  );
}

function TableHeader() {
  const { translate } = useLocales();

  return (
    <TableRow>
      <TableHeaderCell sx={{ width: '30%' }}>{translate('entities.recommendations.rule.serviceItemName')}</TableHeaderCell>
      <TableHeaderCell sx={{ width: '20%' }}>{translate('entities.recommendations.rule.categoryName')}</TableHeaderCell>
      <TableHeaderCell sx={{ width: '25%' }}>{translate('entities.recommendations.rule.rule')}</TableHeaderCell>
      <TableHeaderCell sx={{ width: '25%' }}>{translate('entities.recommendations.rule.quantityRule')}</TableHeaderCell>
    </TableRow>
  );
}
