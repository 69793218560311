import NavigateFrontendUtils from 'utils/NavigateFrontend';
import data from './mock.json';
import IEntityApiService, { METHODS } from './IEntityApiService';
import { BACKEND_ROOT } from 'configs/apis';
import { PATH_BACKEND } from 'configs/routes/pathsBackend';

export default class EntityApiServiceMockImpl implements IEntityApiService {
  private service = window.fetch.bind(window);

  private defaultHeaders = { 'Content-Type': 'application/json' };

  private defaultPagination: Pagination = {
    pageNumber: 1,
    pageSize: 20,
    totalPages: 39,
  };

  private data: any = data;

  public get = <T>(url: string): Promise<T> => {
    console.log(url, 'GET');
    const [path, id] = this.parseUrl(url);
    const entityList: any[] = this.getEntities(path);
    const entity = entityList.find((i) => i.id === id);
    return Promise.resolve(entity);
  };

  getRawWithCredentials(url: string): Promise<Response> {
    console.log(url, 'GET');
    const [path, id] = this.parseUrl(url);
    const entityList: any[] = this.getEntities(path);
    const entity = entityList.find((i) => i.id === id);
    return Promise.resolve(entity);
  }
  public getList = <T>(url: string): Promise<EntityListData<T>> => {
    console.log(url, 'GET');
    const [location, params] = NavigateFrontendUtils.getPathnameAndSearchObjectFromLocation(url);
    const [path, id] = this.parseUrl(location);
    const isId = /\d/.test(id);
    let entityList = this.getEntities(isId ? path : location);
    const filterLogic = this.getFilterMap[isId ? path : location];
    if (typeof filterLogic === 'function' && params) entityList = filterLogic(params, entityList);
    return Promise.resolve({ data: entityList, pagination: this.defaultPagination });
  };

  public getListWithIdAndNamesOnly = this.getList;

  public getListWithCredentials = <T>(...params: Parameters<typeof this.getList>): Promise<EntityListData<T>> => this.getList<T>(...params);

  public getWithCredentials = this.get;

  public put = (url: string, body: any = {}, options?: RequestInit): Promise<void> => {
    console.log(url, 'PUT', body);
    const payload = body;
    return this.service(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
      ...options,
    }).then(this.parseMixedResponse);
  };

  public putWithCredentials = (url: string, body: any = {}): Promise<void> => {
    return this.put(url, body, { credentials: 'include' });
  };

  public putWithResponse = <T>(url: string, body: any = {}): Promise<T> => {
    console.log(url, body);
    return Promise.resolve([]) as any;
  };

  public delete = (url: string, options?: RequestInit): Promise<void> => {
    console.log(url, 'DELETE');
    return this.service(url, {
      method: 'DELETE',
      ...options,
    }).then(this.parseMixedResponse);
  };

  public deleteWithCredentials = (url: string): Promise<void> => {
    return this.delete(url, { credentials: 'include' });
  };

  public post = <T>(url: string, body: any = {}, withResponse?: boolean, method: METHODS = 'POST'): Promise<T> => {
    console.log(url, method, body);
    const payload = body;
    return this.service(url, {
      method,
      body: JSON.stringify(payload, this.jsonParser),
      headers: this.defaultHeaders,
    })
      .then(this.parseJsonResponse)
      .then(this.parseResponsePayload);
  };

  public postWithCredentials = <T>(url: string, body: any = {}, withResponse?: boolean, method: METHODS = 'POST'): Promise<T> => {
    console.log(url, method, body);
    return Promise.resolve([]) as any;
  };

  public patch = <T = void>(url: string, body: any = {}, withResponse?: boolean, method: METHODS = 'PATCH'): Promise<T> => {
    console.log(url, method, body);
    const payload = body;
    return this.service(url, {
      method,
      body: JSON.stringify(payload, this.jsonParser),
      headers: this.defaultHeaders,
    })
      .then(this.parseJsonResponse)
      .then(this.parseResponsePayload);
  };

  public patchWithCredentials = <T = void>(url: string, body: any = {}, withResponse?: boolean, method: METHODS = 'PATCH'): Promise<T> => {
    console.log(url, method, body);
    return Promise.resolve([]) as any;
  };

  public postMultipart = <T>(url: string, body: FormData): Promise<T> => {
    console.log(url, 'POST', body);
    return this.service(url, {
      method: 'POST',
      body,
    })
      .then(this.parseJsonResponse)
      .then(this.parseResponsePayload);
  };

  // temp: Удаляет все записи id, т.к. монга ругается
  private jsonParser = (key: string, value: string) => {
    if (value === '') return null;
    return value;
  };

  private parseJsonResponse = async (res: any) => {
    if (res.ok) return res.json();
    let resJson: any = {};
    try {
      resJson = await res.json();
    } catch (e) {
      resJson.error = { message: res.status };
    }
    console.error(JSON.stringify(resJson));
    throw new Error(`${resJson.error?.message}` || 'Fetch failed');
  };

  private parseMixedResponse = async (res: any) => {
    if (res.ok) return;
    const resJson: any = await res.json();
    console.error(JSON.stringify(resJson));
    throw new Error(`${resJson.error?.message}` || 'Fetch failed');
  };

  private parseResponsePayloadWithPaging = ({ data, paging }: any): EntityListData<any> => ({ data: data, pagination: paging });

  private parseResponsePayload = ({ data }: any) => data;

  private apiHost = BACKEND_ROOT;

  private apiHostOld = process.env.REACT_APP_BACKEND_URL;

  private getPathMap = {
    [`${this.apiHost}/cities`]: 'city',
    [`${this.apiHost}/users`]: 'user',
    [`${this.apiHostOld}/service-companies`]: 'serviceProvider',
    [`${this.apiHost}/enterprises`]: 'enterprise',
    [`${this.apiHost}/legal-entities`]: 'legalEntity',
    [`${this.apiHost}/departments`]: 'department',
    [`${this.apiHostOld}/brands`]: 'brand',
    [`${this.apiHost}/service-category-groups`]: 'serviceCategoryGroup',
    [`${this.apiHost}/svc-category/native`]: 'serviceCategoryNative',
    [`${this.apiHost}/svc-category/native`]: 'serviceCategoryNative',
    [`${this.apiHost}/services/native`]: 'serviceNative',
    [`${this.apiHost}/services`]: 'serviceNative',
    [`${this.apiHost}/services/category`]: 'serviceNative',
    [`${this.apiHost}/orders?size=999 `]: 'order',
    [PATH_BACKEND.call.root]: 'call',
    [PATH_BACKEND.order.root]: 'order',
    [`${this.apiHostOld}/reports`]: 'report',
  };

  private getFilterMap: { [key: string]: (params: LocationSearchObject, list: any[]) => any[] } = {
    //  [`${this.apiHost}/legal-entities`]: (params, list) => {
    //    return list.filter((i) => i.enterprise.id === params.enterpriseId);
    //  },
    //  [`${this.apiHost}/departments`]: (params, list) => {
    //    return list.filter((i) => i.enterprise.id === params.enterpriseId);
    //  },
  };

  private getEntities = (location: string) => {
    return this.data[this.getPathMap[location]];
  };

  private parseUrl = (url: string): [string, string] => {
    const idIndex = url.lastIndexOf('/');
    const path = url.slice(0, idIndex);
    const id = url.slice(idIndex + 1);
    return [path, id];
  };
}

export const webServiceMockImpl = new EntityApiServiceMockImpl();
