import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { GetPriceAnyOrderRequestListDTO, PriceAnyOrderResponseDTOContainer, PriceAnyResponseDTO } from 'typings/dto/price';
import IPriceAnyActionsService from './IPriceAnyActionsService';

export default class PriceAnyActionsServiceImpl
  extends ModelActionsService<Price, EntityDTOResponse, PriceAnyResponseDTO>
  implements IPriceAnyActionsService
{
  /** @throws `BackendResponseError` */
  public getPricesForOrder = ({
    brandId,
    regionId,
    orderId,
  }: GetPriceAnyOrderRequestListDTO): Promise<{ customServicesCommission: number; prices: Price[] }> => {
    let url = `${PATH_BACKEND.order.root}/services?brandId=${brandId}&regionId=${regionId}`;
    if (orderId) {
      url += `&orderId=${orderId}`;
    }
    return this.entityApiService.getWithCredentials<PriceAnyOrderResponseDTOContainer>(url).then((res) => ({
      customServicesCommission: res.customServicesCommission,
      prices: res.services.map(this.modelMapper.responseDTOToModel),
    }));
  };

  /** @throws `BackendResponseError` */
  public getPricesForOrderSession = ({
    brandId,
    regionId,
    orderId,
  }: GetPriceAnyOrderRequestListDTO): Promise<{ customServicesCommission: number; prices: Price[] }> => {
    let url = `${PATH_BACKEND.orderSession.root}/services?brandId=${brandId}&regionId=${regionId}`;
    if (orderId) {
      url += `&orderId=${orderId}`;
    }
    return this.entityApiService.getWithCredentials<PriceAnyOrderResponseDTOContainer>(url).then((res) => ({
      customServicesCommission: res.customServicesCommission,
      prices: res.services.map(this.modelMapper.responseDTOToModel),
    }));
  };

  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return super.getDefaultSortFilter(filter, 'createdAt');
  };
}
