export default function Badge() {
  return {
    MuiBadge: {
      styleOverrides: {
        dot: {
          width: 12,
          height: 12,
          borderRadius: '50%',
          border: '2px solid white',
        },
      },
    },
  };
}
