import FormProvider from 'components/ui/forms/FormProvider';
import TextFieldMemorized from 'components/ui/forms/TextFieldMemorized';
import { Box, Stack, SxProps, TextField, Typography } from '@mui/material';
import AmountInputMemorized from 'components/ui/forms/AmountInputMemorized';
import { INPUT_FULL_WIDTH_CLASS_NAME } from 'configs/layout';
import Spoiler from 'components/ui/Spoiler';
import CheckboxMemorized from 'components/ui/forms/CheckboxMemorized';
import TimeInputMemorized from 'components/ui/forms/dateTime/TimeInputMemorized';
import ConfirmButtonGroup from 'components/ui/forms/ConfirmButtonGroup';
import React from 'react';
import useStableNavigate from 'hooks/useStableNavigate';
import useLocales from 'hooks/useLocales';
import { Theme } from '@mui/material/styles';
import { FormikContextType } from 'formik';
import useDI from 'hooks/useDI';

type Props = {
  isCustom: boolean;
  formState: FormikContextType<any>;
  sx?: SxProps<Theme>;
  currencySymbol: string;
  amIOwnerOfOrder: boolean;
  isAssignPO: boolean;
  backPath?: string;
  cancelHandler?: () => void;
  isServiceSelected: boolean;
  isWorkEstimationCustom: boolean;
  isSubmitting: boolean;
  quantity: number;
  priceClientValue: number;
  technicianCommission: string;
  customServiceBlurHandler: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => void;
};
export default function ServiceOrderedAddCustomBlock({
  backPath,
  cancelHandler,
  isCustom,
  formState,
  sx,
  currencySymbol,
  amIOwnerOfOrder,
  isAssignPO,
  isServiceSelected,
  isWorkEstimationCustom,
  isSubmitting,
  quantity,
  priceClientValue,
  technicianCommission,
  customServiceBlurHandler,
}: Props) {
  const navigate = useStableNavigate();
  const { translate } = useLocales();
  const { statefulUtils, services } = useDI();

  return (
    <>
      {isCustom && (
        <FormProvider formState={formState} fullWidth containerSx={{ flex: 1, ...sx }}>
          <TextFieldMemorized
            formState={formState}
            fieldName="name"
            label={translate('fields.name_title')}
            fullWidth
            onBlur={customServiceBlurHandler}
          />
          <Stack spacing={2}>
            {isServiceSelected && (
              <Box>
                <Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' } }}>
                  <TextFieldMemorized
                    disabled={!isCustom}
                    type="number"
                    label={`${translate('entities.price.forClient')}, ${currencySymbol}`}
                    fieldName="priceClientValue"
                    formState={formState}
                    fullWidth
                  />
                  <AmountInputMemorized label={translate('fields.quantity')} fieldName="quantity" formState={formState} fullWidth />
                  <TextField
                    variant="outlined"
                    label={`${translate('entities.service.clientPriceTotal')}, ${currencySymbol}`}
                    value={statefulUtils.money.toString(
                      priceClientValue * quantity,
                      services.language.getCurrentLocale().localeCurrency,
                      false
                    )}
                    disabled
                    className={INPUT_FULL_WIDTH_CLASS_NAME}
                  />
                </Box>
                <Typography color="text.secondary" variant="caption">
                  {isAssignPO && amIOwnerOfOrder && translate('entities.price.forPO') + `: ${technicianCommission}`}
                  {!(isAssignPO && amIOwnerOfOrder) && translate('entities.price.forTechnician') + `: ${technicianCommission}`}
                </Typography>
              </Box>
            )}

            <Spoiler
              key="custom_spoiler"
              defaultState={true}
              title={translate('entities.service.jobDetails')}
              variant="subtitle1"
              color="text.secondary"
            >
              <Stack spacing={2}>
                <TextFieldMemorized
                  fullWidth
                  multiline
                  minRows={5}
                  label={translate('pages.orderServiceEdit.includedHelpText')}
                  fieldName="included"
                  formState={formState}
                />
                <TextFieldMemorized
                  fullWidth
                  multiline
                  minRows={5}
                  label={translate('pages.orderServiceEdit.notIncludedHelpText')}
                  fieldName="notIncluded"
                  formState={formState}
                />
              </Stack>
            </Spoiler>

            {isServiceSelected && (
              <>
                <CheckboxMemorized
                  variant="switch"
                  formState={formState}
                  fieldName="isWorkEstimationCustom"
                  label={translate('pages.serviceEdit.setEstimateWorkTime')}
                />
                {isWorkEstimationCustom && <TimeInputMemorized formState={formState} fieldName="workEstimateMin" />}
              </>
            )}
          </Stack>
          {(backPath || cancelHandler) && (
            <ConfirmButtonGroup
              isLoading={isSubmitting}
              cancelHandler={() => (backPath ? navigate(backPath) : cancelHandler ? cancelHandler() : undefined)}
            />
          )}
        </FormProvider>
      )}
    </>
  );
}
