import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import useDI from 'hooks/useDI';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { useEffect, useState } from 'react';
import IPermissionService from 'services/permission/IPermissionService';
import { useSelector } from 'storage';
import { TENANT_TYPE } from 'typings/subEntities/tenant.enum';
import FunctionUtils from 'utils/Function';
import ContractUtils from 'utils/models/ContractUtils';
import EnterpriseUtils from 'utils/models/EnterpriseUtils';

/** Получение сущности с конфигами */
export default function useServiceOrderedPermissionConfigRequest(enterpriseId: string) {
  const { storageActions } = useDI();
  const { currentUser } = useSelector((state) => state.auth);
  const snackbarErrorHandler = useSnackbarErrorHandler();
  const [permissionConfig, setPermissionConfig] = useState<null | IPermissionService.PermissionConfigs>(null);
  const [isLoading, setIsLoading] = useState(true);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    let request: Promise<any>;
    switch (currentUser.type) {
      case TENANT_TYPE.platformOperator:
      case TENANT_TYPE.enterprise: {
        request = storageActions.models.enterprise
          .requestById(currentUser.tenant.id)
          .then((enterprise) => setPermissionConfig(EnterpriseUtils.getPermissionConfigs(enterprise, currentUser)));
        break;
      }
      case TENANT_TYPE.serviceProvider: {
        request = storageActions.models.contractSP
          .getOrderContract({ tenantId: enterpriseId, serviceProviderId: currentUser.tenant.id })
          .then((contract) => setPermissionConfig(ContractUtils.getPermissionConfigs(contract)));
        break;
      }
      case TENANT_TYPE.superadmin: {
        request = Promise.resolve();
        break;
      }
      default: {
        request = Promise.resolve();
        FunctionUtils.exhaustiveCheck(currentUser);
      }
    }

    request
      .catch((error) => snackbarErrorHandler({ error, source: 'User permission config request' }))
      .finally(() => isMountedRef.current && setIsLoading(false));
  }, []);

  return {
    isLoading,
    permissionConfig,
  };
}
