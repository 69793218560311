import ModelActionsService from '../ModelActionsService';
import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import { OrderCustomFieldRequestDTO } from 'typings/dto/order';
import { CustomFieldResponseDTO } from 'typings/dto/customField';
import ICustomFieldActionService from './ICustomFieldActionService';

export default class CustomFieldActionsServiceImpl
  extends ModelActionsService<CustomField, OrderCustomFieldRequestDTO, CustomFieldResponseDTO>
  implements ICustomFieldActionService
{
  /** @throws `BackendResponseError` */
  public getValuesFromOrderForFilter = (): Promise<void> => {
    const url = PATH_BACKEND.customField.orderFilter;
    return this.getAllForFilter(undefined, undefined, url);
  };
}
