import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import useLocales from 'hooks/useLocales';
import React, { useState } from 'react';
import ConfirmButtonGroup from 'components/ui/forms/ConfirmButtonGroup';
import { PopupTransition } from './PopupContent';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import useSnackbarSuccessHandler from 'hooks/snackbar/useSnackbarSuccessHandler';

// Размер соответствует small из соседнего PopupContent
type Props = {
  closeHandler: VoidFunction;
  /** Catch не нужен (используется snackbarErrorHandler), closeHandler не нужен, будет выполнен в конце, если не установлено autoClose вручную */
  actionHandler: () => void | Promise<void>;
  isOpened: boolean;
  title?: string;
  children?: React.ReactNode;
  confirmText?: string;
  /** @deprecated @todo delete */
  color?: any;
  /** Будет отображено через всплывающие сообщения в случае успеха */
  successMessage?: string;
  /** @default true */
  autoClose?: boolean;
};

function PopupAction(props: Props) {
  const { translate } = useLocales();
  const isMountedRef = useIsMountedRef();
  const snackbarErrorHandler = useSnackbarErrorHandler();
  const snackbarSuccessHandler = useSnackbarSuccessHandler();
  const [isLoading, setIsLoading] = useState(false);
  const {
    closeHandler,
    actionHandler = () => null,
    isOpened,
    title = translate('messages.confirmAction'),
    confirmText,
    children,
    autoClose = true,
    successMessage,
  } = props;

  const actionHandlerWithClose = async () => {
    try {
      setIsLoading(true);
      await actionHandler();
      if (successMessage) {
        snackbarSuccessHandler({ message: successMessage });
      }
      if (isMountedRef.current) {
        setIsLoading(false);
        if (autoClose) closeHandler();
      }
    } catch (error) {
      snackbarErrorHandler({ error, callback: () => setIsLoading(false) });
    }
  };

  return (
    <Dialog
      TransitionComponent={PopupTransition}
      open={isOpened}
      onClose={closeHandler}
      scroll="paper"
      PaperProps={{ sx: { width: { xs: '100%', md: '400px' }, maxWidth: '400px', mx: 2 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      {children && (
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <ConfirmButtonGroup
          sx={{ m: 0 }}
          cancelHandler={closeHandler}
          submitHandler={actionHandlerWithClose}
          submitText={confirmText || translate('buttons.confirm')}
          isLoading={isLoading}
          fullWidth
        />
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(PopupAction, (prevProps, nextProps) => prevProps.isOpened === nextProps.isOpened);
