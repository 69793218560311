import React, { useState, useEffect } from 'react';
import { FormikContextType } from 'formik';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import LoaderComponent from 'components/ui/loadingIndicators/LoaderComponent';

function DynamicFieldRadioGroupEditState({
                                           field,
                                           formState,
                                           dataSourceOptions,
                                           areDataSourceOptionsLoading,
                                           showLabel
                                         }: {
  field: DynamicField.DynamicFieldSelectField;
  formState: FormikContextType<any>;
  /** Если для селекта требуется передать варианты из вне (например, кастомные филды ордера получают данные из datasource, но добавляют в варианты ещё "выбрать всё") */
  dataSourceOptions?: any[];
  areDataSourceOptionsLoading?: boolean;
  showLabel?: boolean;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([] as EntityWithName[]);

  useEffect(() => {
    // Если переданы из вне
    if (typeof areDataSourceOptionsLoading === 'boolean' && dataSourceOptions !== undefined) {
      setIsLoading(areDataSourceOptionsLoading);
      setOptions(dataSourceOptions);
      return;
    }

    const { options } = field.dataSource;
    setOptions(options);
    setIsLoading(false);
  }, [areDataSourceOptionsLoading]);

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <>
      {showLabel && <Typography variant="subtitle2">{field.name}</Typography>}
      <RadioGroup
        value={formState.values[field.technicalName] ? formState.values[field.technicalName].id : ''}
        onChange={(event) => {
          formState.setFieldValue(field.technicalName, options.find((c) => c.id === event.target.value) || null);
        }}
      >
        {options.map((c) => (
          <FormControlLabel key={c.id} value={c.id} label={c.name} control={<Radio />} />
        ))}
      </RadioGroup>
    </>
  );
}

export default React.memo(DynamicFieldRadioGroupEditState, (prevProps, nextProps) => {
  const prevName = prevProps.field.technicalName;
  const nextName = nextProps.field.technicalName;
  return (
    prevProps.formState.values[prevName] === nextProps.formState.values[nextName] &&
    prevProps.formState.touched[prevName] === nextProps.formState.touched[nextName] &&
    prevProps.formState.errors[prevName] === nextProps.formState.errors[nextName]
  );
});
