import { CreatePricelistPlatformOperatorRequestDTO, PricelistResponseDTO } from 'typings/dto/pricelist';
import Mapper from './Mapper';

export default class PricelistPlatformOperatorMapper extends Mapper<
  Pricelist.PlatformOperator,
  PricelistResponseDTO.PlatformOperator,
  CreatePricelistPlatformOperatorRequestDTO
> {
  constructor() {
    super(
      {
        platformOperator: 'platformOperator',
        name: 'name',
        settings: 'settings',
        technicalName: 'technicalName',
        currency: 'currency',
      },
      null
    );
  }
}
