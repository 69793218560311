import { CreateWidgetMatchSettingRequestDTO, WidgetMatchSettingResponseDTO } from 'typings/dto/widgetMatchSetting';
import Mapper from './Mapper';

export default class WidgetMatchSettingMapper extends Mapper<
  WidgetMatchSetting,
  WidgetMatchSettingResponseDTO,
  CreateWidgetMatchSettingRequestDTO
> {
  constructor() {
    super({}, null);
  }
}
