import { styled } from '@mui/material/styles';
import Logo from '../features/Logo';
import { Button, Container } from '@mui/material';
import useLocales from 'hooks/useLocales';
import RoutingService from 'services/routing/RoutingService';
import useStableNavigate from 'hooks/useStableNavigate';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

interface IProps {
  children?: React.ReactNode;
  showLoginButton?: boolean;
}

// в этом лейауте нельзя использовать outlet т.к. сломается auth guard!
export default function LogoOnlyLayout({ children, showLoginButton }: IProps) {
  const { translate } = useLocales();
  const navigate = useStableNavigate();

  return (
    <>
      <HeaderStyle>
        <Logo />
        {showLoginButton && (
          <Button onClick={() => navigate(RoutingService.auth.getLoginPath())} variant="text">
            {translate('pages.login.title')}
          </Button>
        )}
      </HeaderStyle>

      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          alignItems: 'stretch',
          justifyContent: 'center',
          p: 3,
        }}
      >
        {children}
      </Container>
    </>
  );
}
