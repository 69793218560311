import Mapper from './Mapper';
import DIContainer from 'services/DIContainer';
import { RecommendationConfigRequestDTO, RecommendationConfigResponseDTO } from 'typings/dto/recommendationsConfig';

export default class RecommendationsConfigMapper extends Mapper<
  RecommendationsConfig,
  RecommendationConfigResponseDTO,
  RecommendationConfigRequestDTO
> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        hash: 'hash',
        version: 'version',
        tenantId: 'tenantId',
        updatedBy: 'updatedBy',
        recommendationsConfig: null,
      },
      null
    );
  }

  public responseDTOToModel(dto: RecommendationConfigResponseDTO): RecommendationsConfig {
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      rules: dto.recommendationsConfig.recommendations,
      predefined: dto.recommendationsConfig.predefined,
      version: dto.version,
      tenantId: dto.tenantId,
      updatedBy: dto.updatedBy,
    };
  }

  public modelToRequestDTO(model: RecommendationsConfig): RecommendationConfigResponseDTO {
    return {
      id: model.id,
      createdAt: model.createdAt.toISOString(),
      updatedAt: model.updatedAt.toISOString(),
      recommendationsConfig: {
        recommendations: model.rules,
        predefined: model.predefined,
      },
      version: model.version,
      tenantId: model.tenantId,
      updatedBy: model.updatedBy,
      hash: model.hash || '',
    };
  }
}
