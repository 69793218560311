import { CloseIcon } from 'components/ui/icons';
import useLocales from 'hooks/useLocales';
import IconButtonStyled from './IconButtonStyled';

export default function CloseButton({ title: titleRaw, ...rest }: React.ComponentProps<typeof IconButtonStyled>) {
  const { translate } = useLocales();
  const title = titleRaw ?? translate('buttons.close');

  return (
    <IconButtonStyled {...rest} title={title}>
      <CloseIcon />
    </IconButtonStyled>
  );
}
