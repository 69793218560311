import MODEL_NAME from 'typings/models/_model.enum';
import defaultState from 'storage/defaultState';
import SliceFactory from './SliceFactory';
import ServiceOrderedUtils from 'utils/models/ServiceOrderedUtils';

const initialState: ServiceOrderedState = {
  ...defaultState,
  serviceOrdered: null,
  serviceOrderedList: [],
  serviceOrderedAll: [],
  serviceOrderedAllAreLoading: false,
  serviceOrderedIsLoading: false,
  arePaid: false,
  totalPriceClient: 0,
  unpaidTotalPriceClient: 0,
  paidTotalPriceClient: 0,
  totalPriceProvider: 0,
  totalProviderSaleBonus: 0,
  totalPricePO: 0,
  totalPOBonus: 0,
  totalDiscount: 0,
};

const reducers = SliceFactory.createModelReducers<MODEL_NAME.serviceOrdered, ServiceOrdered, ServiceOrderedState>(
  MODEL_NAME.serviceOrdered,
  initialState
);

reducers.setList = function (state, action) {
  const { data, pagination, requestProps } = action.payload;
  state.isListOutdated = false;
  state.error = null;
  state.pagination = pagination;
  state.lastListRequestFilter = requestProps?.lastListRequestFilter;
  state.listOfEntities = data;
  state.serviceOrderedList = data;

  const countedPrices = ServiceOrderedUtils.count(data);
  state.totalPriceClient = countedPrices.totalPriceClient;
  state.unpaidTotalPriceClient = countedPrices.unpaidTotalPriceClient;
  state.paidTotalPriceClient = countedPrices.paidTotalPriceClient;
  state.arePaid = !countedPrices.unpaidTotalPriceClient;
  state.totalPriceProvider = countedPrices.totalPriceProvider;
  state.totalProviderSaleBonus = countedPrices.totalProviderSaleBonus;
  state.totalPricePO = countedPrices.totalPricePO;
  state.totalPOBonus = countedPrices.totalPOBonus;
  state.totalDiscount = countedPrices.totalDiscount;
};

const serviceOrderedSlice = SliceFactory.createModelSlice<MODEL_NAME.serviceOrdered, ServiceOrdered, ServiceOrderedState>(
  MODEL_NAME.serviceOrdered,
  initialState,
  reducers
);

export default serviceOrderedSlice;
