export enum INVOICE_CUSTOMER_TYPE {
  enterprise = 'ENTERPRISE',
  platformOperator = 'PLATFORM_OPERATOR',
}

export enum INVOICE_PROVIDER_TYPE {
  serviceProvider = 'SERVICE_PROVIDER',
  internalTechnician = 'INTERNAL_TECHNICIAN',
  platformOperator = 'PLATFORM_OPERATOR',
}

export enum INVOICE_STATUS {
  draft = 'DRAFT',
  paymentPending = 'PAYMENT_PENDING',
  paid = 'PAID',
}

export enum INVOICE_TRANSACTION_TYPE {
  addition = 'ADDITION',
  deduction = 'DEDUCTION',
}

export enum INVOICE_EXPORT_TYPE {
  CSV = 'CSV',
  PDF = 'PDF',
}
