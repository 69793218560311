import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/** Меняет дефолтное поведение браузера - при изменении адреса всегда перематывает скролл на верх страницы */
export default function ScrollToTop() {
  const { pathname, state } = useLocation();
  const scrollToTop = (state as any)?.scrollToTop;

  useEffect(() => {
    if (scrollToTop !== false) window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
