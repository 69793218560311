import { PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import IPriceServiceProviderActionsService from './IPriceServiceProviderActionsService';
import { GetPriceAnyRequestListDTO, PriceAnyRequestDTO, PriceAnyResponseDTO, UpdatePriceRequestDTO } from 'typings/dto/price';

export default class PriceServiceProviderActionsServiceImpl
  extends ModelActionsService<Price.SP, PriceAnyRequestDTO, PriceAnyResponseDTO.SP>
  implements IPriceServiceProviderActionsService
{
  /** @throws `BackendResponseError` */
  public async getList(dto: GetPriceAnyRequestListDTO, filter?: LocationSearchObject): Promise<void> {
    const path = this.getModelApiRootPath(dto) + '/' + dto.pricelistId + '/' + PATH_BACKEND_PART.price.priceItem;
    super.getList(dto, filter, path);
  }

  /** @throws `BackendResponseError` */
  public updateServicePrice(dto: UpdatePriceRequestDTO.SP): Promise<void> {
    const path = this.getModelApiRootPath(dto) + '/' + dto.pricelistId + '/' + PATH_BACKEND_PART.price.priceItem;
    return this.entityApiService.patchWithCredentials<void>(path, dto);
  }

  /** @throws `BackendResponseError` */
  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return super.getDefaultSortFilter(filter, 'createdAt');
  };
}
