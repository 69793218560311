import IEntityApiService from 'services/entityApi/IEntityApiService';
import CurrencyMapper from 'utils/mappers/subEntities/CurrencyMapper';

/** @deprecated @todo delete? */
export default class CurrencyApiService {
  private webService: IEntityApiService;

  private mapper: CurrencyMapper;

  constructor(webService: IEntityApiService, mapper: CurrencyMapper) {
    this.webService = webService;
    this.mapper = mapper;
  }

  public get = (url: string): Promise<Currency[]> => {
    return this.webService.getListWithCredentials<any>(url).then((res) => res.data.map(this.mapper.responseDTOToModel));
  };
}
