import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import IModelActionsService from '../IModelActionsService';
import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import { batch as reduxBatch } from 'react-redux';
import NavigateBackendUtils from 'utils/NavigateBackend';

export default class AvailableTeamResourcesActionsServiceImpl
  extends ModelActionsService<AvailableTeamResources, AvailableTeamResources, ModelDTOResponse>
  implements IModelActionsService<AvailableTeamResources, AvailableTeamResources, ModelDTOResponse> {
  protected getModelApiRootPath = (dto: { orderId: string }) => {
    return PATH_BACKEND.user.root + '/' + PATH_BACKEND_PART.user.order + '/' + dto.orderId + '/' + super.getModelApiRootPath(dto);
  }

  public getAvailableTimeResources = async (orderId: string, requiredHours?: number | string) => {
    this.storageDispatch(this.modelStorageActions.startLoading())

    let finalPath: string;
    if (requiredHours) {
      finalPath = NavigateBackendUtils.addParamsToExistedUrl(this.getModelApiRootPath({ orderId }), { requiredHours })
    } else {
      finalPath = this.getModelApiRootPath({ orderId })
    }

    const response: AvailableTeamResources = await this.entityApiService.getWithCredentials(finalPath)
    await reduxBatch(async () => {
      this.storageDispatch(this.modelStorageActions.setOne(response))
      this.storageDispatch(this.modelStorageActions.stopLoading())
    })
  }
}
