import FrontendRoutesBase from './FrontendRoutesBase';
import { PATH_PARAM_DASHBOARD, PAGE_TYPE, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';

export default class SuperadminRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public root = this.getDBPath(PATH_PARAM_DASHBOARD.superadmin.root);

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.superadmin> = (_p, permissions, translate) => {
    return [{ name: translate('menus.main.superadmin'), href: this.getListPath() }];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.superadmin, 'superadminName'> = (p, pm, t, n) => {
    const breadcrumbs = this.getInfoBc(p, pm, t);
    breadcrumbs.push({ name: n.superadminName, href: this.getInfoPath(p) });
    return breadcrumbs;
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.superadmin.root, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.superadmin> = (params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.superadmin.root, params.userId, PAGE_TYPE.info);

  getAddBc = this.getInstanceBc;
  getAddPath = () => this.getDBPath(PATH_PARAM_DASHBOARD.superadmin.root, PAGE_TYPE.new, PAGE_TYPE.add);
}
