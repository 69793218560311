import IHttpService from 'services/http/IHttpService';
import ILanguageService from 'services/language/ILanguageService';
import GeoServiceGoogleImpl from './GeoServiceGoogleImpl';
import IGeoService from './IGeoService';

export default class GeoServiceYandexV1Impl implements IGeoService {
  private readonly API_KEY = process.env.REACT_APP_ADDRESS_SERVICE_KEY || '';
  private readonly API_URL = 'https://geocode-maps.yandex.ru/1.x/?&format=json&';
  constructor(private httpService: IHttpService, private languageService: ILanguageService) {}

  /**
   * @param _accuracy не реализовано
   * @throws Error
   */
  public getGeoByAddress = async (address: string, region?: string | null, _accuracy?: number): Promise<IGeoService.Point | null> => {
    const url = this.getRequestAddress(address, region);
    const response: GeoServiceYandexImpl.AddressServiceResponseDTO = await this.httpService
      .fetch(url)
      .then(this.httpService.parseResponseJson);
    const result = this.getPreciseResult(response);
    return result && this.createExternalPoint(result.GeoObject.Point.pos);
  };

  /**
   * @param _accuracy не реализовано
   * @throws Error
   */
  public getAddressByGeo = async (geo: IGeoService.Point, _accuracy?: number): Promise<string | null> => {
    const url = this.getRequestAddress(geo);
    const response: GeoServiceYandexImpl.AddressServiceResponseDTO = await this.httpService
      .fetch(url)
      .then(this.httpService.parseResponseJson);
    const result = this.getPreciseResult(response);
    return result && result.GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted;
  };

  /** @throws Error */
  public formatAddress = async (address: string, region?: string | null): Promise<string | null> => {
    // Получение данных копирует getGeoByAddress
    const url = this.getRequestAddress(address, region);
    const response: GeoServiceYandexImpl.AddressServiceResponseDTO = await this.httpService
      .fetch(url)
      .then(this.httpService.parseResponseJson);
    const result = this.getPreciseResult(response);
    return result && result.GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted;
  };

  // Учитывается точность поиска, поверхностные не пропускает; TODO сюда тоже можно добавить accuracy
  /** @throws Error */
  public validateAddress = async (address: string, region?: string | null): Promise<boolean> => {
    const result = address ? await this.formatAddress(address, region) : false;
    return Boolean(result);
  };

  private getRequestAddress(address: string, region?: string | null): string;
  private getRequestAddress(geo: IGeoService.Point): string;
  private getRequestAddress(param1: string | IGeoService.Point, region?: string | null): string {
    let geocodeString: string;
    if (typeof param1 === 'string') {
      geocodeString = `geocode=${encodeURIComponent(this.getAddressWithRegion(param1, region))}`;
    } else {
      geocodeString = `geocode=${param1.lon},${param1.lat}`;
    }
    return `${this.API_URL}${geocodeString}&apikey=${this.API_KEY}&lang=${this.languageService.getCurrentLocale().localeFull}`;
  }

  public getCurrentGeoPosition = GeoServiceGoogleImpl.getCurrentGeoPosition;

  private getAddressWithRegion = (address: string, region?: string | null): string => {
    if (!region) {
      return address;
    }
    return address.toLocaleLowerCase().includes(region.toLocaleLowerCase()) ? address : region + ', ' + address;
  };

  /**
   * Ищет среди всех присланных результатов наиболее точные, не ниже определённого уровня
   * @param _accuracy Не реализовано!
   */
  private getPreciseResult = (
    dto: GeoServiceYandexImpl.AddressServiceResponseDTO,
    _accuracy?: number
  ): GeoServiceYandexImpl.AddressServiceResponseDTO['response']['GeoObjectCollection']['featureMember'][0] | null => {
    const variants = dto.response.GeoObjectCollection.featureMember;
    let preciseResult = variants.find((variant) => variant.GeoObject.metaDataProperty.GeocoderMetaData.precision === 'exact');
    if (!preciseResult)
      preciseResult = variants.find((variant) => variant.GeoObject.metaDataProperty.GeocoderMetaData.precision === 'number');
    if (!preciseResult) preciseResult = variants[0];

    return preciseResult || null;
  };

  /** @param coords строка, в которой через пробел идут долгота и широта */
  private createExternalPoint = (coords: string): IGeoService.Point => {
    const point = coords.split(' ').map(Number);
    return { lat: point[1], lon: point[0] };
  };
}

namespace GeoServiceYandexImpl {
  export type AddressServiceResponseDTO = {
    response: {
      GeoObjectCollection: {
        metaDataProperty: {
          GeocoderResponseMetaData: {
            request: string;
            found: string;
            results: string;
          };
        };
        featureMember: [
          {
            GeoObject: {
              metaDataProperty: {
                GeocoderMetaData: {
                  kind: string;
                  text: string;
                  precision: 'exact' | 'number' | 'near' | 'range' | 'street' | 'other';
                  Address: {
                    country_code: string;
                    postal_code: string;
                    formatted: string;
                    Components: [
                      {
                        kind: string;
                        name: string;
                      }
                    ];
                  };
                  AddressDetails: {
                    Country: {
                      AddressLine: string;
                      CountryNameCode: string;
                      CountryName: string;
                      AdministrativeArea: {
                        AdministrativeAreaName: string;
                        Locality: {
                          LocalityName: string;
                          Thoroughfare: {
                            ThoroughfareName: string;
                            Premise: {
                              PremiseNumber: string;
                              PostalCode: {
                                PostalCodeNumber: string;
                              };
                            };
                          };
                        };
                      };
                    };
                  };
                };
              };
              description: string;
              name: string;
              boundedBy: {
                Envelope: {
                  lowerCorner: string;
                  upperCorner: string;
                };
              };
              Point: {
                pos: string;
              };
            };
          }
        ];
      };
    };
  };

  // Хэндлить эту ошибку не требуется, т.к. она и так имеет поле message, которое будет обработано по дефолту
  export type AddressServiceResponseDTOError = {
    statusCode: number;
    error: string;
    message: string;
  };
}
