import { Box, Typography } from '@mui/material';
import Label from 'components/ui/info/Label';
import useDI from 'hooks/useDI';
import useLocales from 'hooks/useLocales';
import { PRICE_VALUE_TYPE } from '../../../../typings/models/price.enum';

type Props = {
  discountsValues: string[];
  totalClientValue: number;
  priceClientInitialValue: number;
  catalogPrice: PriceValue | null;
  productPrice: number;
};

export function ServiceOrderedValueWithDiscount({
  discountsValues,
  totalClientValue,
  priceClientInitialValue,
  catalogPrice,
  productPrice,
}: Props) {
  const { translate } = useLocales();
  const { services, statefulUtils } = useDI();

  if (!catalogPrice) {
    return null;
  }

  const getPriceWithoutDiscount = () => {
    switch (catalogPrice.type) {
      case PRICE_VALUE_TYPE.amount:
        return (
          <Typography variant="body2" color="text.secondary" sx={{ display: 'inline-block', mr: 1 }}>
            {statefulUtils.money.toString(priceClientInitialValue, services.language.getCurrentLocale().localeCurrency, true)}{' '}
            {translate('entities.service.priceForQuantity', { quantity: 1 })}
          </Typography>
        );
      case PRICE_VALUE_TYPE.category:
        return (
          <Typography variant="body2" color="text.secondary" sx={{ display: 'inline-block', mr: 1 }}>
            {`${catalogPrice.value} %`}{' '}
            {translate('entities.service.fromCustomFields', {
              customFieldsSum: statefulUtils.money.toString(productPrice, services.language.getCurrentLocale().localeCurrency, true),
            })}
          </Typography>
        );
      case PRICE_VALUE_TYPE.percent:
        return (
          <Typography variant="body2" color="text.secondary" sx={{ display: 'inline-block', mr: 1 }}>
            {`${catalogPrice.value} %`} {translate('entities.service.fromProductPrice')}
          </Typography>
        );
    }
  };

  return (
    <Box>
      {Boolean(discountsValues.length) ? (
        <>
          <Typography variant="body2" color="text.secondary" sx={{ display: 'inline-block', mr: 1 }}>
            <Box component="span" fontWeight="bold" sx={{ mr: 1 }}>
              {statefulUtils.money.toString(totalClientValue, services.language.getCurrentLocale().localeCurrency, true)}
            </Box>
            {catalogPrice.type === PRICE_VALUE_TYPE.amount ? (
              <>
                <Box component="span" sx={{ textDecoration: 'line-through' }}>
                  {statefulUtils.money.toString(priceClientInitialValue, services.language.getCurrentLocale().localeCurrency, true)}
                </Box>{' '}
                {translate('entities.service.priceForQuantity', { quantity: 1 })}
              </>
            ) : (
              <>
                <Box component="span" sx={{ textDecoration: 'line-through' }}>
                  {`${catalogPrice.value} %`}{' '}
                  {translate('entities.service.fromCustomFields', {
                    customFieldsSum: statefulUtils.money.toString(
                      priceClientInitialValue,
                      services.language.getCurrentLocale().localeCurrency,
                      true
                    ),
                  })}
                </Box>
              </>
            )}
          </Typography>

          {discountsValues.map((sale, index) => (
            <Label key={index} color="warning" sx={{ mr: 1 }}>
              {sale}
            </Label>
          ))}
        </>
      ) : (
        getPriceWithoutDiscount()
      )}
    </Box>
  );
}
