import LoaderComponent from 'components/ui/loadingIndicators/LoaderComponent';
import { Outlet } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSelector } from 'storage';
import ErrorComponent from 'components/features/ErrorComponent';
import useDI from 'hooks/useDI';
import useFirstRender from 'hooks/useFirstRender';
import NavigateFrontendUtils from 'utils/NavigateFrontend';
import NavigateBackendUtils from 'utils/NavigateBackend';

function RecommendationsConfigDataWrapper() {
  const { storageActions } = useDI();
  const { error, recommendationsConfigIsLoading, isOneOutdated } = useSelector((state) => state.recommendationsConfig);
  const isFirstRender = useFirstRender();
  const { currentUser, isLoading: isUserLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isOneOutdated) {
      storageActions.models.recommendationsConfig.getRecommendationsConfig();
    }
  }, [isOneOutdated]);

  useEffect(() => {
    if (currentUser) {
      storageActions.models.dictionary.getAllDictionariesForRecommendations(currentUser.tenant.id);
      storageActions.models.service.getList(
        { tenantId: currentUser.tenant.id },
        { size: NavigateFrontendUtils.createLocationSearchParam(NavigateBackendUtils.MAX_PAGE_SIZE) }
      );
    }
  }, [isUserLoading]);

  useEffect(() => {
    const promotionConfigActionService = storageActions.requestScope.recommendationsConfig();

    return () => {
      promotionConfigActionService.cancelRequests();
      promotionConfigActionService.resetData();
    };
  }, []);

  if (error) return <ErrorComponent error={error} />;
  else if (recommendationsConfigIsLoading || isFirstRender) return <LoaderComponent />;
  else return <Outlet />;
}

export default React.memo(RecommendationsConfigDataWrapper, () => true);
