import { PATH_PARAM_DASHBOARD } from '../PATH_PARAM';

/** Набор утильных методов */
export default abstract class FrontendRoutesBase {
  /** Метод для dashboard путей */
  protected getDBPath = (...paths: (string | undefined)[]) => {
    return `/${PATH_PARAM_DASHBOARD.root}` + (paths ? `/${paths.join('/')}` : '');
  };

  /** Используется за пределами dashboard, в будущем нужно улучшить */
  protected getPath = (root: string, sublink: string) => {
    return `/${root}/${sublink}`;
  };

  /** Если надо заполнить ненужный метод пустышкой */
  protected getEmptyBreadcrumbs = () => [] as BreadcrumbData[];

  /** Утильный метод, для распространённой ситуации, когда надо взять существующие bc и подменить в них последнюю ссылку на новую */
  protected setLastBcLink = (bc: BreadcrumbData[], link: string) => {
    const lastBc = bc[bc.length - 1];
    if (lastBc) lastBc.href = link;
  };
}
