import {
  INPUT_FULL_WIDTH_CLASS_NAME,
  LARGE_INPUT_WIDTH,
  INPUT_LARGE_WIDTH_CLASS_NAME,
  MEDIUM_INPUT_WIDTH,
  SMALL_INPUT_WIDTH,
  INPUT_SMALL_WIDTH_CLASS_NAME,
} from 'configs/layout';
import { Theme } from '@mui/material/styles';

// [IMPLEMENTATION_DATA]
export default function TextField(theme: Theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: `${MEDIUM_INPUT_WIDTH}px`,
          // [`.${INPUT_MEDIUM_WIDTH_CLASS_NAME} &`]: `${MEDIUM_INPUT_WIDTH}px`, // TODO дебагер ругается, потом разберусь
          [`&.${INPUT_LARGE_WIDTH_CLASS_NAME}, .${INPUT_LARGE_WIDTH_CLASS_NAME} &`]: { width: `${LARGE_INPUT_WIDTH}px` }, // Элемент с классом это стандартный случай; элемент, который является прямым потомком нужного класса, это для всех обёрток TextField, таких как Autocomplete и пр.
          [`&.${INPUT_SMALL_WIDTH_CLASS_NAME}, .${INPUT_SMALL_WIDTH_CLASS_NAME} &`]: { width: `${SMALL_INPUT_WIDTH}px` },
          [`&.${INPUT_FULL_WIDTH_CLASS_NAME}, .${INPUT_FULL_WIDTH_CLASS_NAME} &`]: { width: '100%' },

          [theme.breakpoints.down('md')]: { width: '100% !important' }, // На мобиле всегда 100% во всех случаях
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '.inline-input &': { borderRadius: 0 },
        },
      },
    },
  };
}
