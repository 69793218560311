export enum USER_ROLE {
  orderManager = 'ORDER_MANAGER',
  orderSpManager = 'ORDER_SP_MANAGER',
  orderTechnician = 'ORDER_TECHNICIAN',
}

export enum ACCOUNT_TYPE {
  user = 'USER',
  guest = 'GUEST',
  publicApi = 'PUBLIC_API',
}

export enum USER_POSITION {
  admin = 'ADMIN',
  manager = 'MANAGER',
  technician = 'TECHNICIAN',
  superadmin = 'SUPER_ADMIN',
  supportSpecialist = 'SUPPORT_SPECIALIST',
}
