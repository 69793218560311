import { BACKEND_ROOT } from 'configs/apis';
import { PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import { CreateTechnicianTeamRequestDTO, TechnicianTeamResponseDTO } from 'typings/dto/technicianTeam';
import ModelActionsService from '../ModelActionsService';
import ITechnicianTeamActionsService from './ITechnicianTeamActionsService';

export default class TechnicianTeamActionsServiceImpl
  extends ModelActionsService<TechnicianTeam, CreateTechnicianTeamRequestDTO, TechnicianTeamResponseDTO>
  implements ITechnicianTeamActionsService
{
  public getMyTeamOfferedList = (orderId: string) => {
    const url = `${BACKEND_ROOT}/${PATH_BACKEND_PART.order.distribution}/order/${orderId}/${PATH_BACKEND_PART.order.offer}/accept/teams`;
    return this.modelApiService.getList(url, this.modelMapper).then((res) => res.data);
  };
}
