import { ReactNode, useRef } from 'react';
import { SnackbarProvider, SnackbarKey } from 'notistack';
// @mui
import { useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import IconButtonStyled from '../ui/buttons/IconButtonStyled';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { ErrorIcon } from 'components/ui/icons';

// ----------------------------------------------------------------------

function SnackbarStyles() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .SnackbarContent-root': {
            width: '100%',
            maxWidth: '400px',
            padding: theme.spacing(1),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.grey[isLight ? 0 : 800],
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.grey[isLight ? 900 : 0],
            '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo': {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.paper,
            },
            [theme.breakpoints.up('md')]: {
              minWidth: 240,
            },
          },
          '& .SnackbarItem-message': {
            padding: '0 !important',
            overflowX: 'auto',
          },
          '& .SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,
            '& svg': { width: 20, height: 20 },
          },
        },
      }}
    />
  );
}

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function NotistackProvider({ children }: Props) {
  const notistackRef = useRef<any>(null);

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        variant="success"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <CloseRoundedIcon color="info" sx={{ mr: 1 }} />,
          success: <CheckCircleRoundedIcon color="success" sx={{ mr: 1 }} />,
          warning: <WarningRoundedIcon color="warning" sx={{ mr: 1 }} />,
          error: <ErrorIcon color="error" sx={{ mr: 1 }} />,
        }}
        action={(key) => (
          <IconButtonStyled size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
            <CloseRoundedIcon />
          </IconButtonStyled>
        )}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}
