import useLocales from 'hooks/useLocales';
import { useParams } from 'react-router';
import { RoutingServiceDashboard } from 'services/routing/RoutingService';
import { PAGE_TYPE, PATH_PARAM_DASHBOARD } from 'services/routing/PATH_PARAM';
import { useMemo } from 'react';
import { useSelector } from 'storage';

export default function useRecommendationTabs() {
  const { recommendationsConfig } = useSelector((state) => state.recommendationsConfig);
  const { translate } = useLocales();
  const params = useParams();

  const tabs = useMemo(() => {
    const tabs: TabData[] = [
      {
        value: PAGE_TYPE.list,
        href: RoutingServiceDashboard.recommendationsConfig.getListPath(),
        label: translate(`pages.recommendationsConfig.tabs.promotions`),
      },
      {
        value: PATH_PARAM_DASHBOARD.promotionsConfig.variables,
        href: RoutingServiceDashboard.recommendationsConfig.variables.getEditPath(params),
        label: translate(`pages.recommendationsConfig.tabs.variables`),
      },
    ];
    return tabs;
  }, [recommendationsConfig?.updatedAt.getTime()]);

  return tabs;
}
