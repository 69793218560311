import { MODEL_PERMISSION } from 'configs/permissions/modelPermissions';
import { Navigate, useParams } from 'react-router';
import { PATH_PARAM_TOKEN } from 'services/routing/PATH_PARAM';
import RoutingService from 'services/routing/RoutingService';
import { useSelector } from 'storage';

export default function OrderUniversalRedirectPage() {
  const { currentUser, permissions } = useSelector((state) => state.auth);
  const pathParams = useParams<typeof PATH_PARAM_TOKEN.order>();
  const orderId = pathParams.orderId || '';

  if (!currentUser) {
    return <Navigate to={RoutingService.client.order.getInfoPath({ orderId })} replace />;
  }
  if (permissions.can(MODEL_PERMISSION.CAN_READ_ORDER)) {
    return <Navigate to={RoutingService.dashboard.order.getInfoPathAbsolute(orderId)} replace />;
  }

  return <Navigate to={RoutingService.client.order.getInfoPath({ orderId })} replace />;
}
