import { Button, ButtonProps, Typography } from '@mui/material';

type Props = ButtonProps & {
  children: React.ReactNode;
};

export default function CaptionButton({ children, sx, ...rest }: Props) {
  // minWidth приходится сбрасывать, у Button есть
  return (
    <Button variant="text" sx={{ p: 0, minWidth: 'inherit', textAlign: 'left', ...sx }} {...rest}>
      <Typography variant="caption">{children}</Typography>
    </Button>
  );
}
