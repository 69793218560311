import { SettingsValueProps } from 'typings/template/settings';

export const DRAWER_WIDTH = 260;

export const DASHBOARD_HEADER_MOBILE = 64;
export const DASHBOARD_HEADER_DESKTOP = 0; // 92;
export const DASHBOARD_NAVBAR_WIDTH = 280;
export const DASHBOARD_NAVBAR_COLLAPSE_WIDTH = 88;

export const DASHBOARD_NAVBAR_ROOT_ITEM_HEIGHT = 48;
export const DASHBOARD_NAVBAR_SUB_ITEM_HEIGHT = 40;
export const DASHBOARD_NAVBAR_ICON_ITEM_SIZE = 22;

export const MAIN_HEADER_DESKTOP = 88;
export const MAIN_HEADER_MOBILE = 64;

export const EDIT_FORM_WIDTH = 600;
/** @deprecated */
export const CONTAINER_OFFSET = 0;
/** @deprecated */
export const CONTAINER_OFFSET_STYLE = 0;

export const MEDIUM_INPUT_WIDTH = 344;
export const SMALL_INPUT_WIDTH = 160;
export const LARGE_INPUT_WIDTH = EDIT_FORM_WIDTH;

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeStretch: false,
};

// Class names

/** Стилизованная таблица №1  */
export const TABLE_VARIANT_1_CLASS_NAME = 'table_variant-1';
/** Таблица внутри таблицы */
export const TABLE_INTERNAL_CLASS_NAME = 'table_internal';
/** Ряд в таблице, к которому не надо применять стили */
export const TABLE_ROW_EMPTY_CLASS_NAME = 'table-row_empty';
/** Ряд в таблице, по которому можно кликать */
export const TABLE_ROW_SELECTABLE_CLASS_NAME = 'table-row_selectable';

/**
 * Показывает инпуту, что надо занимать всю доступную ширину, а не использовать свой дефолтный размер
 * Можно ставить как непосредственно TextField так и его родителю
 * @deprecated Этот класс должен использоваться только внутри ui компонентов и не выше, а ещё лучше только внутри TextFieldMemorized, но сейчас он по всей системе и это надо отрефакторить.
 * Чтобы добиться эффекта от этого класса не используя его за пределами TextFieldMemorized, последнему нужно передать проп fillWidth и это будет правильный способ
 */
export const INPUT_FULL_WIDTH_CLASS_NAME = 'input_full-width';
export const INPUT_LARGE_WIDTH_CLASS_NAME = 'input_large-width';
export const INPUT_MEDIUM_WIDTH_CLASS_NAME = 'input_medium-width';
export const INPUT_SMALL_WIDTH_CLASS_NAME = 'input_small-width';

// Создал для тестов, пока не понятна дальнейшая судьба
export const ELEMENT_CLASS_NAME = {
  mainMenu: 'main-menu',
  orderAction: 'order-action',
  orderLabel: 'order-label',
  orderContainer: 'order-main-block__container',
  orderHeader: 'order-main-block__header',
  orderContent: 'order-main-block__content',
  orderListContainer: 'order-list__container',
  orderListItem: 'order-list__item',
};
