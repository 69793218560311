import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import IOrderDocumentActionsService from './IOrderDocumentActionsService';
import { CreateOrderDocumentRequestDTO, OrderDocumentResponseDTO } from 'typings/dto/orderDocument';
import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import IHttpService from 'services/http/IHttpService';
import DIContainer from 'services/DIContainer';
import ISliceActions from 'storage/slices/ISliceActions';
import IMapper from 'utils/mappers/IMapper';
import IEntityApiService from 'services/entityApi/IEntityApiService';
import IModelApiService from 'services/entityApi/IModelApiService';
import IServerEventsService from 'services/serverEvents/IServerEventsService';
import { AppDispatch, RootState } from 'storage';

export default class OrderDocumentActionsServiceImpl
  extends ModelActionsService<OrderDocument, CreateOrderDocumentRequestDTO, OrderDocumentResponseDTO>
  implements IOrderDocumentActionsService
{
  private httpService: IHttpService;

  constructor(
    modelStorageData: { name: DIContainer.ModelsWithActionServices; actions: ISliceActions<OrderDocument> },
    modelMapper: IMapper<OrderDocument, OrderDocumentResponseDTO, CreateOrderDocumentRequestDTO>,
    entityApiService: IEntityApiService,
    modelApiService: IModelApiService,
    subEntitiesApiServices: DIContainer.SubEntitiesApiServices,
    modelApiRootPath: string,
    serverEventsService: IServerEventsService,
    storageStateGetter: () => RootState,
    storageDispatch: AppDispatch,
    httpService: IHttpService
  ) {
    super(
      modelStorageData,
      modelMapper,
      entityApiService,
      modelApiService,
      subEntitiesApiServices,
      modelApiRootPath,
      serverEventsService,
      storageStateGetter,
      storageDispatch
    );

    this.httpService = httpService;
  }

  public getOrderDocumentTypes = async (orderId: string): Promise<EntityWithName[]> => {
    const path = PATH_BACKEND.order.root + '/' + orderId + '/' + PATH_BACKEND_PART.order.document + '/types';
    return this.entityApiService.getListWithCredentials<EntityWithName>(path).then((res) => res.data);
  };

  public downloadOrderDocument = async (orderId: string, documentId: string): Promise<Blob> => {
    const path = PATH_BACKEND.order.root + '/' + orderId + '/' + PATH_BACKEND_PART.order.document + '/' + documentId + '/template/pdf';
    const response = await this.entityApiService.getRawWithCredentials(path);
    return this.httpService.parseResponseBlob(response);
  };

  protected getModelApiRootPath = (dto: { orderId: string }) => {
    return PATH_BACKEND.order.root + '/' + dto.orderId + '/' + super.getModelApiRootPath(dto);
  };
}
