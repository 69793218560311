import FrontendRoutesBase from './FrontendRoutesBase';
import { PAGE_TYPE, PATH_PARAM_DASHBOARD, PATH_PARAM_TOKEN } from '../PATH_PARAM';
import IFrontendRoutes from './IFrontendRoutes';

export default class RecommendationsConfigRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  public root = this.getDBPath(PATH_PARAM_DASHBOARD.recommendationConfig.root);

  public variables: VariablesRoutes;

  constructor() {
    super();
    this.variables = new VariablesRoutes(this);
  }

  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.recommendationConfig> = (_p, _permissions, translate) => {
    return [{ name: translate('menus.main.recommendations'), href: this.getListPath() }];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.recommendationConfig, 'ruleName'> = (
    p,
    pm,
    t,
    n
  ) => {
    const breadcrumbs = this.getInfoBc(p, pm, t);
    breadcrumbs.push({ name: n.ruleName, href: this.getInfoPath(p) });
    return breadcrumbs;
  };

  getListBc = this.getEmptyBreadcrumbs;
  getListPath = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.recommendationConfig.root, PATH_PARAM_DASHBOARD.recommendationConfig.rules, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.recommendationConfig> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.recommendationConfig.root,
      PATH_PARAM_DASHBOARD.recommendationConfig.rules,
      params.ruleId,
      PAGE_TYPE.info
    );

  getAddBc = this.getInstanceBc;
  getAddPath = () =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.recommendationConfig.root,
      PATH_PARAM_DASHBOARD.recommendationConfig.rules,
      PAGE_TYPE.new,
      PAGE_TYPE.add
    );

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.recommendationConfig> = (params) =>
    this.getDBPath(
      PATH_PARAM_DASHBOARD.recommendationConfig.root,
      PATH_PARAM_DASHBOARD.recommendationConfig.rules,
      params.ruleId,
      PAGE_TYPE.edit
    );
}

class VariablesRoutes extends FrontendRoutesBase implements IFrontendRoutes {
  constructor(private parent: RecommendationsConfigRoutes) {
    super();
  }
  getInstanceBc: IFrontendRoutes.BreadcrumbsGetter<typeof PATH_PARAM_TOKEN.recommendationConfig> = (_p, _permissions, translate) => {
    return [{ name: translate('pages.promotionsVariablesEdit.title'), href: this.getListPath(_p) }];
  };

  getInstanceChildBc: IFrontendRoutes.BreadcrumbsGetterWithNames<typeof PATH_PARAM_TOKEN.recommendationConfig, 'variableName'> = (
    p,
    pm,
    t,
    n
  ) => {
    const breadcrumbs = this.getInfoBc(p, pm, t);
    breadcrumbs.push({ name: n.variableName, href: this.getInfoPath(p) });
    return breadcrumbs;
  };

  getListBc = this.parent.getInstanceBc;
  getListPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.recommendationConfig> = (_params) =>
    this.getDBPath(PATH_PARAM_DASHBOARD.recommendationConfig.root, PATH_PARAM_DASHBOARD.recommendationConfig.variables, PAGE_TYPE.list);

  getInfoBc = this.getInstanceBc;
  getInfoPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.recommendationConfig> = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.recommendationConfig.root, PATH_PARAM_DASHBOARD.recommendationConfig.variables, PAGE_TYPE.info);

  getEditBc = this.getInstanceChildBc;
  getEditPath: IFrontendRoutes.PathGetter<typeof PATH_PARAM_TOKEN.recommendationConfig> = () =>
    this.getDBPath(PATH_PARAM_DASHBOARD.recommendationConfig.root, PATH_PARAM_DASHBOARD.recommendationConfig.variables, PAGE_TYPE.edit);
}
