import EditIcon from '@mui/icons-material/Edit';
import useLocales from 'hooks/useLocales';
import IconButtonStyled from './IconButtonStyled';

export default function EditButton({ title: titleRaw, ...rest }: React.ComponentProps<typeof IconButtonStyled>) {
  const { translate } = useLocales();
  const title = titleRaw || translate('buttons.edit');

  return (
    <IconButtonStyled {...rest} title={title}>
      <EditIcon />
    </IconButtonStyled>
  );
}
