import { STATUS_COLOR } from 'typings/common/commonEnums';

export enum TASK_TYPE {
  orderSetToWaiting = 'order_set_to_waiting',
  newOrder = 'new_order',
  orderNotClosed = 'order_not_closed',
  scNotCalling = 'mc_not_calling',
  scNotFound = 'mc_not_found',
  badReviewReceived = 'bad_review_received',
  callbacklessCall = 'callbackless_call',
  orderClosedUnsuccessfully = 'order_closed_unsuccessfully',
  orderClosedSuccessfully = 'order_closed_successfully',
  orderCanceled = 'order_canceled',
  orderRescheduled = 'order_rescheduled',
  commentReceived = 'comment_received',
  reviewReceived = 'review_received',
  custom = 'custom',
}

export enum TASK_STATE {
  active = 'ACTIVE',
  outdated = 'OVERDUE',
  closed = 'COMPLETED',
  scheduled = 'PLANNED',
}

/** @deprecated не используется? */
export const TASK_COLOR: { [key in TASK_TYPE]: STATUS_COLOR.info | STATUS_COLOR.error } = {
  [TASK_TYPE.orderSetToWaiting]: STATUS_COLOR.info,
  [TASK_TYPE.newOrder]: STATUS_COLOR.info,
  [TASK_TYPE.orderNotClosed]: STATUS_COLOR.error,
  [TASK_TYPE.scNotCalling]: STATUS_COLOR.error,
  [TASK_TYPE.scNotFound]: STATUS_COLOR.error,
  [TASK_TYPE.badReviewReceived]: STATUS_COLOR.error,
  [TASK_TYPE.callbacklessCall]: STATUS_COLOR.error,
  [TASK_TYPE.orderClosedUnsuccessfully]: STATUS_COLOR.info,
  [TASK_TYPE.orderClosedSuccessfully]: STATUS_COLOR.info,
  [TASK_TYPE.orderCanceled]: STATUS_COLOR.info,
  [TASK_TYPE.orderRescheduled]: STATUS_COLOR.info,
  [TASK_TYPE.commentReceived]: STATUS_COLOR.error,
  [TASK_TYPE.reviewReceived]: STATUS_COLOR.info,
  [TASK_TYPE.custom]: STATUS_COLOR.info,
};

export enum TASK_PRIORITY {
  low = 'LOW',
  medium = 'MEDIUM',
  high = 'HIGH',
}

export enum TASK_ASSIGN_GROUP {
  technicians = 'TECHNICIANS',
  enterpriseManagers = 'ENTERPRISE_MANAGERS',
  platformOperatorManagers = 'PLATFORM_OPERATOR_MANAGERS',
}
