import { BoxProps, CircularProgress } from '@mui/material';
import { Box } from '@mui/material';

export default function Spinner(props: BoxProps) {
  return (
    <Box {...props} sx={{ display: 'flex', alignItems: 'center', ...props.sx }}>
      <CircularProgress color="primary" />
    </Box>
  );
}
