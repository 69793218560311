import { useRef, useState } from 'react';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, IconButton, Button, SxProps, Tooltip } from '@mui/material';
import useLocales from 'hooks/useLocales';

type Props = {
  title?: string;
  options: MenuItemData[];
  variant?: 'full' | 'icon';
  sx?: SxProps;
  id?: string;
  /** Если нужно отобразить свою иконку вместо стандартной */
  customIcon?: React.ReactNode;
  /** Если нужно переопределить пропс variant компонента Button */
  styleVariant?: 'contained' | 'outlined' | 'text';
};

export default function MenuButton(props: Props) {
  if (!props.options.length) {
    return null;
  }

  return <MenuComponent {...props} />;
}

// Два компонента, т.к. были проблемы при динамическом изменении списка (на length 0) реакт ругался на ref null
function MenuComponent({ title, variant, id, sx, options, customIcon, styleVariant = 'contained' }: Props) {
  const { translate } = useLocales();
  const ref = useRef(null);
  const [isOpened, setIsOpened] = useState(false);

  if (!title) {
    title = translate('buttons.actions');
  }

  return (
    <>
      {variant === 'full' ? (
        <Button id={id} ref={ref} variant={styleVariant} onClick={() => setIsOpened(true)} color="primary" sx={sx} startIcon={customIcon}>
          {title}
          {!customIcon && <ExpandMoreRoundedIcon sx={{ ml: 0.5, mr: -1 }} />}
        </Button>
      ) : (
        <Tooltip title={title}>
          <IconButton id={id} ref={ref} onClick={() => setIsOpened(true)} sx={sx}>
            {!customIcon ? <MoreVertIcon /> : customIcon}
          </IconButton>
        </Tooltip>
      )}
      <Menu
        open={isOpened}
        anchorEl={ref.current}
        onClose={() => setIsOpened(false)}
        PaperProps={{ sx: { minWidth: 300, maxWidth: '100%' } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: variant === 'full' ? 'left' : 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: variant === 'full' ? 'left' : 'right' }}
      >
        {options.map((item) => (
          <MenuItem
            className={item.technicalName}
            disabled={Boolean(item.disabled)}
            key={item.title}
            onClick={() => {
              setIsOpened(false);
              item.handler();
            }}
            sx={{ color: 'text.secondary' }}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
