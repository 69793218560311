import { IconButton, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React from 'react';

interface IProps {
  showPassword: boolean;
  setShowPassword: (value: React.SetStateAction<boolean>) => void;
}

function TogglePasswordButton({ showPassword, setShowPassword }: IProps) {
  return (
    <InputAdornment position="end">
      <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </InputAdornment>
  );
}

export default React.memo(TogglePasswordButton, (prevProps, nextProps) => prevProps.showPassword === nextProps.showPassword);
