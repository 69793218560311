import { FormikContextType } from 'formik';
import IGeoService from 'services/geo/IGeoService';
import ILanguageService from 'services/language/ILanguageService';
import IMapService from 'services/map/IMapService';

// Работа не со статик методами, а с экземпляром (создаётся один в memo), чтобы не передавать в методы много параметров
/** Набор методов для работы с картой в попапе указания адреса */
export default class AddressMapUtils {
  private markerId: string = '';
  private mapId: string = '';

  constructor(
    public geoService: IGeoService,
    private languageService: ILanguageService,
    private mapService: IMapService,
    private errorHandler: (error: any) => void,
    private geoPointFieldName: string,
    /** Важно - values не актуальные тут */
    private formState: FormikContextType<any>,
    private addressStreetFieldName: string,
    private isMapEditable: boolean = false,
    private regionName?: string,
    /** Если нужен флаг загрузки получения адреса из геоточки */
    private setIsAddressLoading?: React.Dispatch<React.SetStateAction<boolean>>
  ) {}

  /** @throws Error */
  public initializeGeoPoint = async (streetAddress?: string, geoPoint?: IGeoService.Point): Promise<IGeoService.Point | undefined> => {
    if (!streetAddress || geoPoint) {
      return geoPoint;
    }

    const autoGeoPoint = await this.geoService.getGeoByAddress(streetAddress, this.regionName);
    if (autoGeoPoint) {
      this.formState.setFieldValue(this.geoPointFieldName, autoGeoPoint, false); // валидация на этапе инициализации не нужна
      return autoGeoPoint;
    }
  };

  /**
   * Создаёт карту с маркером в ней (если передана точка)
   * @throws Error
   */
  public initializeMap = async (MAP_CONTAINER_ID: string, geoPoint?: IGeoService.Point) => {
    let center = geoPoint || (this.regionName ? await this.geoService.getGeoByAddress(this.regionName!, null, 0) : null);
    let zoom = this.mapService.getDefaultMapZoom().REGION;
    if (!center) {
      // Сказано сделать, чтобы всегда была центровка
      zoom = this.mapService.getDefaultMapZoom().COUNTRY;
      const countryName = this.languageService.getCountryName(this.languageService.getCurrentLocale().localeCountry);
      center = await this.geoService.getGeoByAddress(countryName, null, 0);
      if (!center) throw new Error(this.languageService.translate('errors.mapCenterIsNotFound'));
    }
    const lang = this.languageService.getCurrentLocale().localeShort;
    await this.mapService.initialize(lang);

    const existedMarkerIdGetter = () => this.markerId;
    this.mapId = await this.mapService.createMap(
      MAP_CONTAINER_ID,
      center,
      this.isMapEditable,
      zoom,
      this.dropMarkerHandler,
      existedMarkerIdGetter
    );

    if (!geoPoint) {
      return;
    }

    this.markerId = this.mapService.createMarker(this.mapId, geoPoint, this.isMapEditable, this.dropMarkerHandler);
  };

  // Handlers

  public setPositionHandler = (geoPoint: IGeoService.Point | null) => {
    this.formState.setFieldValue(this.geoPointFieldName, geoPoint);
    // Тут непонятно что за логика была до рефакторинга - маркер должен удалиться, исчезнуть или ничего не делать?
    if (geoPoint) {
      if (this.markerId) this.mapService.setMarkerPosition(this.markerId, geoPoint);
      else this.markerId = this.mapService.createMarker(this.mapId, geoPoint, this.isMapEditable, this.dropMarkerHandler);
    }
  };

  public setAddressByGeoPositionHandler = async (formState: FormikContextType<any>, geoPoint: IGeoService.Point) => {
    try {
      if (this.setIsAddressLoading) this.setIsAddressLoading(true);
      const address = await this.geoService.getAddressByGeo(geoPoint, 0); // 0 т.к. должны иметь возможность получить адрес для любой точки
      formState.setFieldValue(this.addressStreetFieldName, address || '');
      if (this.setIsAddressLoading) this.setIsAddressLoading(false);
    } catch (error) {
      if (this.setIsAddressLoading) this.setIsAddressLoading(false);
      this.errorHandler(error);
    }
  };

  public setPositionByAddressHandler = async (address: string) => {
    try {
      const geoPoint = await this.geoService.getGeoByAddress(address, this.regionName);

      if (geoPoint) {
        this.mapService.setMapCenter(this.mapId, geoPoint);
        this.setPositionHandler(geoPoint);
      }
    } catch (error) {
      this.errorHandler(error);
    }
  };

  /** Не только валидирует, но и проставляет форматированное значение адреса, если он валиден */
  public validateAndFormatAddressHandler = async (address: string): Promise<boolean> => {
    try {
      if (!address) {
        return false; // Кастомная валидация не нужна, сработает дефолтная валидация формика на пустое поле
      }

      const result = await this.geoService.formatAddress(address, this.regionName);
      if (result) {
        this.formState.setFieldValue(this.addressStreetFieldName, result);
      }
      // Если требуется валидировать
      // if (!result) {
      //   formState.setFieldError(this.addressStreetFieldName, translate('errors.addressValidationFailed'));
      // } else {
      //   formState.setFieldError(this.addressStreetFieldName, undefined);
      //   formState.setFieldValue(this.addressStreetFieldName, result);
      // }
      return Boolean(result);
    } catch (error) {
      this.errorHandler(error);
      return false;
    }
  };

  public onBlurAddressHandler = async (newValue: string) => {
    this.formState.setFieldTouched(this.addressStreetFieldName, true); // Это дефолтное поведение формика
    const isValid = await this.validateAndFormatAddressHandler(newValue);
    if (isValid) this.setPositionByAddressHandler(newValue);
    else this.setPositionHandler(null);
  };

  private dropMarkerHandler = (point: IGeoService.Point, markerId: string): void => {
    this.markerId = markerId;
    this.setPositionHandler(point);
    this.setAddressByGeoPositionHandler(this.formState, point);
  };
}
