import useIsMountedRef from 'hooks/useIsMountedRef';
import { useEffect, useState } from 'react';
import IFileService from 'services/file/IFileService';
import useSnackbarErrorHandler from '../snackbar/useSnackbarErrorHandler';
import useDI from '../useDI';

/**
 * Получает актуальную ссылку на файл для отображения на странице
 * @returns [hydratedFiles, areFilesLoading]
 */
export default function usePrepareFilesForView(filesRaw: IFileService.File_[], lastUpdate?: number): [IFileService.File_[], boolean] {
  const { apiService } = useDI();
  const isMountedRef = useIsMountedRef();
  const handleFormErrors = useSnackbarErrorHandler();
  const [hydratedFiles, setHydratedFiles] = useState(filesRaw);
  const [areLoading, setAreLoading] = useState(true);

  useEffect(() => {
    setAreLoading(true);
    const initialize = async () => {
      const fileRequests: Promise<IFileService.File_>[] = [];
      filesRaw.forEach((fileRaw) => {
        if (fileRaw.isHydrated) fileRequests.push(Promise.resolve(fileRaw));
        else fileRequests.push(apiService.subEntity.file.getActualFileData(fileRaw));
      });
      const hydratedFilesRes = await Promise.all(fileRequests);
      if (isMountedRef.current) {
        setHydratedFiles(hydratedFilesRes);
        setAreLoading(false);
      }
    };
    initialize().catch((error) => handleFormErrors({ error }));
  }, [lastUpdate]);

  return [hydratedFiles, areLoading];
}
