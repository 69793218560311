import { PATH_BACKEND } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import { OrderProviderTransactionRequestDTO, OrderProviderTransactionsDataResponseDTO } from 'typings/dto/orderProviderTransactionsData';
import IOrderProviderTransactionsDataActionsService from './IOrderProviderTransactionsDataActionsService';

export default class OrderProviderTransactionsDataActionsServiceImpl
  extends ModelActionsService<OrderProviderTransactionsData, EntityDTOResponse, OrderProviderTransactionsDataResponseDTO>
  implements IOrderProviderTransactionsDataActionsService
{
  createTransaction = ({ orderId, ...dto }: OrderProviderTransactionRequestDTO) => {
    const url = `${PATH_BACKEND.order.root}/${orderId}/${this.getModelApiRootPath(dto)}/provider`;
    return this.entityApiService.postWithCredentials<void>(url, dto, false);
  };

  updateTransaction = ({ orderId, ...dto }: OrderProviderTransactionRequestDTO & { id: string }) => {
    const url = `${PATH_BACKEND.order.root}/${orderId}/${this.getModelApiRootPath(dto)}/provider`;
    return this.modelApiService.update(url, dto);
  };

  deleteTransaction = (id: string, orderId: string) => {
    const url = `${PATH_BACKEND.order.root}/${orderId}/${this.getModelApiRootPath(null)}/provider`;
    return this.modelApiService.delete(url, id);
  };

  createPOTransaction = ({ orderId, ...dto }: OrderProviderTransactionRequestDTO) => {
    const url = `${PATH_BACKEND.order.root}/${orderId}/${this.getModelApiRootPath(dto)}/po`;
    return this.entityApiService.postWithCredentials<void>(url, dto, false);
  };

  updatePOTransaction = ({ orderId, ...dto }: OrderProviderTransactionRequestDTO & { id: string }) => {
    const url = `${PATH_BACKEND.order.root}/${orderId}/${this.getModelApiRootPath(dto)}/po`;
    return this.modelApiService.update(url, dto);
  };

  deletePOTransaction = (id: string, orderId: string) => {
    const url = `${PATH_BACKEND.order.root}/${orderId}/${this.getModelApiRootPath(null)}/po`;
    return this.modelApiService.delete(url, id);
  };
}
