import { useSelector } from '../../../storage';
import { Navigate } from 'react-router';
import { PAGE_TYPE } from '../../../services/routing/PATH_PARAM';
import useDI from '../../../hooks/useDI';
import { useEffect } from 'react';

export default function OrderFilterRedirectPage() {
  const { orderViewList } = useSelector((state) => state.orderView);
  const { storageActions } = useDI();
  const { currentUser } = useSelector((state) => state.auth);

  // Вкладки должны получаться ТОЛЬКО после того как загружены кастомные поля, так как в маппере вкладок уже должны быть актуальные customField
  useEffect(() => {
    const orderViewActions = storageActions.requestScope.orderView();
    orderViewActions.getList({ userId: currentUser?.id || '' });

    return () => {
      orderViewActions.cancelRequests();
      orderViewActions.resetData();
    };
  }, []);

  if (orderViewList.length === 0) {
    return null;
  }
  return <Navigate to={`${orderViewList[0].technicalName}/${PAGE_TYPE.list}`} replace />;
}
