import { ReactNode } from 'react';
import useLocales from 'hooks/useLocales';
import ForbiddenError from 'utils/errors/ForbiddenError';

// ----------------------------------------------------------------------

interface IBooleanGuardProp {
  isAllowed: boolean;
  onSuccess?: ReactNode; // на выбор можно передать либо через проп, либо как стандартный children (через проп иногда лучше для читаемости кода)
  children?: ReactNode;
}

export default function BooleanGuard({ isAllowed, children, onSuccess }: IBooleanGuardProp) {
  const { translate } = useLocales();

  if (!isAllowed) {
    throw new ForbiddenError(translate('errors.invalidPermissionsPage'));
  }

  return (
    <>
      {children}
      {onSuccess}
    </>
  );
}
