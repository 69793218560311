import { TextField, Autocomplete, AutocompleteProps, Checkbox, Typography, Stack } from '@mui/material';
import { FormikContextType } from 'formik';
import useLocales from 'hooks/useLocales';
import React, { useMemo } from 'react';
import { areArraysEqual } from 'utils/Entity';

type AutocompleteCheckboxesMemorizedProps = Omit<
  AutocompleteProps<any, boolean, undefined, undefined>,
  'renderInput' | 'translate' | 'multiple'
> & {
  fieldName: string;
  label: string;
  options: EntityWithName[];
  excluded?: EntityWithName[];
  isLoading?: boolean;
  formState: FormikContextType<any>;
};

/** Работает с массивом EntityWithName */
function AutocompleteCheckboxesMemorized({
  fieldName,
  label,
  isLoading,
  disabled,
  options,
  excluded,
  formState: { getFieldProps, setFieldValue, touched, errors, values },
  ...additionalProps
}: AutocompleteCheckboxesMemorizedProps) {
  const { translate } = useLocales();
  const optionsMemorized = useMemo(() => {
    if (!excluded) return options;
    return options.filter((option) => !excluded.some((f) => f.id === option.id));
  }, [isLoading]);

  return isLoading ? (
    <TextField fullWidth label={label} value={values[fieldName]?.name || '...'} />
  ) : (
    <Autocomplete
      fullWidth
      disabled={disabled}
      multiple={true}
      options={optionsMemorized}
      disableCloseOnSelect
      noOptionsText={translate('messages.noOptionsLeft')}
      {...getFieldProps(fieldName)}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option) => option.name}
      onChange={(_, values) => {
        setFieldValue(fieldName, values);
      }}
      {...additionalProps}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          <Stack spacing={1}>
            <Typography variant="body1">{option.name}</Typography>
            {option.viewDescription && (
              <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body1" color="text.secondary">
                {option.viewDescription}
              </Typography>
            )}
          </Stack>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          name={fieldName}
          error={Boolean(touched[fieldName] && errors[fieldName])}
          helperText={touched[fieldName] && errors[fieldName]}
          label={label}
          autoComplete="nope"
          {...params}
        />
      )}
    />
  );
}

// Входящий список не учитывается; если есть необходимость его менять (при додгрузке новых данных например), то можно передавать в isLoading флаг загрузки или использовать лайфхак со сменой key
export default React.memo(
  AutocompleteCheckboxesMemorized,
  (prevProps, nextProps) =>
    prevProps.disabled === nextProps.disabled &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.formState.touched[prevProps.fieldName] === nextProps.formState.touched[nextProps.fieldName] &&
    prevProps.formState.errors[prevProps.fieldName] === nextProps.formState.errors[nextProps.fieldName] &&
    areArraysEqual(prevProps.formState.values[prevProps.fieldName], nextProps.formState.values[nextProps.fieldName])
);
