import { CreateCityRequestDTO, CityResponseDTO } from 'typings/dto/city';
import Mapper from './Mapper';
import EnterpriseUtils from 'utils/models/EnterpriseUtils';

export default class CityMapper extends Mapper<City, CityResponseDTO, CreateCityRequestDTO> {
  constructor(private currentUserGetter: () => User | null) {
    super({ name: 'name', technicalName: 'technicalName', timezone: 'timezone', tenant: 'tenant' }, null);
  }

  public responseDTOToModel = (city: CityResponseDTO): City => {
    const currentUser = this.currentUserGetter();

    return {
      ...this.responseDTOToModelViaDiffMap(city),
      isInherited: EnterpriseUtils.isEntityInherited(currentUser, city),
      viewDescription: EnterpriseUtils.getEntityOwnerName(currentUser, city),
    };
  };

  // Этот маппер по умолчанию используется при запросе данных для фильтра, а там нужен descriptionsInDropdown
  public responseDTOToModelIdAndNamesOnly = (city: CityResponseDTO): City => {
    return this.responseDTOToModel(city);
  };
}
