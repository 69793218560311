import ILanguageService from 'services/language/ILanguageService';
import { TASK_ASSIGN_GROUP, TASK_STATE } from 'typings/models/task.enum';
import { USER_ROLE } from 'typings/models/user.enum';
import ModelUtils from './ModelUtils';
import { TENANT_TYPE } from 'typings/subEntities/tenant.enum';

export default class TaskUtils {
  public static getTaskListsByState(mainList: Task[]) {
    let activeTasks: Task[] = [];
    let outdatedTasks: Task[] = [];
    let closedTasks: Task[] = [];
    let scheduledTasks: Task[] = [];
    mainList.forEach((task) => {
      switch (task.state) {
        case TASK_STATE.closed:
          closedTasks.push(task);
          break;
        case TASK_STATE.scheduled:
          scheduledTasks.push(task);
          break;
        case TASK_STATE.outdated:
          outdatedTasks.push(task);
          break;
        default:
          activeTasks.push(task);
      }
    });
    // У каждого стейта задач своя сортировка
    // Закрытые по убыванию даты закрытия
    closedTasks.sort((a, b) => b.closedAt!.getTime() - a.closedAt!.getTime());
    // Активные по дате создания, сначала свежие
    activeTasks.sort(ModelUtils.sortByDateDescendingComparator);
    outdatedTasks.sort(ModelUtils.sortByDateDescendingComparator);
    // Запланированные по дате старта по убыванию
    scheduledTasks.sort((a, b) => a.startsAt!.getTime() - b.startsAt!.getTime());

    return { activeTasks, outdatedTasks, closedTasks, scheduledTasks, activeAndOutdatedTasks: outdatedTasks.concat(activeTasks) };
  }

  /** @throws `Error` если входящие параметры не привести к числу */
  public static getDeadlineMinutes = (
    languageService: ILanguageService,
    days?: string | number | null,
    hours?: string | number | null,
    minutesRaw?: string | number | null
  ): number | undefined => {
    if (!days && !hours && !minutesRaw) {
      return;
    }
    let minutes = 0;
    if (minutesRaw) {
      const minutesNumber = Number(minutesRaw);
      if (Number.isNaN(minutesNumber)) throw new Error(languageService.translate('errors.numberParseFailed') + ': ' + minutesRaw);
      else minutes += minutesNumber;
    }
    if (hours) {
      const hoursNumber = Number(hours);
      if (Number.isNaN(hoursNumber)) throw new Error(languageService.translate('errors.numberParseFailed') + ': ' + hoursNumber);
      else minutes += hoursNumber * 60;
    }
    if (days) {
      const daysNumber = Number(days);
      if (Number.isNaN(daysNumber)) throw new Error(languageService.translate('errors.numberParseFailed') + ': ' + daysNumber);
      else minutes += daysNumber * 24 * 60;
    }
    return minutes;
  };

  public static getRecipientsStr = (task: Task, { translate }: ILanguageService): string => {
    const { groupRecipients, userRecipients } = task;
    let res: string[] = [];
    if (groupRecipients.length) {
      const assignedGroups = this.getTaskAssignedGroups(task);
      if (assignedGroups.ENTERPRISE_MANAGERS) {
        res.push(translate('entities.task.assignGroup.' + TASK_ASSIGN_GROUP.enterpriseManagers));
      }
      if (assignedGroups.PLATFORM_OPERATOR_MANAGERS) {
        res.push(translate('entities.task.assignGroup.' + TASK_ASSIGN_GROUP.platformOperatorManagers));
      }
      if (assignedGroups.TECHNICIANS) {
        res.push(translate('entities.task.assignGroup.' + TASK_ASSIGN_GROUP.technicians));
      }
      // Старое
      // groupRecipients.forEach((g) => g.roles.forEach((r) => res.push(translate('entities.task.assignGroup.' + g.technicalName + '_' + r))));
    }
    if (userRecipients.length) {
      res.push(task.userRecipients.map((r) => r.name).join(', '));
    }
    return res.join(', ');
  };

  public static getTaskAssignedGroups = (task: Task | null): Record<TASK_ASSIGN_GROUP, boolean> => {
    if (!task) {
      return {
        [TASK_ASSIGN_GROUP.enterpriseManagers]: false,
        [TASK_ASSIGN_GROUP.technicians]: false,
        [TASK_ASSIGN_GROUP.platformOperatorManagers]: false,
      };
    } else {
      return {
        [TASK_ASSIGN_GROUP.enterpriseManagers]: Boolean(
          task.groupRecipients.find((g) => g.roles.includes(USER_ROLE.orderManager) && g.technicalName === TENANT_TYPE.enterprise)
        ),
        [TASK_ASSIGN_GROUP.platformOperatorManagers]: Boolean(
          task.groupRecipients.find((g) => g.roles.includes(USER_ROLE.orderManager) && g.technicalName === TENANT_TYPE.platformOperator)
        ),
        [TASK_ASSIGN_GROUP.technicians]: Boolean(task.groupRecipients.find((g) => g.roles.includes(USER_ROLE.orderTechnician))),
      };
    }
  };
}
