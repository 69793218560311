import React from 'react';
import useLocales from 'hooks/useLocales';
import { Box, BoxProps, Stack, Typography } from '@mui/material';
import ModelUtils from 'utils/models/ModelUtils';
import useDI from 'hooks/useDI';
import { useSelector } from 'storage';

type Props = Omit<BoxProps, 'order'> & {
  services: ServiceOrdered[];
  orderSession: OrderContractSessionData;
};

function ServiceOrderedSessionTotalBlock({ services, orderSession, sx, ...props }: Props) {
  const {
    totalPriceClient,
    unpaidTotalPriceClient,

    totalDiscount,
  } = useSelector((state) => state.serviceOrdered);
  const { statefulUtils } = useDI();
  const { translate } = useLocales();
  const { balance: customersBalance } = orderSession.customer ? orderSession.customer : { balance: 0 };
  const unpaidTotalPriceClientWithBalance = unpaidTotalPriceClient - customersBalance;

  return (
    <Stack spacing={2}>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 2, ...sx }} {...props}>
        <Typography variant="subtitle1">{translate('pages.orderServiceList.total')}</Typography>
        <Typography variant="subtitle1" textAlign="right">
          {statefulUtils.money.toString(totalPriceClient + totalDiscount, orderSession.currency)}
        </Typography>

        {totalDiscount > 0 && <Typography variant="body2">{translate('pages.orderServiceList.discount')}</Typography>}
        {totalDiscount > 0 && (
          <Typography variant="body2" textAlign="right">
            {statefulUtils.money.toString(totalDiscount, orderSession.currency)}
          </Typography>
        )}

        {customersBalance > 0 && <Typography variant="body2">{translate('pages.orderServiceList.customersBalance')}</Typography>}
        {customersBalance > 0 && (
          <Typography variant="body2" textAlign="right">
            {statefulUtils.money.toString(customersBalance, orderSession.currency)}
          </Typography>
        )}

        <Typography variant="body2" fontWeight="bold">
          {unpaidTotalPriceClientWithBalance >= 0
            ? translate('pages.orderServiceList.unpaidTotal')
            : translate('pages.orderServiceList.overpayment')}
        </Typography>
        <Typography variant="body2" textAlign="right" fontWeight="bold">
          {statefulUtils.money.toString(
            unpaidTotalPriceClientWithBalance >= 0 ? unpaidTotalPriceClientWithBalance : customersBalance - unpaidTotalPriceClient,
            orderSession.currency
          )}
        </Typography>
      </Box>
    </Stack>
  );
}

export default React.memo(
  ServiceOrderedSessionTotalBlock,
  (pp, np) => ModelUtils.checkArraysEquality(pp.services, np.services) && ModelUtils.checkEquality(pp.orderSession, np.orderSession)
);
