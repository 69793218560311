import PhoneUtils from 'utils/Phone';

export default class PhoneMapper {
  constructor(private phoneUtils: PhoneUtils) {}

  public modelToRequestDTO = (model: Phone): string => {
    return this.phoneUtils.getRFC3966(model.raw);
  };

  public responseDTOToModel = (dto: string): Phone => {
    return this.phoneUtils.getPhoneObjectFromStr(dto);
  };
}
