import { AppDispatch, RootState } from 'storage';
import IMapper from 'utils/mappers/IMapper';
import ISliceActions from 'storage/slices/ISliceActions';
import IModelActionsService from './IModelActionsService';
import IModelApiService from 'services/entityApi/IModelApiService';
import IEntityApiService from 'services/entityApi/IEntityApiService';
import DIContainer from 'services/DIContainer';
import IServerEventsService from 'services/serverEvents/IServerEventsService';
import NavigateFrontendUtils from 'utils/NavigateFrontend';
import { batch as reduxBatch } from 'react-redux';
import EntityUtils from 'utils/Entity';
import { PATH_BACKEND_PART } from 'configs/routes/pathsBackend';

/** Описание public методов в интерфейсе */
export default abstract class ModelActionsService<
  MODEL extends Model,
  CREATEDTO extends Record<string, any>,
  RESPONSEDTO extends ModelDTOResponse
> implements IModelActionsService<MODEL, CREATEDTO, RESPONSEDTO>
{
  protected readonly storageDispatch: AppDispatch;
  protected readonly modelApiService: IModelApiService;
  protected readonly modelMapper: IMapper<MODEL, RESPONSEDTO, CREATEDTO>;
  protected readonly modelStorageActions: ISliceActions<MODEL>;
  protected readonly modelStorageName: DIContainer.ModelsWithActionServices;
  protected readonly entityApiService: IEntityApiService;
  protected readonly subEntitiesApiServices: DIContainer.SubEntitiesApiServices;
  protected readonly serverEventsService: IServerEventsService;

  /**
   * От race condition при веб запросах, см хуки навигации (помечается true, если раньше ответа мы уходим со страницы)
   * Возможно стоит упростить и просто у каждого метода вместо промиса отдавать контроллер запроса, через который можно произвести отмену извне
   */
  protected areActionsOutdated = false;

  protected readonly getStorageState: () => RootState;
  protected getStorageCurrentState = (): ModelState<MODEL, typeof this.modelStorageName> => {
    return this.getStorageState()[this.modelStorageName] as any; // Костыль - в storage бывают разные типы хранилищ, но нам нужен тип текущей модели; хоть такой тип и дженерик, но сам сторадж их не различает, поэтому пока вот так
  };

  /** Работает следующим образом: у большинства сущностей адрес всегда константа, с добавлением в конце id сущности, но есть некоторые, у которых надо в адрес добавить id родителя в начале. Для этого достаточно переопределить геттер данного свойства */
  private readonly modelApiRootPath: string;
  /**
   * @see modelApiRootPath
   * @param _dto копируется из дто запроса, в котором вызывается данный метод
   */
  protected getModelApiRootPath(_dto: any): string {
    return this.modelApiRootPath;
  }

  constructor(
    modelStorageData: { name: DIContainer.ModelsWithActionServices; actions: ISliceActions<MODEL> },
    modelMapper: IMapper<MODEL, RESPONSEDTO, CREATEDTO>,
    entityApiService: IEntityApiService,
    modelApiService: IModelApiService,
    subEntitiesApiServices: DIContainer.SubEntitiesApiServices,
    modelApiRootPath: string,
    serverEventsService: IServerEventsService,
    storageStateGetter: () => RootState,
    storageDispatch: AppDispatch
  ) {
    this.modelApiService = modelApiService;
    this.modelApiRootPath = modelApiRootPath;
    this.entityApiService = entityApiService;
    this.modelMapper = modelMapper;
    this.modelStorageActions = modelStorageData.actions;
    this.modelStorageName = modelStorageData.name;
    this.subEntitiesApiServices = subEntitiesApiServices;
    this.serverEventsService = serverEventsService;
    this.getStorageState = storageStateGetter;
    this.storageDispatch = storageDispatch;

    // Биндим эти методы, т.к. через стрелочную функцию их не сделать, т.к. они где-то вызываются через super (стрелочная так не может)
    this.getList = this.getList.bind(this);
    this.concatListPageBackground = this.concatListPageBackground.bind(this);
    this.getAllForFilter = this.getAllForFilter.bind(this);
    this.setAllForFilter = this.setAllForFilter.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.getModelApiRootPath = this.getModelApiRootPath.bind(this);
    this.getDefaultSortFilter = this.getDefaultSortFilter.bind(this);
  }

  // TODO [getById auto id concatenation remove]
  public getById = async (id: string, customPath?: string, dto?: Record<string, any>): Promise<void> => {
    this.storageDispatch(this.modelStorageActions.startLoading());
    await reduxBatch(async () => {
      await this.getByIdBackground(id, customPath, dto);
      if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.stopLoading());
    });
  };

  public getByIdBackground = async (id: string, customPath?: string, dto?: Record<string, any>): Promise<void> => {
    try {
      const response = await this.requestById(id, customPath || this.getModelApiRootPath(dto), dto);
      if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.setOne(response));
      else console.warn(`Get by id background request ${this.modelStorageName} is outdated`);
    } catch (error) {
      if (!this.areActionsOutdated) {
        console.error(error);
        this.storageDispatch(this.modelStorageActions.setError(error));
      }
    }
  };

  /** @throws `BackendResponseError` */
  public requestById = (id: string, customPath?: string, dto?: Record<string, any>): Promise<MODEL> => {
    return this.modelApiService.getById(customPath || this.getModelApiRootPath(dto), id, this.modelMapper, dto);
  };

  public async getList(dto?: Record<string, any> | null, filter?: LocationSearchObject, customPath?: string): Promise<void> {
    this.storageDispatch(this.modelStorageActions.startLoading());
    await reduxBatch(async () => {
      await this.getListBackground(dto, filter, customPath);
      if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.stopLoading());
    });
  }

  public async getListBackground(dto?: Record<string, any> | null, filter?: LocationSearchObject, customPath?: string): Promise<void> {
    try {
      const response = await this.requestList(dto, filter, customPath || this.getModelApiRootPath(dto));
      if (!this.areActionsOutdated) this.storageDispatch(this.modelStorageActions.setList(response));
      else console.warn(`Get list request ${this.modelStorageName} is outdated`);
    } catch (error) {
      if (!this.areActionsOutdated) {
        console.error(error);
        this.storageDispatch(this.modelStorageActions.setError(error));
      }
    }
  }

  public async concatListPageBackground(
    pageNumber: number,
    dto?: Record<string, any> | null,
    filter?: LocationSearchObject,
    customPath?: string
  ) {
    try {
      const { listOfEntities, lastListRequestFilter } = this.getStorageCurrentState();
      // TODO пока фильтр из вне в приоритете над внутренним, чтобы можно было работать со спец фильтрами у заказа. Потом надо обработку спецфильтров поместить сюда внутрь actionsService
      if (!filter && lastListRequestFilter?.frontendSearchParams) {
        filter = NavigateFrontendUtils.getLocationSearchObjectFromQueryStr(lastListRequestFilter.frontendSearchParams);
      }
      filter = { ...filter, page: NavigateFrontendUtils.createLocationSearchParam(pageNumber) };
      const response = await this.requestList(dto, filter, customPath || this.getModelApiRootPath(dto));

      if (!this.areActionsOutdated) {
        response.data = EntityUtils.filterDuplicates(listOfEntities, response.data); // За то время, что мы были на странице 1, могли быть добавлены материалы и новая страница 2 будет содержать записи из старой страницы 1
        this.storageDispatch(this.modelStorageActions.setList(response));
      } else {
        console.warn(`Get list background request ${this.modelStorageName} is outdated`);
      }
    } catch (error) {
      if (!this.areActionsOutdated) {
        console.error(error);
        this.storageDispatch(this.modelStorageActions.setError(error));
      }
    }
  }

  /** @throws `BackendResponseError` */
  public async getAllForFilter(dto?: Record<string, any> | null, filter?: LocationSearchObject, customPath?: string) {
    try {
      this.storageDispatch(this.modelStorageActions.startAllLoading());
      const response = await this.requestAll(dto, filter, customPath || this.getModelApiRootPath(dto));

      reduxBatch(() => {
        if (!this.areActionsOutdated) {
          this.storageDispatch(this.modelStorageActions.setAll(response));
          this.storageDispatch(this.modelStorageActions.stopAllLoading());
        } else {
          console.warn(`Get all for filter request ${this.modelStorageName} is outdated`);
        }
      });
    } catch (error: any) {
      reduxBatch(() => {
        if (!this.areActionsOutdated) {
          this.storageDispatch(this.modelStorageActions.stopAllLoading());
          console.error(error);
          throw new Error(error.message);
        }
      });
    }
  }

  /** @throws `BackendResponseError` */
  public requestList = (
    dto?: Record<string, any> | null,
    filter?: LocationSearchObject,
    customPath?: string
  ): Promise<EntityListData<MODEL>> => {
    filter = this.getDefaultSortFilter(filter);
    return this.modelApiService.getList(customPath || this.getModelApiRootPath(dto), this.modelMapper, dto, filter);
  };

  /** @throws `BackendResponseError` */
  public requestAll = (
    dto?: Record<string, any> | null,
    filter?: LocationSearchObject,
    customPath?: string
  ): Promise<EntityListData<MODEL>> => {
    filter = this.getDefaultSortFilter(filter);
    return this.modelApiService.getAllForFilter(customPath || this.getModelApiRootPath(dto), this.modelMapper, dto, filter);
  };

  public requestByIds = async (ids: string[], customPath?: string, dto?: Record<string, any>): Promise<EntityListData<MODEL>> => {
    const url = customPath || this.getModelApiRootPath(dto) + '/' + PATH_BACKEND_PART.common.ids;
    const response = ids.length ? await this.modelApiService.getByIds(url, ids, this.modelMapper) : { data: [] };
    return response;
  };

  /** @throws `BackendResponseError` */
  public async getByIdsForFilter(ids: string[], customPath?: string, forceReload?: boolean, dto?: Record<string, any>): Promise<void> {
    try {
      this.storageDispatch(this.modelStorageActions.startAllLoading());
      const { allEntities, areAllOutdated } = this.getStorageCurrentState();
      let response: EntityListData<MODEL>;

      if (forceReload || areAllOutdated) {
        response = await this.requestByIds(ids, customPath, dto);
      } else {
        // Небольшая оптимизация, действительно ли она тут так необходима пока не очень ясно. Если у нас уже получены сущности и они не устарели, то ищем сначала в них искомые id
        const existedModels: MODEL[] = [];
        const restIds: string[] = [];
        ids.forEach((id) => {
          const model = allEntities.find((m) => m.id === id);
          if (model) existedModels.push(model);
          else restIds.push(id);
        });

        response = await this.requestByIds(restIds, customPath, dto);
        response.data.push(...existedModels);
      }

      reduxBatch(() => {
        if (!this.areActionsOutdated) {
          this.storageDispatch(this.modelStorageActions.setAll(response));
          this.storageDispatch(this.modelStorageActions.stopAllLoading());
        } else {
          console.warn(`Get by ids for filter request ${this.modelStorageName} is outdated`);
        }
      });
    } catch (error: any) {
      reduxBatch(() => {
        if (!this.areActionsOutdated) {
          this.storageDispatch(this.modelStorageActions.stopAllLoading());
          console.error(error);
          throw new Error(error.message);
        }
      });
    }
  }

  public getByIdAndList = async (
    id: string,
    dto?: Record<string, any> | null,
    filter?: LocationSearchObject,
    customOnePath?: string,
    customListPath?: string
  ) => {
    this.storageDispatch(this.modelStorageActions.startLoading());
    await reduxBatch(async () => {
      try {
        const [listResponse, one] = await Promise.all([this.requestList(dto, filter, customListPath), this.requestById(id, customOnePath)]);
        if (!this.areActionsOutdated) {
          this.storageDispatch(this.modelStorageActions.setList(listResponse));
          this.storageDispatch(this.modelStorageActions.setOne(one));
          this.storageDispatch(this.modelStorageActions.stopLoading());
        } else {
          console.warn(`GetByIdAndList request ${this.modelStorageName} is outdated`);
        }
      } catch (error) {
        if (!this.areActionsOutdated) {
          console.error(error);
          this.storageDispatch(this.modelStorageActions.setError(error));
        }
      }
    });
  };

  // Form actions ---------------------------------------------------------------

  /** @throws `BackendResponseError` */
  public async create(dto: CREATEDTO, customPath?: string): Promise<string> {
    const id = await this.modelApiService.create(customPath || this.getModelApiRootPath(dto), dto);
    this.storageDispatch(this.modelStorageActions.setOneIsOutdated());
    return id;
  }

  /** @throws `BackendResponseError` */
  public async update(dto: Partial<CREATEDTO> & { id: string }, customPath?: string): Promise<void> {
    await this.modelApiService.update(customPath || this.getModelApiRootPath(dto), dto);
    this.storageDispatch(this.modelStorageActions.setOneIsOutdated());
  }

  /** @throws `BackendResponseError` */
  public async patch(dto: Partial<Record<string, any>> & { id: string }, customPath?: string): Promise<void> {
    await this.modelApiService.patch(customPath || this.getModelApiRootPath(dto), dto);
    this.storageDispatch(this.modelStorageActions.setOneIsOutdated());
  }

  /** @throws `BackendResponseError` */
  public delete = async (id: string, customPath?: string, dto?: Record<string, any>): Promise<void> => {
    await this.modelApiService.delete(customPath || this.getModelApiRootPath(dto), id);
    this.storageDispatch(this.modelStorageActions.setListIsOutdated());
  };

  // Artificial actions ---------------------------------------------------------------

  public setOne = (data: MODEL | null) => {
    this.storageDispatch(this.modelStorageActions.setOne(data));
  };

  public setList = (dto: MODEL[], pagination?: Pagination, requestProps?: EntityListData<MODEL>['requestProps']) => {
    this.storageDispatch(this.modelStorageActions.setList({ data: dto, pagination, requestProps }));
  };

  public setAllForFilter(data: MODEL[]): void {
    this.storageDispatch(this.modelStorageActions.setAll({ data: data }));
  }

  public setOneOutdated: VoidFunction = () => this.storageDispatch(this.modelStorageActions.setOneIsOutdated());
  public setListOutdated: VoidFunction = () => this.storageDispatch(this.modelStorageActions.setListIsOutdated());
  public setAllOutdated: VoidFunction = () => this.storageDispatch(this.modelStorageActions.setAllAreOutdated());
  public resetData: VoidFunction = () => this.storageDispatch(this.modelStorageActions.resetState());

  // Utils ----------------------------------------------------------------------------------------------

  /**
   * Если у запроса списка нет сортировки, то пытаемся назначить сортировку по имени, если такое поле есть.
   * @param sortFieldName чтобы можно было легко переопределить данный метод; по умолчанию используется поле `name`.
   * @returns метод либо создаёт/дописывает параметр сортировки в фильтр, либо отдаёт обратно что передали
   */
  protected getDefaultSortFilter<T extends LocationSearchObject>(
    filter?: T,
    sortFieldName: keyof MODEL = 'name' as keyof MODEL,
    sortOrder: NavigateFrontendUtils.SORT_ORDER = NavigateFrontendUtils.SORT_ORDER.asc
  ): T {
    // Если уже есть порядок, то возвращаем как есть
    if (filter && filter.sortedBy) {
      return filter;
    }

    // Если фильтра нет, то создаём
    if (!filter) {
      filter = {} as T;
    }

    // Дописываем в существующий данные о сортировке
    filter = {
      ...filter,
      sortOrder: NavigateFrontendUtils.createLocationSearchParam(sortOrder),
      sortedBy: NavigateFrontendUtils.createLocationSearchParam(sortFieldName as string), // Тут мы вписываем во фронтовый объект фронтовое название поля
    };
    return filter;
  }

  public isOneOutdatedOrChanged = (paramId: string) => {
    const { isOneOutdated, oneEntity } = this.getStorageCurrentState();

    if (isOneOutdated && paramId === oneEntity?.id) return true;
    if (paramId !== oneEntity?.id) return true;
    return false;
  };

  public cancelRequests = () => {
    this.areActionsOutdated = true;
  };
}
