import { Navigate } from 'react-router';
import PATH_PARAM, { PATH_PARAM_DASHBOARD } from 'services/routing/PATH_PARAM';
import { useSelector } from 'storage';
import { TENANT_TYPE } from 'typings/subEntities/tenant.enum';

export default function CanReadOrderRedirectPage() {
  const { currentUser } = useSelector((state) => state.auth);

  if (currentUser?.type !== TENANT_TYPE.superadmin) {
    return <Navigate to={PATH_PARAM_DASHBOARD.order.root} replace />;
  } else {
    return <Navigate to={PATH_PARAM.dashboard.me.root} replace />;
  }
}
