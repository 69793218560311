import { lazy } from 'react';
import { LoadableForm } from './AsyncLoaders';
import { UploadAvatarProps } from './upload/UploadAvatar';
import { UploadMultiFileProps } from './upload/UploadMultiFile';
import { UploadSingleFileProps } from './upload/UploadSingleFile';

// Вынесены в отдельный файл бандла для оптимизации
// Использовать их, а не напрямую

export const UploadAvatar = LoadableForm<UploadAvatarProps>(
  lazy(() => import(/* webpackChunkName: "uploadAvatar" */ './upload/UploadAvatar'))
);

export const UploadMultiFile = LoadableForm<UploadMultiFileProps>(
  lazy(() => import(/* webpackChunkName: "uploadMultiFile" */ './upload/UploadMultiFile'))
);

export const UploadSingleFile = LoadableForm<UploadSingleFileProps>(
  lazy(() => import(/* webpackChunkName: "uploadSingleFile" */ './upload/UploadSingleFile'))
);
