/** Аналог сета, только с возможность указать как будет проходить сравнение */
export default class SetCustom<T extends object> implements Set<T | string> {
  private storageMap: Map<T | string, T>;
  private readonly keyGetter: (v: T) => string | T;

  get size(): number {
    return this.storageMap.size;
  }

  get [Symbol.toStringTag]() {
    return Array.from(this.values()).join(', ');
  }

  constructor(initialData?: T[], keyGetter: (v: T) => string | T = (v) => v) {
    this.keyGetter = keyGetter;
    this.storageMap = new Map();
    if (initialData) {
      initialData.forEach((item) => this.storageMap.set(this.keyGetter(item), item));
    }
  }

  // Implementation

  [Symbol.iterator] = (): IterableIterator<T> => {
    return this.values();
  };

  public add = (value: T): this => {
    this.storageMap.set(this.keyGetter(value), value);
    return this;
  };

  public clear = (): void => {
    this.storageMap = new Map();
  };

  public delete = (value: T | string): boolean => {
    return this.storageMap.delete(typeof value === 'string' ? value : this.keyGetter(value));
  };

  public forEach = (callbackfn: (value: T, value2: T, set: Set<T>) => void, thisArg?: any): void => {
    return this.storageMap.forEach(callbackfn as any, thisArg);
  };

  public has = (value: T | string): boolean => {
    return this.storageMap.has(typeof value === 'string' ? value : this.keyGetter(value));
  };

  public entries = (): IterableIterator<[T, T]> => {
    return this.storageMap.entries() as IterableIterator<[T, T]>;
  };

  public keys = (): IterableIterator<T> => {
    return this.storageMap.values();
  };

  public values = (): IterableIterator<T> => {
    return this.storageMap.values();
  };

  // New

  public customKeys = (): IterableIterator<T | string> => {
    return this.storageMap.keys();
  };

  public getByKey = (value: string | T): T | undefined => {
    return this.storageMap.get(value);
  };
}
