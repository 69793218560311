import MODEL_NAME from 'typings/models/_model.enum';
import defaultState from 'storage/defaultState';
import SliceFactory from './SliceFactory';
import TaskUtils from 'utils/models/TaskUtils';

const initialState: TaskState = {
  ...defaultState,
  activeTasks: [],
  closedTasks: [],
  outdatedTasks: [],
  scheduledTasks: [],
  task: null,
  taskAll: [],
  taskAllAreLoading: false,
  taskIsLoading: false,
  taskList: [],
};

const reducers = SliceFactory.createModelReducers<MODEL_NAME.task, Task, TaskState>(MODEL_NAME.task, initialState);

reducers.setList = function (state, action) {
  const { data, pagination, requestProps } = action.payload;
  state.isListOutdated = false;
  state.error = null;
  state.pagination = pagination;
  state.lastListRequestFilter = requestProps?.lastListRequestFilter;
  state.listOfEntities = data;
  state.taskList = data;

  const { activeTasks, scheduledTasks, closedTasks, outdatedTasks } = TaskUtils.getTaskListsByState(data);
  state.activeTasks = activeTasks;
  state.scheduledTasks = scheduledTasks;
  state.closedTasks = closedTasks;
  state.outdatedTasks = outdatedTasks;
};

const taskSlice = SliceFactory.createModelSlice<MODEL_NAME.task, Task, TaskState>(MODEL_NAME.task, initialState, reducers);

export default taskSlice;
