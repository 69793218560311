import { UserPositionDTO } from 'typings/dto/user';
import { USER_POSITION } from 'typings/models/user.enum';

export default class PositionMapper {
  public responseDTOToModel = (dto: UserPositionDTO): User.Position => {
    return {
      id: dto.id,
      name: dto.name,
      technicalName: dto.technicalName as USER_POSITION,
      tenant: dto.tenant,
      type: dto.type,
    };
  };

  public modelToRequestDTO = (model: User.Position): USER_POSITION => {
    return model.technicalName;
  };
}
