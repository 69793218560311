import MODEL_NAME from 'typings/models/_model.enum';
import defaultState from 'storage/defaultState';
import SliceFactory from './SliceFactory';

const initialState: UserNotificationState = {
  ...defaultState,

  notification: null,
  notificationAll: [],
  notificationAllAreLoading: false,
  notificationIsLoading: false,
  notificationList: [],
  unreadNotificationList: [],
};

const reducers = SliceFactory.createModelReducers<MODEL_NAME.notification, UserNotification, UserNotificationState>(
  MODEL_NAME.notification,
  initialState
);

reducers.setList = function (state, action) {
  const { data, pagination, requestProps } = action.payload;
  state.isListOutdated = false;
  state.error = null;
  state.pagination = pagination;
  state.lastListRequestFilter = requestProps?.lastListRequestFilter;

  state.listOfEntities = data;
  state.notificationList = data;
  // Отличие от дефолтного метода здесь
  state.unreadNotificationList = data.filter((n) => n.isUnread);
};

const notificationSlice = SliceFactory.createModelSlice<MODEL_NAME.notification, UserNotification, UserNotificationState>(
  MODEL_NAME.notification,
  initialState,
  reducers
);

export default notificationSlice;
