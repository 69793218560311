import { Container, Alert, AlertTitle } from '@mui/material';
import useDI from 'hooks/useDI';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import UnauthorizedError from 'utils/errors/UnauthorizedError';

interface IProps {
  error: { message: string };
}

export default function ErrorComponent({ error }: IProps) {
  const { services, storageActions } = useDI();
  const { translate } = services.language;
  const navigate = useNavigate();

  console.error(error);

  useEffect(() => {
    // If 401, always redirects to login page (logout user manualy and re request current page to trigger auth guard)
    // Same logic in useSnackbarErrorHandler
    if (error instanceof UnauthorizedError) {
      storageActions.auth.setUser(null, []);
      navigate(window.location.pathname + window.location.search, { replace: true });
    }
  }, []);

  return (
    <Container>
      <Alert severity="error" sx={{ wordBreak: 'break-all' }}>
        <AlertTitle>{translate('errors.errorOccurred')}</AlertTitle>
        {error.message || JSON.stringify(error)}
      </Alert>
    </Container>
  );
}
