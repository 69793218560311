import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'storage';
import { RoutingServiceDashboard } from '../../../services/routing/RoutingService';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children?: ReactNode;
  onSuccess?: ReactNode;
};

export default function GuestGuard({ children, onSuccess }: GuestGuardProps) {
  const { isAuthenticated } = useSelector((state) => state.auth);

  if (isAuthenticated) {
    return <Navigate to={RoutingServiceDashboard.root} />;
  }

  return (
    <>
      {children}
      {onSuccess}
    </>
  );
}
