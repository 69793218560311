import { TIME_ZONE } from 'typings/common/date.enum';
import { DateIntervalDTO } from 'typings/dto/subEntity/dateInterval';
import DateUtils from 'utils/Date';
import DateWithTimezone from 'utils/implementations/DateWithTimezone';
import StringUtils from 'utils/String';

export default class DateIntervalMapper {
  constructor(private dateUtils: DateUtils) {
    this.responseDTOToModel = this.responseDTOToModel.bind(this);
  }

  public modelToRequestDTO = (interval: DateInterval | DateIntervalWithTimezone): DateIntervalDTO => {
    return {
      id: interval.id,
      from: interval.from.toISOString(),
      to: interval.to.toISOString(),
    };
  };

  public responseDTOToModel(dto: DateIntervalDTO): DateInterval;
  public responseDTOToModel(dto: DateIntervalDTO, timezone: TIME_ZONE): DateIntervalWithTimezone;
  public responseDTOToModel(dto: DateIntervalDTO, timezone?: TIME_ZONE): DateInterval | DateIntervalWithTimezone {
    if (timezone) {
      return {
        id: dto.id || StringUtils.generateUUIDv4(), // Пока непонятно, всегда ли будет приходить id
        from: new DateWithTimezone(dto.from, timezone, this.dateUtils),
        to: new DateWithTimezone(dto.to, timezone, this.dateUtils),
      };
    }

    return {
      id: dto.id || StringUtils.generateUUIDv4(), // Пока непонятно, всегда ли будет приходить id
      from: new Date(dto.from),
      to: new Date(dto.to),
    };
  }
}
