import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TextFieldMemorized from './TextFieldMemorized';
import { TextFieldMemorizedProps } from './TextFieldMemorized';

export default function SearchFieldMemorized({ sx, ...rest }: TextFieldMemorizedProps) {
  return (
    <TextFieldMemorized
      {...rest}
      sx={{ ...sx }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
